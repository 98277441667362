import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';

import { startOfYear, subYears } from 'date-fns';

import { BehaviorSubject, combineLatest, Observable, of, startWith, Subject, switchMap, tap } from 'rxjs';
import { filter, pairwise, shareReplay, takeUntil } from 'rxjs/operators';

import { debounceTime } from '@enerkey/rxjs';
import { formControlsFrom } from '@enerkey/ts-utils';
import { ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';

import { Comparability } from '../../../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { ValueType } from '../../../../../shared/ek-inputs/value-type-select/value-type-select.component';

import { EtCurveModelConverter } from '../../../../analytics/utils/et-curve-model-converter.util';
import { ReportingModalsService } from '../../../../reporting/services/reporting-modals.service';
import { ReportingSearchParams } from '../../../../reporting/shared/reporting-search-params';
import { WidgetBase } from '../../../shared/widget-base.interface';
import { EtCurveChartComponent } from '../../../../analytics/components/et-curve-components/et-curve-chart/et-curve-chart.component';
import { EtCurveBase } from '../../../../analytics/models/et-curve-base.model';
import { EtCurveAdminParams, ETCurveSearchParams } from '../../../../analytics/models/et-curve.model';
import { EtCurveService } from '../../../../analytics/services/et-curve/et-curve.service';
import { FacilityEtCurveWidgetOptions } from '../facility-et-curve-widget-options/facility-et-curve-widget-options.component';

@Component({
  selector: 'facility-et-curve-widget',
  templateUrl: './facility-et-curve-widget.component.html',
  styleUrls: ['./facility-et-curve-widget.component.scss']
})
export class FacilityEtCurveWidgetComponent extends EtCurveBase<ETCurveSearchParams>
  implements WidgetBase<FacilityEtCurveWidgetOptions>, OnInit, OnDestroy {

  public dataModelOptions: FacilityEtCurveWidgetOptions;
  public readonly dataModelChange$: Observable<FacilityEtCurveWidgetOptions>;
  public readonly error$: Observable<void>;
  public readonly chartLoaded$: Observable<boolean>;
  public facilityChange$: Observable<number>;
  public quantities$: Observable<number[] | null>;

  private readonly _error$ = new Subject<void>();
  private readonly _dataModelChange$ = new Subject<FacilityEtCurveWidgetOptions>();
  private readonly _chartLoaded$ = new BehaviorSubject<boolean>(false);
  private readonly _quantities = new BehaviorSubject<number[] | null>(null);

  @ViewChild(EtCurveChartComponent) private readonly etCurveChart: EtCurveChartComponent;

  public constructor(
    injector: Injector,
    etCurveService: EtCurveService,
    private readonly reportingModalsService: ReportingModalsService
  ) {
    super(
      injector,
      formControlsFrom<ETCurveSearchParams>(
        etCurveService.defaultFormState(),
        {
          facilityId: Validators.required,
          quantity: Validators.required,
          period: Validators.required,
          resolution: Validators.required,
          startDate: Validators.required,
        }
      )
    );

    this.error$ = this._error$.asObservable();
    this.dataModelChange$ = this._dataModelChange$.asObservable();
    this.chartLoaded$ = this._chartLoaded$.asObservable();
    this.quantities$ = this._quantities.asObservable();
  }

  public ngOnInit(): void {
    this._loading.next(true);

    this.activeEtCurve$.pipe(
      filter(() => Number.isFinite(this.controls.quantity.value)),
      debounceTime(50),
      tap(etCurve => {
        if (etCurve === null || etCurve === undefined) {
          this._loading.next(false);
          return;
        }

        this._chartLoaded$.next(false);

        this.etCurveChart.update(
          this.formGroup.getRawValue(),
          EtCurveModelConverter.etCurveAdminParamsToEtCurveParametersCons(etCurve as EtCurveAdminParams)
        );
      }),
      takeUntil(this._destroy)
    ).subscribe();

    this.facilityChange$ = this.formGroup.controls.facilityId.valueChanges.pipe(
      startWith(null),
      pairwise(),
      filter(([prevFacilityId, facilityId]) => Number.isFinite(facilityId) && prevFacilityId !== facilityId),
      switchMap(([prevFacilityId, facilityId]) => {
        if (Number.isFinite(prevFacilityId)) {
          this.controls.quantity.setValue(null);
          this._quantities.next(null);
        }
        return of(facilityId);
      }),
      shareReplay(1),
      takeUntil(this._destroy)
    );

    combineLatest([
      this.facilityChange$.pipe(startWith(null)),
      this.formGroup.controls.quantity.valueChanges.pipe(startWith(null))
    ]).pipe(
      filter(([facilityId, quantityId]) =>
        Number.isFinite(facilityId) && Number.isFinite(quantityId) &&
        (facilityId !== this.dataModelOptions.facilityId || quantityId !== this.dataModelOptions.quantity)),
      tap(([changedFacilityId, changedQuantityId]) =>
        this._dataModelChange$.next({ facilityId: changedFacilityId, quantity: changedQuantityId })),
      takeUntil(this._destroy)
    ).subscribe();

    this.facilityEtCurveQuantities$.pipe(
      tap(quantities => this._quantities.next(quantities)),
      shareReplay(1),
      takeUntil(this._destroy)
    ).subscribe();

    const { facilityId, quantity } = this.dataModelOptions;
    this.formGroup.patchValue({ facilityId, quantity });
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._error$.complete();
    this._dataModelChange$.complete();
    this._chartLoaded$.complete();
    this._quantities.complete();
  }

  public onChartLoaded(): void {
    this._chartLoaded$.next(true);
    this._loading.next(false);
  }

  public onFacilityButtonClick(): void {
    const facilityId = this.formGroup.controls.facilityId.value;
    const quantityId = this.formGroup.controls.quantity.value;

    if (!Number.isFinite(facilityId)) { throw Error('facilityId is not set'); }
    this.openFacilityModal(facilityId, quantityId);
  }

  private openFacilityModal(facilityId: number, quantityId: number): void {
    const searchParams: Partial<ReportingSearchParams> = {
      formValue: {
        quantityIds: Number.isFinite(quantityId) ? [quantityId] : [],
        change: { relative: false, absolute: false },
        comparability: Comparability.All,
        costIds: [],
        distributionAsPercent: false,
        distributionType: 0,
        durationLength: 1,
        durationName: 'years',
        emissionIds: [],
        minMaxAvg: { min: false, max: false, average: false },
        periods: [startOfYear(new Date()), startOfYear(subYears(new Date(), 1))],
        reportingUnit: ReportingUnit.Default,
        resolution: RequestResolution.P1M,
        showConsumption: true,
        showSummedConsumption: true,
        specificIds: [],
        targetTypes: [],
        relatedQuantities: [],
        temperature: false,
        valueType: ValueType.Measured
      }
    };

    this.reportingModalsService.openReport(facilityId, searchParams as ReportingSearchParams);
  }
}
