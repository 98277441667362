<modal-view-header headingText="{{ 'ADMIN.CONTRACTS.TERMINATE_AND_CREATE' | translate }}">
</modal-view-header>
<div class="modal-body">
  <div class="wizard-wrapper">
    <wizard
      [steps]="wizardSteps"
      [(step)]="activeStep"
      (stepChange)="stepChange($event)"
    >
    </wizard>
  </div>
  <form
    #endForm="ngForm"
    [formGroup]="formGroup"
  >
    <input
      formControlName="terminateContractIds"
      type="hidden"
    >

    <div [ngSwitch]="activeStep.id">

      <div *ngSwitchCase="ContractTerminateStep.TerminateDate">
        <kendo-datepicker
          class="terminate-date"
          formControlName="terminateDate"
          [min]="minTerminateDate"
        ></kendo-datepicker>
      </div>

      <div *ngSwitchCase="ContractTerminateStep.SelectContracts">
        <table class="table striped">
          <thead>
            <th>
              <input
                type="checkbox"
                kendoCheckBox
                [indeterminate]="allContractsChecked === undefined"
                [(ngModel)]="allContractsChecked"
                [ngModelOptions]="{ standalone: true }"
              >
            </th>
            <th>{{ 'ADMIN.FACILITYID' | translate }}</th>
            <th>{{ 'ADMIN.FACILITY_NAME' | translate }}</th>
            <th>{{ 'ADMIN.CONTRACTS.CONTRACT_ID' | translate }}</th>
            <th>{{ 'ADMIN.CONTRACTS.PRODUCT_ID' | translate }}</th>
            <th>{{ 'ADMIN.CONTRACTS.UNIT_COUNT' | translate }}</th>
            <th>{{ 'ADMIN.CONTRACTS.UNIT_PRICE' | translate }}</th>
            <th>{{ 'ADMIN.CONTRACTS.FROM_DATE' | translate }}</th>
            <th>{{ 'ADMIN.CONTRACTS.TO_DATE' | translate }}</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let contract of previewContracts">
              <tr [class.selected]="checkedContracts[contract.id]">
                <td class="checkbox-column">
                  <input
                    type="checkbox"
                    kendoCheckBox
                    [(ngModel)]="checkedContracts[contract.id]"
                    [ngModelOptions]="{ standalone: true }"
                  >
                </td>
                <td>{{ contract.facilityId }}</td>
                <td>{{ previewFacilities[contract.facilityId] }}</td>
                <td>{{ contract.contractId }}</td>
                <td>{{ contract.productId }}</td>
                <td>{{ contract.unitCount | number }}</td>
                <td>{{ contract.unitPrice | number:'1.2-2' }}</td>
                <td>{{ contract.fromDate ? (contract.fromDate | todate | date) : '' }}</td>
                <td>{{ terminateDate | date }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div *ngSwitchCase="ContractTerminateStep.RecreateContracts">
        <ng-template #noContracts>
          <div>
            {{ 'ADMIN.CONTRACTS.NO_RECREATED_CONTRACTS' | translate }}
          </div>
        </ng-template>

        <div
          *ngIf="formArray.length > 0; else noContracts"
          class="grid-wrapper"
        >
          <div><label>{{ 'ADMIN.FACILITYID' | translate }}</label></div>
          <div><label>{{ 'ADMIN.FACILITY_NAME' | translate }}</label></div>
          <div>
            <contract-editable-label (open)="rowEdit('contractId')">
              {{ 'ADMIN.CONTRACTS.CONTRACT_ID' | translate }}
            </contract-editable-label>
          </div>
          <div>
            <contract-editable-label (open)="rowEdit('productId')">
              {{ 'ADMIN.CONTRACTS.PRODUCT_ID' | translate }}
            </contract-editable-label>
          </div>
          <div>
            <contract-editable-label (open)="rowEdit('unitCount')">
              {{ 'ADMIN.CONTRACTS.UNIT_COUNT' | translate }}
            </contract-editable-label>
          </div>
          <div>
            <contract-editable-label (open)="rowEdit('unitPrice')">
              {{ 'ADMIN.CONTRACTS.UNIT_PRICE' | translate }}
            </contract-editable-label>
          </div>
          <div><label>{{ 'ADMIN.CONTRACTS.FROM_DATE' | translate }}</label></div>

          <ng-container
            *ngFor="let contract of formArray.controls; let idx = index"
            formArrayName="newContracts"
          >
            <ng-container [formGroupName]="idx">
              <input
                type="hidden"
                formControlName="id"
              >

              <div>
                <input
                  type="text"
                  formControlName="facilityId"
                  readonly
                >
              </div>
              <div>
                <input
                  type="text"
                  formControlName="facilityName"
                  readonly
                >
              </div>
              <div>
                <input
                  type="text"
                  formControlName="contractId"
                >
              </div>
              <div>
                <product-input formControlName="productId"></product-input>
              </div>
              <div>
                <kendo-numerictextbox
                  formControlName="unitCount"
                  [decimals]="0"
                  [format]="'0'"
                  [step]="numberInputOpts.step"
                  [autoCorrect]="numberInputOpts.autoCorrect"
                  [spinners]="numberInputOpts.spinners"
                ></kendo-numerictextbox>
              </div>
              <div>
                <kendo-numerictextbox
                  formControlName="unitPrice"
                  [decimals]="numberInputOpts.decimals"
                  [format]="numberInputOpts.format"
                  [step]="numberInputOpts.step"
                  [autoCorrect]="numberInputOpts.autoCorrect"
                  [spinners]="numberInputOpts.spinners"
                ></kendo-numerictextbox>
              </div>
              <div>
                <kendo-datepicker
                  [readonly]="true"
                  [value]="newFromDate"
                ></kendo-datepicker>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div
        *ngSwitchCase="ContractTerminateStep.SavingChanges"
        [loadingSpinner]="true"
      >
      </div>

      <div *ngSwitchCase="ContractTerminateStep.Done">
        <p>
          {{ 'DONE' | translate }}.
        </p>
        <div>
          <button
            class="button button--primary"
            type="button"
            (click)="close()"
          >
            {{ 'MODALS.CLOSE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
