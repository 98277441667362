import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { asyncScheduler, Observable, scheduled } from 'rxjs';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { ProductService } from '../../services/product.service';

@Component({
  selector: 'product-name-modal',
  templateUrl: './product-name-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductNameModalComponent extends ModalBase implements OnDestroy {

  public readonly productNames$: Observable<{ id: string; name: string }[]>;
  public readonly loading$: Observable<boolean>;

  private readonly _loading = new LoadingSubject();

  public constructor(
    activeModal: NgfActiveModal,
    productService: ProductService
  ) {
    super(activeModal);
    this.loading$ = this._loading.asObservable();
    this.productNames$ = scheduled(productService.getIdNamePairs(), asyncScheduler).pipe(indicate(this._loading));
  }

  public ngOnDestroy(): void {
    this._loading.complete();
  }

  public close(): void {
    super.closeModal();
  }
}
