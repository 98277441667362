import { ConsumptionElementResponse } from '@enerkey/clients/reporting';

export const valuesPropertyKey = 'values';
export const normalizedValuesPropertyKey = 'normalizedValues';

export const tableReportValueKeys = [valuesPropertyKey, normalizedValuesPropertyKey] as const;
export type TableReportValueKey = typeof tableReportValueKeys[number];

export const absoluteChangeKey: keyof Pick<ConsumptionElementResponse, 'absoluteChange'> = 'absoluteChange' as const;
export const relativeChangeKey: keyof Pick<ConsumptionElementResponse, 'relativeChange'> = 'relativeChange' as const;
export const valueKey: keyof ConsumptionElementResponse = 'value';

export const nonAggregableKeys: Set<string> = new Set([
  'FacilityInformation.BusinessIdentityCode',
  'FacilityInformation.ConstructionYear',
  'FacilityInformation.DecadeOfBuild',
  'FacilityInformation.RenovationYear',
]);

export const specialFormatingProperties: Record<string, string> = {
  BusinessIdentityCode: '#',
  ConstructionYear: '#',
  DecadeOfBuild: '#',
  RenovationYear: '#',
};
