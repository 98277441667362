import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { ReportingDistributionType, ReportingSearchFormValue } from '../shared/reporting-search-form-value';
import { ReportingSearchParams, ReportingSearchParamsError } from '../shared/reporting-search-params';
import { ReportingParams } from '../reporting.states';
import { getDefaultReportingParams } from './reporting.search.functions';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ReportingTimePeriodService } from './reporting-time-period.service';

@Injectable()
export class ReportingSearchService {
  public readonly searchParameters$: Observable<ReportingSearchParams>;
  public readonly visibleSections$: Observable<string[]>;
  public readonly chartVisibility$: Observable<boolean>;
  public readonly gridVisibility$: Observable<boolean>;
  public readonly meterInfoVisibility$: Observable<boolean>;
  public readonly facilityIds$: Observable<number[]>;

  private readonly _searchParameters$ = new ReplaySubject<ReportingSearchParams>(1);
  private readonly _visibleFacilityInfoSections$ = new BehaviorSubject<string[]>([]);
  private readonly _chartVisibility$ = new BehaviorSubject<boolean>(true);
  private readonly _gridVisibility$ = new BehaviorSubject<boolean>(true);
  private readonly _meterInfoVisibility$ = new BehaviorSubject<boolean>(true);
  private readonly _facilityIds$ = new BehaviorSubject<number[]>([]);

  public constructor(
    private readonly toasterService: ToasterService,
    private readonly timePeriodService: ReportingTimePeriodService
  ) {
    this.searchParameters$ = this._searchParameters$.asObservable();
    this.visibleSections$ = this._visibleFacilityInfoSections$.asObservable();
    this.chartVisibility$ = this._chartVisibility$.asObservable();
    this.gridVisibility$ = this._gridVisibility$.asObservable();
    this.meterInfoVisibility$ = this._meterInfoVisibility$.asObservable();
    this.facilityIds$ = this._facilityIds$.asObservable();
  }

  public setFacilities(facilityIds: number[]): void {
    this._facilityIds$.next(facilityIds);
  }

  public search(formValue: ReportingSearchFormValue): void {
    try {
      const params = new ReportingSearchParams(formValue);
      params.warnings.forEach(warn => {
        this.toasterService[warn.type](warn.message);
      });
      this._searchParameters$.next(params);
    } catch (err) {
      if (err instanceof ReportingSearchParamsError) {
        this.toasterService.error(err.paramErrorMessage);
      }
    }
  }

  public toggleSections(sections: string[]): void {
    this._visibleFacilityInfoSections$.next(sections);
  }

  public setChartsVisibility(isVisible: boolean): void {
    this._chartVisibility$.next(isVisible);
  }

  public setGridsVisibility(isVisible: boolean): void {
    this._gridVisibility$.next(isVisible);
  }

  public setMeterInfoVisibility(isVisible: boolean): void {
    this._meterInfoVisibility$.next(isVisible);
  }

  public paramsFromState(stateParams: ReportingParams): ReportingSearchFormValue {
    const defaultParams = this.getDefaultParams();

    return {
      quantityIds: stateParams.quantityIds ?? defaultParams.quantityIds,
      durationName: stateParams.durationName ?? defaultParams.durationName,
      durationLength: stateParams.durationLength ?? defaultParams.durationLength,
      periods: stateParams.periods
        ? stateParams.periods.map((p: string) => new Date(p))
        : defaultParams.periods,
      resolution: stateParams.resolution ?? defaultParams.resolution,
      change: stateParams.change ?? defaultParams.change,
      valueType: stateParams.valueType ?? defaultParams.valueType,
      showConsumption: stateParams.showConsumption ?? defaultParams.showConsumption,
      showSummedConsumption: stateParams.showSummedConsumption ?? defaultParams.showSummedConsumption,
      specificIds: stateParams.specificIds ?? defaultParams.specificIds,
      costIds: stateParams.costIds ?? defaultParams.costIds,
      emissionIds: stateParams.emissionIds ?? defaultParams.emissionIds,
      targetTypes: stateParams.targetTypes ?? defaultParams.targetTypes,
      relatedQuantities: stateParams.relatedQuantities ?? defaultParams.relatedQuantities,
      reportingUnit: stateParams.reportingUnit ?? defaultParams.reportingUnit,
      distributionType: stateParams.distributionType ?? ReportingDistributionType.None,
      distributionAsPercent: stateParams.distributionAsPercent ?? defaultParams.distributionAsPercent,
      temperature: stateParams.temperature ?? defaultParams.temperature,
      comparability: stateParams.comparability ?? defaultParams.comparability,
      minMaxAvg: stateParams.minMaxAvg ?? defaultParams.minMaxAvg,
    };
  }

  public getDefaultParams(): ReportingSearchFormValue {
    return {
      ...getDefaultReportingParams(),
      periods: this.timePeriodService.getYearPeriods(),
    };
  }
}
