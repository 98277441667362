<modal-view-header [headingText]="'BOOKMARK.BOOKMARKS' | translate"></modal-view-header>
<div
  class="modal-body"
  [loadingSpinner]="loading$ | async"
  [loadingOverlay]="true"
>
  <div *ngIf="(currentState$ | async) !== null" class="row newBookmark">
    <div class="large-6 columns">
      <label class="header">{{ 'BOOKMARK.ADD_BOOKMARK' | translate }}</label>
      <div class="newBookmarkContainer">
        <input
          type="text" class="newBookmarkInput no-border-on-pristine-invalid"
          [formControl]="bookmarkInputField"
        >
        <button
          class="button button--primary"
          type="button"
          [loading]="createLoading$ | async"
          (click)="createBookmark()"
          [disabled]="!bookmarkInputField.valid"
        >
          {{ 'ADD' | translate }}
        </button>
      </div>
      <div *ngIf="(variableBookmarkType$ | async) as type" class="checkboxContainer">
        <input
          *labelWrap="VariableBookmarkTranslation[type] | translate"
          [formControl]="dateAdjustableField"
          type="checkbox"
          kendoCheckBox
        >
      </div>
    </div>
    <ng-container *ngIf="(bookmarks$ | async)?.sticky as stickyBookmarks">
      <div *ngIf="stickyBookmarks.length" class="large-6 columns">
        <table>
          <tr>
            <th class="header">{{ 'BOOKMARK.PAGE_STICKY_BOOKMARKS' | translate }}</th>
          </tr>
          <tr *ngFor="let bookmark of stickyBookmarks">
            <td>
              <div class="container">
                <a
                  [title]="bookmark.title"
                  (click)="showBookmark(bookmark)"
                >
                  {{ bookmark.title }}
                </a>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </ng-container>
  </div>
  <div *ngFor="let row of bookmarksRows" class="row">
    <div *ngFor="let col of row" class="large-6 columns">
      <table>
        <tr>
          <th class="header">{{ col.header | translate }}</th>
        </tr>
        <ng-container *ngIf="bookmarks$ | async as bookmarks">
          <tr *ngFor="let bookmark of bookmarks.saved[col.bookmarksKey][col.partitionKey]">
            <td>
              <div class="container">
                <div
                  [style.background]="bookmark.rollingDate | bookmarkIndicatorColor"
                  class="inlineIndicator"
                ></div>
                <a
                  class="link"
                  [title]="bookmark | bookmarkTitle | async"
                  (click)="showBookmark(bookmark)"
                >
                  {{ bookmark.title }}
                </a>
              </div>
            </td>
            <td *ngIf="bookmark.createdByCurrentUser" class="actions">
              <ng-container *ngIf="bookmark.shared; else share">
                <button
                  class="button icon" type="button"
                  [title]="'STOP_SHARING' | translate"
                  [loading]="shareLoading$ | async"
                  (click)="unShareBookmark(bookmark)"
                >
                  <i class="fas fa-unlink"></i>
                </button>
              </ng-container>
              <ng-template #share>
                <button
                  class="button icon" type="button"
                  [title]="'SHARE' | translate"
                  [loading]="shareLoading$ | async"
                  (click)="shareBookmark(bookmark)"
                >
                  <i class="fa fa-share-alt"></i>
                </button>
              </ng-template>
              <button
                class="button icon" type="button"
                [title]="'DELETE' | translate"
                [loading]="removeLoading$ | async"
                (click)="removeBookmark(bookmark)"
              >
                <i class="fa fa-times"></i>
              </button>
            </td>
          </tr>
          <ng-container *ngIf="!bookmarks.saved[col.bookmarksKey][col.partitionKey].length">
            <tr>
              <td class="noBookmarks">{{ col.noBookmarkText | translate }}</td>
            </tr>
          </ng-container>
        </ng-container>
      </table>
      <hr>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      type="button"
      class="button button--primary"
      (click)="dismiss()"
    >
      {{ 'MODALS.CLOSE' | translate }}
    </button>
  </div>
</div>
