import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';
import { ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';

import { ColorService } from '../../../shared/services/color.service';
import { ConsumptionLike, ReportingSeries } from '../shared/reporting-series';
import { ReportingSeriesByFacility, ReportingSeriesCollection } from '../shared/reporting-series-collection';
import { getForecastSeriesValues } from './forecast-report.functions';
import { ReportingData, ReportingGraphColorType, SerieChartOptions } from './reporting-data-service-base';
import { ReportingSearchParams } from '../shared/reporting-search-params';

export abstract class ForecastReportBaseService {
  protected readonly consumptionSerieSettings: Readonly<SerieChartOptions> = {
    serieSettings: [
      { serieType: 'line' },
      { serieType: 'area', lineOpacity: 0.8 }
    ],
    colorType: ReportingGraphColorType.Alternative,
    positionInTooltip: 3
  };

  protected readonly derivedSerieSettings: Readonly<SerieChartOptions> = {
    serieSettings: [
      { serieType: 'line' },
      { serieType: 'area', lineOpacity: 0.5 }
    ],
    positionInTooltip: 3,
  };

  public constructor(
    private readonly translateService: TranslateService,
    private readonly colorService: ColorService
  ) {
  }

  protected addForecastSeries(
    series: ReportingData[],
    ids: number[],
    threshold: number,
    unitKey: ReportingUnit,
    hideInGrid: boolean
  ): void {
    series.forEach(quantity => {
      const serie = quantity.series;
      const quantityId = quantity.quantityId;
      ids.forEach(id => {
        if (serie[id]) {
          const comparisonValues = serie[id].find(s => !s.options.isInspectionPeriod)?.consumptions;
          const inspectionValues = serie[id].find(s => s.options.isInspectionPeriod)?.consumptions;
          if (comparisonValues && inspectionValues) {
            const forecastSeries = this.getForecastSeries({
              inspectionValues,
              comparisonValues,
              quantityId,
              hideInGrid,
              unitKey,
              thresholdForIncomplete: threshold,
              unit: serie[id][0].options.unit,
              isNormalized: serie[id][0].options.isNormalized
            });
            serie[id].push(...forecastSeries);
          }
        }
      });
    });
  }

  protected mapValuesByIds({
    values,
    normalizedValues,
    measuredDerivedValues,
    normalizedDerivedValues,
    measuredTargets,
    normalizedTargets,
    ids,
    averageQuantities
  }: {
    values: ReportingData[],
    normalizedValues: ReportingData[],
    measuredDerivedValues?: ReportingData[],
    normalizedDerivedValues?: ReportingData[],
    measuredTargets?: ReportingData[],
    normalizedTargets?: ReportingData[],
    ids: number[],
    averageQuantities: Quantities[]
  }): ReportingSeriesByFacility {
    normalizedDerivedValues = normalizedDerivedValues ?? [];
    measuredDerivedValues = measuredDerivedValues ?? [];
    measuredTargets = measuredTargets ?? [];
    normalizedTargets = normalizedTargets ?? [];

    return ids.toRecord(
      fId => fId,
      fId => {
        const measured = this.getSeriesConsumptionValues(values, measuredTargets, fId, false, averageQuantities);
        const normalized = this.getSeriesConsumptionValues(
          normalizedValues,
          normalizedTargets,
          fId,
          true,
          averageQuantities
        );
        const measuredDerived = this.getSeriesConsumptionValues(
          measuredDerivedValues,
          [],
          fId,
          false,
          averageQuantities
        );
        const normalizedDerived = this.getSeriesConsumptionValues(
          normalizedDerivedValues,
          [],
          fId,
          true,
          averageQuantities
        );
        return [...measured, ...normalized, ...measuredDerived, ...normalizedDerived].sortByMany(
          'quantityId',
          'isNormalized',
          'derivedValueId'
        );
      }
    );
  }

  protected getEmptySeries(): Observable<{ measured: ReportingData[], normalized: ReportingData[] }> {
    return forkJoin({
      measured: of<ReportingData[]>([]),
      normalized: of<ReportingData[]>([])
    });
  }

  protected hasUnsupportedParams(params: ReportingSearchParams): boolean {
    const isSupportedDuration = params.duration.years === 1;
    return !isSupportedDuration || params.resolution !== RequestResolution.P1M;
  }

  private getForecastSeries(
    {
      inspectionValues,
      comparisonValues,
      quantityId,
      unit,
      thresholdForIncomplete,
      hideInGrid,
      unitKey,
      isNormalized,
    }: {
      inspectionValues: ConsumptionLike[],
      comparisonValues: ConsumptionLike[],
      quantityId: Quantities,
      unit: string,
      thresholdForIncomplete: number,
      hideInGrid: boolean,
      unitKey: ReportingUnit
      isNormalized: boolean
    }
  ): [ReportingSeries, ReportingSeries] {
    const forecastSeries: ReportingSeries = this.getSingleForecastSeries({
      quantityId,
      unit,
      hideInGrid,
      comparisonValues,
      unitKey,
      forecastSeriesValues: getForecastSeriesValues(
        inspectionValues, comparisonValues, thresholdForIncomplete, 'factor'
      ),
      color: '#30b000',
      positionInTooltip: 1,
      title: 'FACILITIES.FORECAST_TYPES.REALIZED_CHANGE',
      isNormalized: isNormalized,
      serieType: 'forecast1'
    });

    const forecastSeries2: ReportingSeries = this.getSingleForecastSeries({
      quantityId,
      unit,
      hideInGrid,
      comparisonValues,
      unitKey,
      forecastSeriesValues: getForecastSeriesValues(
        inspectionValues, comparisonValues, thresholdForIncomplete, 'difference'
      ),
      color: '#704bcb',
      positionInTooltip: 2,
      title: 'FACILITIES.FORECAST_TYPES.LAST_YEAR_ACTUALS',
      isNormalized: isNormalized,
      serieType: 'forecast2'
    });

    return [forecastSeries, forecastSeries2];
  }

  private getSingleForecastSeries(
    {
      forecastSeriesValues,
      quantityId,
      color,
      positionInTooltip,
      title,
      unit,
      hideInGrid,
      comparisonValues,
      unitKey,
      isNormalized,
      serieType
    }: {
      forecastSeriesValues: ConsumptionLike[],
      quantityId: Quantities,
      color: string,
      positionInTooltip: number,
      title: string,
      unit: string,
      hideInGrid: boolean,
      comparisonValues: ConsumptionLike[],
      unitKey: ReportingUnit,
      isNormalized: boolean,
      serieType: 'forecast1' | 'forecast2'
    }
  ): ReportingSeries {
    return new ReportingSeries({
      chartOptions: {
        serieType: 'line',
        dashType: 'longDash',
        lineWidth: 1.5,
      },
      gridOptions: {
        hideInGrid: hideInGrid,
        gridColor: color,
        comparisonColor: this.colorService.firstComparisonPeriodColor
      },
      options: {
        color: color,
        serieTitle: this.translateService.instant(title),
        unit: unit,
        unitKey: unitKey,
        quantityId: quantityId,
        isNormalized: isNormalized,
        serieType: serieType,
      },
      chartItemOptions: { positionInTooltip },
      consumptions: forecastSeriesValues,
      comparisonConsumptions: comparisonValues,
      isCumulative: true
    });
  }

  private getSeriesConsumptionValues(
    values: ReportingData[],
    targets: ReportingData[],
    id: number,
    isNormalized: boolean,
    averageQuantities: Quantities[]
  ): ReportingSeriesCollection[] {
    return values.reduce<ReportingSeriesCollection[]>((mapped, { series, quantityId, derivedId }) => {
      const quantityValues = series[id];
      const quantityTargets = targets.find(t => t.quantityId === quantityId)?.series?.[id] ?? [];
      if (quantityValues) {
        if (Array.hasItems(quantityTargets)) {
          const inspectionPeriodValues = quantityValues.find(s => s.options.isInspectionPeriod).consumptions;
          quantityTargets.forEach(t => {
            t.calculateChanges(inspectionPeriodValues, true);
          });
        }
        mapped.push(new ReportingSeriesCollection({
          quantityId,
          isNormalized,
          derivedValueId: derivedId,
          series: [...quantityValues, ...quantityTargets],
          averageQuantities
        }));
      }
      return mapped;
    }, []);
  }
}
