<ng-container *ngIf="sectionForm">
  <div
    [formGroup]="sectionForm"
    *ngIf="facilityPropertyGroups$ | async as groups"
  >
    <input
      *labelWrap="'FACILITIES_REPORT.GRAPHS' | translate"
      type="checkbox"
      kendoCheckBox
      [formControl]="chartVisibilityControl"
    >
    <input
      *labelWrap="'FACILITIES_REPORT.TABLES' | translate"
      type="checkbox"
      kendoCheckBox
      [formControl]="gridVisibilityControl"
    >
    <ng-container *ngIf="showFacilityOrMeterInfo$ | async">
      <ng-container *ngIf="isMeterReport; else facilityProperties">
        <input
          *labelWrap="'FACILITIES_REPORT.METER_DETAILS' | translate"
          type="checkbox"
          kendoCheckBox
          [formControl]="meterInfoVisibilityControl"
        >
      </ng-container>
      <ng-template #facilityProperties>
        <h3>{{ 'FACILITY_INFO' | translate }}</h3>
        <div class="facility-section-selection">
          <ng-container *ngFor="let group of groups">
            <input
              *labelWrap="group.Name"
              type="checkbox"
              kendoCheckBox
              [formControlName]="group.Property"
            >
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
