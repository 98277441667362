import {
  ChangeDetectionStrategy,
  Component,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';

import { NgfTabChangeEvent, NgfTabsetComponent } from '@enerkey/foundation-angular';

import { ReportModalService } from '../../services/report-modal.service';
import { ReportType } from '../../shared/report-type';
import { EnvironmentService } from '../../../../services/environment-service';

@Component({
  selector: 'modal-quantity-reports',
  templateUrl: './modal-quantity-reports.component.html',
  styleUrls: ['./modal-quantity-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalQuantityReportsComponent {
  @ViewChild(NgfTabsetComponent) public readonly tabset: NgfTabsetComponent;

  public readonly ReportType = ReportType;

  public readonly reportType$: Observable<ReportType>;

  public readonly isProd: boolean;

  public constructor(
    private readonly reportModalService: ReportModalService,
    private readonly environmentService: EnvironmentService
  ) {
    this.reportType$ = this.reportModalService.reportType$;
    this.isProd = this.environmentService.isProduction();
  }

  public tabChange(tab: NgfTabChangeEvent): void {
    this.reportModalService.moveToReportOfType(tab.nextId as ReportType);
  }
}
