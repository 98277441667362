import {
  ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, Observable, Subject, switchMap } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { StringKeys } from '@enerkey/ts-utils';

import { SelectComponent } from '../select/select.component';
import { FacilityService } from '../services/facility.service';

interface FacilitySelectItem {
  name: string;
  id: number;
}

@Component({
  selector: 'facility-select',
  templateUrl: '../select/select.component.html',
  styleUrls: ['../select/select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FacilitySelectComponent),
    multi: true
  }]
})
export class FacilitySelectComponent extends SelectComponent<FacilitySelectItem, 'id'>
  implements OnInit, OnDestroy, OnChanges {
  @Input() public defaultItemText: string;
  @Input() public showDefaultText: boolean = true;
  @Input() public facilityIds: number[] | null = null;

  public textField: StringKeys<FacilitySelectItem> = 'name';
  public valueField: 'id' = 'id' as const;
  public defaultItem: FacilitySelectItem;

  protected sourceData$: Observable<FacilitySelectItem[]>;
  protected facilityIds$: Observable<number[] | null>;

  private readonly _destroy$ = new Subject<void>();
  private readonly _facilityIds$ = new BehaviorSubject<number[] | null>(null);

  public constructor(
    private facilityService: FacilityService,
    private translateService: TranslateService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
    this.facilityIds$ = this._facilityIds$.asObservable();
  }

  public ngOnInit(): void {
    if (this.showDefaultText) {
      this.defaultItem = {
        id: null,
        name: this.defaultItemText ?? this.translateService.instant('SELECT_FACILITY')
      };
    }

    this.sourceData$ = this.getFacilities().pipe(
      switchMap(facilities => this.facilityIds$.pipe(
        map(facilityIds => facilityIds !== null ?
          facilities.filter(facility => facilityIds.includes(facility.id)) :
          facilities)
      )),
      tap(facilities => {
        this.sourceData = facilities;
        this.updateValueAfterFilterChange();
        this.changeDetectorRef.markForCheck();
      }),
      takeUntil(this._destroy$)
    );

    this.sourceData$.subscribe();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.facilityIds) { this.onFacilityIdsChange(changes.facilityIds); }
  }

  private onFacilityIdsChange(change: SimpleChange): void {
    const { currentValue, previousValue } = change;
    const areBothValuesNullOrUndefined = this.isNullOrUndefined(currentValue) && this.isNullOrUndefined(previousValue);

    if (!areBothValuesNullOrUndefined) { this._facilityIds$.next(currentValue); }
  }

  private getFacilities(): Observable<FacilitySelectItem[]> {
    return this.facilityService.filteredProfileFacilities$
      .pipe(
        map(facilities => facilities
          .filter(facility => Number.isFinite(facility.facilityId))
          .map((facility): FacilitySelectItem => ({ id: facility.facilityId, name: facility.name }))
          .sort((facility1, facility2) => facility1.name.localeCompare(facility2.name)))
      );
  }

  private updateValueAfterFilterChange(): void {
    const valueInDataSource = !!this.value && (
      this.sourceData.find(item => item[this.valueField] === this.value)
    );
    this.value = valueInDataSource?.[this.valueField] ?? null;
    this.updateValue?.(this.value);
  }

  private isNullOrUndefined(value: unknown): boolean {
    return value === null || value === undefined;
  }
}
