import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AggregateResult, State } from '@progress/kendo-data-query';
import { PageChangeEvent, PagerSettings } from '@progress/kendo-angular-grid';

import { RequestResolution } from '@enerkey/clients/reporting';

import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { absoluteChangeKey, relativeChangeKey } from '../../constants/table-report-constants';
import { ReportingGridColumnGroup, ReportingGridData } from '../../services/reporting-grid.service';
import { ReportType } from '../../shared/report-type';

@Component({
  selector: 'reporting-grid',
  templateUrl: './reporting-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportingGridComponent implements OnChanges {
  @Input() public data: ReportingGridData[];
  @Input() public gridColumns: ReportingGridColumnGroup[];
  @Input() public searchParams: ReportingSearchParams;
  @Input() public aggregates: AggregateResult;
  @Input() public modalReportType: ReportType;
  @Input() public reportName: string;

  public readonly currentDate = new Date();

  public readonly absoluteChangeKey = absoluteChangeKey;
  public readonly relativeChangeKey = relativeChangeKey;
  public state: State = { skip: 0, take: 50, sort: [{ field: 'Name', dir: 'asc' }] };

  public pageSize = 30;
  public pageSettings: PagerSettings | boolean = false;

  public ngOnChanges(): void {
    switch (this.searchParams.resolution) {
      case RequestResolution.PT15M:
        this.pageSize = 24;
        this.pageSettings = this.data?.length > this.pageSize ? { pageSizes: [24, 48, 72, 96] } : false;
        break;
      case RequestResolution.PT1H:
        this.pageSize = 24;
        this.pageSettings = this.data?.length > this.pageSize ? { pageSizes: [12, 24, 48, 72] } : false;
        break;
      case RequestResolution.P1D:
        this.pageSize = 31;
        this.pageSettings = this.data?.length > this.pageSize ? { pageSizes: [10, 31, 50, 100] } : false;
        break;
      case RequestResolution.P1M:
        this.pageSize = 12;
        this.pageSettings = this.data?.length > this.pageSize ? { pageSizes: [12, 24, 48, 72] } : false;
        break;
      default:
        this.pageSize = 30;
        this.pageSettings = this.data?.length > this.pageSize ? { pageSizes: [10, 30, 50, 100] } : false;
    }

    this.state = { ...this.state, skip: 0 };
  }

  public pageChange(event: PageChangeEvent): void {
    this.state = { ...this.state, skip: event.skip };
  }
}
