import { DashType, SeriesType } from '@progress/kendo-angular-charts';

import { EnergyTargetType, ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';
import { Quantities } from '@enerkey/clients/metering';

import { isAverageCost, isCost, isEmission } from '../../reportingobjects/shared/relational-value-functions';
import { SelectableResolution } from '../components/reporting-search-form/reporting-search-form.component';
import { convertToCumulative } from './reporting.functions';

export interface ReportingSerieChartOptions {
  readonly lineWidth?: number;
  readonly lineOpacity?: number;
  readonly dashType?: DashType;
  readonly serieType: SeriesType;
  readonly hideInChart?: boolean;
  readonly showMarkers?: boolean;
  readonly stackGroup?: string;
}

export interface ReportingSerieGridOptions {
  readonly hideInGrid?: boolean;
  readonly gridTitle?: string;
  readonly gridColor?: string;
  readonly comparisonColor?: string;
}

export const relatedSerieTypes = [
  'relatedMin',
  'relatedMax',
  'relatedAverage'
] as const;

type Forecast = 'forecast1' | 'forecast2';
type MinMax = 'min' | 'max';
type Derived = `derived${number}`;
type Distribution = 'distribution1' | 'distribution2';
type Related = typeof relatedSerieTypes[number];
// eslint-disable-next-line max-len
export type ReportingSerieType = 'consumption' | Distribution | EnergyTargetType | Derived | MinMax | Forecast | 'temperature' | Related;

const seriesHiddenInTableReport: ReportingSerieType[] = ['min', 'max'];

export interface ReportingSerieOptions {
  readonly color: string;
  readonly serieTitle: string;
  readonly unit: string;
  readonly serieType: ReportingSerieType;
  readonly isInspectionPeriod?: boolean;
  readonly isComparisonPeriod?: boolean;
  readonly isNormalized?: boolean;
  readonly unitKey?: ReportingUnit;
  readonly quantityId: Quantities | null;
  readonly isPercentSerie?: boolean;
}

export interface ReportingSerieChartItemOptions {
  readonly derivedId?: number;
  readonly title?: string;
  readonly positionInTooltip?: number;
  readonly translateTitle?: boolean;
}

interface IReportSeriesDataPoint {
  value: number;
  incomplete: number;
  modeled: number;
  relativeChange: number;
  absoluteChange: number;
  timestamp: Date;
  index: number;
  /**
   * Used only for percent distribution series. Needed for calculating percent value.
   * E.g. for day distribution, set day value to {@link value} and night value to {@link counterpartValue}
   */
  counterpartValue?: number;
}

export type ConsumptionLike = Partial<IReportSeriesDataPoint> & Pick<IReportSeriesDataPoint, 'timestamp'>

export class ReportSeriesDataPoint {
  public readonly value: number;
  public readonly counterpartValue: number;
  public readonly incomplete: number;
  public readonly modeled: number;
  public relativeChange: number;
  public absoluteChange: number;
  public readonly timestamp: Date;
  public readonly index: number;
  /**
   * Used only for percent distribution series.
   * Percent of distribution value from total value
   */
  public readonly percentValue: number;

  public constructor(
    private readonly series: ReportingSeries,
    params: IReportSeriesDataPoint
  ) {
    this.value = params.value;
    this.incomplete = params.incomplete;
    this.modeled = params.modeled;
    this.relativeChange = params.relativeChange;
    this.absoluteChange = params.absoluteChange;
    this.timestamp = params.timestamp;
    this.counterpartValue = params.counterpartValue;
    this.index = params.index;

    if (this.isPercentSerie) {
      const totalValue = this.value + this.counterpartValue;
      this.percentValue = totalValue ? this.value / (this.value + this.counterpartValue) : null;
    }
  }

  public get isPercentSerie(): boolean {
    return this.series.options.isPercentSerie;
  }

  public get visibleValue(): number {
    return this.series.options.isPercentSerie ? this.percentValue : this.value;
  }

  // Without this kendo chart throws errors
  /* istanbul ignore next */
  public set visibleValue(_v: number) {
  }

  public get positionInTooltip(): number {
    return this.series.chartItemOptions.positionInTooltip;
  }

  public get unit(): string {
    return this.series.options.unit;
  }

  public get unitKey(): ReportingUnit {
    return this.series.options.unitKey;
  }

  public get derivedId(): number {
    return this.series.chartItemOptions.derivedId;
  }

  public get title(): string {
    return this.series.chartItemOptions.title;
  }

  public get translateTitle(): boolean {
    return this.series.chartItemOptions.translateTitle;
  }

  public get quantityId(): Quantities {
    return this.series.options.quantityId;
  }

  public get isNormalized(): boolean {
    return this.series.options.isNormalized;
  }
}

interface IReportingSeries {
  chartOptions: ReportingSerieChartOptions,
  gridOptions?: ReportingSerieGridOptions,
  options: ReportingSerieOptions,
  chartItemOptions?: ReportingSerieChartItemOptions,
  consumptions: ConsumptionLike[],
  isCumulative?: boolean,
  compareToPreviousMonth?: boolean,
  comparisonConsumptions?: ConsumptionLike[],
  comparisonPeriodAsBase?: boolean
}

interface IReportingSeriesExtraProperties{
  resolution?: SelectableResolution,
  searchPeriods?: {readonly start: Date; readonly end: Date; }[],
  durationLength?: number
}

export class ReportingSeries {
  public readonly values: ReportSeriesDataPoint[];
  public readonly aggregatedValue: number;
  public readonly averageValue: number;
  public readonly hasValues: boolean;

  public readonly chartOptions: ReportingSerieChartOptions;
  public readonly options: ReportingSerieOptions;
  public readonly chartItemOptions: ReportingSerieChartItemOptions;
  public readonly consumptions: ConsumptionLike[];

  public readonly minValue: number;
  public readonly minValueIndex: number;
  public readonly maxValue: number;
  public readonly maxValueIndex: number;
  public readonly gridOptions: ReportingSerieGridOptions;

  public readonly compareToPreviousMonth: boolean;
  public comparisonConsumptions: ConsumptionLike[];
  public readonly isRelatedSerie: boolean;

  private readonly isCumulative: boolean;

  public constructor(private readonly params: IReportingSeries,
    private readonly extraParams: IReportingSeriesExtraProperties = {}) {
    this.consumptions = params.consumptions;
    this.chartOptions = params.chartOptions;
    this.gridOptions = params.gridOptions ?? {};
    this.options = {
      ...params.options,
      isPercentSerie: params.options.isPercentSerie ?? false
    };
    this.chartItemOptions = params.chartItemOptions ?? {};
    this.isCumulative = params.isCumulative ?? false;
    this.compareToPreviousMonth = params.compareToPreviousMonth ?? false;
    this.comparisonConsumptions = params.comparisonConsumptions;

    this.values = params.consumptions.map((c, index) => {
      let categoryAxisValue: number = index;
      const { durationLength, searchPeriods, resolution } = extraParams;

      if (resolution === RequestResolution.P1Y && durationLength === 1 && searchPeriods.length > 1) {
        const curentConsumptionIndex = this.getCurrentConsumptionIndex(
          this.consumptions,
          searchPeriods
        );
        categoryAxisValue = curentConsumptionIndex;
      }
      return new ReportSeriesDataPoint(this, {
        value: c.value,
        incomplete: c.incomplete,
        modeled: c.modeled,
        timestamp: c.timestamp,
        absoluteChange: null,
        relativeChange: null,
        counterpartValue: c.counterpartValue,
        index: categoryAxisValue,
      });
    });
    this.hasValues = this.values.some(v => Number.isFinite(v.value));
    this.aggregatedValue = this.getAggregatedValue();
    this.averageValue = this.getAverageValue();
    if (this.isCumulative) {
      convertToCumulative(this.values);
    }

    if (this.compareToPreviousMonth) {
      this.calculateChangesWithSelf();
    } else if (params.comparisonConsumptions) {
      this.calculateChanges(params.comparisonConsumptions, params.comparisonPeriodAsBase);
    }

    this.minValue = Math.min(...this.values.filterMap(v => Number.isFinite(v.value), v => v.value));
    this.maxValue = Math.max(...this.values.filterMap(v => Number.isFinite(v.value), v => v.value));
    this.minValueIndex = this.values.findIndex(v => v.value === this.minValue);
    this.maxValueIndex = this.values.findIndex(v => v.value === this.maxValue);

    this.isRelatedSerie = relatedSerieTypes.some(t => t === this.options.serieType);
  }

  public fromValues(
    newParams: Partial<IReportingSeries>,
    extraParams?: IReportingSeriesExtraProperties
  ): ReportingSeries {
    return new ReportingSeries({
      ...this.params,
      ...newParams,
    }, { ...extraParams });
  }

  public calculateChanges(
    comparisonConsumptions: ConsumptionLike[],
    comparisonPeriodAsBase: boolean
  ): void {
    this.comparisonConsumptions = comparisonConsumptions;
    const comparisonCopy: ConsumptionLike[] = comparisonConsumptions.map(c => ({ ...c }));
    if (this.isCumulative) {
      convertToCumulative(comparisonCopy);
    }
    for (const [index, comparisonConsumption] of comparisonCopy.entries()) {
      if (Number.isFinite(comparisonConsumption.value)) {
        const inspectionConsumption = this.values[index];

        if (Number.isFinite(inspectionConsumption?.value)) {
          const absoluteChange = comparisonPeriodAsBase
            ? comparisonConsumption.value - inspectionConsumption.value
            : inspectionConsumption.value - comparisonConsumption.value
          ;
          inspectionConsumption.absoluteChange = absoluteChange;

          const relativeChange = comparisonPeriodAsBase
            ? inspectionConsumption.absoluteChange / Math.abs(inspectionConsumption.value)
            : inspectionConsumption.absoluteChange / Math.abs(comparisonConsumption.value)
          ;
          inspectionConsumption.relativeChange = Number.isFinite(relativeChange) ? relativeChange : 0;
        }
      }
    }
  }

  private calculateChangesWithSelf(): void {
    for (const [index, currentValue] of this.values.entries()) {
      if (index > 0) {
        const previousValue = this.values[index - 1];
        const absoluteChange = previousValue.value !== null
          ? currentValue.value - previousValue.value
          : null;
        currentValue.absoluteChange = absoluteChange;

        const relativeChange = previousValue.value
          ? currentValue.absoluteChange / Math.abs(previousValue.value)
          : null;
        currentValue.relativeChange = relativeChange;
      }
    }
  }

  public get isConsumptionSeries(): boolean {
    return this.options.serieType === 'consumption';
  }

  public get isMinMaxApplicableSeries(): boolean {
    return !!this.isConsumptionSeries &&
      !this.chartOptions.hideInChart &&
      !!this.options.isInspectionPeriod &&
      !this.chartOptions.stackGroup;
  }

  public get isShownInGrid(): boolean {
    return !this.gridOptions.hideInGrid;
  }

  public get serieStart(): string {
    return this.values[0]?.timestamp.getTime().toString();
  }

  public get isShownInTable(): boolean {
    return !seriesHiddenInTableReport.includes(this.options.serieType) && !this.gridOptions.hideInGrid;
  }

  public get isShownInChart(): boolean {
    return !this.chartOptions.hideInChart;
  }

  public get gridColor(): string {
    return this.gridOptions.gridColor ?? this.options.color;
  }

  public get gridTitle(): string {
    return this.gridOptions.gridTitle ?? this.options.serieTitle;
  }

  public get gridComparisonColor(): string {
    return this.gridOptions.comparisonColor;
  }

  public get isChangeVisible(): boolean {
    if (this.isRelatedSerie) {
      return false;
    }
    if (this.options.serieType === 'temperature') {
      return false;
    }
    const hasChangeValues = this.values.some(v => v.absoluteChange !== null);
    return (!this.options.isInspectionPeriod || this.compareToPreviousMonth) && hasChangeValues;
  }

  public get chartAxisName(): string {
    const derivedId = this.chartItemOptions.derivedId;
    const checkSpecificConsumptions = derivedId
      && !isCost(derivedId)
      && !isEmission(derivedId);

    if (this.isRelatedSerie) {
      return `related${this.options.unit}`;
    }

    if (isAverageCost(derivedId)) {
      return 'averageCost';
    }

    if (checkSpecificConsumptions) {
      return this.chartItemOptions.title;
    }

    return this.options.isPercentSerie
      ? `${this.options.unit}%`
      : this.options.unit;
  }

  private getAggregatedValue(): number {
    return this.consumptions.map(v => v.value).reduce(
      (aggregate, current) => Number.isFinite(current) ? aggregate + current : aggregate,
      null
    );
  }

  private getAverageValue(): number {
    const values = this.consumptions.filterMap(v => Number.isFinite(v.value), v => v.value);
    return values.length ? values.reduce((acc, cur) => acc + cur, 0) / values.length : null;
  }

  private getCurrentConsumptionIndex(
    currentConsumption: ConsumptionLike[],
    searchPeriods: {readonly start: Date; readonly end: Date; }[]
  ): number {
    const currentConsumptionYear = currentConsumption[0].timestamp.getFullYear().toString();

    const sortedSearchPeriods = searchPeriods.sort((a, b) => a.start.getTime() - b.start.getTime())
      .unique(v => v.start.getFullYear().toString());
    for (let i = 0; i < sortedSearchPeriods.length; i++) {
      if (currentConsumptionYear === sortedSearchPeriods[i]) {
        return i;
      }
    }
    return -1;
  }
}
