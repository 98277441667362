import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable } from 'rxjs';
import { AggregateResult, CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { ColumnBase, ColumnComponent } from '@progress/kendo-angular-treelist';

import { ReportingMeterSelection } from '../shared/reporting-meter-selection';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility, ReportingSeriesCollection } from '../shared/reporting-series-collection';
import { ReportingData } from './reporting-data-service-base';
import { ReportingMeterDataService } from './reporting-meter-data.service';
import { GridStateForBookmark } from '../../../services/bookmark.service';
import { ReportType } from '../shared/report-type';
import { absoluteChangeKey, relativeChangeKey } from '../constants/table-report-constants';
import { getAbsoluteChangeFromTotals } from './reporting-grid.service';

export type MeterInfoColumnVisibilityByQuantity = {
  [quantityId: number]: {
    [field: string]: {
      hidden: boolean
    }
  }
};

@Injectable({
  providedIn: 'root'
})
export class MeterTableReportService {
  public readonly meterInfoColumnVisibility$: Observable<MeterInfoColumnVisibilityByQuantity>;
  public readonly gridFilters$: Observable<{[quantityId: number]: CompositeFilterDescriptor}>;
  public readonly gridSorts$: Observable<{[quantityId: number]: SortDescriptor[]}>;

  private readonly _meterInfoColumnVisibility$ = new BehaviorSubject<MeterInfoColumnVisibilityByQuantity>(null);
  private readonly _gridFilters$ = new BehaviorSubject<{[quantityId: number]: CompositeFilterDescriptor}>(null);
  private readonly _gridSorts$ = new BehaviorSubject<{[quantityId: number]: SortDescriptor[]}>(null);

  public constructor(
    private readonly reportingMeterDataService: ReportingMeterDataService
  ) {
    this.meterInfoColumnVisibility$ = this._meterInfoColumnVisibility$.asObservable();
    this.gridFilters$ = this._gridFilters$.asObservable();
    this.gridSorts$ = this._gridSorts$.asObservable();
  }

  public setGridMeterInfoColumns(quantityId: number, columns: ColumnBase[]): void {
    const cols = columns.filter(column => !column.isColumnGroup) as ColumnComponent[];
    this._meterInfoColumnVisibility$.next({
      ...this._meterInfoColumnVisibility$.value,
      [quantityId]: cols.toRecord(col => col.field, col => ({ hidden: col.hidden }))
    });
  }

  public setGridFilters(quantityId: number, filters: CompositeFilterDescriptor): void {
    this._gridFilters$.next({
      ...this._gridFilters$.value,
      [quantityId]: filters
    });
  }

  public setGridSorts(quantityId: number, sorts: SortDescriptor[]): void {
    this._gridSorts$.next({
      ...this._gridSorts$.value,
      [quantityId]: sorts
    });
  }

  public resetGridState(): void {
    this._meterInfoColumnVisibility$.next(null);
    this._gridFilters$.next(null);
    this._gridSorts$.next(null);
  }

  public getGridStateForBookmark(): {
    [quantityId: number]: GridStateForBookmark
  } {
    const uniqueQuantities = [
      ...Object.integerKeys(this._meterInfoColumnVisibility$.value ?? {}),
      ...Object.integerKeys(this._gridFilters$.value ?? {}),
      ...Object.integerKeys(this._gridSorts$.value ?? {})
    ].unique();

    const gridState: {[quantityId: number]: GridStateForBookmark} = {};

    for (const quantityId of uniqueQuantities) {
      const visibleColumns = Object.entries(this._meterInfoColumnVisibility$.value?.[quantityId] ?? {}).filterMap(
        ([_field, { hidden }]) => !hidden,
        ([field, _]) => field
      );
      gridState[quantityId] = {
        filter: this._gridFilters$.value?.[quantityId] ?? null,
        sort: this._gridSorts$.value?.[quantityId] ?? [],
        visibleColumns: visibleColumns,
      };
    }
    return gridState;
  }

  public getData(
    params: ReportingSearchParams,
    facilityId: number,
    meters: ReportingMeterSelection
  ): Observable<ReportingSeriesByFacility> {
    return forkJoin({
      measured: forkJoin([
        this.reportingMeterDataService.getMeasuredValues(ReportType.Table, params, facilityId, meters),
      ]),
      normalized: forkJoin([
        this.reportingMeterDataService.getNormalizedValues(ReportType.Table, params, facilityId, meters),
      ]),
    }).pipe(
      map(({ measured, normalized }) => meters.meterIds.toRecord(
        mId => mId,
        mId => {
          const measuredValues = this.getSeriesValues({ id: mId, values: measured.flat(), isNormalized: false });
          const normalizedValues = this.getSeriesValues({ id: mId, values: normalized.flat(), isNormalized: true });
          return [...measuredValues, ...normalizedValues];
        }
      ))
    );
  }

  // Reused calculateChangeAggregates method from src\app\modules\reporting\services\reporting-grid.service.ts
  public calculateChangeAggregates(aggregates: AggregateResult): AggregateResult {
    aggregates = getAbsoluteChangeFromTotals(aggregates, 'value');
    Object.keys(aggregates).forEach(k => {
      if (k.endsWith(relativeChangeKey)) {
        const valuePointKey = k.substring(0, k.lastIndexOf('.'));
        const periodTotalValue = aggregates[`${valuePointKey}.value`].sum;
        const periodAbsoluteChange = aggregates[`${valuePointKey}.${absoluteChangeKey}`].sum;
        const periodRelativeChange = (periodAbsoluteChange / periodTotalValue);
        aggregates[k].sum = isNumeric(periodRelativeChange) ? periodRelativeChange : null;
      }
    });
    return aggregates;
  }

  private getSeriesValues({
    id,
    values,
    isNormalized
  }: {
    id: number
    values: ReportingData[],
    isNormalized: boolean
  }): ReportingSeriesCollection[] {
    return values.filterMap(
      s => s.series[id],
      s => new ReportingSeriesCollection({
        quantityId: s.quantityId,
        series: s.series[id],
        isNormalized
      })
    );
  }
}

// Taken from the github answer: https://stackoverflow.com/a/58550111
function isNumeric(num: unknown): boolean {
  return (typeof (num) === 'number' || typeof (num) === 'string' && num.trim() !== '') &&
    !isNaN(num as number) && isFinite(num as number);
}
