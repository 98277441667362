import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';

import { map } from 'rxjs/operators';

import { AlarmClient, LogLiteDto, LogSearchCriteriaDto } from '@enerkey/clients/alarm';
import {
  ActionsFlatListSearchCriteria,
  ActionSlimViewModel,
  AttachmentsClient,
  ExecutionPhase
} from '@enerkey/clients/attachments';
import { Quantities } from '@enerkey/clients/metering';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ACTION_TYPE_IDS, COMMENT_TYPE_IDS } from '../../energy-management/constants/em-shared.constant';
import { UserService } from '../../../services/user-service';
import { getChartEvents, ReportEventContainer } from './reporting-chart-event.functions';
import { Service } from '../../../constants/service';

export interface ReportEvents {
  plain: ReportPlainEvents;
  chartEvents: ReportChartEvents;
  graphPlainEvents: GraphPlainEvents;
}

export interface ReportPlainEvents {
  actions: ActionSlimViewModel[];
  comments: ActionSlimViewModel[];
  alarms: LogLiteDto[];
}

export interface GraphPlainEvents {
  actions: ActionSlimViewModel[];
  alarms: LogLiteDto[];
}

export type ReportChartEvents = Record<number, Partial<Record<Quantities, ReportEventContainer[]>>>

@Injectable({
  providedIn: 'root'
})
export class ReportEventService {
  private readonly actionsAllowed: boolean = false;
  private readonly commentsAllowed: boolean = false;
  private readonly alarmsAllowed: boolean = false;

  public constructor(
    private readonly attachmentsClient: AttachmentsClient,
    private readonly alarmClient: AlarmClient,
    private readonly userService: UserService
  ) {
    this.actionsAllowed = this.userService.hasService(Service.Actions);
    this.commentsAllowed = this.userService.hasService(Service.Comments);
    this.alarmsAllowed = this.userService.hasService(Service.Alarms);
  }

  public getEvents(
    facilityIds: number[],
    params: ReportingSearchParams
  ): Observable<ReportEvents> {
    const interval = params.getInterval();

    const actionsAndComments$ = this.getActionsAndComments(facilityIds, interval);
    const alarms$ = this.getAlarms(facilityIds, interval, params.quantityIds);
    const graphActionsAndComments$ = this.getActionsAndComments(facilityIds, interval, true);

    return forkJoin([
      actionsAndComments$,
      alarms$,
      graphActionsAndComments$
    ]).pipe(
      map(([actionsAndComments, alarms, graphActionsAndComments]) => {
        const actions = actionsAndComments.filter(a => ACTION_TYPE_IDS.includes(a.actionType));
        const comments = actionsAndComments.filter(a => COMMENT_TYPE_IDS.includes(a.actionType));

        return {
          plain: {
            actions,
            comments,
            alarms
          },
          graphPlainEvents: {
            actions: graphActionsAndComments,
            alarms
          },
          chartEvents: getChartEvents({
            actions: actions,
            comments: comments,
            alarms: alarms,
            params,
            facilityIds
          })
        };
      })
    );
  }

  private getActionsAndComments(
    facilityIds: number[],
    interval: { start: Date, end: Date },
    includeDecided: boolean = false
  ): Observable<ActionSlimViewModel[]> {

    if (!this.actionsAllowed && !this.commentsAllowed) {
      return of([]);
    }

    const searchParams = new ActionsFlatListSearchCriteria({
      beginDate: interval.start,
      endDate: interval.end,
      facilityIds: facilityIds,
      includedActionTypes: [
        ...(this.actionsAllowed ? ACTION_TYPE_IDS : []),
        ...(this.commentsAllowed ? COMMENT_TYPE_IDS : [])
      ],
      includedExecutionPhases: [
        ExecutionPhase.Implemented,
        ...(includeDecided ? [ExecutionPhase.ImplementationDecided] : [])
      ]
    });

    return this.attachmentsClient.getActionsFlatList(undefined, searchParams).pipe(
      map(actions => actions.sortBy('updatedAt', 'desc'))
    );
  }

  private getAlarms(
    facilityIds: number[],
    interval: { start: Date, end: Date },
    quantityIds: Quantities[]
  ): Observable<LogLiteDto[]> {

    if (!this.alarmsAllowed) {
      return of([]);
    }

    return this.alarmClient.getLiteLogsBySearchCriteria(
      this.userService.profileId,
      new LogSearchCriteriaDto({
        beginDate: interval.start,
        endDate: interval.end,
        facilityIds: facilityIds,
        quantityIds: quantityIds
      })
    ).pipe(
      map(alarms => alarms.sortBy('executedAt', 'desc'))
    );
  }
}
