import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { TranslateModule } from '@ngx-translate/core';
import { IntlModule } from '@progress/kendo-angular-intl';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';

import { CollapseModule, NgfTooltipModule } from '@enerkey/foundation-angular';

import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { ConsumptionChartComponent } from './components/consumption-chart/consumption-chart.component';
import { WidgetChartTooltipComponent } from './components/widget-chart-tooltip/widget-chart-tooltip.component';
import { ResolutionTitlePipe } from './pipes/resolution-title.pipe';
import { WidgetTooltipConsumptionRowComponent } from './components/widget-tooltip-consumption-row/widget-tooltip-consumption-row.component';
import { WidgetTooltipRelationalValueRowComponent } from './components/widget-tooltip-relational-value-row/widget-tooltip-relational-value-row.component';
import { ConsumptionChartRelationalValueFieldPipe } from './pipes/consumption-chart-relational-value-field.pipe';
import { ChartTooltipComponent } from './components/chart-tooltip/chart-tooltip.component';
import { ChartTooltipRowComponent } from './components/chart-tooltip-row/chart-tooltip-row.component';
import { ValueFormatPipe } from './pipes/value-format.pipe';
import { SidebarQuantityPickerComponent } from './components/sidebar-quantity-picker/sidebar-quantity-picker.component';
import { FacilitiesPopupDocumentsComponent } from './components/facilities-popup-documents/facilities-popup-documents.component';
import { EkDropdownModule } from '../ek-dropdown/ek-dropdown.module';
import { ReportingGridValueFormatPipe } from './pipes/reporting-grid-value-format.pipe';
import { ConsumptionValuePipe } from './pipes/consumption-value.pipe';
import { ConsumptionPercentPipe } from './pipes/consumption-percent.pipe';
import { EkKendoModule } from '../ek-kendo/ek-kendo.module';
import { QuantityChartHeaderComponent } from './components/quantity-chart-header/quantity-chart-header.component';
import { EkInputsModule } from '../ek-inputs/ek-inputs.module';
import { ChartValueAxisOptionsPipe } from './pipes/chart-value-axis-options.pipe';
import { ChartCategoriesPipe } from './pipes/chart-categories.pipe';
import { WidgetConsumptionChartComponent } from './components/widget-consumption-chart/widget-consumption-chart.component';
import { FacilitiesPopupActionsComponent } from './components/facilities-popup-actions/facilities-popup-actions.component';
import { EnergyManagementSharedModule } from '../energy-management-shared/energy-management-shared.module';
import { ConsumptionsChangeTooltipComponent } from './components/consumptions-change-tooltip/consumptions-change-tooltip.component';
import { YearlyTrendChartComponent } from './components/yearly-trend-chart/yearly-trend-chart.component';
import { YearlyTrendReportTableComponent } from './components/yearly-trend-report-table/yearly-trend-report-table.component';
import { YearlyTrendReportComponent } from './components/yearly-trend-report/yearly-trend-report.component';
import { YearlyTrendReportTableCellHeaderPipe } from './pipes/yearly-trend-report-table-cell-header.pipe';
import { YearlyTrendReportTableCellValuePipe } from './pipes/yearly-trend-report-table-cell-value.pipe';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [
    ConsumptionChartComponent,
    WidgetChartTooltipComponent,
    ResolutionTitlePipe,
    WidgetTooltipConsumptionRowComponent,
    WidgetTooltipRelationalValueRowComponent,
    ConsumptionChartRelationalValueFieldPipe,
    ChartTooltipComponent,
    ChartTooltipRowComponent,
    ValueFormatPipe,
    SidebarQuantityPickerComponent,
    FacilitiesPopupDocumentsComponent,
    ReportingGridValueFormatPipe,
    ConsumptionValuePipe,
    ConsumptionPercentPipe,
    QuantityChartHeaderComponent,
    ChartValueAxisOptionsPipe,
    ChartCategoriesPipe,
    WidgetConsumptionChartComponent,
    FacilitiesPopupActionsComponent,
    ConsumptionsChangeTooltipComponent,
    YearlyTrendReportComponent,
    YearlyTrendChartComponent,
    YearlyTrendReportTableCellValuePipe,
    YearlyTrendReportTableCellHeaderPipe,
    YearlyTrendReportTableComponent,
  ],
  imports: [
    CommonModule,
    CommonPipesModule,
    ChartsModule,
    TranslateModule,
    IntlModule,
    FormsModule,
    CollapseModule,
    InputsModule,
    EkDropdownModule,
    EkKendoModule,
    EkInputsModule,
    NgfTooltipModule,
    EnergyManagementSharedModule,
    PDFExportModule,
    SharedModule
  ],
  providers: [
    ValueFormatPipe,
    ConsumptionValuePipe,
    ConsumptionPercentPipe,
  ],
  exports: [
    ConsumptionChartComponent,
    ChartTooltipComponent,
    ValueFormatPipe,
    SidebarQuantityPickerComponent,
    FacilitiesPopupDocumentsComponent,
    ReportingGridValueFormatPipe,
    ConsumptionValuePipe,
    ConsumptionPercentPipe,
    ChartValueAxisOptionsPipe,
    ChartCategoriesPipe,
    QuantityChartHeaderComponent,
    WidgetConsumptionChartComponent,
    FacilitiesPopupActionsComponent
  ]
})
export class EnergyReportingSharedModule { }
