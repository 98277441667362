import { DEFAULT_WIDTH } from '../../../../shared/spreadsheet.functions';

export const ContractSpreadsheetColumns = [
  'enegiaId',
  'unitCount',
  'unitPrice',
  'productId',
  'contractId',
  'fromDate',
  'toDate',
] as const;
export type ContractSheetCell = (typeof ContractSpreadsheetColumns)[number];
type Cell = kendo.ui.SpreadsheetSheetRowCell;

const formats = {
  date: 'd.M.yyyy',
  raw: '@',
  number: '0.00',
  integer: '0'
} as const;

export const contractSheetColumns: ContractSpreadsheetCellConfig[] = [
  {
    field: 'enegiaId',
    key: 'ADMIN.ENEGIAID',
    dataType: 'number',
    format: formats.integer,
  },
  {
    field: 'unitCount',
    key: 'ADMIN.CONTRACTS.UNIT_COUNT',
    dataType: 'number',
    format: formats.integer,
  },
  {
    field: 'unitPrice',
    key: 'ADMIN.CONTRACTS.UNIT_PRICE',
    dataType: 'number',
    format: formats.number,
  },
  {
    field: 'productId',
    key: 'ADMIN.CONTRACTS.PRODUCT_ID',
    dataType: 'number',
    format: formats.raw,
  },
  {
    field: 'contractId',
    key: 'ADMIN.CONTRACTS.CONTRACT_ID',
    dataType: 'number',
    format: formats.raw,
  },
  {
    field: 'fromDate',
    key: 'ADMIN.CONTRACTS.FROM_DATE',
    dataType: 'date',
    format: formats.date,
  },
  {
    field: 'toDate',
    key: 'ADMIN.CONTRACTS.TO_DATE',
    dataType: 'date',
    format: formats.date,
  },
];

export class ContractSheetOptions implements kendo.ui.SpreadsheetOptions {
  public readonly columns: number = ContractSpreadsheetColumns.length;
  public readonly rows: number;

  public readonly toolbar: boolean = false;
  public readonly sheetsbar: boolean = false;

  public readonly sheets: [kendo.ui.SpreadsheetSheet];

  public constructor(
    rowCount: number,
    headerRow: Cell[],
    bodyRow: Cell[]
  ) {
    this.rows = rowCount;
    this.sheets = [{
      name: 'default',
      columns: this.repeat(this.columns, () => ({ width: DEFAULT_WIDTH })),
      rows: [
        { cells: headerRow },
        ...this.repeat(rowCount, () => ({ cells: bodyRow }))
      ]
    }];
  }

  /* istanbul ignore next */
  public changing(evt: kendo.ui.SpreadsheetChangingEvent): void {
    if (evt.changeType !== 'validation') {
      evt.range.validation(null);
    }
  }

  private repeat<T>(length: number, fn: () => T): T[] {
    return Array.from({ length }).map(fn);
  }
}

interface ContractSpreadsheetCellConfig {
  field: ContractSheetCell;
  key: string;
  format: typeof formats[keyof typeof formats];
  dataType: 'date' | 'number';
}

