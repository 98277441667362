import ManualQaInspectComponent from './manual-qa-inspect-component';
import ManualQaServiceModule from '../../services';

const dependencies = [ManualQaServiceModule.name];

const ManualQaInspectModule = angular
  .module('app.modules.manual-qa.components.manual-qa-inspect', dependencies)
  .component('manualQaInspect', ManualQaInspectComponent)
;

export default ManualQaInspectModule;
