<form [formGroup]="formGroup">
  <div class="sidebar-component">
    <div class="filter-checkbox">
      <label
        class="k-checkbox-label"
        [class.disabled]="controls.facilityFilter.disabled"
        for="facilityCheckbox"
      >
        {{ 'FILTER' | translate }}
      </label>

      <input
        id="facilityCheckbox"
        formControlName="facilityFilter"
        type="checkbox"
        kendoCheckBox
      />
    </div>

    <facility-select
      *labelBefore="'FACILITIES.FACILITY' | translate"
      [defaultItemText]="'ADMIN.METERGROUPS.DROPDOWN.ALL' | translate"
      [facilityIds]="meterGroupIdFilterState$ | async"
      formControlName="facilityId"
      class="searchInput"
    ></facility-select>
  </div>

  <div class="sidebar-component">
    <div class="filter-checkbox">
      <label
        class="k-checkbox-label"
        [class.disabled]="controls.meterGroupFilter.disabled"
        for="meterGroupsCheckbox"
      >
        {{ 'FILTER' | translate }}
      </label>

      <input
        id="meterGroupsCheckbox"
        formControlName="meterGroupFilter"
        type="checkbox"
        kendoCheckBox
      />
    </div>

    <meter-groups-dropdown-list
      *labelBefore="'ADMIN.METERGROUPS.METER_GROUP' | translate"
      [defaultItemText]="'ADMIN.METERGROUPS.DROPDOWN.ALL' | translate"
      [facilityId]="facilityIdFilterState$ | async"
      (meterGroupChange)="onMeterGroupChange($event)"
      formControlName="meterGroupId"
    ></meter-groups-dropdown-list>
  </div>

  <div class="sidebar-component">
    <div class="description-field" *labelBefore="'ADMIN.METERGROUPS.DESCRIPTION' | translate">
      {{ controls.meterGroupDescription.value }}
    </div>
  </div>

  <div class="buttons-container">
    <button
      class="button button--primary"
      [disabled]="editMode"
      (click)="searchClick.emit()"
    >
      {{ 'SEARCH' | translate }}
    </button>
  </div>
</form>
