import { Directive } from '@angular/core';

import { debounceTime, map, Observable } from 'rxjs';

import { ReportBase } from './report-base';

import { MeterGroupTreeItem, ReportModalMeterGroupService } from '../services/report-modal-meter-group.service';
import { FacilityService } from '../../../shared/services/facility.service';
import { ReportingSearchService } from '../services/reporting-search.service';
import { ReportEventService } from '../services/report-events.service';

@Directive()
export abstract class MeterGroupReportBase extends ReportBase {
  public override readonly pageOptions$: Observable<number>;
  public readonly selectedMeterGroups$: Observable<MeterGroupTreeItem[]>;
  public readonly debouncedSelectedMeterGroups$: Observable<MeterGroupTreeItem[]>;

  public constructor(
    reportModalMeterGroupService: ReportModalMeterGroupService,
    facilityService: FacilityService,
    reportingSearchService: ReportingSearchService,
    reportEventService: ReportEventService
  ) {
    super(facilityService, reportingSearchService, reportEventService);

    this.selectedMeterGroups$ = reportModalMeterGroupService.selectedMeterGroups$;
    this.debouncedSelectedMeterGroups$ = this.selectedMeterGroups$.pipe(
      debounceTime(100)
    );

    this.pageOptions$ = this.selectedMeterGroups$.pipe(
      map(meterGroups => Math.ceil(meterGroups.length / this.pageSize))
    );
  }
}
