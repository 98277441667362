<kendo-dropdownbutton
  [data]="quickSelectOptions"
  (itemClick)="click($event.value)"
  rounded="full"
  [popupSettings]="{ appendTo: 'component', popupClass: 'time-period-quick-select-popup' }"
  buttonClass="time-period-quick-select-button"
>
  {{ 'REPORTING.SEARCH.PERIOD_QUICK_TITLE' | translate }} <i class="k-icon k-i-arrow-s"></i>
  <ng-template kendoDropDownButtonItemTemplate let-dataItem>
    <span>{{ dataItem.text | translate }}</span>
  </ng-template>
</kendo-dropdownbutton>