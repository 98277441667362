import { FacilityProperty, FacilityPropertyEnums } from '@enerkey/clients/facility';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ConsumptionLike, ReportingSeries } from '../shared/reporting-series';
import { getIncompleteOrModeledAggregate } from '../shared/reporting.functions';

const TIME_DEPENDENT_SPECIFIC_CONSUMPTIONS_IDS: FacilityPropertyEnums[] = [
  FacilityPropertyEnums.ProductionAmountPieces,
  FacilityPropertyEnums.ProductionAmountKg,
  FacilityPropertyEnums.AmountVolume,
  FacilityPropertyEnums.AccommodationNights,
  FacilityPropertyEnums.Inhabitants,
];

export const monthsToAddToRequest = 11;

/**
 * Extend request duration by 11 months so trend of 12 months can be calculated for every visible month
 * @param params searchParams
 * @returns request duration in months
 */
export function getRequestDurationInMonths(params: ReportingSearchParams): number {
  const originalDuration = params.formValue.durationName;
  if (originalDuration === 'months') {
    return params.formValue.durationLength + monthsToAddToRequest;
  } else if (originalDuration === 'years') {
    return params.formValue.durationLength * 12 + monthsToAddToRequest;
  }
}

export function getTrendSeries(series: ReportingSeries, average: boolean = false): ReportingSeries {
  const values: ConsumptionLike[] = [];

  for (let i = monthsToAddToRequest; i < series.values.length; i++) {
    const valueSlice = series.values.slice(i - monthsToAddToRequest, i + 1);
    const sliceHasValues = valueSlice.some(v => v.value !== null);

    let trendValue = sliceHasValues
      ? valueSlice.reduce((accum, current) => accum + current.value, 0)
      : null;

    let distributionTrendValue: number;
    if (series.options.isPercentSerie) {
      distributionTrendValue = valueSlice
        .reduce((accum, current) => accum + current.counterpartValue, 0);
    }

    if (average) {
      trendValue = trendValue / (monthsToAddToRequest + 1);
    }

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const seriesYear = series.values[i].timestamp.getFullYear();
    const seriesMonth = series.values[i].timestamp.getMonth();
    if (seriesYear < currentYear || (seriesYear === currentYear && seriesMonth < currentMonth)) {
      values.push({
        value: trendValue,
        timestamp: series.values[i].timestamp,
        counterpartValue: distributionTrendValue,
        incomplete: getIncompleteOrModeledAggregate(valueSlice, 'incomplete'),
        modeled: getIncompleteOrModeledAggregate(valueSlice, 'modeled'),
      });
    }
  }

  return series.fromValues({
    consumptions: values,
    compareToPreviousMonth: series.gridOptions.hideInGrid === false,
  });
}

export function getSpecificTrendSeries(
  consumptionSeries: ReportingSeries,
  specificSeries: ReportingSeries,
  specificValues: FacilityProperty[],
  derivedId: number
): ReportingSeries {
  const isTimeDependent = TIME_DEPENDENT_SPECIFIC_CONSUMPTIONS_IDS.includes(derivedId);
  const values: ConsumptionLike[] = [];
  const specificConsumptionRefinedValues: ConsumptionLike[] = [];

  for (let i = 0; i < consumptionSeries.values.length; i++) {
    const consumptionValue = consumptionSeries.values[i].value;
    const specificConsumptionValue = specificSeries.values[i].value;
    let validSpecificConsumptionValue;

    if (specificConsumptionValue) {
      validSpecificConsumptionValue = consumptionValue / specificConsumptionValue;
    } else {
      validSpecificConsumptionValue = +findClosestLesserDate(
        specificSeries.values[i].timestamp,
        specificValues.sortBy('fromDate', 'desc')
      )?.value;
    }

    values.push({
      ...specificSeries.values[i],
      value: validSpecificConsumptionValue,
    });
  }

  const grossSpecificConsumptionRefinedSerie = specificSeries.fromValues({ consumptions: values });

  const grossConsumptionSeries = getTrendSeries(consumptionSeries);
  const grossSpecificConsumptionSeries = getTrendSeries(grossSpecificConsumptionRefinedSerie, true);

  for (let i = 0; i < grossSpecificConsumptionSeries.values.length; i++) {
    const consumptionValue = grossConsumptionSeries.values[i].value;
    const specificConsumptionValue = grossSpecificConsumptionSeries.values[i].value;
    let validSpecificConsumption = (consumptionValue && specificConsumptionValue) ?
      consumptionValue / specificConsumptionValue :
      null;

    if (isTimeDependent) {
      validSpecificConsumption = (validSpecificConsumption / (monthsToAddToRequest + 1));
    }

    specificConsumptionRefinedValues.push({
      ...grossSpecificConsumptionSeries.values[i],
      value: validSpecificConsumption,
    });
  }

  const specificConsumptionRefinedSerie = grossSpecificConsumptionSeries.fromValues({
    consumptions: specificConsumptionRefinedValues
  });

  return specificConsumptionRefinedSerie;
}

/**
 * Returns the date which is lesser than or equal to the target date
 * Example:
 * @param targetDate = 01-05-2024
 * @param dateList = [01-02-2024, 01-01-2024, 01-06-2024, 01-04-2024, 01-08-2024, 01-07-2024]
 * @returns 01-04-2024 FacilityProperty object
 */
function findClosestLesserDate(targetDate: Date, properties: FacilityProperty[]): FacilityProperty {
  if (!properties.length) { return null; }

  const targetTimestamp = new Date(targetDate).getTime();

  const validDates = properties.filter(property => new Date(property.fromDate).getTime() <= targetTimestamp);

  if (!validDates.length) { return null; }

  return validDates.reduce((closestDate, currentDate) => {
    const closestTimestamp = new Date(closestDate.fromDate).getTime();
    const currentTimestamp = new Date(currentDate.fromDate).getTime();

    return Math.abs(currentTimestamp - targetTimestamp) <
      Math.abs(closestTimestamp - targetTimestamp)
      ? currentDate
      : closestDate;
  }, validDates?.[0]);
}
