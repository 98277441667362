import { CalendarView } from '@progress/kendo-angular-dateinputs';
import { startOfMonth, startOfYear } from 'date-fns';

import { RequestResolution } from '@enerkey/clients/reporting';
import { assertUnreachable } from '@enerkey/ts-utils';

import { SelectableResolution } from './reporting-search-form.component';

export interface ReportingDatepickerSettings {
  calendarView: CalendarView;
  bottomView: CalendarView;
  disabledFn: (date: Date) => boolean;
  focusedDate?: Date;
}

function isMonthDisabled(date: Date): boolean {
  return date.getDate() !== 1;
}

function isYearDisabled(date: Date): boolean {
  return date.getDate() !== 1 || date.getMonth() !== 0;
}

function isDayDisabled(_date: Date): boolean {
  return false;
}

/*
 * Use named functions for disabledFn instead of arrow functions
 * With arrow functions new function is created every time when this function is called
 * Kendo datepicker goes crazy and emits valueChanges constantly if disabledDates
 * function changes on the fly
 */
export function getCalendarSettingsForResolution(resolution: SelectableResolution): ReportingDatepickerSettings {
  switch (resolution) {
    case RequestResolution.P1Y: {
      return {
        calendarView: 'decade',
        bottomView: 'decade',
        disabledFn: isYearDisabled,
        focusedDate: startOfYear(new Date()),
      };
    }
    case RequestResolution.P3M:
    case RequestResolution.P1M: {
      return {
        calendarView: 'year',
        bottomView: 'year',
        disabledFn: isMonthDisabled,
        focusedDate: startOfMonth(new Date()),
      };
    }
    case RequestResolution.P7D:
    case RequestResolution.P1D:
    case RequestResolution.PT1H:
    case RequestResolution.PT15M: {
      return {
        calendarView: 'month',
        bottomView: 'month',
        disabledFn: isDayDisabled,
        focusedDate: new Date(),
      };
    }
    /* istanbul ignore next */
    default: {
      assertUnreachable(resolution);
    }
  }
}
