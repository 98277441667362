import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { combineLatest, from, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { aggregateBy, AggregateResult } from '@progress/kendo-data-query';

import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalService } from '@enerkey/foundation-angular';
import { Quantities } from '@enerkey/clients/metering';
import { ContactClient, FacilityContactInformation } from '@enerkey/clients/contact';
import { Facility, FacilityClient } from '@enerkey/clients/facility';
import { MeteringType, MeterManagementClient, MeterTypeCountsDto } from '@enerkey/clients/meter-management';

import {
  electricityTaxClassTranslations,
  facilityGridContactInfoColumns,
  ownershipTranslations,
  totalMeterCountColumns
} from '../../../../constants/facility.constant';
import { KendoGridService } from '../../../../shared/ek-kendo/services/kendo-grid.service';
import { KendoGridSelection } from '../../../alarm-management/shared/kendo-grid-selection';
import { FacilityManagementService } from '../../services/facility-management.service';
import { FacilityTagEditModalComponent } from '../facility-tag-edit-modal/facility-tag-edit-modal.component';
import { quantityTranslations } from '../../../../constants/quantity.constant';
import { meterTypeTranslation } from '../../../../constants/meter.constant';
import { FacilityEditModalComponent } from '../facility-edit-modal/facility-edit-modal.component';
import { CompaniesService } from '../../../../shared/services/companies.service';
import { ReportingModalsService } from '../../../reporting/services/reporting-modals.service';
import { ReportingSearchService } from '../../../reporting/services/reporting-search.service';
import { ReportingSearchParams } from '../../../reporting/shared/reporting-search-params';

type RowSelectKey = 'id';

type QuantityColumn = {
  id: number,
  meterType: MeteringType
  title: string
}

type MeterCountGroup = {
  title: string,
  meterTypes: QuantityColumn[]
}

interface IMeterTypeCounts {
  automaticMetersTotalCount: number,
  manualMetersTotalCount: number,
  virtualMetersTotalCount: number,
  metersTotalCount: number,
  meterCountsByType: Record<number, Record<number, number>>
}

interface IMeterCounts {
  mainMeterCounts: IMeterTypeCounts,
  meterCountsPerQuantity: Record<number, number>,
  subMeterCounts: IMeterTypeCounts,
  totalMeterCount: number
}

export class ExtendedFacility extends Facility {
  public companyName?: string | null;
}

export interface IGridFacility {
  id: number,
  facility: ExtendedFacility,
  meterCounts?: IMeterCounts | null,
  contactInfo?: FacilityContactInformation | null
}

@Component({
  selector: 'facility-management-grid',
  templateUrl: './facility-management-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KendoGridService, ReportingSearchService]
})
export class FacilityManagementGridComponent implements AfterViewInit, OnDestroy {
  public gridSelection: KendoGridSelection<Facility, RowSelectKey>;
  public selectedFacilities$: Observable<number[]> = of([]);
  public showMeterCount = false;
  public QuantityColumns: QuantityColumn[];
  public MainMeterCountColumns: MeterCountGroup[];
  public SubMeterCountColumns: MeterCountGroup[];
  public showContactInfo = false;
  public aggregates: Record<string, AggregateResult> = {};

  public readonly facilities$: Observable<IGridFacility[]>;
  public readonly locales$: Observable<Record<number, string>>;
  public readonly buildingClasses$: Observable<Record<number, string>>;
  public readonly loading$: Observable<boolean>;
  public readonly currentDate = new Date();
  public readonly selectKey: RowSelectKey = 'id';
  public readonly gridSelectableSettings: SelectableSettings = {
    checkboxOnly: true,
    enabled: true,
    mode: 'multiple',
  };
  public readonly defaultColumnWidth = 100;
  public readonly OwnershipTypes = ownershipTranslations;
  public readonly ElectricityTaxClassTypes = electricityTaxClassTranslations;
  public readonly totalMeterCountColumns = totalMeterCountColumns;
  public readonly quantityTranslations = quantityTranslations;
  public readonly facilityGridContactInfoColumns = facilityGridContactInfoColumns;

  @ViewChild(GridComponent) private readonly kendoGrid: GridComponent;
  private readonly _loading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly facilityManagementService: FacilityManagementService,
    private readonly companiesService: CompaniesService,
    private readonly facilityClient: FacilityClient,
    private readonly gridService: KendoGridService<IGridFacility, RowSelectKey>,
    private readonly modalService: ModalService,
    private readonly meterManagementClient: MeterManagementClient,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly contactClient: ContactClient,
    private readonly reportingModalsService: ReportingModalsService,
    private readonly reportingSearchService: ReportingSearchService
  ) {
    this.loading$ = combineLatest([
      facilityManagementService.loading$,
      this._loading$
    ]).pipe(map(([a, b]) => a || b));

    this.facilities$ = this.facilityManagementService.searchResult$.pipe(
      map(({ facilities, params }) => {
        // reset grid selection when data is refreshed
        this.gridSelection = new KendoGridSelection([], 'id');

        if (!facilities.length) {
          return [];
        }

        this.showMeterCount = false;
        this.showContactInfo = false;

        const newFacilities: IGridFacility[] = facilities.map(f => ({
          id: f.id,
          facility: f
        }));
        this.getCompanyInformation(newFacilities);

        if (params.getMeterCount) {
          this.getMeterCount(newFacilities);
        }
        if (params.getContactInformation) {
          this.getContactInformation(newFacilities);
        }
        this.gridService.dataChanged(newFacilities);
        return newFacilities;
      }),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.locales$ = this.facilityClient.getLocales().pipe(
      map(locales => locales.toRecord(l => l.id, l => l.timeZone)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.buildingClasses$ = this.facilityClient.getBuildingClasses().pipe(
      map(bClasses => bClasses.toRecord(b => b.id, b => b.translation)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

  }

  public ngAfterViewInit(): void {
    this.gridService.initialize(this.selectKey, this.kendoGrid);

    this.selectedFacilities$ = this.gridService.selection$;
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public openTagEditor(dataItem: IGridFacility): void {
    const modal = this.modalService.open(FacilityTagEditModalComponent);
    modal.componentInstance.selectedFacility = dataItem.facility;

    from(modal.result).subscribe({
      next: () => this.facilityManagementService.repeatSearch(),
    });
  }

  public openEditModal(dataItem: IGridFacility): void {
    const modalRef = this.modalService.open(FacilityEditModalComponent);
    modalRef.componentInstance.facilityId = dataItem.id;
    modalRef.componentInstance.facility = dataItem.facility;
    from(modalRef.result).subscribe({
      next: () => this.facilityManagementService.repeatSearch()
    });
  }

  public openFacilityReport(dataItem: IGridFacility): void {
    const searchParams: Partial<ReportingSearchParams> = {
      formValue: {
        ...this.reportingSearchService.getDefaultParams(),
      }
    };
    this.reportingModalsService.openReport(
      dataItem.facility.id,
      searchParams as ReportingSearchParams
    );
  }

  private getCompanyInformation(facilities: IGridFacility[]): void {
    this.companiesService.getCompanies().pipe(
      indicate(this._loading$)
    ).subscribe(company => {
      facilities.forEach(item => {
        item.facility.companyName = company.find(c => c.id === item.facility.companyId)?.name;
      });
      this.changeDetectorRef.detectChanges();
    });
  }

  private getContactInformation(facilities: IGridFacility[]): void {
    this.contactClient.getFacilitiesContactInformation(
      facilities.map(f => f.facility.id)
    ).pipe(
      indicate(this._loading$)
    ).subscribe(contactInfos => {
      facilities.forEach(facility => {
        facility.contactInfo = contactInfos.find(c => c.facilityId === facility.id);
      });
      this.showContactInfo = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  private getMeterCount(facilities: IGridFacility[]): void {
    this.meterManagementClient.getFacilitiesMetersCount(
      facilities.map(f => f.facility.id)
    ).pipe(
      indicate(this._loading$)
    ).subscribe(meters => {
      const quantities: number[] = [];
      const mainMeterQuantities: QuantityColumn[] = [];
      const subMeterQuantities: QuantityColumn[] = [];
      meters.forEach(meter => {
        quantities.push(...(meter?.meterCountsPerQuantity.map(m => m.quantityId) ?? []));
        if (meter.mainMeterCounts) {
          mainMeterQuantities.push(...this.getMeterCountsByType(meter.mainMeterCounts));
        }
        if (meter.subMeterCounts) {
          subMeterQuantities.push(...this.getMeterCountsByType(meter.subMeterCounts));
        }

        // Set meterCounts for facility
        facilities.find(f => f.facility.id === meter.facilityId).meterCounts = {
          mainMeterCounts: this.reshapeMeterCounts(meter.mainMeterCounts),
          meterCountsPerQuantity: meter.meterCountsPerQuantity.toRecord(m => m.quantityId, m => m.count),
          subMeterCounts: this.reshapeMeterCounts(meter.subMeterCounts),
          totalMeterCount: meter.totalMeterCount
        };
      });

      this.QuantityColumns = quantities.unique().map(q => ({
        id: q,
        title: quantityTranslations[q as Quantities],
        meterType: null
      }));
      this.MainMeterCountColumns = this.getMeterCountGroups(mainMeterQuantities);
      this.SubMeterCountColumns = this.getMeterCountGroups(subMeterQuantities);

      this.calculateSumAggregates(facilities);
      this.showMeterCount = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  private getMeterCountsByType(counts: MeterTypeCountsDto): QuantityColumn[] {
    return counts.meterCountsByType.map(m => ({
      id: m.quantityId,
      meterType: m.meteringType,
      title: null as string
    }));
  }

  private getMeterCountGroups(arr: QuantityColumn[]): MeterCountGroup[] {
    return arr
      .uniqueByMany('id', 'meterType')
      .toGroupsBy('id')
      .getEntries()
      .map(([id, items]) => {
        const title = this.translateService.instant(quantityTranslations[id as Quantities]);
        return {
          title: title,
          meterTypes: items.map(g => ({
            id: items[0].id,
            meterType: g.meterType,
            title: `${title}, ${this.translateService.instant(
              meterTypeTranslation[g.meterType]
            ).toLowerCase()}`
          }))
        };
      });
  }

  // Converts MeterTypeCountsDto to IMeterTypeCounts
  private reshapeMeterCounts(data: MeterTypeCountsDto): IMeterTypeCounts {
    if (!data) {
      return null;
    }
    const c: Record<number, Record<number, number>> = {};
    data.meterCountsByType.forEach(type => {
      if (!c[type.quantityId]) {
        c[type.quantityId] = {};
      }
      c[type.quantityId][type.meteringType] = type.count;
    });

    return {
      automaticMetersTotalCount: data.automaticMetersTotalCount,
      manualMetersTotalCount: data.manualMetersTotalCount,
      virtualMetersTotalCount: data.virtualMetersTotalCount,
      metersTotalCount: data.metersTotalCount,
      meterCountsByType: c
    };
  }

  private calculateSumAggregates(facilities: IGridFacility[]): void {
    // Calculate sum aggregates for totalMeterCounts
    this.aggregates.totalMeter = aggregateBy(
      facilities,
      totalMeterCountColumns.map(m => ({
        aggregate: 'sum',
        field: m.field
      }))
    );

    // Calculate sum aggregates for meterCountsPerQuantity
    this.aggregates.quantityMeter = aggregateBy(
      facilities,
      this.QuantityColumns.map(q => ({
        aggregate: 'sum',
        field: `meterCounts.meterCountsPerQuantity.${q.id}`
      }))
    );

    // Calculate sum aggregates for mainMeterCounts
    const mainMeterCols: QuantityColumn[] = this.MainMeterCountColumns.flatMap(c => c.meterTypes);
    this.aggregates.mainMeterCounts = aggregateBy(
      facilities,
      mainMeterCols.map(q => ({
        aggregate: 'sum',
        field: `meterCounts.mainMeterCounts.meterCountsByType.${q.id}.${q.meterType}`
      }))
    );

    // Calculate sum aggregates for subMeterCounts
    const subMeterCols: QuantityColumn[] = this.SubMeterCountColumns.flatMap(c => c.meterTypes);
    this.aggregates.subMeterCounts = aggregateBy(
      facilities,
      subMeterCols.map(q => ({
        aggregate: 'sum',
        field: `meterCounts.subMeterCounts.meterCountsByType.${q.id}.${q.meterType}`
      }))
    );
  }
}
