import { Injectable } from '@angular/core';

import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { bignumber } from 'mathjs';

import { MeterGroupBulkUpdateResultCollectionDto } from '@enerkey/clients/metering';

import {
  FacilityGridItem, MeterGridItem, MeterGroupGridItem, MeterGroupGridItemBase, MeterGroupMeter,
  MeterGroupMeterUpdateActions, MeterGroupMeterUpdateActionsResult, MeterGroupWithMeters
} from '../../models/meter-groups-grid.model';
import {
  MeterGroupAddMetersError, MeterGroupCategory, MeterGroupCategoryQuantities, MeterGroupRemoveMetersError
} from '../../models/meter-groups.model';
import { MeterGroupsService } from '../meter-groups/meter-groups.service';

@Injectable({ providedIn: 'root' })
export class MeterGroupsGridService {

  private static filterByActiveMeters(treeList: MeterGroupGridItemBase[]): MeterGroupGridItemBase[] {
    return treeList.map(node => {
      if (node.children?.length) {
        const filteredChildren = this.filterByActiveMeters(node.children);
        if (filteredChildren.length) { return node.clone(filteredChildren); }
      } else if (node.isActive) {
        return node;
      }

      return null;
    }).filter(item => item !== null);
  }

  private static flattenGridItemsToMeterGroupsWithMeters(gridItems: MeterGroupGridItemBase[]): MeterGroupWithMeters[] {
    if (!gridItems?.length) { return null; }

    const firstRootItem = gridItems[0];
    if (firstRootItem instanceof MeterGroupGridItem) {
      return MeterGroupsGridService.flattenToMeterGroupsWithMetersByMeterGroupRoot(gridItems as MeterGroupGridItem[]);
    } else if (firstRootItem instanceof FacilityGridItem) {
      return MeterGroupsGridService.flattenToMeterGroupsWithMetersByFacilityRoot(gridItems as FacilityGridItem[]);
    }

    throw new Error('Unsupported rootItem type');
  }

  private static flattenToMeterGroupsWithMetersByMeterGroupRoot(
    rootItems: MeterGroupGridItem[]
  ): MeterGroupWithMeters[] {
    return rootItems.map(rootItem => new MeterGroupWithMeters(
      rootItem.id,
      rootItem.children
        .flatMap(facility => (facility as FacilityGridItem).children)
        .map(meter => ({
          id: meter.id,
          weight: MeterGroupsGridService.getMeterWeight(meter as MeterGridItem),
          active: meter.isActive
        }))
    ));
  }

  private static flattenToMeterGroupsWithMetersByFacilityRoot(
    rootItems: FacilityGridItem[]
  ): MeterGroupWithMeters[] {
    return rootItems
      .flatMap(facility => (facility as FacilityGridItem).children)
      .map(meterGroup => new MeterGroupWithMeters(
        meterGroup.id,
        meterGroup.children.map(meter => ({
          id: meter.id,
          weight: MeterGroupsGridService.getMeterWeight(meter as MeterGridItem),
          active: meter.isActive
        }))
      ));
  }

  private static getMeterWeight(meter: MeterGridItem): number {
    if (!Number.isFinite(meter.percentage)) { return undefined; }
    if (meter.percentage === 0) { return 0; }

    const value = (meter.subtract ? -1 : 1) * meter.percentage;
    const result = bignumber(value).div(100);

    const truncatedString = result
      .toNumber()
      .toString()
      .split('.')
      .map((part, index) => index === 1 ? part.substring(0, 5) : part)
      .join('.');

    return Number(truncatedString);
  }

  public constructor(private readonly meterGroupsService: MeterGroupsService) {}

  public getMeterGroupsTreeList(
    facilityId?: number,
    meterGroupId?: number,
    filterByActiveMeters: boolean = false
  ): Observable<MeterGroupGridItemBase[]> {
    const isFacilityIdDefined = Number.isFinite(facilityId);
    return this.meterGroupsService.getBaseData(
      facilityId,
      meterGroupId,
      { onlyMeterGroupsOnCurrentProfile: true, onlyMetersOnCurrentProfile: true }
    ).pipe(
      map(({ meterGroups, facilities, meters }) => isFacilityIdDefined ?
        FacilityGridItem.generateTreeList(facilities, meterGroups, meters) :
        MeterGroupGridItem.generateTreeList(meterGroups, facilities, meters)),
      map((tree: FacilityGridItem[] | MeterGroupGridItem[]) => filterByActiveMeters ?
        MeterGroupsGridService.filterByActiveMeters(tree) :
        tree)
    );
  }

  public saveMeterGroupsWithMeters(items: MeterGroupGridItemBase[]): Observable<MeterGroupMeterUpdateActionsResult> {
    return this.getUpdateActionsFromGridItems(items).pipe(
      switchMap(mgUpdateActions => {
        const addMetersRequest = mgUpdateActions.add.length
          ? this.meterGroupsService.addMeterToMeterGroupBatch(mgUpdateActions.add).pipe(
            catchError(err => of(new MeterGroupAddMetersError(err)))
          )
          : of(new MeterGroupBulkUpdateResultCollectionDto({ results: [], allSucceeded: true }));

        const removeMetersRequest = mgUpdateActions.remove.length
          ? this.meterGroupsService.removeMeterFromMeterGroupBatch(mgUpdateActions.remove).pipe(
            catchError(err => of(new MeterGroupRemoveMetersError(err)))
          )
          : of(new MeterGroupBulkUpdateResultCollectionDto({ results: [], allSucceeded: true }));

        return forkJoin({ added: addMetersRequest, removed: removeMetersRequest });
      })
    );
  }

  public getDistinctQuantityIdsFromMeterGroups(facilityId?: number, meterGroupId?: number): Observable<number[]> {
    const category = MeterGroupCategory.ENERGY;
    const quantityIds = MeterGroupCategoryQuantities.getByCategory(category);

    return this.meterGroupsService.getBaseData(facilityId, meterGroupId).pipe(
      map(({ meters: facilityMeters }) => Array
        .from(new Set(
          Object.values(facilityMeters).flatMap(meters => meters.map(meter => meter.quantityId))
        ))
        .filter(id => quantityIds.includes(id)))
    );
  }

  private getUpdateActionsFromGridItems(
    items: MeterGroupGridItemBase[]
  ): Observable<MeterGroupMeterUpdateActions> {
    const meterGroupsInGrid = MeterGroupsGridService.flattenGridItemsToMeterGroupsWithMeters(items);
    const meterGroupIdsInGrid = meterGroupsInGrid.map(mg => mg.meterGroupId);

    return this.meterGroupsService.getMeterGroups(meterGroupIdsInGrid).pipe(
      map(meterGroups => meterGroups
        .reduce((acc, meterGroup) => {
          const meterGroupInGrid = meterGroupsInGrid.find(mg => mg.meterGroupId === meterGroup.id);
          if (!meterGroupInGrid) { return acc; }

          const meterIdsInMeterGroup = meterGroupInGrid.meters.map(m => m.id);

          const activeMeters = meterGroupInGrid?.withActiveMetersOnly()?.meters ?? [];
          const previousMeters = meterGroup.meters
            .filter(m => meterIdsInMeterGroup.includes(m.meterId))
            .map(m => ({ id: m.meterId, weight: m.weight } as MeterGroupMeter));

          const metersToAdd = activeMeters.filter(m =>
            !previousMeters.some(pm => pm.id === m.id && pm.weight === m.weight));

          const metersToRemove = previousMeters.filter(pm =>
            !activeMeters.some(m => m.id === pm.id && m.weight === pm.weight));

          if (metersToAdd.length) { acc.add.push(new MeterGroupWithMeters(meterGroup.id, metersToAdd)); }
          if (metersToRemove.length) { acc.remove.push(new MeterGroupWithMeters(meterGroup.id, metersToRemove)); }

          return acc;
        }, { add: [], remove: [] } as MeterGroupMeterUpdateActions))
    );
  }
}
