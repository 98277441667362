<ngf-tabset
  [activeId]="reportType$ | async"
  [itemCount]="selectedMeterCount$ | async"
  [showCountLabel]="true"
  (tabChange)="tabChange($event)"
>
  <ngf-tab
    [title]="'FACILITIES.TREELIST' | translate"
    [id]="ReportType.Table"
  >
    <ng-template ngfTabContent>
      <meter-table-report></meter-table-report>
    </ng-template>
  </ngf-tab>
  <ngf-tab
    [title]="'FACILITIES.REPORT' | translate"
    [id]="ReportType.Period"
  >
    <ng-template ngfTabContent>
      <period-meter-report></period-meter-report>
    </ng-template>
  </ngf-tab>
  <ngf-tab
    [title]="'FACILITIES.SUM' | translate"
    [id]="ReportType.Sum"
  >
    <ng-template ngfTabContent>
      <sum-meter-report></sum-meter-report>
    </ng-template>
  </ngf-tab>
  <ngf-tab
    [title]="'FACILITIES.TREND' | translate"
    [id]="ReportType.Trend"
  >
    <ng-template ngfTabContent>
      <trend-meter-report></trend-meter-report>
    </ng-template>
  </ngf-tab>
  <ngf-tab
    [title]="'FACILITIES.FORECAST' | translate"
    [id]="ReportType.Forecast"
  >
    <ng-template ngfTabContent>
      <forecast-meter-report></forecast-meter-report>
    </ng-template>
  </ngf-tab>
</ngf-tabset>
