import { Injectable, OnDestroy } from '@angular/core';

import { combineLatest, Observable, Subject } from 'rxjs';
import { map, mergeMap, mergeWith, take, takeUntil, tap } from 'rxjs/operators';

import { MeterManagementClient, MeterManagementMeter } from '@enerkey/clients/meter-management';

import {
  AddMetersToMeterGroupDto, AddMetersToMultipleMeterGroupsDto, MeterGroupBulkUpdateResultCollectionDto,
  MeterGroupCreateDto, MeterGroupDto, MeterGroupMetadataUpdateDto, MeterGroupMeterCreateDto, MeteringClient,
  RemoveMetersFromMeterGroupDto, RemoveMetersFromMultipleMeterGroupsDto
} from '@enerkey/clients/metering';

import { UserService } from '../../../../services/user-service';
import { FacilityService } from '../../../../shared/services/facility.service';
import { CacheManagerObservable } from '../../../../shared/utils/cache/cache.manager.util';
import { MeterGroupWithMeters } from '../../models/meter-groups-grid.model';
import { MeterGroup, MeterGroupBaseData } from '../../models/meter-groups.model';

@Injectable({ providedIn: 'root' })
export class MeterGroupsService implements OnDestroy {
  public invalidateMeterGroupsCache$: Observable<void>;
  public meterGroupCreate$: Observable<MeterGroupDto>;
  public meterGroupUpdate$: Observable<MeterGroupDto>;
  public meterGroupDelete$: Observable<void>;

  private readonly _meterGroupsCache: CacheManagerObservable<string, MeterGroupDto[]>;
  private readonly _metersCache: CacheManagerObservable<string, Record<string, MeterManagementMeter[]>>;

  private readonly _invalidateMeterGroupsCache$ = new Subject<void>();
  private readonly _meterGroupCreate$ = new Subject<MeterGroupDto>();
  private readonly _meterGroupUpdate$ = new Subject<MeterGroupDto>();
  private readonly _meterGroupDelete$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly meteringClient: MeteringClient,
    private readonly meterManagementClient: MeterManagementClient,
    private readonly facilityService: FacilityService,
    private readonly userService: UserService
  ) {
    this._meterGroupsCache = new CacheManagerObservable<string, MeterGroupDto[]>(60 * 5);
    this._metersCache = new CacheManagerObservable<string, Record<string, MeterManagementMeter[]>>(60 * 5);

    this.invalidateMeterGroupsCache$ = this._invalidateMeterGroupsCache$.asObservable();
    this.meterGroupCreate$ = this._meterGroupCreate$.asObservable();
    this.meterGroupUpdate$ = this._meterGroupUpdate$.asObservable();
    this.meterGroupDelete$ = this._meterGroupDelete$.asObservable();

    this.invalidateMeterGroupsCache$.pipe(
      tap(_ => this._meterGroupsCache.clear()),
      takeUntil(this._destroy$)
    ).subscribe();

    this.meterGroupCreate$.pipe(
      mergeWith(this.meterGroupUpdate$, this.meterGroupDelete$),
      tap(_ => this.invalidateMeterGroupsCache()),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._meterGroupCreate$.complete();
    this._meterGroupUpdate$.complete();
    this._meterGroupDelete$.complete();
    this._invalidateMeterGroupsCache$.complete();
  }

  public getMeterGroups(meterGroupIds?: number[]): Observable<MeterGroupDto[]> {
    const cacheKey = meterGroupIds?.length ? meterGroupIds?.sort((a, b) => a - b).join(',') : 'all';
    return this._meterGroupsCache.getOrFetch(cacheKey, () => this.meteringClient.getMeterGroups(meterGroupIds));
  }

  /**
   * Get meter groups associated with the current profile.
   *
   * @param {number} [facilityId] - Optional
   * @param {number} [meterGroupId] - Optional
   * @param {boolean} [onlyMetersOnCurrentProfile] - Optional.
   * If set to true (default), only meters associated with the current profile's facilities will be included on the
   * meter group.
   * @returns {Observable<MeterGroupDto[]>} An observable emitting the meter groups.
   */
  public getMeterGroupsOnCurrentProfile(
    facilityId?: number,
    meterGroupId?: number,
    onlyMetersOnCurrentProfile: boolean = true
  ): Observable<MeterGroupDto[]> {
    return this.getBaseData(facilityId, meterGroupId, {
      onlyMeterGroupsOnCurrentProfile: true,
      onlyMetersOnCurrentProfile
    }).pipe(
      map(({ meterGroups }) => meterGroups)
    );
  }

  public createMeterGroup(meterGroup: MeterGroup): Observable<MeterGroupDto> {
    const data = new MeterGroupCreateDto({
      name: meterGroup.name,
      description: meterGroup.description,
      quantityGroupId: 1,
      owningProfileId: this.userService.profileId
    });

    return this.meteringClient.createMeterGroup(data).pipe(
      take(1),
      tap(createdMeterGroup => this._meterGroupCreate$.next(createdMeterGroup)),
      takeUntil(this._destroy$)
    );
  }

  public updateMeterGroup(meterGroup: MeterGroup): Observable<MeterGroupDto> {
    return this.meteringClient.updateMeterGroupMetadata(meterGroup.id, new MeterGroupMetadataUpdateDto({
      name: meterGroup.name,
      description: meterGroup.description,
      quantityGroupId: meterGroup.quantityGroupId
    })).pipe(
      take(1),
      tap(updatedMeterGroup => this._meterGroupUpdate$.next(updatedMeterGroup)),
      takeUntil(this._destroy$)
    );
  }

  public deleteMeterGroup(meterGroupId: number): Observable<void> {
    return this.meteringClient.deleteMeterGroup(meterGroupId).pipe(
      take(1),
      tap(_ => this._meterGroupDelete$.next()),
      takeUntil(this._destroy$)
    );
  }

  public addMeterToMeterGroupBatch(
    meterGroupsWithMeters: MeterGroupWithMeters[]
  ): Observable<MeterGroupBulkUpdateResultCollectionDto> {
    const mgMetersDtoList: AddMetersToMeterGroupDto[] = meterGroupsWithMeters.map(meterGroupWithMeters =>
      new AddMetersToMeterGroupDto({
        meterGroupId: meterGroupWithMeters.meterGroupId,
        meters: meterGroupWithMeters.meters.map(meter => new MeterGroupMeterCreateDto({
          meterId: meter.id,
          weight: meter.weight
        }))
      }));

    const body = new AddMetersToMultipleMeterGroupsDto({ requests: mgMetersDtoList });

    return this.meteringClient.addMetersToMeterGroups(body).pipe(
      take(1),
      tap(_ => this.invalidateMeterGroupsCache()),
      takeUntil(this._destroy$)
    );
  }

  public removeMeterFromMeterGroupBatch(
    meterGroupsWithMeters: MeterGroupWithMeters[]
  ): Observable<MeterGroupBulkUpdateResultCollectionDto> {
    const mgMetersDtoList: RemoveMetersFromMeterGroupDto[] = meterGroupsWithMeters.map(meterGroupWithMeters =>
      new RemoveMetersFromMeterGroupDto({
        meterGroupId: meterGroupWithMeters.meterGroupId,
        meterIds: meterGroupWithMeters.meters.map(meter => meter.id)
      }));

    const body = new RemoveMetersFromMultipleMeterGroupsDto({ requests: mgMetersDtoList });

    return this.meteringClient.removeMetersFromMeterGroups(body).pipe(
      take(1),
      tap(_ => this.invalidateMeterGroupsCache()),
      takeUntil(this._destroy$)
    );
  }

  /**
   * Retrieves base data consisting of meter groups, (profile) facilities and meters.
   *
   * @param {number} [facilityId] - Optional facility ID to filter facilities.
   * @param {number} [meterGroupId] - Optional meter group ID to filter meter groups.
   * @param {{ onlyMeterGroupsOnCurrentProfile: boolean, onlyMetersOnCurrentProfile: boolean }} [filter] - Optional:
   * - onlyMeterGroupsOnCurrentProfile: If set to true, meter groups not associated with the current profile's
   * facilities will be excluded.
   * - onlyMetersOnCurrentProfile: If set to true, meters on a meter group not associated with the current profile's
   * facilities will be excluded. Requires onlyMeterGroupsOnCurrentProfile to be set to true.
   *
   * @returns {Observable<MeterGroupBaseData>} An observable emitting the base data.
   */
  public getBaseData(
    facilityId?: number,
    meterGroupId?: number,
    filter: { onlyMeterGroupsOnCurrentProfile?: boolean, onlyMetersOnCurrentProfile?: boolean } = {
      onlyMeterGroupsOnCurrentProfile: false,
      onlyMetersOnCurrentProfile: false
    }
  ): Observable<MeterGroupBaseData> {
    if (filter.onlyMetersOnCurrentProfile && !filter.onlyMeterGroupsOnCurrentProfile) {
      throw new Error('onlyMetersOnCurrentProfile requires onlyMeterGroupsOnCurrentProfile to be set to true.');
    }

    const isFacilityIdDefined = Number.isFinite(facilityId);
    return combineLatest([
      this.getMeterGroups().pipe(
        map(meterGroups => Number.isFinite(meterGroupId) ?
          meterGroups.filter(meterGroup => meterGroup.id === meterGroupId) :
          meterGroups)
      ),
      this.facilityService.profileFacilities$.pipe(
        map(facilities => isFacilityIdDefined ?
          facilities.filter(facility => facility.facilityId === facilityId) :
          facilities)
      )
    ]).pipe(
      mergeMap(([meterGroups, facilities]) => {
        const facilityIds = facilities.map(facility => facility.facilityId);
        return this.getMetersByFacilityIds(facilityIds).pipe(
          map(meters => {
            const filteredMeterGroups = filter.onlyMeterGroupsOnCurrentProfile
              ? this.filterMeterGroupsByMetersOnCurrentProfile(meterGroups, meters, filter.onlyMetersOnCurrentProfile)
              : meterGroups;

            return { meterGroups: filteredMeterGroups, facilities, meters };
          })
        );
      })
    );
  }

  public invalidateMeterGroupsCache(): void {
    this._invalidateMeterGroupsCache$.next();
  }

  private getMetersByFacilityIds(facilityIds: number[]): Observable<Record<string, MeterManagementMeter[]>> {
    const cacheKey = facilityIds.sort((a, b) => a - b).join(',');
    return this._metersCache.getOrFetch(cacheKey, () => this.meterManagementClient.getMetersByFacility(facilityIds));
  }

  /**
   * Filters meter groups based on meters associated with the current profile's facilities.
   * @param {MeterGroupDto[]} meterGroups - List of meter groups to filter.
   * @param {Record<string, MeterManagementMeter[]>} facilityMeters - Meters associated with the current profile's
   * facilities.
   * @param {boolean} [onlyMetersOnCurrentProfile] - Optional. If set to true, only meters associated with the
   * current profile's facilities will be included.
   * @private
   */
  private filterMeterGroupsByMetersOnCurrentProfile(
    meterGroups: MeterGroupDto[],
    facilityMeters: Record<string, MeterManagementMeter[]>,
    onlyMetersOnCurrentProfile: boolean = false
  ): MeterGroupDto[] {
    const meters = Object.values(facilityMeters).flat();
    const meterIds = [...new Set(meters.map(meter => meter.id))];

    return meterGroups
      .filter(meterGroup => {
        const hasEmptyMeterGroupMeters = meterGroup.meters.length === 0;
        const hasMetersFromProfileFacilities = meterGroup.meters.some(meter => meterIds.includes(meter.meterId));
        return hasEmptyMeterGroupMeters || hasMetersFromProfileFacilities;
      })
      .map(meterGroup => onlyMetersOnCurrentProfile
        ? new MeterGroupDto({
          ...meterGroup,
          meters: meterGroup.meters.filter(meter => meterIds.includes(meter.meterId))
        })
        : meterGroup);
  }
}
