import _ from 'lodash';
import { RejectType } from '@uirouter/core';

run.$inject = [
  '$rootScope', '$state', '$window', 'authenticationService', 'UserService',
  'StateLocationService', 'LocalStorageService',
  '$translate', 'languageChangeService', '$transitions',
  '$timeout', 'serviceLevelService', 'TelemetryService',
  '$location', 'OAuthService', 'BookmarkService'
];

const stateAuthCriteria = {
  to: state => state.data?.auth
};

function run(
  $rootScope, $state, $window, authenticationService, UserService,
  StateLocationService, LocalStorageService,
  $translate, languageChangeService, $transitions,
  $timeout, serviceLevelService, telemetryService,
  $location, oauthService,
  // Create instance of bookmark service eagerly
  _bookmarkService
) {
  // register state accesses
  languageChangeService.setLocaleInitial();

  let state;
  let profilesResolved = false;

  UserService.isInitializedWithInitialProfileAsync()
    .then(() => {
      profilesResolved = true;

      telemetryService.setAuthenticatedUserContext(
        authenticationService.getUserId(),
        authenticationService.organizationId
      );

      const initState = LocalStorageService.getValueByKeyAndRemove('initGoToState');
      // Go to certain state when opening new tab (see StateLocationService.openStateInNewTab)
      if (initState) {
        $state.go(initState.name, initState.params);
        state = null;
        return;
      }

      // Get previous state and params in case user refreshed the page
      const redirect = LocalStorageService.getValueByKeyAndRemove('redirect');
      if (redirect) {
        state = { name: redirect.state, params: redirect.stateParams };
      }

      const url = oauthService.state;
      const urlWithoutParams = url?.split('?')?.[0];

      const stateUrl = $state.href(state?.name);

      // If address bar url differs from state in localstorage use the address bar url/state
      if (stateUrl && (stateUrl !== urlWithoutParams)) {
        state = null;
      }

      if (state?.name && state.name !== 'error') {
        $state.go(state.name, state.params);
      } else if (urlWithoutParams && urlWithoutParams !== '/') {
        $location.url(url);
      } else {
        $state.go('dashboard');
      }

      state = null;
    })
    .catch(() => {
      // fetching profiles failed
      profilesResolved = true;
      $state.go('error', { errorReasonKey: 'INITIALIZATION_FAILED', errorMessage: 'Failed to get profiles' });
    })
    .finally(() => {
      // remove loader
      angular.element('.initial-loader').remove();
      angular.element('#initial-load-style').remove();
    })
  ;

  $transitions.onStart({}, transition => {
    if (authenticationService.isIdentityResolved) {
      if (!profilesResolved) {
        // prevent state change until profiles are resolved
        state = { name: transition.to().name, params: transition.params() };
        return false;
      }
    }
  }, { priority: 10 });

  $transitions.onStart(stateAuthCriteria, transition => {
    if (!UserService.hasAccess(transition.to().data.auth)) {
      return transition.router.stateService.target('forbidden');
    }
  }, { priority: 9 });

  $transitions.onSuccess({}, transition => {
    telemetryService.trackPageView(transition.to().name);
  });

  $window.addEventListener('beforeunload', () => {
    const redirect = LocalStorageService.getValueByKey('redirect');
    const stateName = $state.current.name;
    if (!redirect && stateName !== 'error') {
      LocalStorageService.saveValueByKey('redirect', { state: stateName, stateParams: $state.params });
    }
  });

  // Ignore "Transition superseded" errors in select states to avoid console spam
  $state.defaultErrorHandler(err => {
    if (err.type === RejectType.SUPERSEDED &&
      _.get(err, 'detail._targetState._identifier') === 'facilities.grid') {
      return;
    }

    throw err;
  });
}

export default run;
