import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'grid-input-search',
  templateUrl: './grid-input-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridInputSearchComponent extends BaseFilterCellComponent implements OnInit {
  @Input() public override filter: CompositeFilterDescriptor;
  @Input() public field: string;
  @Input() public placeholder: string;

  public searchText: string = '';

  public constructor(filterService: FilterService,
    private readonly translate: TranslateService) {
    super(filterService);
  }

  public ngOnInit(): void {
    this.placeholder = this.placeholder ? this.placeholder : this.translate.instant('SEARCH');
    const filter = this.filterByField(this.field);
    this.searchText = filter ? filter.value : '';
  }

  public onSearch(): void {
    this.applyFilter(
      this.searchText
        ? this.updateFilter({
          field: this.field,
          operator: 'contains',
          value: this.searchText,
        })
        : this.removeFilter(this.field)
    );
  }
}
