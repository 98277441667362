import {
  AfterViewInit,
  Component,
  Injector,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { ModalService } from '@enerkey/foundation-angular';

import { ContractGridComponent } from '../contract-grid/contract-grid.component';
import { ContractEditModalComponent } from '../contract-edit-modal/contract-edit-modal.component';
import { ContractSearchService } from '../../services/contract-search.service';
import { ContractTerminateModalComponent } from '../contract-terminate-modal/contract-terminate-modal.component';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { ContractMassAddComponent } from '../contract-mass-add/contract-mass-add.component';
import { ProductNameModalComponent } from '../product-name-modal/product-name-modal.component';
import { TemplateLifterService } from '../../../../shared/services/template-lifter.service';

@Component({
  selector: 'contracts',
  templateUrl: './contracts.component.html',
  providers: [ContractSearchService]
})
export class ContractsComponent implements AfterViewInit, OnDestroy {

  @ViewChild('gridComponent', { static: true })
  public readonly gridComponent: ContractGridComponent;

  @ViewChild('topbarTemplate')
  private readonly topRightTemplate: TemplateRef<unknown>;

  public constructor(
    private readonly contractService: ContractSearchService,
    private readonly modalService: ModalService,
    private readonly toaster: ToasterService,
    private readonly injector: Injector,
    private readonly templateLifter: TemplateLifterService
  ) { }

  public ngAfterViewInit(): void {
    this.templateLifter.template = this.topRightTemplate;
  }

  public ngOnDestroy(): void {
    this.templateLifter.template = null;
  }

  public openMassCreateModal(): void {
    const modal = this.modalService.open(ContractMassAddComponent);
    const subscription = modal.componentInstance.createCompleted.subscribe(
      () => this.contractService.repeatPreviousSearch()
    );
    modal.result.finally(() => subscription.unsubscribe());
  }

  public openEditModal(): void {
    const modal = this.modalService.open(ContractEditModalComponent);
    modal.componentInstance.setContracts(this.gridComponent.selectedRows);
    modal.result.resolved(() => this.contractService.repeatPreviousSearch());
  }

  public openTerminateModal(): void {
    const selectedRows = this.gridComponent.selectedRows;

    if (selectedRows.some(row => !!row.toDate)) {
      this.toaster.error('ADMIN.CONTRACTS.ALREADY_TERMINATED');
      return;
    }

    const modal = this.modalService.open(ContractTerminateModalComponent, { injector: this.injector });
    modal.componentInstance.setContractProducts(selectedRows.map(row => row.id));
    modal.result.resolved(() => this.contractService.repeatPreviousSearch());
  }

  public openProductNameModal(): void {
    this.modalService.open(ProductNameModalComponent);
  }
}
