<div>
  <div class="widget-filter-container">
    <quantity-dropdown
      *ngIf="(quantities$ | async) as quantities"
      [formControl]="selectedQuantityIdControl"
      [quantities]="quantities"
    ></quantity-dropdown>
    <button
      (click)="openFacilityReport()"
      class="button button--link button--external-link"
    >
      {{'FACILITIES.SHOW_FACILITY' | translate}}
    </button>
  </div>
  <div class="consumptions-chart-grid">
    <ng-container *ngIf="(data$ | async)?.length; else noData">
      <div class="chart-container">
        <div
          *ngFor="let quantity of (data$ | async); trackBy: trackByMethod"
          class="card"
        >
          <quantity-chart-header [seriesCollection]="quantity"></quantity-chart-header>
          <widget-consumption-chart
            (seriesClick)="openFacilityReport()"
            [series]="quantity.series"
            [quantityId]="quantity.quantityId"
            [labelSettings]="quantity.series | chartCategories:(params$ | async) : chartLabelSettingOverrides | async"
          ></widget-consumption-chart>
        </div>
      </div>
    </ng-container>
    <ng-template #noData>
      <span>{{ 'WIDGET.NO_DATA' | translate }}</span>
    </ng-template>
  </div>
</div>
