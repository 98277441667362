import { subMinutes } from 'date-fns';

import { ActionSlimViewModel } from '@enerkey/clients/attachments';
import { LogLiteDto } from '@enerkey/clients/alarm';

import { ReportNote } from '../../../energy-reporting/shared/report-note';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { GraphPlainEvents } from '../../services/report-events.service';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface NoteAlarmsAndActions {
  alarms: ReportNote[];
  actions: ReportNote[];
}

export interface NoteProcessedData {
  alarms: ReportNote[];
  actions: ReportNote[];
  facilityId: number;
  quantityId: number;
  meterId?: number;
}

export type NoteRawData = {
  notes?: GraphPlainEvents,
  searchParams: ReportingSearchParams,
  facilityId: number,
  quantityId: number,
  meterId?: number,
};

export function toNoteData(value: NoteRawData): NoteProcessedData {
  return {
    ...getNotesRelevantToGraph(value),
    facilityId: value.facilityId,
    quantityId: value.quantityId,
    meterId: value.meterId ?? undefined,
  };
}

function getNotesRelevantToGraph({
  notes,
  searchParams,
  quantityId,
  meterId,
  facilityId
}: NoteRawData): NoteAlarmsAndActions {
  const relevantActionsAndComments = getActionsRelevantToGraph(
    notes?.actions,
    searchParams,
    quantityId,
    facilityId,
    meterId
  );

  const relevantAlarms = getAlarmsRelevantToGraph(
    notes?.alarms,
    quantityId,
    facilityId,
    meterId
  ) as any as (ReportNote & { alarmTypeName?: string })[];

  return {
    actions: relevantActionsAndComments,
    alarms: relevantAlarms,
  };
}

/*
Returns an array of actions that are relevant for the given graph and time series.

Relevance is based on:
  - correct quantity (if action defines none, it is suitable for all quantities)
  - correct meter id (when in meter context. If action defines none, it is suitable for all meters)
  - either Effect start or Effect end date is within the time line of one of the given series
  - action type is other than general comment

Returns the actions sorted by their effect start or stop date, latest first. If both dates
match the series then sort using start date.
*/
function getActionsRelevantToGraph(
  actions: ActionSlimViewModel[],
  params: ReportingSearchParams,
  quantityId: number,
  facilityId: number,
  meterId?: number
): ReportNote[] {

  const periodStartAndEndDates = params.searchPeriods.map(period => ({
    start: period.start,
    end: subMinutes(period.end, 1)
  }));

  return (actions || [])
    .filter(n => n.reportingObjectId === facilityId)
    .filter(action => !Array.hasItems(action.quantityIds) || action.quantityIds.includes(quantityId))
    .filter(action => !Number.isInteger(meterId)
      || !Array.hasItems(action.meterIds) || action.meterIds.includes(meterId))
    .sortBy('updatedAt', 'desc')
    .map(action => {
      if (!action.effectStartsAt && !action.effectStopsAt) {
        return;
      }
      let matchedActionNote: ReportNote;
      for (const period of periodStartAndEndDates) {
        const startMatch =
          action.effectStartsAt >= period.start &&
          action.effectStartsAt <= period.end;
        const stopMatch =
          action.effectStopsAt >= period.start &&
          action.effectStopsAt <= period.end;

        if (startMatch) {
          matchedActionNote = ReportNote.parse(action, 'effectStartsAt');
          break;
        } else if (stopMatch) {
          matchedActionNote = ReportNote.parse(action, 'effectStopsAt');
          break;
        }
      }
      return matchedActionNote;
    })
    .filter(actionNote => actionNote)
    .sort((noteA, noteB) => noteA[noteA.matchedDate] > noteB[noteB.matchedDate] ? -1 : 1);
}

function getAlarmsRelevantToGraph(
  alarms: LogLiteDto[],
  quantityId: number,
  facilityId: number,
  meterId?: number
): ReportNote[] {
  return (alarms || [])
    .filter(alarm => alarm.facilityId === facilityId)
    .filter(alarm => alarm.quantityId === quantityId)
    .filter(alarm => Number(alarm.meterId) === meterId || meterId === undefined)
    .sortBy('executedAt', 'desc')
    .map(alarm => ReportNote.parse(alarm))
    .sort((noteA, noteB) => noteA[noteA.matchedDate] > noteB[noteB.matchedDate] ? -1 : 1);
}
