import { downgradeInjectable } from '@angular/upgrade/static';
import { OAuthService } from 'angular-oauth2-oidc';

import { ModalService } from '@enerkey/foundation-angular';

import 'angular-cookies';
import 'angular-dynamic-locale';
import 'angular-foundation-6';
import 'angular-translate';
import '@uirouter/angular-hybrid';
import 'ngtouch';

import { MeteringClient } from '@enerkey/clients/metering';
import { MeterManagementClient } from '@enerkey/clients/meter-management';
import { CostFactorClient } from '@enerkey/clients/cost-factor';
import { ContactClient } from '@enerkey/clients/contact';
import { ConfigurationControlClient } from '@enerkey/clients/configuration-control';
import { EnergyReportingClient } from '@enerkey/clients/energy-reporting';
import { FacilityClient } from '@enerkey/clients/facility';
import { AlarmClient } from '@enerkey/clients/alarm';
import { AttachmentsClient } from '@enerkey/clients/attachments';
import { SettingsClient } from '@enerkey/clients/settings';

import KendoModule from '../modules/kendo';
import LodashModule from '../modules/lodash';
import MomentModule from '../modules/moment';
import authHttpResponseInterceptor from './auth-http-response-interceptor';
import { AppStatusService } from './app-status-service';
import clickAnywhereButHereService from './click-anywhere-but-here-service';
import { ColorService } from '../shared/services/color.service';
import httpPendingRequestsService from './http-pending-requests-service';
import LoadingService from './loading-service';
import { MeterInterfaceService } from './meter-interface-service';
import { SpreadsheetService } from './spreadsheet-service';
import { Utils } from './utils';
import ScrollModule from './scroll';
import ModalServiceModule from './modal';
import { UserService } from './user-service';
import { EnvironmentService } from './environment-service';
import { LanguageChangeService } from '../shared/services/language-change.service';
import { LanguageService } from '../shared/services/language.service';
import { TerminalStatusComponent } from '../shared/terminal/terminal-status/terminal-status.component';
import { MeterService } from '../shared/services/meter.service';
import { GridStateService } from './grid-state-service';
import { KendoFunctions } from './kendo-functions';
import { StateLocationService } from './state-location.service';
import { EnergyReportingClientCacheService } from './energy-reporting-client-cache.service';
import { HttpConfigService } from './http-config.service';
import { DateFormatService } from '../shared/services/date-format.service';
import { ClipboardService } from '../shared/services/clipboard.service';
import { ToasterService } from '../shared/services/toaster.service';
import { ServiceLevelService } from '../shared/services/service-level.service';
import { ContentfulService } from '../shared/services/contentful.service';
import { TenantService } from '../shared/services/tenant.service';
import { ProfileService } from '../shared/services/profile.service';
import { TerminalService } from '../shared/services/terminal.service';
import { EmailSendingService } from '../shared/services/email-sending.service';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { RelationalValuesService } from '../shared/services/relational-values.service';
import { CompaniesService } from '../shared/services/companies.service';
import { FilterService } from './filter.service';
import { BookmarkService } from './bookmark.service';
import { FacilityService } from '../shared/services/facility.service';
import { TelemetryService } from './telemetry.service';
import { ReportingModalsService } from '../modules/reporting/services/reporting-modals.service';

export default angular.module('EnerKey2.Services', [
  'pascalprecht.translate',
  'ngCookies',
  'tmh.dynamicLocale',
  LodashModule.name,
  'ui.router',
  KendoModule.name,
  MomentModule.name,
  'mm.foundation',
  ScrollModule.name,
  ModalServiceModule.name
])
  .service('appStatusService', AppStatusService)
  .factory('authHttpResponseInterceptor', authHttpResponseInterceptor)
  .factory('clickAnywhereButHereService', clickAnywhereButHereService)
  .service('EnergyReportingClientCacheService', EnergyReportingClientCacheService)
  .factory('GridStateService', GridStateService)
  .factory('HttpPendingRequestsService', httpPendingRequestsService)
  .factory('KendoFunctions', KendoFunctions)
  .factory('LoadingService', LoadingService)
  .service('MeterInterfaceService', MeterInterfaceService)
  .service('SpreadsheetService', SpreadsheetService)
  .service('StateLocationService', StateLocationService)
  .service('TerminalService', TerminalService)
  .service('UserService', UserService)
  .service('utils', Utils)
  .factory('MeterManagementClient', downgradeInjectable(MeterManagementClient))
  .factory('MeteringClient', downgradeInjectable(MeteringClient))
  .factory('CostFactorClient', downgradeInjectable(CostFactorClient))
  .factory('ContactClient', downgradeInjectable(ContactClient))
  .factory('languageChangeService', downgradeInjectable(LanguageChangeService))
  .factory('languageService', downgradeInjectable(LanguageService))
  .factory('ConfigurationControlClient', downgradeInjectable(ConfigurationControlClient))
  .factory('TerminalStatusComponent', downgradeInjectable(TerminalStatusComponent))
  .factory('meterService', downgradeInjectable(MeterService))
  .service('modalServiceAngular', downgradeInjectable(ModalService))
  .factory('EnergyReportingClient', downgradeInjectable(EnergyReportingClient))
  .factory('AlarmClient', downgradeInjectable(AlarmClient))
  .service('httpConfigService', HttpConfigService)
  .factory('FacilityClient', downgradeInjectable(FacilityClient))
  .service('dateFormatService', downgradeInjectable(DateFormatService))
  .service('clipboard', downgradeInjectable(ClipboardService))
  .service('AttachmentsClient', downgradeInjectable(AttachmentsClient))
  .service('ToasterService', downgradeInjectable(ToasterService))
  .service('serviceLevelService', downgradeInjectable(ServiceLevelService))
  .service('contentfulService', downgradeInjectable(ContentfulService))
  .service('tenantService', downgradeInjectable(TenantService))
  .service('profileService', downgradeInjectable(ProfileService))
  .factory('TerminalService', downgradeInjectable(TerminalService))
  .factory('EmailSendingService', downgradeInjectable(EmailSendingService))
  .factory('LocalStorageService', downgradeInjectable(LocalStorageService))
  .factory('authenticationService', downgradeInjectable(AuthenticationService))
  .factory('relationalValuesService', downgradeInjectable(RelationalValuesService))
  .factory('companiesService', downgradeInjectable(CompaniesService))
  .factory('ColorService', downgradeInjectable(ColorService))
  .service('filterService', downgradeInjectable(FilterService))
  .service('BookmarkService', downgradeInjectable(BookmarkService))
  .service('FacilityService', downgradeInjectable(FacilityService))
  .service('TelemetryService', downgradeInjectable(TelemetryService))
  .factory('OAuthService', downgradeInjectable(OAuthService))
  .factory('UserService', downgradeInjectable(UserService))
  .service('SettingsClient', downgradeInjectable(SettingsClient))
  .service('EnvironmentService', downgradeInjectable(EnvironmentService))
  .service('reportingModalsService', downgradeInjectable(ReportingModalsService))
;
