import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
  CellClickEvent, CellCloseEvent, RowClassArgs, SelectableSettings, SelectionChangeEvent, TreeListComponent
} from '@progress/kendo-angular-treelist';

import { BehaviorSubject, EMPTY, Observable, of, startWith, Subject, switchMap, take, throwError, timeout } from 'rxjs';
import { catchError, filter, map, shareReplay, skip, takeUntil, tap } from 'rxjs/operators';

import { Quantities } from '@enerkey/clients/metering';
import { getNumericEnumValues } from '@enerkey/ts-utils';
import { MeteringType } from '@enerkey/clients/meter-management';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { quantityTranslations } from '../../../../../constants/quantity.constant';
import { KendoGridService } from '../../../../../shared/ek-kendo/services/kendo-grid.service';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import {
  FacilityGridItem, MeterGridItem, MeterGroupGridItem, MeterGroupGridItemBase, MeterGroupSelectionItem
} from '../../../models/meter-groups-grid.model';
import { MeterGroupError, MeterGroupFormValidationError } from '../../../models/meter-groups.model';
import { MeterGroupsGridService } from '../../../services/meter-groups-grid/meter-groups-grid.service';

@Component({
  selector: 'meter-groups-grid',
  templateUrl: './meter-groups-grid.component.html',
  styleUrls: ['./meter-groups-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KendoGridService]
})
export class MeterGroupsGridComponent implements OnDestroy {
  @ViewChild(TreeListComponent) public readonly treeList: TreeListComponent;
  @Input() public editMode: boolean = false;
  @Input() public meterGroupId?: number = null;
  @Input() public facilityId?: number = null;

  @Output() public readonly save = new EventEmitter<void>();
  @Output() public readonly cancel = new EventEmitter<void>();

  protected readonly meterGroupTreeList$: Observable<MeterGroupGridItemBase[]>;
  protected readonly quantities$: Observable<string[]>;
  protected readonly loading$: Observable<boolean>;
  protected readonly invalidEditForm$: Observable<boolean>;
  protected readonly activeFormGroup$: Observable<FormGroup>;
  protected readonly quantitiesLoading$: Observable<boolean>;

  protected readonly selectableSettings: SelectableSettings = {
    enabled: true,
    readonly: false,
    multiple: true,
    drag: false,
    mode: 'row'
  };

  protected selectedRows: MeterGroupSelectionItem[] = [];
  protected readonly meterTypes: readonly MeteringType[];

  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();
  private readonly _meterGroupTreeList$ = new BehaviorSubject<MeterGroupGridItemBase[]>(null);
  private readonly _invalidEditForm$ = new BehaviorSubject<boolean>(false);
  private readonly _activeFormGroup$ = new BehaviorSubject<FormGroup>(null);
  private readonly _quantitiesLoading = new BehaviorSubject<boolean>(false);

  public constructor(
    private readonly meterGroupsGridService: MeterGroupsGridService,
    private readonly toasterService: ToasterService
  ) {
    this.meterGroupTreeList$ = this._meterGroupTreeList$.asObservable();
    this.loading$ = this._loading$.asObservable();
    this.invalidEditForm$ = this._invalidEditForm$.asObservable();
    this.activeFormGroup$ = this._activeFormGroup$.asObservable();
    this.quantitiesLoading$ = this._quantitiesLoading.asObservable();

    this.meterTypes = getNumericEnumValues(MeteringType);

    this.activeFormGroup$.pipe(
      filter(formGroup => formGroup !== null),
      switchMap(formGroup => formGroup.valueChanges.pipe(
        startWith(formGroup),
        tap(() => this._invalidEditForm$.next(formGroup.invalid))
      )),
      takeUntil(this._destroy$)
    ).subscribe();

    this.quantities$ = this.meterGroupTreeList$.pipe(
      tap(() => this._quantitiesLoading.next(true)),
      switchMap(() => this.meterGroupsGridService.getDistinctQuantityIdsFromMeterGroups(
        this.facilityId,
        this.meterGroupId
      )),
      map(ids => ids.map(id => quantityTranslations[id as Quantities])),
      tap(() => this._quantitiesLoading.next(false)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public reloadData(filterByActiveMeters: boolean = false): void {

    this.meterGroupsGridService.getMeterGroupsTreeList(
      this.facilityId,
      this.meterGroupId,
      filterByActiveMeters
    ).pipe(
      take(1),
      indicate(this._loading$),
      tap(meterGroups => this._meterGroupTreeList$.next(meterGroups)),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._meterGroupTreeList$.complete();
    this._invalidEditForm$.complete();
    this._activeFormGroup$.complete();
    this._quantitiesLoading.complete();
  }

  protected rowCssClassFn(context: RowClassArgs): Record<string, boolean> {
    if (context.dataItem instanceof MeterGroupGridItem) { return { 'meter-group-row': true }; }
    if (context.dataItem instanceof FacilityGridItem) { return { 'facility-row': true }; }
    if (context.dataItem instanceof MeterGridItem) { return { 'meter-row': true }; }
  }

  protected createFormGroup(item: MeterGridItem): FormGroup {
    return new FormGroup({
      percentage: new FormControl(item.percentage, [
        Validators.min(0),
        Validators.max(100)
      ]),
      subtract: new FormControl(item.subtract)
    });
  }

  protected isItemSelected(dataItem: MeterGroupGridItemBase): boolean {
    return dataItem.isActive;
  }

  protected onSaveClick(): void {
    this.activeFormGroup$.pipe(
      take(1),

      // Ensure that any edited cell is closed by waiting for activeFormGroup$ to emit null
      switchMap(formGroup => formGroup === null ?
        of(null) :
        this.activeFormGroup$.pipe(
          skip(1),
          take(1),
          timeout(2000)
        )),
      switchMap(() => this.invalidEditForm$.pipe(
        take(1),
        switchMap(invalid => invalid ?
          throwError(() => new MeterGroupFormValidationError()) :
          of(null))
      )),
      switchMap(() => this.meterGroupTreeList$.pipe(
        take(1)
      )),
      switchMap(items =>
        this.meterGroupsGridService.saveMeterGroupsWithMeters(items)),
      tap(res => {
        const errors: MeterGroupError[] = [];

        if (res.added instanceof MeterGroupError) { errors.push(res.added); }
        if (res.removed instanceof MeterGroupError) { errors.push(res.removed); }

        if (errors.length > 0) {
          throw new AggregateError(errors);
        } else {
          this.toasterService.success('ADMIN.METERGROUPS.METER_GROUP_METERS_UPDATE_SUCCESS');
          this.save.emit();
        }
      }),
      catchError(err => {
        const errorMessageKey = err instanceof MeterGroupFormValidationError ?
          'ADMIN.METERGROUPS.INVALID_FORM_INPUT' :
          'ADMIN.METERGROUPS.METER_GROUP_METERS_UPDATE_FAILURE';

        this.toasterService.error(errorMessageKey);
        return EMPTY;
      }),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  protected onCancelClick(): void {
    this.clearFormAndSelection();
    this.cancel.emit();
  }

  protected onSelectionChange(event: SelectionChangeEvent): void {
    if (!this.isValidSelectionChangeEvent(event)) { return; }

    // Check if form is valid, if not, prevent selection change
    this.activeFormGroup$.pipe(
      take(1),
      switchMap(formGroup => formGroup === null ? of(null) : this.invalidEditForm$.pipe(take(1))),
      filter(invalid => !invalid),
      tap(() => {
        this.treeList.closeCell();
        switch (event.action) {
          case 'add':
          case 'select':
            event.items.forEach(item => { (item.dataItem as MeterGroupGridItemBase).isActive = true; });
            break;
          case 'remove':
            event.items.forEach(item => { (item.dataItem as MeterGroupGridItemBase).isActive = false; });
            break;
        }
      }),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  protected onCellClick(event: CellClickEvent): void {
    if (!this.canEditCell(event)) { return; }
    if (!event.isEdited) {
      const formGroup = this.createFormGroup(event.dataItem as MeterGridItem);

      this._activeFormGroup$.next(formGroup);
      event.sender.editCell(event.dataItem, event.columnIndex, formGroup);
    }
  }

  protected onCellClose(event: CellCloseEvent): void {
    if (!this.canEditCell(event) || !event.formGroup.valid) { return event.preventDefault(); }

    if (event.formGroup.controls.percentage.dirty) {
      const percentage = event.formGroup.controls.percentage.value;

      // Ensure that percentage is always a number with three decimal places
      event.dataItem.percentage = Number.isFinite(percentage) ? Math.floor(percentage * 1000) / 1000 : percentage;
    }

    if (event.formGroup.controls.subtract.dirty) {
      event.dataItem.subtract = event.formGroup.controls.subtract.value;
    }

    this.clearFormAndSelection();
    event.sender.closeCell();
  }

  private clearFormAndSelection(): void {
    this._invalidEditForm$.next(false);
    this._activeFormGroup$.next(null);
    this.treeList.cancelCell();
  }

  private isValidSelectionChangeEvent(event: SelectionChangeEvent): boolean {
    if (!this.editMode) { return false; }

    const firstItem = event.items[0];
    const hasMultipleItems = event.items.length > 1;
    const isColumnIndexZero = firstItem?.columnIndex === 0;
    const isFirstItemMeterGridItem = firstItem?.dataItem instanceof MeterGridItem;

    return isFirstItemMeterGridItem ?
      isColumnIndexZero :
      isColumnIndexZero || hasMultipleItems;
  }

  private canEditCell(event: CellClickEvent | CellCloseEvent): boolean {
    return this.editMode &&
      this.isActiveItemEvent(event) &&
      this.isEditableColumnEvent(event) &&
      this.isEditableRowEvent(event);
  }

  private isEditableColumnEvent(cellClickEvent: CellClickEvent | CellCloseEvent): boolean {
    const editableColumns = ['percentage', 'subtract'];
    return editableColumns.includes(cellClickEvent?.column?.field);
  }

  private isEditableRowEvent(cellClickEvent: CellClickEvent | CellCloseEvent): boolean {
    return cellClickEvent?.dataItem instanceof MeterGridItem;
  }

  private isActiveItemEvent(cellClickEvent: CellClickEvent | CellCloseEvent): boolean {
    return cellClickEvent?.dataItem?.isActive === true;
  }
}
