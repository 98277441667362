import _ from 'lodash';
import moment from 'moment';
import { differenceInSeconds } from 'date-fns';

import { ModelStatus } from '@enerkey/clients/manual-qa';

import VALUE_TYPES from '../../constants/value-types';
import * as Configs from '../../constants/configs';
import SearchType from '../../constants/search-types';
import { clearPreviewValues, getModelingFunction } from '../../shared/modeling.functions';
import { ReadingConverter } from '../../shared/reading-converter';
import * as Utils from '../../shared/utils.functions';
import { ACTIONS } from '../manual-qa-actions-select/actions';
import * as Presets from '../manual-qa-change-time-frame-presets/presets';
import * as FetchType from '../manual-qa-fetch-type/manual-qa-fetch-type.constant';
import * as SeriesType from './manual-qa-inspect.constant';
import * as SeriesTypeFunctions from './manual-qa-inspect.functions';
import { padOrphanReadings } from '../manual-qa-chart/manual-qa-chart.functions';
import TimeFrame from '../../../../services/time-frame-service';
import { Reading } from '../../shared/reading';

function getItemIndex(array, item, field = 'id') {
  return array.findIndex(_item => _item[field] && item && item[field] && item[field] === _item[field]);
}

const $inject = [
  'ManualQaApiRawReadings',
  'ManualQaApiDefectIssue',
  'utils',
  '$q',
  'kendo',
  '$scope',
  'ManualQaPopupService',
  'modalService',
  'ManualQaApiInfoService',
];

function ManualQaInspectController(
  ManualQaApiRawReadings,
  ManualQaApiDefectIssue,
  utils,
  $q,
  kendo,
  $scope,
  ManualQaPopupService,
  modalService,
  ManualQaApiInfoService
) {
  const vm = this;

  vm.constants = { ...Configs, ...SearchType, ...FetchType };
  vm.isPreviewMode = false;
  vm.dataLoaded = false;
  vm.isLoading = false;
  vm.dataSource = new kendo.data.DataSource([]);
  vm.readings = [];
  vm.preset = Presets.PREVIOUS_PERIOD;

  vm.meters = [];
  vm.meter = null;
  vm.defects = [];
  vm.defect = null;
  vm.selectedMeterIndex = 0;
  vm.selectedDefectIndex = 0;
  vm.originalActualTimeFrame = null;

  vm.rawReadings = {
    actual: [],
    comparison: []
  };
  vm.isSearchOpen = true;
  vm.fetchType = FetchType.HOUR;
  vm.useComparisonPeriod = false;

  vm.$onInit = onInit;
  vm.createPreview = createPreview;
  vm.clearPreview = clearPreview;
  vm.saveChanges = saveChanges;
  vm.lockModel = lockModel;
  vm.onValueChange = onValueChange;
  vm.getHourlyModel = getHourlyModel;
  vm.getReadings = getReadings;
  vm.focusOnMissingReadings = focusOnMissingReadings;
  vm.handleSave = handleSave;
  vm.handleDeleteModel = handleDeleteModel;
  vm.handleDeleteConsumption = handleDeleteConsumption;
  vm.onFetchTypeChange = onFetchTypeChange;
  vm.confirmMarkAsDone = confirmMarkAsDone;
  vm.markAsDone = markAsDone;
  vm.onPlotAreaClick = onPlotAreaClick;

  vm.onChangeMeter = onChangeMeter;
  vm.onChangeDefect = onChangeDefect;
  vm.onChangeComparisonTimeFrame = onChangeComparisonTimeFrame;
  vm.onChangeActualTimeFrame = onChangeActualTimeFrame;
  vm.onChangeOperationTimeFrame = onChangeOperationTimeFrame;
  vm.onChangeTimeFramePreset = onChangeTimeFramePreset;
  vm.onChangeUseComparisonPeriod = onChangeUseComparisonPeriod;
  vm.adjustActualTimeFrameAccordingToPreset = adjustActualTimeFrameAccordingToPreset;

  vm.toggleSearch = toggleSearch;
  vm.getSpreadsheetClass = getSpreadsheetClass;
  vm.broadcastResize = broadcastResize;

  vm.$onDestroy = function() {
    setTimeout(() => {
      jQuery('body').children('.k-calendar-container, .k-list-container').remove();
    }, 100);
  };

  function onChangeComparisonTimeFrame(timeFrame) {
    vm.comparisonTimeFrame = timeFrame.uniformLengthWith(vm.actualTimeFrame);

    if (vm.useComparisonPeriod) {
      getReadings(SeriesType.COMPARISON);
    }
  }

  function onChangeActualTimeFrame(timeFrame) {
    vm.originalActualTimeFrame = angular.copy(timeFrame);
    vm.actualTimeFrame = angular.copy(timeFrame);
    vm.comparisonTimeFrame = vm.comparisonTimeFrame
      .uniformLengthWith(vm.actualTimeFrame)
      .alignToSameWeekDay(vm.actualTimeFrame);

    getReadingsAndDefectsIfNeeded();
  }

  function onChangeOperationTimeFrame(timeFrame) {
    vm.operationTimeFrame = angular.copy(timeFrame);
    vm.viewTimeFrame = getExpandedViewTimeFrame();

    vm.clearPreview(false);
  }

  function onChangeTimeFramePreset(preset) {
    vm.preset = preset;
    vm.comparisonTimeFrame = getTimeFrameFromPreset();

    if (vm.useComparisonPeriod) {
      getReadings(SeriesType.COMPARISON);
    }
  }

  function adjustActualTimeFrameAccordingToPreset(preset) {
    const monthsToAdd = preset === Presets.PREVIOUS_PERIOD ? -1 : 1;
    const fromDate = vm.actualTimeFrame.getFromDate().add(monthsToAdd, 'months').startOf('month');
    const toDate = fromDate.clone().endOf('month');

    onChangeActualTimeFrame(new TimeFrame(fromDate, toDate));
    onChangeOperationTimeFrame(new TimeFrame(fromDate, toDate));
  }

  function onChangeUseComparisonPeriod(useComparisonPeriod) {
    vm.useComparisonPeriod = useComparisonPeriod;
    broadcastResize();

    getReadings(SeriesType.COMPARISON);
  }

  /**
   * Finds time frame for empty hourly readings and sets it as operation period
   */
  function focusOnMissingReadings() {
    try {
      vm.operationTimeFrame = Utils.findEmptyHourlyReadingsTimeFrame(vm.dataSource.data());
      vm.viewTimeFrame = getExpandedViewTimeFrame();
      vm.clearPreview(false);
    } catch (exception) {
      ManualQaPopupService.errorPopUp(exception.message);
    }
  }

  function onInit() {
    vm.originalActualTimeFrame = angular.copy(vm.actualTimeFrame);
    vm.actualTimeFrame = angular.copy(vm.actualTimeFrame);
    vm.operationTimeFrame = angular.copy(vm.actualTimeFrame);
    vm.viewTimeFrame = getExpandedViewTimeFrame();
    vm.comparisonTimeFrame = getTimeFrameFromPreset();

    ManualQaApiInfoService.getMeterInfo(vm.meterIds).then(meters => {
      vm.meters = meters;

      const defaultMeter = vm.defaultMeterId
        ? vm.meters.find(meter => meter.id === vm.defaultMeterId)
        : vm.meters[0]
      ;
      onChangeMeter(defaultMeter);
    });
  }

  function onChangeDefect(defect) {
    const index = getItemIndex(vm.defects, defect);

    vm.selectedDefectIndex = index > 0 ? index : 0;

    handleDefectChange(vm.defects[vm.selectedDefectIndex], true);
  }

  function handleDefectChange(defect, shouldClearPreview = false) {
    if (!defect) {
      return;
    }

    vm.defect = defect;
    vm.operationTimeFrame = angular.copy(defect.getTimeFrame());
    vm.viewTimeFrame = getExpandedViewTimeFrame();

    if (shouldClearPreview) {
      vm.clearPreview(false);
    }
  }

  function setLoading(value) {
    vm.isLoading = value;
  }

  function onChangeMeter(meter, movingForward = true) {
    if (!meter) {
      return;
    }

    setLoading(true);

    const index = getItemIndex(vm.meters, meter);

    vm.selectedMeterIndex = index > 0 ? index : 0;
    vm.selectedDefectIndex = 0;
    vm.meter = vm.meters[vm.selectedMeterIndex];

    // Restore original actual timeframe on meter change.
    vm.actualTimeFrame = angular.copy(vm.originalActualTimeFrame);
    vm.operationTimeFrame = angular.copy(vm.actualTimeFrame);
    vm.viewTimeFrame = getExpandedViewTimeFrame();

    getReadingsAndDefectsIfNeeded(movingForward);
  }

  function getReadingsAndDefectsIfNeeded(movingForward = true) {
    setLoading(true);

    if (vm.searchType !== SearchType.STORAGE_TYPE.DEFECT) {
      getReadings();
    } else {
      ManualQaApiDefectIssue.getDefectIssuesWithFaults(vm.actualTimeFrame, [vm.meter.getId()])
        .then(defects => {
          if (defects.length === 0) {
            return;
          }

          vm.selectedDefectIndex = movingForward ? 0 : defects.length - 1;
          vm.defects = angular.copy(defects);

          adjustDatesForDefects(vm.defects);
          handleDefectChange(vm.defects[vm.selectedDefectIndex]);
        })
        .then(getReadings)
        .then(() => vm.clearPreview(false, false))
        .catch(() => {
          ManualQaPopupService.infoPopUp('MQA.INFO.GET_DEFECT_ISSUES_ERROR');
          vm.defects = [];
          vm.defect = null;

          return getReadings();
        });
    }
  }

  /**
   * Adjust actual and comparison time frames using given defects
   *
   * @param {DefectIssue[]} defects
   */
  function adjustDatesForDefects(defects) {
    if (defects && defects.length === 0) {
      return;
    }

    const timeFrame = Utils.getMinMaxDatesFromDefectsList(defects).expand(12, 'hours');

    vm.actualTimeFrame = vm.actualTimeFrame.adjustToContain(timeFrame);
    vm.comparisonTimeFrame = getTimeFrameFromPreset();
  }

  /**
   * Gets raw readings from backend
   *
   * @returns {Object}
   */
  function getReadings(seriesType = SeriesType.BOTH) {
    const promises = [];

    setLoading(true);

    vm.dataLoaded = false;

    if (SeriesTypeFunctions.isSeriesTypeActualOrBoth(seriesType)) {
      const requestTimeFrame = angular.copy(vm.actualTimeFrame);
      requestTimeFrame.toDate = requestTimeFrame.toDate.startOf('hour').add(1, 'hours');

      promises.push(ManualQaApiRawReadings.getReadings(requestTimeFrame, [vm.meter.getId()], vm.fetchType));
    }

    if (SeriesTypeFunctions.isSeriesTypeComparisonOrBoth(seriesType) && vm.useComparisonPeriod) {
      const requestTimeFrame = angular.copy(vm.comparisonTimeFrame);
      requestTimeFrame.toDate = requestTimeFrame.toDate.startOf('hour').add(1, 'hours');

      promises.push(ManualQaApiRawReadings.getReadings(requestTimeFrame, [vm.meter.getId()], vm.fetchType));
    }

    function onGetDataError() {
      ManualQaPopupService.errorPopUp('MQA.ERRORS.INSPECT.GET_RAW_READINGS_ERROR');
    }

    function onGetDataFinally() {
      setLoading(false);
      vm.isPreviewMode = false;
    }

    return $q
      .all(promises)
      .then(_.partialRight(onGetDataSuccess, seriesType))
      .catch(onGetDataError)
      .finally(onGetDataFinally);
  }

  /**
   * Gets calculated consumption readings from backend and creates the preview.
   *
   * @param {ModelType} modelType
   */
  function getHourlyModel(modelType) {
    setLoading(true);

    return ManualQaApiRawReadings.getHourlyModel(vm.meter, vm.operationTimeFrame, modelType)
      .then(values => createPreview({ id: ACTIONS.CALCULATED }, { values }))
      .catch(error => {
        ManualQaPopupService.errorPopUp(getPreviewFailureErrorMessage(error));
      })
      .finally(() => setLoading(false));
  }

  function getPreviewFailureErrorMessage(error) {
    if (error) {
      switch (error.data) {
        case ModelStatus.TooLongPeriod:
          return 'MQA.ERRORS.INSPECT.TOO_LONG_PERIOD';
        case ModelStatus.MissingHistoryValues:
          return 'MQA.ERRORS.INSPECT.MISSING_HISTORY_VALUES';
      }
    }

    return 'MQA.ERRORS.INSPECT.GET_CALCULATED_CONSUMPTION_ERROR';
  }

  /**
   * Handles actual, comparison and model readings for ui.
   *
   * @param {Object} result
   * @param {String} type
   */
  function onGetDataSuccess(result, type) {
    vm.dataLoaded = true;

    switch (type) {
      case SeriesType.ACTUAL:
        vm.rawReadings.actual = _.get(result, '0.0.rawReadings', []);
        break;
      case SeriesType.COMPARISON:
        vm.rawReadings.comparison = _.get(result, '0.0.rawReadings', []);
        break;
      case SeriesType.BOTH:
        vm.rawReadings.actual = _.get(result, '0.0.rawReadings', []);
        vm.rawReadings.comparison = _.get(result, '1.0.rawReadings', []);
        break;
    }

    updateDataSource();
  }

  /**
   * Updates kendo DataSource with converted data and notifies all components
   * for a change.
   *
   * @param {boolean} [notifyChanges]
   */
  function updateDataSource(notifyChanges = true) {
    vm.dataSource.data(ReadingConverter.convert(vm.rawReadings, vm.defects, vm.fetchType));

    if (notifyChanges) {
      notifyChangeAllComponents();
    }
  }

  /**
   * Creates preview using actions parameters
   *
   * @see manual-qa-actions-select -component
   *
   * @param {Object}  [action]
   * @param {Object}  [parameters]
   * @param {Boolean} [showPopUp]
   */
  function createPreview(action, parameters = {}, showPopUp = false) {
    if (vm.isPreviewMode && (!action || action.id !== ACTIONS.MANUAL)) {
      clearPreview(false, false);
    }

    const readings = vm.dataSource.data();

    getModelingFunction(action)(readings, vm.operationTimeFrame, parameters);

    padOrphanReadings(readings);
    readings.trigger('change');
    vm.isPreviewMode = true;

    notifyChangeAllComponents();

    if (showPopUp) {
      ManualQaPopupService.successPopUp('MQA.SUCCESS.CREATE_PREVIEW_SUCCESS');
    }
  }

  /**
   * Clears preview
   */
  function clearPreview(showPopUp = true, triggerChange = true) {
    const readings = vm.dataSource.data();

    clearPreviewValues(readings);

    vm.isPreviewMode = false;

    if (triggerChange) {
      readings.trigger('change');
      notifyChangeAllComponents();
    }

    if (showPopUp) {
      ManualQaPopupService.successPopUp('MQA.SUCCESS.CLEAR_PREVIEW_SUCCESS');
    }
  }

  function handleSave(...args) {
    if (
      differenceInSeconds(
        vm.meter.getAutomaticReadingStartTime(),
        vm.operationTimeFrame.getFromDate().toDate()
      ) <= 0
    ) {
      saveChanges(...args);
    } else {
      modalService
        .getConfirmationModal({
          text: utils.localizedString('MQA.SAVE_BEFORE_AUTOMATIC_READING_START.MODAL.INFO_TEXT'),
          title: utils.localizedString('MQA.SAVE_BEFORE_AUTOMATIC_READING_START.MODAL.TITLE'),
          isDelete: false
        })
        .then(() => saveChanges(...args));
    }
  }

  function saveChanges(lock, valueType, moveToNextDefectIssue) {
    if (!vm.isPreviewMode && vm.meter && vm.defect) {
      return;
    }

    setLoading(true);

    const readings = Utils.getDirtyAndHourlyReadings(vm.dataSource.data());
    readings.forEach(reading => Reading.setValueType(reading, valueType));

    const valueTypeString = valueType === VALUE_TYPES.MODELLED ? 'MODEL' : 'CONSUMPTION';

    ManualQaApiRawReadings.saveReadings(vm.meter, readings, valueType)
      .then(() => (lock ? ManualQaApiRawReadings.lockReadings(vm.meter, vm.operationTimeFrame) : null))
      .then(() => ManualQaPopupService.successPopUp(`MQA.SUCCESS.SAVE_${valueTypeString}_SUCCESS`))
      .then(() => markAsDone(Utils.getIntersectingDefects([vm.operationTimeFrame], vm.defects)))
      .then(() => getReadings(SeriesType.BOTH))
      .then(() => {
        if (vm.searchType === SearchType.STORAGE_TYPE.DEFECT && moveToNextDefectIssue) {
          nextDefectIssue();
        }
      })
      .catch(() => {
        ManualQaPopupService.errorPopUp(`MQA.ERRORS.INSPECT.SAVE_${valueTypeString}_ERROR`);
        getReadings(SeriesType.BOTH);
      });
  }

  function confirmMarkAsDone(defects) {
    if (vm.confirmationModalIsOpen) {
      return;
    }
    vm.confirmationModalIsOpen = true;

    const isSingle = defects.length === 1;
    const textTag = isSingle ? 'MQA.MARK_AS_DONE.MODAL.INFO_TEXT' : 'MQA.MARK_ALL_AS_DONE.MODAL.INFO_TEXT';
    const titleTag = isSingle ? 'MQA.MARK_AS_DONE.MODAL.TITLE' : 'MQA.MARK_ALL_AS_DONE.MODAL.TITLE';

    modalService.getConfirmationModal({
      text: utils.localizedString(textTag),
      title: utils.localizedString(titleTag),
      isDelete: false
    })
      .then(() => vm.markAsDone(defects, true, false))
      .finally(() => (vm.confirmationModalIsOpen = false));
  }

  /**
   * Marks given defect issues as done
   *
   * @param {DefectIssue[]} defects
   * @param {boolean} refreshDataSource
   *
   * @returns {Object}
   */
  function markAsDone(defects, refreshDataSource = false, moveToNextDefectIssue = true) {
    if (vm.searchType !== SearchType.STORAGE_TYPE.DEFECT || defects.length <= 0) {
      return $q.resolve();
    }

    const isSingle = defects.length === 1;
    const successMessage = isSingle ? 'MQA.SUCCESS.MARK_AS_DONE' : 'MQA.SUCCESS.MARK_ALL_AS_DONE';
    const errorMessage = isSingle ? 'MQA.ERRORS.MARK_AS_DONE' : 'MQA.ERRORS.MARK_ALL_AS_DONE';

    return ManualQaApiDefectIssue.closeDefectIssues(defects)
      .then(closedDefectIssues => Utils.removeDefectsFromArray(vm.defects, closedDefectIssues))
      .then(_defects => {
        vm.defects = _defects;
        vm.selectedDefectIndex = isSingle ? vm.selectedDefectIndex - 1 : 0;
      })
      .then(() => ManualQaPopupService.successPopUp(successMessage))
      .catch(() => ManualQaPopupService.errorPopUp(errorMessage))
      .finally(() => {
        if (refreshDataSource) {
          updateDataSource();
          if (moveToNextDefectIssue) {
            nextDefectIssue();
          } else {
            vm.clearPreview(false);
          }
        }
      });
  }

  function onPlotAreaClick(timeFrame) {
    vm.viewTimeFrame = angular.copy(timeFrame);
  }

  function lockModel(shouldLockModel) {
    const promise = shouldLockModel
      ? ManualQaApiRawReadings.lockReadings(vm.meter, vm.operationTimeFrame)
      : ManualQaApiRawReadings.unlockReadings(vm.meter, vm.operationTimeFrame);
    return promise
      .then(() => {
        ManualQaPopupService.successPopUp(shouldLockModel ? 'MQA.SUCCESS.MODEL_LOCKED' : 'MQA.SUCCESS.MODEL_UNLOCKED');
      })
      .catch(() => {
        ManualQaPopupService.errorPopUp('MQA.ERRORS.INSPECT.LOCK_READINGS_ERROR');
      })
      .finally(getReadings);
  }

  /**
   * Handles value change event from spreadsheet
   *
   * @param {Array} values
   */
  function onValueChange(values) {
    const action = { id: ACTIONS.MANUAL };
    const parameters = { values: values };

    createPreview(action, parameters);
  }

  /**
   * Called when series mode has been changed
   *
   * @param {number} fetchType
   */
  function onFetchTypeChange(fetchType) {
    vm.fetchType = fetchType;

    return getReadings();
  }

  /**
   * Handles delete model action
   */
  function handleDeleteModel() {
    const parameters = { timeFrame: vm.operationTimeFrame.getAsString() };

    modalService
      .getConfirmationModal({
        text: utils.localizedString('MQA.DELETE_MODEL.MODAL.INFO_TEXT', parameters),
        title: utils.localizedString('MQA.DELETE_MODEL.MODAL.TITLE', parameters),
        isDelete: true
      })
      .then(deleteModel);
  }

  /**
   * Sends delete model request for meter with operation time frame
   *
   * @returns {Object}
   */
  function deleteModel() {
    return ManualQaApiRawReadings.deleteReadings(vm.meter, vm.operationTimeFrame)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);

    function onSuccess() {
      ManualQaPopupService.successPopUp('MQA.SUCCESS.MODEL_DELETED');
    }

    function onError() {
      ManualQaPopupService.errorPopUp('MQA.ERRORS.MODEL_DELETE');
    }

    function onFinally() {
      return getReadings();
    }
  }

  /**
   * Handles delete consumption action
   */
  function handleDeleteConsumption() {
    const parameters = { timeFrame: vm.operationTimeFrame.getAsString() };

    modalService
      .getConfirmationModal({
        text: utils.localizedString('MQA.DELETE_CONSUMPTION.MODAL.INFO_TEXT', parameters),
        title: utils.localizedString('MQA.DELETE_CONSUMPTION.MODAL.TITLE', parameters),
        isDelete: true
      })
      .then(deleteConsumption);
  }

  /**
   * Sends delete consumption request for meter with operation time frame
   *
   * @returns {Object}
   */
  function deleteConsumption() {
    return ManualQaApiRawReadings.deleteConsumption(vm.meter, vm.operationTimeFrame)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);

    function onSuccess() {
      ManualQaPopupService.successPopUp('MQA.SUCCESS.CONSUMPTION_DELETED');
    }

    function onError() {
      ManualQaPopupService.errorPopUp('MQA.ERRORS.CONSUMPTION_DELETE');
    }

    function onFinally() {
      return getReadings();
    }
  }

  /**
   * Sends change event for all children components
   */
  function notifyChangeAllComponents() {
    notifyChangeChartComponent();
    notifyChangeSpreadsheetComponent();
    notifyChangeStatsComponent();
  }

  /**
   * Sends change event for chart component
   */
  function notifyChangeChartComponent() {
    if (!vm.dataLoaded) {
      return;
    }

    $scope.$broadcast('manual-qa-data-source-changed-chart');
  }

  /**
   * Sends change event for spreadsheet component
   */
  function notifyChangeSpreadsheetComponent() {
    if (!vm.dataLoaded) {
      return;
    }

    $scope.$broadcast('manual-qa-data-source-changed-spreadsheet');
  }

  /**
   * Sends change event for stats component
   */
  function notifyChangeStatsComponent() {
    if (!vm.dataLoaded) {
      return;
    }

    $scope.$broadcast('manual-qa-data-source-changed-stats');
  }

  function nextDefectIssue() {
    vm.selectedDefectIndex++;

    if (vm.selectedDefectIndex >= vm.defects.length) {
      nextMeter();
      return;
    }

    handleDefectChange(vm.defects[vm.selectedDefectIndex], true);
  }

  function previousDefectIssue() {
    vm.selectedDefectIndex--;

    if (vm.selectedDefectIndex < 0) {
      previousMeter();
      return;
    }

    handleDefectChange(vm.defects[vm.selectedDefectIndex], true);
  }

  function nextMeter() {
    vm.selectedDefectIndex = 0;
    vm.defect = null;

    vm.selectedMeterIndex++;

    if (vm.selectedMeterIndex >= vm.meters.length) {
      return;
    }

    onChangeMeter(vm.meters[vm.selectedMeterIndex]);
  }

  function previousMeter() {
    vm.selectedDefectIndex = 0;
    vm.defect = null;

    vm.selectedMeterIndex--;

    if (vm.selectedMeterIndex < 0) {
      return;
    }

    onChangeMeter(vm.meters[vm.selectedMeterIndex], false);
  }

  /**
   * Returns time frame based on preset
   *
   * @returns {TimeFrame}
   */
  function getTimeFrameFromPreset() {
    const hoursBetween = vm.operationTimeFrame.getFromDate().diff(vm.actualTimeFrame.getFromDate(), 'hours');

    let timeFrame = null;
    let alignForward = false;

    switch (vm.preset) {
      case Presets.PREVIOUS_PERIOD:
        timeFrame = vm.operationTimeFrame.subtract(vm.operationTimeFrame.getUnitsBetween('seconds'), 'seconds');
        break;
      case Presets.NEXT_PERIOD:
        timeFrame = vm.operationTimeFrame.add(vm.operationTimeFrame.getUnitsBetween('seconds'), 'seconds');
        alignForward = true;
        break;
      case Presets.PREVIOUS_YEAR:
        timeFrame = vm.operationTimeFrame.subtract(1, 'year');
        break;
    }

    return timeFrame
      .alignToSameWeekDay(vm.operationTimeFrame, alignForward)
      .alignTime(vm.operationTimeFrame)
      .uniformLengthWith(vm.actualTimeFrame)
      .subtract(hoursBetween, 'hours');
  }

  function toggleSearch() {
    vm.isSearchOpen = !vm.isSearchOpen;
    broadcastResize();
  }

  // Required for redrawing Kendo spreadhseet and graph when the top bar height is changed programmatically
  // (eg. when collapsing the consumption table or the entire top bar).
  function broadcastResize() {
    $scope.$broadcast('manual-qa-topbar-resized');
  }

  function getSpreadsheetClass() {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      'mqa-inspect__left--small': isCumulative() || (isHourly() && !vm.useComparisonPeriod),
      'mqa-inspect__left--medium': (isBoth() && !vm.useComparisonPeriod) || (isHourly() && vm.useComparisonPeriod)
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  function isHourly() {
    return vm.fetchType === vm.constants.HOUR;
  }

  function isCumulative() {
    return vm.fetchType === vm.constants.CUMULATIVE;
  }

  function isBoth() {
    return vm.fetchType === vm.constants.BOTH;
  }

  function getExpandedViewTimeFrame() {
    const hours = Math.max(vm.operationTimeFrame.getUnitsBetween('hours') * 0.1, 24);
    const start = moment.max(
      moment(vm.operationTimeFrame.fromDate).subtract(hours, 'hours'),
      vm.actualTimeFrame.fromDate
    );

    const end = moment.min(
      moment(vm.operationTimeFrame.toDate).add(hours, 'hours'),
      vm.actualTimeFrame.toDate
    );

    return new TimeFrame(start, end);
  }
}

ManualQaInspectController.$inject = $inject;

export default ManualQaInspectController;
