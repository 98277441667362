<ng-template #topbarTemplate>
  <div class="content-right float-right">
    <ek-dropdown kendoTooltip>
      <ek-dropdown-heading>
        {{ 'DROPDOWN.ACTIONS' | translate }}
      </ek-dropdown-heading>

      <ek-dropdown-item
        [disabled]="(editMode$ | async)"
        (click)="onEditMeterGroupMetersClick()"
      >
        {{ 'ADMIN.METERGROUPS.EDIT_METER_GROUP_METERS' | translate }}
      </ek-dropdown-item>

      <ek-dropdown-item
        [disabled]="!(selectedMeterGroup$ | async) || (editMode$ | async)"
        [title]="!(selectedMeterGroup$ | async) ? ('ADMIN.METERGROUPS.SELECT_A_METER_GROUP' | translate) : ''"
        (click)="onEditMeterGroupClick()"
      >
        {{ 'ADMIN.METERGROUPS.EDIT_METER_GROUP' | translate }}
      </ek-dropdown-item>

      <ek-dropdown-item
        [disabled]="!(selectedMeterGroup$ | async) || (editMode$ | async)"
        [title]="!(selectedMeterGroup$ | async) ? ('ADMIN.METERGROUPS.SELECT_A_METER_GROUP' | translate) : ''"
        (click)="onDeleteMeterGroupClick()"
      >
        {{ 'ADMIN.METERGROUPS.DELETE_METER_GROUP' | translate }}
      </ek-dropdown-item>
    </ek-dropdown>

    <button class="button" (click)="onCreateMeterGroupClick()">
      <i class="fa fa-plus"></i> {{ 'ADMIN.METERGROUPS.CREATE_METER_GROUP' | translate }}
    </button>
  </div>
</ng-template>
<div class="row">
  <div class="large-2 medium-3 columns">
    <meter-groups-sidebar
      [editMode]="editMode$ | async"
      (meterGroupChange)="onMeterGroupChange($event)"
      (facilityChange)="onFacilityChange($event)"
      (searchClick)="onSearchClick()"
    ></meter-groups-sidebar>
  </div>
  <div class="large-10 medium-9 columns">
    <meter-groups-grid
      [editMode]="editMode$ | async"
      [meterGroupId]="(selectedMeterGroup$ | async)?.id"
      [facilityId]="(selectedFacility$ | async)"
      (save)="onGridSave()"
      (cancel)="onGridCancel()"
    ></meter-groups-grid>
  </div>
</div>
