import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@enerkey/foundation-angular';

import { UserService } from '../../services/user-service';
import { FeedbackModalComponent } from '../../shared/feedback-modal/feedback-modal.component';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { LogoutService } from '../../shared/services/logout.service';
import { ProfileService } from '../../shared/services/profile.service';
import { UserSettingsModalComponent } from '../user-settings-modal/user-settings-modal.component';
import { ProfileChangeModalComponent } from '../profile-change-modal/profile-change-modal.component';
import { Roles } from '../../modules/admin/constants/roles';
import { Service } from '../../constants/service';
import { ProfileApiKeysModalComponent } from '../profile-api-keys-modal/profile-api-keys-modal.component';
import { RouteAuthLogic } from '../../shared/routing';
import { EmailNotificationSettingsComponent } from '../../modules/email-notification/components/email-notification-settings/email-notification-settings.component';

@Component({
  selector: 'topbar-settings',
  templateUrl: './topbar-settings.component.html',
  styleUrls: ['./topbar-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarSettingsComponent {
  @Input() @HostBinding('class.mobile-topbar-settings') public isMobile: boolean;

  public readonly profileName$: Observable<string>;
  public readonly userName: string;

  public readonly showApiKeys$: Observable<boolean>;

  public constructor(
    private readonly userService: UserService,
    private readonly authenticationService: AuthenticationService,
    private readonly modalService: ModalService,
    private readonly logoutService: LogoutService,
    profileService: ProfileService,
    private translateService: TranslateService
  ) {

    this.profileName$ = profileService.profile$.pipe(
      switchMap(profile => profile.name ? of(profile.name) : this.translateService.get('NO_PROFILE_SET'))
    );

    this.userName = this.authenticationService.getUsername();

    this.showApiKeys$ = profileService.profile$.pipe(map(() => this.userService.hasAccess({
      roles: [Roles.DATA_API_KEY_MANAGER],
      services: [Service.DataAPIActions, Service.DataAPI],
      serviceLogic: RouteAuthLogic.Any,
    })));
  }

  public openFeedbackModal(): void {
    this.modalService.open(FeedbackModalComponent);
  }

  public logout(): void {
    this.logoutService.logout();
  }

  public openDataApiKeysModal(): void {
    this.modalService.open(ProfileApiKeysModalComponent);
  }

  public openSettingsModal(): void {
    this.modalService.open(UserSettingsModalComponent);
  }

  public openProfileChangeModal(): void {
    const hasAccessToAllProfiles = this.userService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES);
    this.modalService.open(
      ProfileChangeModalComponent,
      { size: hasAccessToAllProfiles ? undefined : 'tiny' }
    );
  }

  public openEmailNotificationModal(): void {
    this.modalService.open(EmailNotificationSettingsComponent);
  }
}
