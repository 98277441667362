<modal-view-header
  [headingText]="this.titleKey | translate"
></modal-view-header>
<div class="modal-body">
  <form [formGroup]="formGroup">
    <input
      formControlName="name"
      type="text"
      kendoTextBox
      *labelBefore="'NAME' | translate"
    />
    <textarea
      class="description-field"
      formControlName="description"
      kendoTextArea
      rows="5"
      *labelBefore="'ADMIN.METERGROUPS.DESCRIPTION' | translate"
    ></textarea>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      [loading]="loading$ | async"
      [disabled]="!formGroup.valid"
      (click)="submit()"
    >
      {{ 'ADMIN.SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="onCloseModalClick()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>

<kendo-dialog
  minWidth="250"
  width="450"
  class="dialog"
  (close)="onConfirmDialogClick(false)"
  *ngIf="showConfirmDialog"
>
  <kendo-dialog-titlebar>
    <h2>{{ 'ADMIN.METERGROUPS.DIALOG.UNSAVED_CHANGES' | translate }}</h2>
  </kendo-dialog-titlebar>

  <div>
    <p>{{ 'ADMIN.METERGROUPS.DIALOG.YOU_HAVE_UNSAVED_CHANGES' | translate }}.</p>
    <p>{{ 'ADMIN.METERGROUPS.DIALOG.PROCEED_WITHOUT_SAVING' | translate }}</p>
  </div>

  <kendo-dialog-actions>
    <button
      kendoButton
      themeColor="primary"
      class="button"
      (click)="onConfirmDialogClick(true)"
    >
      {{ 'ADMIN.METERGROUPS.DIALOG.OK' | translate }}
    </button>
    <button
      kendoButton
      class="button button--secondary"
      (click)="onConfirmDialogClick(false)"
    >
      {{ 'ADMIN.METERGROUPS.DIALOG.BACK' | translate }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
