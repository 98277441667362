<div>
  <kendo-treeview
    [nodes]="nodes$ | async"
    [filterable]="true"
    [expandedKeys]="expandedKeys$ | async"
    [checkedKeys]="checkedKeys$ | async"
    [kendoTreeViewCheckable]="{ checkOnClick: true }"
    (expandedKeysChange)="onExpandedKeysChange($event)"
    (checkedKeysChange)="onCheckedKeysChange($event)"
    [animate]="false"
    textField="name"
    childrenField="meters"
    kendoTreeViewHierarchyBinding
    kendoTreeViewExpandable
  >
    <ng-template
      kendoTreeViewNodeTemplate
      let-dataItem
    >
    <span [ngClass]="dataItem.className + ' meter-item'">
      <span
        class="meter-name"
        [ngfTooltip]="dataItem.className === 'meter' ? dataItem.name : null"
      >{{ dataItem.name }}
      </span>
      <span
        *ngIf="dataItem.weight"
        class="meter-percentage"
      >
        ({{dataItem.percentage | number:'1.1-3' | suffix:'%'}})
      </span>
    </span>
    </ng-template>
  </kendo-treeview>
</div>
