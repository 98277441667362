import { add, format, formatISODuration, getQuarter, subDays } from 'date-fns';

import { RequestDuration } from '@enerkey/clients/reporting';

export function durationToString(
  startDate: Date,
  duration: Duration,
  index: number,
  longFormat = false
): string {
  const endDate = subDays(add(startDate, duration), 1);
  if (duration.years) {
    if (duration.years === 1) {
      return startDate.getMonth() === 0
        ? startDate.getFullYear().toString()
        : `${format(startDate, 'M/yy')} - ${format(endDate, 'M/yy')}`;
    }
    const endYear = startDate.getFullYear() + duration.years - 1;
    return `${startDate.getFullYear()} - ${endYear}`;
  }
  if (duration.months) {
    if (startDate.getDate() === 1) {
      if (duration.months === 1) {
        return longFormat ? format(startDate, 'M/yy') : (startDate.getMonth() + 1).toString();
      } else if (duration.months === 3 && (startDate.getMonth() % 3) === 0) {
        const quarter = getQuarter(startDate);
        const quarterInfo = longFormat ? `${quarter}/${format(startDate, 'yy')}` : quarter;
        return `Q${quarterInfo}`;
      } else {
        return `${format(startDate, 'M/yy')} - ${format(endDate, 'M/yy')}`;
      }
    }
    return `${format(startDate, 'd.M.yy')} - ${format(endDate, 'd.M.yy')}`;
  }
  if (duration.weeks) {
    return `${format(startDate, 'd.M.yy')} - ${format(endDate, 'd.M.yy')}`;
  }
  if (duration.days) {
    if (!longFormat) {
      return startDate.getDate().toString();
    }
    return duration.days === 1
      ? `${format(startDate, 'd.M.yy')}`
      : `${format(startDate, 'd.M.yy')} - ${format(endDate, 'd.M.yy')}`;
  }

  return (index + 1).toString();
}

export function durationToISOString(duration: Duration): string {
  if (duration.years) {
    return `P${duration.years}Y`;
  }
  if (duration.months) {
    return `P${duration.months}M`;
  }
  if (duration.weeks) {
    const days = duration.weeks * 7;
    return `P${days}D`;
  }
  if (duration.days) {
    return `P${duration.days}D`;
  }
  return formatISODuration(duration);
}

/** Converts duration string into RequestDuration object
 * @param durationStr - duration string (e.g. 'P1Y')
 * @returns RequestDuration object (e.g. { years: 1 })
 */
export function durationStringToRequestDuration(durationStr: string): RequestDuration {
  const type = durationStr.slice(-1);
  const value = parseInt(durationStr.slice(1, -1));

  const duration = { years: 0, months: 0, weeks: 0, days: 0 };
  switch (type) {
    case 'Y':
      duration.years = value;
      break;
    case 'M':
      duration.months = value;
      break;
    case 'W':
      duration.weeks = value;
      break;
    case 'D':
      duration.days = value;
      if (duration.days > 0 && duration.days % 7 === 0) {
        duration.weeks = duration.days / 7;
        duration.days = 0;
      }
      break;
  }
  return new RequestDuration(duration);
}
