import { subMonths } from 'date-fns';

import { ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';
import { FacilityProperty } from '@enerkey/clients/facility';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ReportingData, SerieChartOptions } from './reporting-data-service-base';
import { ReportingSeriesService } from './reporting-series.service';
import {
  getRequestDurationInMonths,
  getSpecificTrendSeries,
  getTrendSeries,
  monthsToAddToRequest
} from './trend-report.functions';

export abstract class TrendReportBaseService {
  public constructor(
    private readonly reportingSeriesService: ReportingSeriesService
  ) {
  }

  protected getChartOptions(params: ReportingSearchParams): SerieChartOptions {
    return {
      serieSettings: params.periods.map(() => ({
        serieType: 'column', lineOpacity: 0.85
      })),
    };
  }

  protected getModifiedParams(params: ReportingSearchParams, overrideUnit?: ReportingUnit): ReportingSearchParams {
    const monthsToGet = getRequestDurationInMonths(params);

    if (overrideUnit) {
      params.formValue.reportingUnit = overrideUnit;
    }

    return new ReportingSearchParams({
      ...params.formValue,
      durationName: 'months',
      durationLength: monthsToGet,
      periods: [subMonths(params.formValue.periods[0], monthsToAddToRequest)]
    });
  }

  protected getTitlePeriods(params: ReportingSearchParams): { duration: Duration, periods: Date[] } {
    return {
      duration: params.duration,
      periods: params.periods
    };
  }

  protected handleResponse(
    ids: number[],
    measured: ReportingData[][],
    normalized: ReportingData[][],
    temperatures: ReportingData[],
    nationalCosts: ReportingData[][],
    meterBasedCosts: ReportingData[][],
    emissions: ReportingData[][],
    facilityProperties: { [key: string]: FacilityProperty[] },
    measuredAndNormalizedInKWh: ReportingData[][],
    periods?: Date[]
  ): ReportingSeriesByFacility {

    [measured.flat().filter(data => data.derivedId), normalized.flat().filter(data => data.derivedId)]
      .forEach((dataOfType, index) => {
        dataOfType.forEach(data => {
          for (const id of ids) {
            const specificProperties = facilityProperties[id];
            const consumptionSeries = (index === 0 ? measuredAndNormalizedInKWh[0] : measuredAndNormalizedInKWh[1])
              .find(m => m.quantityId === data.quantityId).series[id];
            const newTrendSeries = data.series[id]?.map((serie, tIndex) =>
              getSpecificTrendSeries(consumptionSeries[tIndex], serie, specificProperties, data.derivedId));
            if (newTrendSeries) {
              data.series[id] = newTrendSeries;
            }
          }
        });
      });

    [
      measured.flat().filter(data => !data.derivedId),
      normalized.flat().filter(data => !data.derivedId),
      nationalCosts.flat(),
      meterBasedCosts.flat(),
      emissions.flat()
    ].forEach(dataOfType => {
      dataOfType.forEach(data => {
        for (const id of ids) {
          const newSeries = data.series[id]?.map(s => getTrendSeries(s));
          if (newSeries) {
            data.series[id] = newSeries;
          }
        }
      });
    });
    return this.reportingSeriesService.mapValuesById({
      ids,
      temperatures,
      measured: measured.flat(),
      normalized: normalized.flat(),
      nationalCosts: nationalCosts.flat(),
      meterBasedCosts: meterBasedCosts.flat(),
      emissions: emissions.flat(),
      periods: periods
    });
  }

  protected hasUnsupportedParams(params: ReportingSearchParams): boolean {
    const unsupportedDurations = ['days', 'weeks'];
    const isUnsupportedDuration = Object.keys(params.duration).some(k => unsupportedDurations.includes(k));
    return isUnsupportedDuration || params.resolution !== RequestResolution.P1M;
  }
}
