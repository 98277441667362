import moment, { Moment } from 'moment';
import { IAngularStatic } from 'angular';

import { RequestResolution } from '@enerkey/clients/reporting';

declare const angular: IAngularStatic;

const DAYS_IN_WEEK = 7;

export type TimeFrameString =
    'P1Y' | 'P2Y' | 'P5Y' |
    'P1M' | 'P2M' | 'P3M' | 'P4M' | 'P5M' |'P6M' | 'P7M' | 'P8M' | 'P9M' | 'P10M' | 'P11M' | 'P12M' |
    'P1D' | 'P7D' |
    'PT1H' | 'PT15M';

const requestResolutionTimeFrames: Record<RequestResolution, TimeFrameString> = {
  [RequestResolution.PT15M]: 'PT15M',
  [RequestResolution.PT1H]: 'PT1H',
  [RequestResolution.P1D]: 'P1D',
  [RequestResolution.P7D]: 'P7D',
  [RequestResolution.P1Y]: 'P1Y',
  [RequestResolution.P1M]: 'P1M',
  [RequestResolution.P2M]: 'P2M',
  [RequestResolution.P3M]: 'P3M',
  [RequestResolution.P4M]: 'P4M',
  [RequestResolution.P5M]: 'P5M',
  [RequestResolution.P6M]: 'P6M',
  [RequestResolution.P7M]: 'P7M',
  [RequestResolution.P8M]: 'P8M',
  [RequestResolution.P9M]: 'P9M',
  [RequestResolution.P10M]: 'P10M',
  [RequestResolution.P11M]: 'P11M'
};

interface Value {
  key: string;
  valueStart: string;
  value: string;
}

type Start = Value[];

export class TimeFrame {
  /**
   * Parse TimeFrame instance from different values
   */
  public static parse(value: any): TimeFrame {
    let fromDate: Moment;
    let toDate: Moment;
    if (value instanceof TimeFrame) {
      fromDate = value.fromDate;
      toDate = value.toDate;
    } else if ((value.fromDate || value.from) && (value.toDate || value.to)) {
      fromDate = value.fromDate || value.from;
      toDate = value.toDate || value.to;
    } else if (value.durationTo && (value.fromDate || value.from)) {
      const duration = moment.fromIsodurationCached(value.durationTo);
      fromDate = value.fromDate || value.from;
      toDate = moment.utc(fromDate).add(duration);
    } else if (value.durationFrom && (value.toDate || value.to)) {
      const duration = moment.fromIsodurationCached(value.durationFrom);
      toDate = value.toDate || value.to;
      fromDate = moment.utc(toDate).subtract(duration);
    } else {
      throw new Error('Given parameter is not instance of TimeFrame');
    }

    return new TimeFrame(fromDate, toDate);
  }

  /**
   * Parse a new TimeFrame instance from a given range string.
   * For example:
   *    'Q1/18' -> 1.1.2018 - 31.3.2018
   */
  public static parseRange(range: string) {
    const [quarter, lastTwoDigits] = range.split('/');
    const index = Number.parseInt(quarter[1]) - 1;
    const year = `20${lastTwoDigits}`; // Be sure to update this every hundred years!
    const quarters = [
      `${year}-01-01`,
      `${year}-04-01`,
      `${year}-07-01`,
      `${year}-10-01`
    ].map(date => moment.utc(date, 'YYYY-MM-DD'));
    const fromDate = angular.copy(quarters[index]).startOf('quarter');
    const toDate = angular.copy(quarters[index]).endOf('quarter');
    return new TimeFrame(fromDate, toDate);
  }

  public static getCurrentMonth() {
    const fromDate = moment().startOf('month');
    const toDate = moment().endOf('month');

    return new TimeFrame(fromDate, toDate);
  }

  public static getCurrentMonthTillTomorrow() {
    const fromDate = moment().startOf('month');
    const endOfMonth = moment().endOf('month');
    const tomorrow = moment().endOf('day').add(1, 'days');
    return (tomorrow > endOfMonth) ?
      new TimeFrame(fromDate, endOfMonth) :
      new TimeFrame(fromDate, tomorrow);
  }

  public static getCurrentQuarter() {
    const fromDate = moment().startOf('quarter');
    const toDate = moment().endOf('quarter');

    return new TimeFrame(fromDate, toDate);
  }

  public static getCurrentYear() {
    const fromDate = moment().startOf('year');
    const toDate = moment().endOf('year');

    return new TimeFrame(fromDate, toDate);
  }

  public static getPreviousMonth() {
    const fromDate = moment().subtract(1, 'month').startOf('month');
    const toDate = moment().subtract(1, 'month').endOf('month');

    return new TimeFrame(fromDate, toDate);
  }

  public static getPreviousTwoMonths() {
    const fromDate = moment().subtract(2, 'month').startOf('month');
    const toDate = moment().subtract(1, 'month').endOf('month');

    return new TimeFrame(fromDate, toDate);
  }

  public static getPreviousYearOneMonth() {
    const fromDate = moment().subtract(1, 'year').startOf('year');
    const toDate = moment().subtract(1, 'year').endOf('year').add(1, 'month');

    return new TimeFrame(fromDate, toDate);
  }

  public static getPreviousQuarter() {
    const fromDate = moment().subtract(1, 'quarter').startOf('quarter');
    const toDate = moment().subtract(1, 'quarter').endOf('quarter');

    return new TimeFrame(fromDate, toDate);
  }

  public static getDynamicTimeFrame(secondsBetween: number) {
    const fromDate = moment().startOf('day');
    const toDate = moment().add(secondsBetween, 'seconds').endOf('day');

    return new TimeFrame(fromDate, toDate);
  }

  /**
   * Returns time range formatted
   ** E.g.
   *    PIY P1M 1.1.2018 -> '2018'
   *    PIY PIM 2.1.2018 -> '2.12 - 1.19'
   *    PIY P3M 1.1.2018 -> 'Q1/18 - Q4/18'
   *
   * @param startDate in UTC format
   * @param timeFrame ISO 8601
   * @param resolution ISO 8601

   * @returns time range formatted
   */
  public static timeRangeFormat(
    startDate: string | Moment | Date,
    timeFrame: TimeFrameString | RequestResolution,
    resolution?: string
  ): string {
    if (typeof timeFrame !== 'string') {
      timeFrame = requestResolutionTimeFrames[timeFrame];
    }
    if (startDate instanceof Date) {
      startDate = moment(startDate);
    }

    // charts do not have resolution set, setting it equal timeFrame
    const reso = angular.isDefined(resolution) ? resolution : timeFrame;

    const start = moment.utc(startDate);
    let years = 1;

    if (timeFrame.slice(-1) === 'Y') {
      years = parseInt(timeFrame.slice(1, -1));
    }
    if (years > 1) {
      if (start.month() === 0) {
        // full calendar years
        return `${start.format('YYYY') } - ${
          // One hour is subtracted from duration because otherwise e.g. one year dur. would outcome 1.1.2018 - 1.1.2019
          start.add(moment.duration({ Y: years }).subtract(3600, 'minutes')).format('YYYY')}`;
      } else {
        // full floating years
        return `${start.format('M/YY') } - ${
          start.add(moment.duration({ Y: years }).subtract(3600, 'minutes')).format('M/YY')}`;
      }
    }

    if (reso === 'P3M' || (timeFrame === 'P3M' && reso === 'P1M')) {
      // potential qvartal cases
      if ([0, 3, 6, 9].includes(start.month())) {
        // true qvartals
        if (timeFrame === 'P3M') {
          // single qvartal
          return `Q${ start.format('Q/YY')}`;
        } else {
          // multi qvartal
          let months = parseInt(timeFrame.substring(1, timeFrame.length));
          if (timeFrame.slice(-1) === 'Y') {
            months = months * 12;
          }
          return `Q${ start.format('Q/YY') } - Q${ start.add(months - 1, 'months').format('Q/YY')}`;
        }
      }
      // not a qvartal -> basic time range formatting
      return getDefaultTimeRangeFormat(start, startDate, timeFrame);
    }
    if (timeFrame === 'P1Y' && (reso.slice(-1) === 'M' || reso.slice(-1) === 'Y')) {
      // Year range and and monthly resolution (and not a qvartal)
      if (start.month() === 0) {
        // calendar year
        return start.format('YYYY');
      } else {
        // floating year
        return `${start.format('M/YY') } - ${ start.add(1, 'year').subtract(1, 'month').format('M/YY')}`;
      }
    }

    if (timeFrame.slice(-1) === 'M' && moment(start).date() === 1) {
      if (timeFrame === 'P1M') {
        // single month
        return start.format('M/YY');
      } else {
        // multiple months
        return `${start.format('M/YY')} - ${start.add(moment.duration(timeFrame).subtract(1, 'hours')).format('M/YY')}`;
      }
    }

    if (timeFrame === 'P1D') {
      return start.format('D.M.YY');
    }

    // This is for all the the left ove-rs that do not have special treatment
    return getDefaultTimeRangeFormat(start, startDate, timeFrame);

    function getDefaultTimeRangeFormat(
      startMoment: Moment, startDateStr: string | Moment, timeFrameStr: string
    ): string {
      // One hour is subtracted from duration because otherwise e.g. one year duration would outcome 1.1.2018 - 1.1.2019
      const end = moment(startDateStr).utc().add(moment.duration(timeFrameStr)).subtract(1, 'hours');
      return `${startMoment.format('D.M.YY') } - ${ end.format('D.M.YY')}`;
    }
  }

  /**
   * Returns value start formatted
   ** E.g.
   *    1.1.2018 -> '1/18'
   *    2.1.2018 -> '2/1'
   *
   * @param startDate in UTC format

   * @returns value start in MM/YY format
   */
  public static valueStart(startDate: string | Moment) {
    return moment(startDate).format('M/YY');
  }

  public fromDate: moment.Moment;
  public toDate: moment.Moment;

  public constructor(
    fromDate?: moment.Moment | Date | string | number,
    toDate?: moment.Moment | Date | string | number
  ) {
    this.fromDate = this.isMoment(fromDate) ? angular.copy(fromDate) : moment(fromDate);
    this.toDate = this.isMoment(toDate) ? angular.copy(toDate) : moment(toDate);
  }

  /**
   * Returns from date
   *
   * @return {moment|null}
   */
  public getFromDate() {
    return this.fromDate;
  }

  /**
   * Returns to date
   *
   * @return {moment|null}
   */
  public getToDate() {
    return this.toDate;
  }

  /**
   * Checks if given date or time frame is between time frame.
   */
  public contains(object: moment.Moment | TimeFrame | Date) {
    if (object instanceof TimeFrame) {
      return this.fromDate <= object.fromDate && this.toDate >= object.toDate;
    } else if (object instanceof Date) {
      return this.fromDate.toDate() <= object && this.toDate.toDate() >= object;
    } else {
      return object.isValid() && this.fromDate <= object && this.toDate >= object;
    }
  }

  /**
   * Returns (positive) number of given units between from and to dates.
   */
  public getUnitsBetween(unit: string) {
    return Math.abs(this.getFromDate().diff(this.getToDate(), unit));
  }

  /**
   * Subtracts amount of units from timeframe (both from and to dates)
   */
  public subtract(amount: number, unit: string) {
    return new TimeFrame(
      angular.copy(this.getFromDate()).subtract(amount, unit),
      angular.copy(this.getToDate()).subtract(amount, unit)
    );
  }

  /**
   * Adds amount of units to timeframe (both from and to dates)
   */
  public add(amount: number, unit: string) {
    return new TimeFrame(
      angular.copy(this.getFromDate()).add(amount, unit),
      angular.copy(this.getToDate()).add(amount, unit)
    );
  }

  /**
   * Expands timeframe from both ends.
   */
  public expand(amount: number, unit: string) {
    return new TimeFrame(
      angular.copy(this.getFromDate()).subtract(amount, unit),
      angular.copy(this.getToDate()).add(amount, unit)
    );
  }

  /**
   * Returns timeframe as string in given format
   */
  public getAsString(format?: string) {
    return [
      this.getFromDate().format(format || 'DD.MM.YYYY HH:mm'),
      ' – ',
      this.getToDate().format(format || 'DD.MM.YYYY HH:mm')
    ].join('');
  }

  /**
   * Returns common query parameters for http request
   *
   * @returns {Object}
   */
  public getQueryParameters() {
    return {
      from: this.getFromDate().toISOString(),
      to: this.getToDate().toISOString()
    };
  }

  public uniformLengthWith(timeFrame: TimeFrame) {
    return new TimeFrame(
      angular.copy(this.getFromDate()),
      angular.copy(this.getFromDate()).add(timeFrame.getUnitsBetween('seconds'), 'seconds')
    );
  }

  /**
   * Aligns timeframe to start with same week day than given timeframe.
   * On default it moves backwards. Direction can be changed with moveForward parameter
   */
  public alignToSameWeekDay(timeFrame: TimeFrame, alignForward?: boolean) {
    const destinationDay = timeFrame.getFromDate().day();
    const sourceDay = this.getFromDate().day();
    let dayDiff = 0;

    if (destinationDay > sourceDay) {
      dayDiff = alignForward ?
        destinationDay - sourceDay :
        DAYS_IN_WEEK - (destinationDay - sourceDay)
      ;
    } else {
      dayDiff = alignForward ?
        DAYS_IN_WEEK - (sourceDay - destinationDay) :
        sourceDay - destinationDay;
    }

    dayDiff = dayDiff === 0 ? DAYS_IN_WEEK : dayDiff;

    return alignForward ? this.add(dayDiff, 'days') : this.subtract(dayDiff, 'days');
  }

  /**
   * Aligns from and to time with using time frame's from
   * and to times
   */
  public alignTime(timeFrame: TimeFrame) {
    const fromDate = angular.copy(this.getFromDate())
      .hours(timeFrame.getFromDate().hours())
      .minutes(timeFrame.getFromDate().minutes())
      .seconds(timeFrame.getFromDate().seconds())
      .milliseconds(timeFrame.getFromDate().milliseconds())
    ;

    const toDate = angular.copy(this.getToDate())
      .hours(timeFrame.getToDate().hours())
      .minutes(timeFrame.getToDate().minutes())
      .seconds(timeFrame.getToDate().seconds())
      .milliseconds(timeFrame.getToDate().milliseconds())
    ;

    return new TimeFrame(fromDate, toDate);
  }

  /**
   * Returns true if given time frame has same from / to dates
   */
  public isSame(timeFrame: TimeFrame) {
    return (
      timeFrame instanceof TimeFrame &&
      this.getFromDate().isSame(timeFrame.getFromDate()) &&
      this.getToDate().isSame(timeFrame.getToDate())
    );
  }

  /**
   * Returns time frame as object that contains all dates
   * between from and to date with given resolution (unit, amount)
   */
  public toDatesObject(unit: string, amount: number) {
    const fromDate = this.getFromDate().clone().startOf(unit);
    const toDate = this.getToDate().clone().endOf(unit);
    const output: any = {};

    while (fromDate.isSameOrBefore(toDate)) {
      output[fromDate.toISOString()] = true;
      fromDate.add(amount, unit);
    }

    return output;
  }

  /**
   * Adjusts time frame to contain given time frame
   */
  public adjustToContain(timeFrame: TimeFrame) {
    const fromDate = this.getFromDate() > timeFrame.getFromDate() ? timeFrame.getFromDate() : this.getFromDate();
    const toDate = this.getToDate() < timeFrame.getToDate() ? timeFrame.getToDate() : this.getToDate();

    return new TimeFrame(fromDate, toDate);
  }

  /**
   *  Checks if iso timestamp (value) equals to previous year start (from now)
   */
  public static isPreviousYearStart(range: TimeFrameString, value: string): boolean {
    if (range !== 'P1Y') {
      return false;
    }
    const valueAsMoment = moment(value);
    const dayOfMonth = valueAsMoment.date();
    const month = valueAsMoment.month();
    const year = valueAsMoment.year();
    const currentYear = moment().year();
    return dayOfMonth === 1 && month === 0 && year === currentYear - 1;
  }

  /**
   *  Checks if iso timestamp (value) equals to previous quarter start (from now)
   */
  public static isPreviousQuarterStart(range: TimeFrameString, value: string): boolean {
    if (range !== 'P3M') {
      return false;
    }
    const valueAsMoment = moment(value);
    const dayOfMonth = valueAsMoment.date();
    const month = valueAsMoment.month();
    const monthsBetween = moment().diff(valueAsMoment, 'months');
    return dayOfMonth === 1 && [0, 3, 6, 9].includes(month) && monthsBetween >= 3 && monthsBetween <= 6;
  }

  /**
   *  Checks if iso timestamp (value) equals to previous month start (from now)
   */
  public static isPreviousMonthStart(range: TimeFrameString, value: string): boolean {
    if (range !== 'P1M') {
      return false;
    }
    const valueAsMoment = moment(value);
    const dayOfMonth = valueAsMoment.date();
    const monthsBetween = moment().diff(valueAsMoment, 'months');
    return dayOfMonth === 1 && monthsBetween >= 1 && monthsBetween < 2;
  }

  /**
   *  Modifies the start array value start parameters by shifting them full years based difference between now
   *  and createdAt.
   *
   *  See setQuarterVariableDates
   */
  public static setMonthVariableDates(
    resolution: TimeFrameString,
    timeFrame: TimeFrameString,
    createdAt: string,
    start: Start
  ): Start {
    const monthsBetween = moment().diff(moment(createdAt).startOf('month'), 'months', false);
    return monthsBetween ? this.modifyStartArray(resolution, timeFrame, monthsBetween, start) : start;
  }

  /**
   *  Modifies the start array value start parameters by shifting them full quarters based difference between now
   *  and createdAt.
   *
   *  Note that function works for multiple quarters time ranges.
   *
   *--+-----+---+--------------+-----
   *  |     |   |              |
   *  |     |   |              Now
   *  |     |   createdAt
   *  |     createdQuarterStart
   *  Quarter start (value)
   *
   */
  public static setQuarterVariableDates(
    resolution: TimeFrameString,
    timeFrame: TimeFrameString,
    createdAt: string,
    start: Start
  ): Start {
    const quartersBetween = Math.floor(moment().diff(moment(createdAt).startOf('quarter'), 'months', false) / 3);
    return quartersBetween ? this.modifyStartArray(resolution, timeFrame, quartersBetween * 3, start) : start;
  }

  /**
   *  Modifies the start array value start parameters by shifting them full years based difference between now
   *  and createdAt.
   *
   *  See setQuarterVariableDates
   */
  public static setYearVariableDates(
    resolution: TimeFrameString,
    timeFrame: TimeFrameString,
    createdAt: string,
    start: Start
  ): Start {
    const yearsBetween = Math.floor(moment().diff(moment(createdAt).startOf('year'), 'months', false) / 12);
    return yearsBetween ? this.modifyStartArray(resolution, timeFrame, yearsBetween * 12, start) : start;
  }

  /**
   *  Modify start array by shifting timestamps by given amount of months
   */
  private static modifyStartArray(
    resolution: TimeFrameString,
    timeFrame: TimeFrameString,
    months: number,
    start: Start
  ): Start {
    start.forEach(item => {
      item.value = this.addMonthsUTC(new Date(Date.parse(item.value)), months).toISOString();
      item.key = TimeFrame.timeRangeFormat(item.value, timeFrame, resolution);
      item.valueStart = TimeFrame.valueStart(item.value);
    });
    return start;
  }

  /**
   *  Modifies Date by shifting it by given months
   *  https://stackoverflow.com/questions/2706125/javascript-function-to-add-x-months-to-a-date/36379438
   */
  private static addMonthsUTC(date: Date, count: number): Date {
    if (date && count) {
      const d = (date = new Date(+date)).getUTCDate();

      date.setUTCMonth(date.getUTCMonth() + count, 1);
      const month = date.getUTCMonth();
      date.setDate(d);
      if (date.getUTCMonth() !== month) {
        date.setUTCDate(0);
      }
    }
    return date;
  }

  private isMoment(param: any): param is moment.Moment {
    return moment.isMoment(param);
  }

}

export default TimeFrame;
