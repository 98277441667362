
export type CostPlottingPoints = {
  value: number | string;
  timestamp: Date;
  derivedId?: number;
};

export type CostsSeries = {
  costs: CostPlottingPoints[];
  currency: string;
  quantityId?: number;
  hasValue?: boolean;
  derivedId?: number;
  hasSingleCurrency?: boolean;
};

export type ResultType = {
  [key: string]: CostsSeries;
};

export type CostTypes = {
  id: number;
  name: string;
}[];

export const nationalCostsIdMatch: CostTypes = [
  { id: 1002, name: 'totalCost' },
  { id: 1003, name: 'totalPurchase' },
  { id: 1004, name: 'totalDistribution' },
  { id: 1005, name: 'costs' },
  { id: 1006, name: 'retailerCost' },
];

export const meterBasedCostsIdMatch: CostTypes = [
  { id: 1007, name: 'totalCost' },
  { id: 1008, name: 'purchaseConsumption' },
  { id: 1009, name: 'distributionConsumption' },
  { id: 1010, name: 'purchaseFixed' },
  { id: 1011, name: 'distributionFixed' },
  { id: 1012, name: 'totalPurchase' },
  { id: 1013, name: 'totalDistribution' },
  { id: 1014, name: 'distributionTax' },
  { id: 1015, name: 'totalCostAverage' },
  { id: 1016, name: 'purchaseAverage' },
  { id: 1017, name: 'distributionAverage' }
];

export const nationalCostsNameMatch = [
  { name: 'totalCost', translationKey: 'COSTREPORTING.NATIONALCOST.TOTALCOST' },
  { name: 'totalPurchase', translationKey: 'COSTREPORTING.NATIONALCOST.NATIONALAVGPURCHASE' },
  { name: 'totalDistribution', translationKey: 'COSTREPORTING.NATIONALCOST.NATIONALAVGDISTRIBUTION' }
];

export const meterBasedCostsNameMatch = [
  { name: 'totalCost', translationKey: 'COSTREPORTING.METERBASEDCOST.TOTALCOST' },
  { name: 'purchaseConsumption', translationKey: 'COSTREPORTING.METERBASEDCOST.PURCHASECONSUMPT' },
  { name: 'distributionConsumption', translationKey: 'COSTREPORTING.METERBASEDCOST.DISTRIBUTIONCONSUMPT' },
  { name: 'purchaseFixed', translationKey: 'COSTREPORTING.METERBASEDCOST.PURCHASEFIXED' },
  { name: 'distributionFixed', translationKey: 'COSTREPORTING.METERBASEDCOST.DISTRIBUTIONFIXED' },
  { name: 'totalPurchase', translationKey: 'COSTREPORTING.METERBASEDCOST.PURCHASETOTAL' },
  { name: 'totalDistribution', translationKey: 'COSTREPORTING.METERBASEDCOST.DISTRIBUTIONTOTAL' },
  { name: 'distributionTax', translationKey: 'COSTREPORTING.METERBASEDCOST.DISTRIBUTIONTAX' },
  { name: 'totalCostAverage', translationKey: 'COSTREPORTING.METERBASEDCOST.TOTALCOSTAVG' },
  { name: 'purchaseAverage', translationKey: 'COSTREPORTING.METERBASEDCOST.PURCHASEAVG' },
  { name: 'distributionAverage', translationKey: 'COSTREPORTING.METERBASEDCOST.DISTRIBUTIONAVG' }
];

export const nationalCostsDropdownItems = [
  { Id: 1002, Name: 'COSTREPORTING.NATIONALCOST.TOTALCOST' },
  { Id: 1003, Name: 'COSTREPORTING.NATIONALCOST.NATIONALAVGPURCHASE' },
  { Id: 1004, Name: 'COSTREPORTING.NATIONALCOST.NATIONALAVGDISTRIBUTION' },
];

export const meterBasedCostsDropdownItems = [
  { Id: 1007, Name: 'COSTREPORTING.METERBASEDCOST.TOTALCOST' },
  { Id: 1008, Name: 'COSTREPORTING.METERBASEDCOST.PURCHASECONSUMPT' },
  { Id: 1009, Name: 'COSTREPORTING.METERBASEDCOST.DISTRIBUTIONCONSUMPT' },
  { Id: 1010, Name: 'COSTREPORTING.METERBASEDCOST.PURCHASEFIXED' },
  { Id: 1011, Name: 'COSTREPORTING.METERBASEDCOST.DISTRIBUTIONFIXED' },
  { Id: 1012, Name: 'COSTREPORTING.METERBASEDCOST.PURCHASETOTAL' },
  { Id: 1013, Name: 'COSTREPORTING.METERBASEDCOST.DISTRIBUTIONTOTAL' },
  { Id: 1014, Name: 'COSTREPORTING.METERBASEDCOST.DISTRIBUTIONTAX' },
  { Id: 1015, Name: 'COSTREPORTING.METERBASEDCOST.TOTALCOSTAVG' },
  { Id: 1016, Name: 'COSTREPORTING.METERBASEDCOST.PURCHASEAVG' },
  { Id: 1017, Name: 'COSTREPORTING.METERBASEDCOST.DISTRIBUTIONAVG' }
];
