import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { NgfTooltipModule } from '@enerkey/foundation-angular';
import { EkFormsModule } from '@enerkey/ek-forms';

import { SharedModule } from '../../shared/shared.module';
import { EkInputsModule } from '../../shared/ek-inputs/ek-inputs.module';
import { EkKendoModule } from '../../shared/ek-kendo/ek-kendo.module';
import { ContractGridComponent } from './components/contract-grid/contract-grid.component';
import { ContractsComponent } from './components/contracts/contracts.component';
import { ContractSearchComponent } from './components/contract-search/contract-search.component';
import { ContractCreateModalComponent } from './components/contract-create-modal/contract-create-modal.component';
import { ContractEditModalComponent } from './components/contract-edit-modal/contract-edit-modal.component';
import { ContractTerminateModalComponent } from './components/contract-terminate-modal/contract-terminate-modal.component';
import { ContractMassAddComponent } from './components/contract-mass-add/contract-mass-add.component';
import { ContractMassAddOptionsComponent } from './components/contract-mass-add/contract-add-options.component';
import { EkDropdownModule } from '../../shared/ek-dropdown/ek-dropdown.module';
import { ContractInputDialogComponent } from './components/contract-input-dialog/contract-input-dialog.component';
import { ContractEditableLabelComponent } from './components/contract-editable-label/contract-editable-label.component';
import { BillingPeriodPickerComponent } from './components/billing-period-picker/billing-period-picker.component';
import { ProductNamePipe } from './pipes/product-name.pipe';
import { ProductInputComponent } from './components/product-input/product-input.component';
import { ProductNameModalComponent } from './components/product-name-modal/product-name-modal.component';
import { AdminSharedModule } from '../../shared/admin-shared/admin-shared.module';
import { CommonPipesModule } from '../../shared/common-pipes/common-pipes.module';
import { contractsState } from './contracts.states';
import { MeteringTypePipe } from '../../shared/common-pipes/metering-type.pipe';

@NgModule({
  declarations: [
    ContractsComponent,
    ContractGridComponent,
    ContractSearchComponent,
    ContractEditModalComponent,
    ContractCreateModalComponent,
    ContractTerminateModalComponent,
    ContractMassAddComponent,
    ContractMassAddOptionsComponent,
    ContractInputDialogComponent,
    ContractEditableLabelComponent,
    BillingPeriodPickerComponent,
    ProductNamePipe,
    ProductInputComponent,
    ProductNameModalComponent,
  ],
  imports: [
    UIRouterUpgradeModule.forChild({ states: [contractsState] }),
    CommonModule,
    SharedModule,
    GridModule,
    PopupModule,
    TranslateModule,
    DropDownsModule,
    InputsModule,
    ReactiveFormsModule,
    DateInputsModule,
    ExcelModule,
    EkDropdownModule,
    EkFormsModule,
    EkInputsModule,
    EkKendoModule,
    AdminSharedModule,
    CommonPipesModule,
    NgfTooltipModule,
    IntlModule,
    ButtonsModule,
  ],
  exports: [
    ContractsComponent
  ],
  providers: [
    MeteringTypePipe,
  ],
})
export class ContractsModule { }
