import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'excelFileName'
})
export class ExcelFileNamePipe implements PipeTransform {
  public transform(prefix: string, date?: Date, timeRequired: boolean = true): string {
    const dateFormat = timeRequired ? 'yyyy-MM-dd_HH-mm-ss' : 'yyyy-MM-dd';
    const suffix = date ? ` ${format(date, dateFormat)}` : '';

    return `${prefix}${suffix}.xlsx`;
  }
}
