import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { map, Observable } from 'rxjs';

import { NgfTabChangeEvent, NgfTabsetComponent } from '@enerkey/foundation-angular';

import { ReportModalService } from '../../services/report-modal.service';
import { ReportType } from '../../shared/report-type';
import { ReportModalMetersService } from '../../services/report-modal-meters.service';
import { ReportingMeterSelection } from '../../shared/reporting-meter-selection';
import { EnvironmentService } from '../../../../services/environment-service';

@Component({
  selector: 'modal-meter-reports',
  templateUrl: './modal-meter-reports.component.html',
  styleUrls: ['./modal-meter-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalMeterReportsComponent {
  @ViewChild(NgfTabsetComponent) public readonly tabset: NgfTabsetComponent;

  public readonly ReportType = ReportType;

  public readonly reportType$: Observable<ReportType>;
  public readonly selectedMeterCount$: Observable<number>;

  public readonly isProd: boolean;

  public constructor(
    private readonly reportModalService: ReportModalService,
    private readonly reportModalMetersService: ReportModalMetersService,
    private readonly environmentService: EnvironmentService
  ) {
    this.reportType$ = this.reportModalService.meterReportType$;
    this.selectedMeterCount$ = this.reportModalMetersService.selectedMeters$.pipe(
      map((selectedMeters: ReportingMeterSelection) => selectedMeters?.meterIds.length)
    );

    this.isProd = this.environmentService.isProduction();
  }

  public tabChange(tab: NgfTabChangeEvent): void {
    this.reportModalService.moveToMeterReportOfType(tab.nextId as ReportType);
  }
}
