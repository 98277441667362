import { Bookmark as genericBookmark, ReportingBookmark } from '@enerkey/clients/settings';

export enum SearchableType {
  Facility = 'FACILITIES.FACILITIES',
  Bookmark = 'BOOKMARK_WIDGET.BOOKMARKS',
}

interface SearchableBase {
  title: string;
  extraText?: string;
  searchableType: SearchableType;
}

export interface SearchableFacility extends SearchableBase {
  searchableType: SearchableType.Facility;
  facilityId: number;
}

export interface SearchableBookmark extends SearchableBase {
  searchableType: SearchableType.Bookmark;
  bookmark: genericBookmark | ReportingBookmark;
}

export type EnerkeySearchable = SearchableFacility | SearchableBookmark;
