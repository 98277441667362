import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { isAfter, isSameDay } from 'date-fns';

import { BehaviorSubject, combineLatest, from, Observable, of, Subject } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ControlsOf } from '@enerkey/ts-utils';
import { QuantityItem } from '@enerkey/clients/energy-reporting';
import { indicate, LoadingSubject, shareUntil } from '@enerkey/rxjs';
import { Quantities } from '@enerkey/clients/metering';

import { quantityGroupDefinitions } from '../../../../../constants/quantity.constant';
import { QuantityDropdownComponent } from '../../../../../shared/ek-inputs/quantity-dropdown/quantity-dropdown.component';
import { QuantityService } from '../../../../../shared/services/quantity.service';
import { ETCurveSearchParams } from '../../../models/et-curve.model';
import { EtCurveService } from '../../../services/et-curve/et-curve.service';

@Component({
  selector: 'et-curve-quantity-list',
  templateUrl: './et-curve-quantity-list.component.html',
  styleUrls: ['./et-curve-quantity-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class EtCurveQuantityListComponent implements OnDestroy, OnInit {
  @Input() public filterByQuantityIds?: Observable<number[]>;
  @Input() public displayNoEtCurveMessageOnEmptyQuantities?: boolean = false;
  @ViewChild(QuantityDropdownComponent) public quantityDropdown: QuantityDropdownComponent;

  public quantities$: Observable<Quantities[]>;
  public allQuantities$: Observable<QuantityItem[]>;
  public loadingQuantities$: Observable<boolean>;
  public noEtCurveMessageVisible$: Observable<boolean>;

  public controls: ControlsOf<ETCurveSearchParams>;

  protected readonly NO_ET_CURVE_TRANSLATION_KEY = 'FACILITY_ETCURVE_WIDGET.NO_ETCURVE_AVAILABLE_FOR_FACILITY';

  private readonly _loadingQuantities = new LoadingSubject();
  private readonly _facilityQuantities = new Map<number, Observable<Quantities[]>>();
  private readonly _noEtCurveMessageVisible = new BehaviorSubject<boolean>(false);
  private readonly _destroy = new Subject<void>();

  public constructor(
    public readonly formGroup: FormGroupDirective,
    private readonly quantityService: QuantityService,
    private readonly etCurveService: EtCurveService
  ) {}

  public ngOnInit(): void {
    this.controls = this.formGroup.control.controls as ControlsOf<ETCurveSearchParams>;
    this.loadingQuantities$ = this._loadingQuantities.asObservable();
    this.noEtCurveMessageVisible$ = this._noEtCurveMessageVisible.asObservable();
    this.allQuantities$ = this.quantityService.getProfileQuantities().pipe(
      take(1),
      indicate(this._loadingQuantities),
      takeUntil(this._destroy)
    );

    this.quantities$ = this.controls.facilityId.valueChanges.pipe(
      startWith(this.controls.facilityId.value),
      tap(() => this.setEtCurveMessageVisibility(false)),
      filter(facilityId => facilityId !== undefined && facilityId !== null),
      switchMap(facilityId => combineLatest([
        this.etCurveService.getFacilityMeters(facilityId),
        this.getFacilityQuantities(facilityId),
        this.allQuantities$,
        this.filterByQuantityIds ?? of(null)
      ])),
      switchMap(([meters, facilityQuantities, quantities, filterByQuantityIds]) =>
        this.etCurveService.getLatestConsumptionOnMeters(meters.map(m => m.Id)).pipe(
          map(latestConsumptions => meters
            .map(meter => ({ meter, consumption: latestConsumptions.find(c => c.meterId === meter.Id) }))
            .filter(({ consumption }) => consumption && (
              isSameDay(new Date(consumption.timestamp), this.etCurveService.oneYearAgo) ||
              isAfter(new Date(consumption.timestamp), this.etCurveService.oneYearAgo)))
            .map(({ meter }) => meter.QuantityId)),
          map(availableMeterQuantityIds => quantities
            .map(q => q.ID)
            .filter(q => facilityQuantities.includes(q) && availableMeterQuantityIds.includes(q))),
          map(filteredQuantities => {
            const sumQuantities = new Set(quantityGroupDefinitions
              .filter(gd => gd.sumQuantityIds.length && gd.quantityIds.some(q => filteredQuantities.includes(q)))
              .flatMap(gd => gd.sumQuantityIds));

            return [...filteredQuantities, ...sumQuantities];
          }),
          map(quantityList => Array.isArray(filterByQuantityIds) ?
            quantityList.filter(q => filterByQuantityIds.includes(q)) :
            quantityList)
        )),
      tap(quantities => {
        const quantityValue = this.controls.quantity.value;
        if (Number.isFinite(quantityValue)) {
          if (!quantities.includes(quantityValue)) {
            this.controls.quantity.reset();
          } else {
            setTimeout(() => this.quantityDropdown.writeValue(quantityValue));
          }
        }

        this.setEtCurveMessageVisibility(quantities.length === 0);
      }),
      takeUntil(this._destroy),
      shareReplay(1)
    );
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._loadingQuantities.complete();
  }

  private getFacilityQuantities(facilityId: number): Observable<Quantities[]> {
    return this._facilityQuantities.getOrAdd(
      facilityId,
      () => from(this.quantityService.getSignificantQuantitiesForFacility(facilityId)).pipe(
        map(items => items.map(x => x.ID)),
        indicate(this._loadingQuantities),
        shareUntil(this._destroy)
      )
    );
  }

  private setEtCurveMessageVisibility(isVisible: boolean): void {
    if (this.displayNoEtCurveMessageOnEmptyQuantities) { this._noEtCurveMessageVisible.next(isVisible); }
  }
}

