import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';

import { ReportType } from '../../shared/report-type';
import { ReportTypeOptionsService } from '../../services/report-type-options.service';

@Component({
  selector: 'static-settings',
  templateUrl: './static-settings.component.html',
  styleUrls: ['./static-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticSettingsComponent implements OnDestroy {
  public readonly reportType$: Observable<ReportType>;
  public readonly ReportType: typeof ReportType = ReportType;

  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly reportTypeOptionsService: ReportTypeOptionsService
  ) {
    this.reportType$ = this.reportTypeOptionsService.currentReport$.pipe(
      takeUntil(this.destroy$)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
