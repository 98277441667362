import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, forkJoin, iif, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import moment from 'moment';

import { indicate } from '@enerkey/rxjs';
import { FacilityMeterCountsDto, MeterManagementClient } from '@enerkey/clients/meter-management';
import { ContractClient, ContractProduct } from '@enerkey/clients/contract';
import { Facility, FacilityClient } from '@enerkey/clients/facility';
import { CompanyModel, ContactClient } from '@enerkey/clients/contact';

import { UserService } from '../../../services/user-service';
import { ContractRow } from '../models/contract-row';
import { BillingPeriod, ContractSearchParam } from '../models/contract-search-params';
import { MeterCounts } from '../models/meter-counts';
import { localToUtc } from '../../../shared/date.functions';
import { ProductService } from './product.service';

type SearchResult = [Facility[], ContractProduct[]];
type SearchResult$ = Observable<SearchResult>;
type RangeParam = [from: moment.Moment, to: moment.Moment];

@Injectable()
export class ContractSearchService implements OnDestroy {

  public readonly loading$: Observable<boolean>;
  public readonly fetched$: Observable<[ContractRow[], BillingPeriod]>;

  private readonly _loading$ = new Subject<boolean>();
  private readonly _search$ = new Subject<ContractSearchParam>();
  private readonly _repeat$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();

  private get default$(): SearchResult$ {
    return of<SearchResult>([[], []]);
  }

  public constructor(
    private readonly contractClient: ContractClient,
    private readonly facilityClient: FacilityClient,
    private readonly contactClient: ContactClient,
    private readonly meters: MeterManagementClient,
    private readonly productService: ProductService,
    private readonly userService: UserService
  ) {
    this.loading$ = this._loading$.asObservable();

    this.fetched$ = combineLatest([
      this._search$,
      this._repeat$.pipe(startWith(null))
    ]).pipe(
      switchMap(([param, _]) => this.getSearchResult(param).pipe(
        switchMap(([facilities, contracts]) => {
          const facilityIds = facilities.unique('id');
          return forkJoin({
            facilities: of(facilities),
            contracts: of(contracts),
            companies: this.getCompanies(facilities),
            productNames: this.productService.getProductNames(),
            softDeleteStates: this.facilityClient.getFacilityDeletedState(facilityIds),
            meterCounts: iif(
              () => param.meterCounts,
              this.meters.getFacilitiesMetersCount(facilityIds),
              of<FacilityMeterCountsDto[]>([])
            ),
            searchParam: of<ContractSearchParam>(param)
          });
        }),
        map(result => this.getResultRows(result)),
        indicate(this._loading$)
      )),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._repeat$.complete();
    this._loading$.complete();
  }

  public onSearch(param: ContractSearchParam): void {
    if (param) {
      param.range ??= { from: null, to: null };
      this._search$.next(param);
    }
  }

  public repeatPreviousSearch(): void {
    this._repeat$.next();
  }

  private getSearchResult(param: ContractSearchParam): SearchResult$ {
    const range: RangeParam = [
      param.range.from ? moment(localToUtc(param.range.from)) : undefined,
      param.range.to ? moment(localToUtc(param.range.to)) : undefined,
    ];

    switch (param.key) {
      case 'currentProfile': {
        if (!param.value) {
          return this.default$;
        }
        return this.searchByProfileIds([this.userService.profileId], range);
      }
      case 'profileIds': {
        return this.searchByProfileIds(param.value, range);
      }
      case 'profileName': { // profile dropdown provides ID as value
        return this.searchByProfileIds([param.value], range);
      }
      case 'companyCodes': {
        const facilityIds$ = this.facilityClient.getFacilitiesUsingCompanyids(param.value)
          .pipe(map(facilities => facilities.unique('id')));

        return this.searchByFacilityIds(
          facilityIds$,
          range
        );
      }
      case 'enegiaIds': {
        const facilityIds$ = this.facilityClient.getFacilityIdsForEnegiaIds(param.value)
          .pipe(map(record => Object.values(record).unique()));

        return this.searchByFacilityIds(
          facilityIds$,
          range
        );
      }
      case 'facilityName': {
        return this.searchByFacilityIds(
          this.facilityClient.getFacilityIdsByNameContainsCaseInsensitive(param.value),
          range
        );
      }
      case 'facilityIds': {
        return this.searchByFacilityIds(of(param.value), range);
      }
      case 'contractIds': {
        return this.searchByContracts(
          this.contractClient.getContractProductsByContractIds(
            param.value.map(id => id.toString()),
            ...range
          )
        );
      }
      default:
        return this.default$;
    }
  }

  /** Search by known profile IDs. */
  private searchByProfileIds(profileIds: number[], range: RangeParam): SearchResult$ {
    if (!Array.hasItems(profileIds)) {
      return this.default$;
    }
    return this.facilityClient.getFacilityIdsForProfileIds(profileIds).pipe(
      map(result => Object.values(result).flat().unique()),
      switchMap(facilityIds => this.searchByFacilityIds(of(facilityIds), range))
    );
  }

  /** Search by known contracts. */
  private searchByContracts(contracts$: Observable<ContractProduct[]>): SearchResult$ {
    return contracts$.pipe(
      switchMap(contracts => {
        if (!Array.hasItems(contracts)) {
          return this.default$;
        }

        return forkJoin([
          this.facilityClient.getFacilities(contracts.map(c => c.facilityId), true),
          of(contracts)
        ]);
      })
    );
  }

  /** Search by known facility IDs. */
  private searchByFacilityIds(facilityIds$: Observable<number[]>, range: RangeParam): SearchResult$ {
    return facilityIds$.pipe(
      switchMap(facilityIds => {
        if (!Array.hasItems(facilityIds)) {
          return this.default$;
        }

        return forkJoin([
          this.facilityClient.getFacilities(facilityIds, true),
          this.contractClient.getContractsByFacilityIds(facilityIds, ...range)
        ]);
      })
    );
  }

  private getCompanies(facilities: Facility[]): Observable<CompanyModel[]> {
    const companyIds = facilities
      .mapFilter(c => c.companyId, id => Number.isInteger(id))
      .unique();

    if (!companyIds.length) {
      return of([]);
    }

    return this.contactClient.getCompanies(companyIds);
  }

  private getResultRows(arg: {
    facilities: Facility[],
    contracts: ContractProduct[],
    companies: CompanyModel[],
    productNames: Record<string, string>,
    softDeleteStates: Record<string, boolean>,
    meterCounts: FacilityMeterCountsDto[],
    searchParam: ContractSearchParam
  }): [ContractRow[], BillingPeriod] {
    const { facilities, contracts, companies, productNames, softDeleteStates, meterCounts, searchParam } = arg;

    const facilitiesById = facilities.toRecord(f => f.id);
    const companiesById = companies.toRecord(f => f.id);

    const countsMap = meterCounts.toMap(
      counts => counts.facilityId,
      counts => new MeterCounts(counts)
    );

    const getContractMeterCount: (c: ContractProduct) => MeterCounts = searchParam.meterCounts
      ? contract => countsMap.get(contract.facilityId) ?? new MeterCounts(new FacilityMeterCountsDto({}))
      : () => undefined;

    const rows = contracts.map(contract => {
      const facility = facilitiesById[contract.facilityId];
      return new ContractRow(
        contract,
        facility,
        productNames[contract.productId],
        softDeleteStates[contract.facilityId],
        getContractMeterCount(contract),
        facility?.companyId ? companiesById?.[facility.companyId] : null
      );
    });

    const period: BillingPeriod = (searchParam.range.from && searchParam.range.to)
      ? searchParam.range
      : null;

    return [rows, period];
  }
}
