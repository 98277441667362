import moment from 'moment';

import { IContractProduct } from '@enerkey/clients/contract';
import { IFacility } from '@enerkey/clients/facility';
import { CompanyModel } from '@enerkey/clients/contact';

import { MeterCounts } from './meter-counts';

class BillingInfoGroup {
  public readonly monthsActive: number;
  public readonly unitCount: number;
  public readonly cost: number;
  public readonly changes: boolean;

  public constructor(
    contract: IContractProduct
  ) {
    const { billingPeriodInfo } = contract;
    this.monthsActive = billingPeriodInfo.billedMonths;
    this.changes = billingPeriodInfo.changes;
    this.cost = billingPeriodInfo.price;
    this.unitCount = Number.isInteger(contract.unitCount)
      ? (billingPeriodInfo.billedMonths * contract.unitCount)
      : null;
  }
}

const MIN_DATE = moment.utc('0001-01-01');

export class ContractRow {
  public readonly id: number;
  public readonly contractId: string;
  public readonly productId: string;
  public readonly unitPrice: number;
  public readonly unitCount: number;
  public readonly cost: number;
  public readonly fromDate: Date;
  public readonly toDate: Date;
  public readonly created: Date;
  public readonly createdBy: string;
  public readonly lastModified: Date;
  public readonly lastModifiedBy: string;

  public readonly productName: string;
  public readonly facility: IFacility & { isDeleted?: boolean };
  public readonly meterCounts?: MeterCounts;
  public readonly company: CompanyModel;

  public readonly billingInfo: BillingInfoGroup;

  public constructor(
    contract: IContractProduct,
    facility: IFacility,
    productName: string,
    isDeleted: boolean,
    meterCounts?: MeterCounts,
    company?: CompanyModel
  ) {
    this.id = contract.id;
    this.contractId = contract.contractId;
    this.productId = contract.productId;
    this.unitPrice = contract.unitPrice;
    this.unitCount = contract.unitCount;
    this.cost = (this.unitPrice && this.unitCount) ? (this.unitPrice * this.unitCount) : null;
    this.fromDate = this.getDate(contract.fromDate);
    this.toDate = this.getDate(contract.toDate);
    this.created = this.getDate(contract.created);
    this.lastModified = this.getDate(contract.lastModified);
    this.createdBy = contract.createdBy;
    this.lastModifiedBy = contract.lastModifiedBy;

    this.productName = productName;
    this.facility = facility;
    this.facility.isDeleted = isDeleted;
    this.meterCounts = meterCounts;
    this.company = company;

    this.billingInfo = contract.billingPeriodInfo ? new BillingInfoGroup(contract) : null;
  }

  private getDate(value: moment.Moment): Date | null {
    if (value?.isAfter(MIN_DATE)) {
      return value.toDate();
    } else {
      return null;
    }
  }
}
