<div
  *ngIf="(consumptionData$ | async) as quantityValues"
  [loadingSpinner]="loading$ | async"
  [loadingOverlay]="true"
>
  <div
    *ngIf="chartsVisible$ | async"
    class="chart-container"
  >
    <div
      *ngFor="let quantityData of quantityValues?.[sumKey]; trackBy: trackByMethod"
      class="card"
    >
      <quantity-chart-header
        [seriesCollection]="quantityData"
      ></quantity-chart-header>
      <reporting-chart
        [series]="quantityData.series"
        [quantityId]="quantityData.quantityId"
        [labelSettings]="quantityData.series | chartCategories | async"
        (seriesClick)="zoomToPeriod($event)"
      ></reporting-chart>
    </div>
  </div>
  <ng-container *ngIf="(gridsVisible$ | async) && (gridConfig$ | async) as gridConfig">
    <reporting-grid
      [data]="gridConfig.data"
      [gridColumns]="gridConfig.columns"
      [searchParams]="searchParams$ | async"
      [aggregates]="gridConfig.aggregates"
      [reportName]="'EXCEL.SUM_REPORT' | translate"
    ></reporting-grid>
  </ng-container>
</div>
<sum-report-facilities-card
  *ngIf="facilities$ | async as facilities"
  [facilities]="facilities"
></sum-report-facilities-card>
