export enum TimeFrameOptions {
  YEAR_BY_YEAR_CALENDAR = 'YearByYearCalendar',
  YEAR_BY_YEAR_FLOATING = 'YearByYearFloating',
  YEAR_FULL_QUARTERS = 'YearFullQuarters',
  QUARTER_BY_QUARTER_MATCH_CURRENT = 'QuarterByQuarterMatchCurrent',
  QUARTER_BY_QUARTER_MATCH_PREVIOUS = 'QuarterByQuarterMatchPrevious',
  QUARTER_BY_QUARTER_LAST_CURRENT = 'QuarterByQuarterLastCurrent',
  QUARTER_BY_QUARTER_LAST_PREVIOUS = 'QuarterByQuarterLastPrevious',
  MONTH_BY_MONTH_MATCH_CURRENT = 'MonthByMonthMatchCurrent',
  MONTH_BY_MONTH_MATCH_PREVIOUS = 'MonthByMonthMatchPrevious',
  MONTH_BY_MONTH_LAST_CURRENT = 'MonthByMonthLastCurrent',
  MONTH_BY_MONTH_LAST_PREVIOUS = 'MonthByMonthLastPrevious',
  TWELVE_MONTHS_FLOATING = 'TwelveMonthsFloating',
  SIX_MONTHS_FLOATING = 'SixMonthsFloating',
  THREE_MONTHS_FLOATING = 'ThreeMonthsFloating'
}

export default TimeFrameOptions;
