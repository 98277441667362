import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { addDays } from 'date-fns';

import { RequestResolution } from '@enerkey/clients/reporting';
import { assertUnreachable } from '@enerkey/ts-utils';

import { MonthNamePipe } from '../../../shared/common-pipes/month-name.pipe';
import { SelectableResolution } from '../components/reporting-search-form/reporting-search-form.component';
import { durationToString } from '../shared/duration-to-string';
import { ReportSeriesDataPoint } from '../shared/reporting-series';

const HOURS_IN_DAY = 24;
const QUARTERS_IN_HOUR = 4; // 15min periods in an hour
const QUARTERS_IN_DAY = HOURS_IN_DAY * QUARTERS_IN_HOUR; // 96 15min periods in a day

@Injectable({
  providedIn: 'root'
})
export class PeriodLabelService {
  public constructor(
    private readonly translateService: TranslateService,
    private readonly monthNamePipe: MonthNamePipe,
    private readonly intlService: IntlService
  ) {
  }

  /**
   * Get period name according to given parameters
   *
   * Can be used for chart x-axis label or formatting individual period timestamp (eg. in chart tooltip and grid)
   */
  public getChartCategoryLabel({
    timestamps,
    resolution,
    index,
    specialDayLabelFormat,
    amountOfPeriods,
    useShortFormat,
    durationLength,
    useIndexForHour,
    useWeekdayForWeek
  }: {
    timestamps: Date[],
    resolution: SelectableResolution,
    index?: number,
    specialDayLabelFormat?: boolean,
    amountOfPeriods?: number,
    /** Use short format for week or 15 min resolution. Does not affect other resolutions. */
    useShortFormat?: boolean,
    durationLength?: number,
    useIndexForHour?: boolean,
    useWeekdayForWeek?: boolean,
  }): string {
    index ??= 0;
    specialDayLabelFormat ??= false;
    amountOfPeriods ??= 1;
    switch (resolution) {
      case RequestResolution.PT15M: {
        if (specialDayLabelFormat) {
          return timestamps[timestamps.length - 1].getDate().toString();
        }
        return this.getHourTitle(index, timestamps[0], amountOfPeriods, useShortFormat, useIndexForHour);
      }
      case RequestResolution.PT1H: {
        if (specialDayLabelFormat) {
          return timestamps[timestamps.length - 1].getDate().toString();
        }
        return this.getDayAndHourTitle(index, timestamps[0], amountOfPeriods, useIndexForHour);
      }
      case RequestResolution.P1D: {
        if (specialDayLabelFormat) {
          return timestamps[timestamps.length - 1].getDate().toString();
        }
        return this.getDayTitle(index, timestamps, amountOfPeriods);
      }
      case RequestResolution.P7D:
        return this.getWeekTitle(timestamps, index, useShortFormat, useWeekdayForWeek);
      case RequestResolution.P1M:
        return this.getMonthTitle(timestamps, amountOfPeriods);
      case RequestResolution.P3M:
        return this.getQuarterTitle(timestamps, amountOfPeriods);
      case RequestResolution.P1Y:
        return this.getYearTitle(timestamps, index, durationLength, amountOfPeriods);
      /* istanbul ignore next */
      default:
        assertUnreachable(resolution);
    }
  }

  /**
   * Get label for reporting chart tooltip header
   *
   * For example:
   * - month name when using month resolution
   * - year number for year resolution
   * - Day [index] for day resolution and multiple periods
   * - d.mm.yy for day resolution and single periods
   */
  public getChartTooltipHeaderLabel(
    points: ReportSeriesDataPoint[],
    resolution: SelectableResolution,
    categoryIndex: number,
    amountOfPeriods: number
  ): string {
    switch (resolution) {
      case RequestResolution.PT15M:
        return this.getHourTitle(categoryIndex, points[0].timestamp, amountOfPeriods, false);
      case RequestResolution.PT1H:
        return this.getDayAndHourTitle(categoryIndex, points[0].timestamp, amountOfPeriods);
      case RequestResolution.P1D:
        return this.getDayTitle(categoryIndex, points.map(p => p.timestamp), amountOfPeriods);
      case RequestResolution.P1M:
        return this.monthNamePipe.transform(points[0].timestamp.getMonth(), true).capitalize();
      case RequestResolution.P1Y:
        return this.getYearTitle([points[0].timestamp]);

    }
    return `${categoryIndex + 1}`;
  }

  private getWeekTitle(
    timestamps: Date[],
    categoryIndex: number,
    shortFormat: boolean,
    weekFormat?: boolean
  ): string {
    if (weekFormat) {
      return this.intlService.formatDate(timestamps[0], 'd/M');
    }
    if (shortFormat) {
      return `${categoryIndex + 1}`;
    }
    const start = this.intlService.formatDate(timestamps[0], 'd');
    const end = this.intlService.formatDate(addDays(timestamps[0], 7), 'd');

    return `${start} - ${end}`;
  }

  private getMonthTitle(
    timestamps: Date[],
    amountOfPeriods: number
  ): string {
    if (amountOfPeriods === 1) {
      return this.intlService.formatDate(timestamps[0], 'M/yy');
    }
    const titles = timestamps.map(t => (t.getMonth() + 1).toString());
    return this.formatTitleForMultiplePeriods(titles);
  }

  private getQuarterTitle(
    timestamps: Date[],
    amountOfPeriods: number
  ): string {
    const titles = timestamps.map(t => durationToString(t, { months: 3 }, 0, amountOfPeriods === 1));
    return this.formatTitleForMultiplePeriods(titles);
  }

  private formatTitleForMultiplePeriods(titles: string[]): string {
    if (titles.every(t => t === titles[0])) {
      return titles[0];
    }

    return titles.reduce(
      (categoryValue, label, labelIndex) => {
        const labelValue = getLabelValue(label, labelIndex === titles.length - 1);
        return `${categoryValue}${labelValue}`;
      }
      , ''
    );
  }

  private getYearTitle(
    timestamps: Date[],
    categoryIndex?: number,
    durationLength?: number,
    amountOfPeriods?: number
  ): string {
    if (durationLength === 1 && amountOfPeriods > 1) {
      /** since we are passing timestamps for it series there is chance to have duplicate timestamps
       * so we need to get unique titles for each category index
       **/
      const uniqueTitles = timestamps.map(t => t.getFullYear().toString()).unique();
      return uniqueTitles[categoryIndex];
    }
    return timestamps[timestamps.length - 1].getFullYear().toString();
  }

  private getDayTitle(
    index: number,
    timestamps: Date[],
    amountOfPeriods: number
  ): string {
    if (amountOfPeriods === 1) {
      return this.intlService.formatDate(timestamps[0], 'd');
    }
    return `${this.translateService.instant('DAY')} ${index + 1}`;
  }

  private getDayAndHourTitle(
    index: number,
    timestamp: Date,
    amountOfPeriods: number,
    useIndexForHour: boolean = false
  ): string {
    const hour = index % HOURS_IN_DAY;
    const timeStr = this.intlService.formatDate(new Date(2020, 0, 1, hour), 't');

    if (amountOfPeriods === 1) {
      if (useIndexForHour) {
        const dateStr = this.intlService.formatDate(timestamp, 'd');
        // hack to get each hour ignoring timezone & dst changes
        return `${dateStr} ${timeStr}`;
      }

      return this.intlService.formatDate(timestamp, 'g');
    }

    const day = Math.floor(index / HOURS_IN_DAY) + 1;
    return `${this.translateService.instant('DAY')} ${day}, ${timeStr}`;
  }

  private getHourTitle(
    index: number,
    timestamp: Date,
    amountOfPeriods: number,
    useShortFormat: boolean,
    useIndexForHour: boolean = false
  ): string {
    if (useIndexForHour) {
      const dateStr = this.intlService.formatDate(timestamp, 'd');
      // hack to get each hour ignoring timezone & dst changes
      const hour = Math.floor(index / QUARTERS_IN_HOUR % HOURS_IN_DAY);
      const minutes = timestamp.getMinutes();
      const timeStr = this.intlService.formatDate(new Date(2020, 0, 1, hour, minutes), 't');

      if (amountOfPeriods === 1) {
        return useShortFormat ? timeStr : `${dateStr} ${timeStr}`;
      }

      const day = Math.floor(index / QUARTERS_IN_DAY) + 1;
      return `${this.translateService.instant('DAY')} ${day}, ${timeStr}`;
    }

    if (amountOfPeriods === 1) {
      const format = useShortFormat ? 't' : 'g';
      return this.intlService.formatDate(timestamp, format);
    }

    const day = Math.floor(index / QUARTERS_IN_DAY) + 1;
    const time = this.intlService.formatDate(timestamp, 't');
    return `${this.translateService.instant('DAY')} ${day}, ${time}`;
  }
}

function getLabelValue(label: string, isLast: boolean): string {
  return !isLast ? `(${label}) ` : `${label}`;
}
