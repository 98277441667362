import { ContentChild, Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CompositeFilterDescriptor, DataResult, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';

import { flattenGroupedData } from '@enerkey/ts-utils';

import {
  GridMultiFilterTemplateContext,
  GridMultiFilterTemplateDirective,
} from './grid-multi-filter-template.directive';
import { PropertyPathPipe } from '../../../common-pipes/property-path.pipe';

type Selector<T, U> = keyof T | ((item: T) => U);
type Model<T, U> = GridMultiFilterTemplateContext<T, U>;
type SortFn<T, U> = (a: Model<T, U>, b: Model<T, U>) => number;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/directive-class-suffix */

/**
 * Base class for a reusable "multiselect" filter component.
 *
 * Note: if data is sourced from grid rows, the filters no longer in the data need to be manually
 * removed, for example in the grid's `dataStateChange`-event. See `removeGridFilters` in `kendo-utils.ts`.
 * Doing so is outside the scope of this component, as it only exists when the filter popup is open.
 *
 * @template T Grid data item type, or primitive value if `rawValues` is `true`
 * @template U Primitive value of the field
 */
@Directive()
export abstract class GridMultiFilterBase<T, U> implements OnInit, OnChanges {

  /** Initialize data in the inheriting component. Contains values derived from data source. */
  protected abstract initialize(values: Model<T, U>[]): void;

  /** Fields that are required for the component to work correctly. */
  private static readonly requiredFields = ['filterService', 'field', 'filter', 'dataSource'] as const;

  /**
   * Checks if the `dataSource` property in a `SimpleChanges` object has changed.
   *
   * @param {SimpleChanges} changes - The changes object containing previous and current values of inputs.
   * @returns {boolean} - Returns `true` if the `dataSource` has changed, otherwise `false`.
   */
  private static isDataSourceChanged(changes: SimpleChanges): boolean {
    if (!changes.dataSource) { return false; }

    const prevValue = changes.dataSource.previousValue;
    const currValue = changes.dataSource.currentValue;

    const isPreviousValueNullOrUndefined = prevValue === null || prevValue === undefined;
    const isCurrentValueNullOrUndefined = currValue === null || currValue === undefined;
    const isBothValuesNullOrUndefined = isPreviousValueNullOrUndefined && isCurrentValueNullOrUndefined;

    return currValue !== prevValue && !isBothValuesNullOrUndefined;
  }

  /** The column's filter service. */
  @Input() public filterService: FilterService;

  /** Field of the column, required by filter service. */
  @Input() public field: string;

  /** Current filter of the column, required for state retention after the filter is toggled. */
  @Input() public filter: CompositeFilterDescriptor;

  /** All items of the grid or an alternative (ie. constant list) to provide the possible values. */
  @Input() public dataSource: ReadonlyArray<T> | DataResult;

  /** Key or function to get the visible text and sort order of each item. Defaults to field value if missing. */
  @Input() public textSelector: Selector<T, string>;

  /** Whether `dataSource` is filterable values and not dataItems, for enums for example. */
  @Input() public rawValues: boolean = false;

  /** Custom sorting function, or true/false to sort by text. */
  @Input() public sort: boolean | 'byValue' | SortFn<T, U> = true;

  /** Whether the filter is currently loading data. */
  @Input() public loading: boolean = false;

  @ContentChild(GridMultiFilterTemplateDirective)
  public contentTemplate: GridMultiFilterTemplateDirective;

  /** Sort comparer in case the text labels are numeric (ensures that '1' < '10' etc). */
  private readonly _collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
    usage: 'sort',
  });

  public constructor(
    private readonly propertyPathPipe: PropertyPathPipe
  ) { }

  /** This component is recreated every time the dropdown opens. */
  public ngOnInit(): void {
    this.checkRequiredFields();
    this.initializeValues();
  }

  /** Initialize values on dataSource changes */
  public ngOnChanges(changes: SimpleChanges): void {
    if (GridMultiFilterBase.isDataSourceChanged(changes)) { this.initializeValues(); }
  }

  /** Returns list of values that are active. */
  protected getActiveSelection(): U[] {
    return this.filter.filters.map(d => (d as FilterDescriptor).value);
  }

  /**
   * Update the filter descriptor.
   * @param values Filter list of shown values for the field in grid.
   */
  protected updateFilter(values: U[]): void {
    this.filterService.filter({
      logic: 'or',
      filters: values.map<FilterDescriptor>(
        value => ({
          field: this.field,
          operator: 'eq',
          value: value,
        })
      )
    });
  }

  private initializeValues(): void {
    const textfn = this.getTextSelector();
    const valuefn = this.getFieldSelector();

    const values = this.getDataItems().mapFilter<Model<T, U>>(
      dataItem => ({
        $implicit: dataItem,
        dataItem: dataItem,
        text: textfn(dataItem),
        value: valuefn(dataItem),
      }),
      model => model.value !== undefined && model.value !== null
    ).uniqueBy('value');

    this.initialize(this.getSortedValues(values));
  }

  private getDataItems(): ReadonlyArray<T> {
    if (!this.dataSource) {
      return [];
    }
    if (this.dataSource instanceof Array) {
      return this.dataSource;
    }
    return flattenGroupedData(this.dataSource.data);
  }

  private getFieldSelector(): (arg: T) => U {
    return (this.rawValues
      ? item => item
      : item => this.propertyFieldSelector(item) as any);
  }

  private getTextSelector(): (arg: T) => string {
    switch (typeof this.textSelector) {
      case 'function':
        return this.textSelector;
      case 'undefined': // Default to field of the column
        return this.rawValues
          ? item => `${item}`
          : item => `${this.propertyFieldSelector(item)}`;
      case 'string':
      case 'symbol':
      case 'number':
        return item => item[this.textSelector as keyof T] as any;
      default:
        throw Error('Selector must be function or key');
    }
  }

  private propertyFieldSelector(item: T): U {
    return this.field.includes('.')
      ? this.propertyPathPipe.transform(item as any, this.field) as any
      : item[this.field as keyof T];
  }

  private checkRequiredFields(): void {
    for (const requiredField of GridMultiFilterBase.requiredFields) {
      if (!(requiredField in this)) {
        throw Error(`Field "${requiredField}" must be input in template to this component.`);
      }
    }
  }

  private getSortedValues(values: Model<T, U>[]): Model<T, U>[] {
    if (!this.sort) {
      return values;
    }

    let sortFn: SortFn<T, U>;

    if (this.sort === 'byValue') {
      sortFn = (a, b) => (+a.value) - (+b.value);
    } else if (typeof this.sort === 'function') {
      sortFn = this.sort;
    } else {
      sortFn = (a, b) => this._collator.compare(a.text, b.text);
    }

    return values.sort(sortFn);
  }
}
