import { Quantities } from '@enerkey/clients/metering';

import { ReportingSeries } from './reporting-series';

export type ReportingSeriesByFacility = Record<number, ReportingSeriesCollection[]>;
export type Amounts = { totalFacilities: number; applicableFacilities: number, metersWithValues?: Set<number> };

export class ReportingSeriesCollection {
  public readonly absoluteChange: number;
  public readonly relativeChange: number;
  public readonly unit: string;
  public readonly quantityId: Quantities;
  public readonly series: ReportingSeries[];
  public readonly isNormalized: boolean;
  public readonly derivedValueId: number;
  public readonly amounts: Amounts;
  public readonly inspectionPeriod: ReportingSeries;
  public readonly comparisonPeriod: ReportingSeries;

  public constructor(params: {
    quantityId: Quantities,
    series: ReportingSeries[],
    isNormalized?: boolean,
    derivedValueId?: number,
    amounts?: Amounts,
    averageQuantities?: Quantities[],
  }) {
    this.quantityId = params.quantityId;
    this.series = params.series;
    this.isNormalized = params.isNormalized ?? false;
    this.derivedValueId = params.derivedValueId;
    this.amounts = params.amounts;

    const isAverageQuantity = params.averageQuantities?.includes(this.quantityId) ?? false;

    this.inspectionPeriod = params.series.find(s => s.options.isInspectionPeriod);
    const inspectionValue = isAverageQuantity ?
      this.inspectionPeriod?.averageValue
      : this.inspectionPeriod?.aggregatedValue;
    this.comparisonPeriod = params.series.find(s => s.options.isComparisonPeriod);
    const comparisonValue = isAverageQuantity ?
      this.comparisonPeriod?.averageValue :
      this.comparisonPeriod?.aggregatedValue;

    if (Number.isFinite(inspectionValue) && Number.isFinite(comparisonValue)) {
      this.absoluteChange = inspectionValue - comparisonValue;
      this.relativeChange = comparisonValue ? this.absoluteChange / comparisonValue : null;
    }

    this.unit = this.inspectionPeriod?.options.unit ?? this.comparisonPeriod?.options.unit;
  }
}
