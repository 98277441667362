import { Component, Input, Optional } from '@angular/core';

import { ModalService, NgfActiveModal } from '@enerkey/foundation-angular';

import { ModalViewHeaderService } from './modal-view-header.service';
import { BookmarksModalComponent } from '../../../../components/bookmarks-modal/bookmarks-modal.component';
import { BookmarkService } from '../../../../services/bookmark.service';

@Component({
  selector: 'modal-view-header',
  templateUrl: './modal-view-header.component.html',
  styleUrls: ['./modal-view-header.component.scss']
})
export class ModalViewHeaderComponent {
  @Input() public headingText: string;
  @Input({ required: false }) public bookmarkEnabled = false;

  public constructor(
    @Optional() public readonly activeModal: NgfActiveModal,
    @Optional() public readonly modalViewHeaderService: ModalViewHeaderService,
    private readonly modalService: ModalService,
    private readonly bookmarkService: BookmarkService
  ) { }

  public openBookmarkModal(): void {
    this.modalService.open(BookmarksModalComponent);
  }

  public dismiss(): void {
    this.activeModal.dismiss();
    if (this.bookmarkEnabled) {
      this.bookmarkService.handleReportingModalChange(null);
    }
  }
}
