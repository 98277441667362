<div>
  <ng-container *ngIf="!isMobile">
    <span
      *ngIf="state.name === 'reporting'"
      class="badge badge--new-tab"
    >{{ 'NEW' | translate | uppercase }}</span>
  </ng-container>

  <a
    class="topbar-tab"
    uiSrefActive="is-active"
    [uiSref]="state.name"
  >{{ state.data.translationKey | translate }}</a>
</div>
