import { SimpleChange } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

/* eslint-disable @typescript-eslint/no-explicit-any */

export type DataBindingContext<T = any> = {
  /**
   * Default value of local variables in `ng-template`. This value is used if no variable is
   * named in template's context.
   *
   * @example
   * ```ts
   * templateRef.createEmbeddedView({ $implicit: 'test' })
   * ```
   * ```html
   * <!-- Renders the string 'text' -->
   * <ng-template let-foo>{{ foo }}</ng-template>
   * ```
   */
  $implicit?: T;
};

export interface ControlValueAccessorOf<T> extends ControlValueAccessor {
  writeValue(value: T): void;
  registerOnChange(changefn: (value: T) => void): void;
  registerOnTouched(touchfn: () => void): void;
}

export type SimpleChangesOf<T> = {
  [K in keyof T]?: SimpleChangeOf<T[K]>
};

interface SimpleChangeOf<T> extends SimpleChange {
  previousValue: T;
  currentValue: T;
}
