<form
  [formGroup]="searchGroup"
  (isFormEmpty)="isSubmitDisabled = $event"
  (ngSubmit)="onSearch()"
  singleInputEnabled
  [exclude]="['meterCounts', 'range', 'rangeType']"
>
  <div class="shortcuts">
    <label><strong>{{ 'GRID_SHORTCUTS.TITLE' | translate }}</strong></label>
    <button
      type="button"
      kendoButton
      [disabled]="loading$ | async"
      (click)="getCurrentProfile()"
    >
      {{ 'SETTINGS.CURRENT_PROFILE' | translate }}
    </button>
    <hr>
  </div>
  <p>
    <profile-search
      formControlName="profileName"
      *labelBefore="'ADMIN.PROFILENAME' | translate"
      [valuePrimitive]="true"
      [valueField]="'id'"
      [placeHolder]="'ADMIN.VISIBLENAME' | translate"
    ></profile-search>
  </p>
  <input
    type="text"
    kendoTextBox
    formControlName="profileIds"
    integers
    *labelBefore="'ADMIN.PROFILEID' | translate"
  >
  <input
    type="text"
    kendoTextBox
    formControlName="facilityName"
    *labelBefore="'ADMIN.FACILITY_NAME' | translate"
  >
  <input
    type="text"
    kendoTextBox
    formControlName="facilityIds"
    integers
    *labelBefore="'ADMIN.FACILITYID' | translate"
  >
  <input
    type="text"
    kendoTextBox
    formControlName="enegiaIds"
    integers
    *labelBefore="'ADMIN.ENEGIAID' | translate"
  >
  <input
    type="text"
    kendoTextBox
    formControlName="companyCodes"
    integers
    *labelBefore="'FACILITYINFORMATION.BUSINESSIDENTITYCODE' | translate"
  >
  <input
    type="text"
    kendoTextBox
    formControlName="contractIds"
    integers
    *labelBefore="'ADMIN.CONTRACTS.CONTRACT_ID' | translate"
  >
  <hr>
  <div class="compact">
    <ng-container *ngFor="let range of allRangeTypes">
      <input
        formControlName="rangeType"
        type="radio"
        name="rangeType"
        kendoRadioButton
        [value]="range.type"
        *labelWrap="range.key | translate"
      >
    </ng-container>
  </div>
  <billing-period-picker
    formControlName="range"
    [hidden]="!showBillingPeriodPicker"
  ></billing-period-picker>
  <hr>
  <p>
    <input
      id="contracts-metercounts"
      type="checkbox"
      formControlName="meterCounts"
      kendoCheckBox
    >
    <label for="contracts-metercounts">{{ 'ADMIN.GET_METER_COUNT' | translate }}</label>
  </p>
  <div class="buttons-container">
    <button
      class="button button--primary"
      type="submit"
      [loading]="loading$ | async"
      [disabled]="isSubmitDisabled || !searchGroup.valid"
    >
      {{ 'SEARCH' | translate }}
    </button>

    <button
      class="button button--secondary float-right"
      type="button"
      (click)="onReset()"
      [disabled]="loading$ | async"
    >
      {{ 'RESET' | translate }}
    </button>
  </div>
</form>
