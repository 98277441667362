import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  combineLatest,
  map,
  Observable,
  shareReplay,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs';

import { indicate } from '@enerkey/rxjs';
import { Quantities } from '@enerkey/clients/metering';

import { FacilityService } from '../../../../shared/services/facility.service';
import { ThresholdService } from '../../../../shared/services/threshold.service';
import { ReportEventService } from '../../services/report-events.service';
import { ReportModalMetersService } from '../../services/report-modal-meters.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { ReportingSeriesByFacility } from '../../shared/reporting-series-collection';
import { ReportingGridConfig } from '../report-base';
import { sumKey } from '../../services/sum-report.service';
import { SumMeterReportService } from '../../services/sum-meter-report.service';
import { MeterReportBase } from '../meter-report-base';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { ReportingGridService } from '../../services/reporting-grid.service';
import { ReportType } from '../../shared/report-type';
import { QuantityService } from '../../../../shared/services/quantity.service';
import { MeterAmounts } from '../../shared/reporting-shared-types';

@Component({
  selector: 'sum-meter-report',
  templateUrl: './sum-meter-report.component.html',
  styleUrls: ['../report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SumMeterReportComponent extends MeterReportBase {
  public readonly consumptionData$: Observable<ReportingSeriesByFacility>;
  public readonly gridConfig$: Observable<ReportingGridConfig>;

  public readonly sumKey = sumKey;

  public readonly meterAmounts$: Observable<Record<number, MeterAmounts>>;
  protected readonly reportType = ReportType.Sum;

  public constructor(
    private readonly sumMeterReportService: SumMeterReportService,
    private readonly reportingGridService: ReportingGridService,
    private readonly quantityService: QuantityService,
    reportModalMetersService: ReportModalMetersService,
    thresholdService: ThresholdService,
    facilityService: FacilityService,
    reportingSearchService: ReportingSearchService,
    reportEventService: ReportEventService
  ) {
    super(reportModalMetersService, facilityService, reportingSearchService, reportEventService);

    this.consumptionData$ = combineLatest({
      params: this.searchParams$,
      threshold: thresholdService.threshold$,
      meters: this.debouncedSelectedMeters$
    }).pipe(
      withLatestFrom(this.facilityIds$),
      switchMap(([{ params, threshold, meters }, facilityIds]) => this.sumMeterReportService.getData(
        params, facilityIds[0], meters, threshold
      ).pipe(
        indicate(this._loading$)
      )),
      takeUntil(this._destroy$),
      shareReplay(1)
    );

    this.gridConfig$ = combineLatest({
      data: this.consumptionData$,
      unsupportedQuantitiesAggregate: this.quantityService.getAverageAggregatesQuantities()
    }).pipe(
      withLatestFrom(this.searchParams$),
      map(([{ data, unsupportedQuantitiesAggregate }, searchParams]) => {
        const gridColumns = this.reportingGridService.getColumns(data, [sumKey]);
        const gridData = this.reportingGridService.getData(data, searchParams, [sumKey]);
        const aggregates = this.reportingGridService.getAggregates(
          [sumKey],
          gridColumns,
          gridData,
          ReportType.Sum,
          unsupportedQuantitiesAggregate,
          searchParams
        );
        return {
          data: gridData[sumKey],
          columns: gridColumns[sumKey]?.filter(c => c?.series && c.series?.length),
          aggregates: aggregates[sumKey]
        };
      }),
      shareReplay(1)
    );

    this.meterAmounts$ = combineLatest([
      reportModalMetersService.selectedMeters$,
      this.consumptionData$,
    ]).pipe(
      map(([selected, consumptionData]) => {
        const selectedRecord = selected.meterIdsByQuantities.toRecord();
        const metersRecord = consumptionData[sumKey].toRecord(
          c => c.quantityId,
          c => c.amounts?.metersWithValues
        );

        const result = Object.integerKeys(selectedRecord).map(quantityId => {
          const selectedMeters = selectedRecord[quantityId]?.length ?? 0;
          const metersWithValues = metersRecord[quantityId as Quantities]?.size ?? 0;

          return {
            quantityId,
            selectedMeters,
            metersWithValues,
          };
        }).toRecord(q => q.quantityId);

        return result;
      })
    );

  }

  protected override transformParams(params: ReportingSearchParams): ReportingSearchParams {
    return params;
  }
}
