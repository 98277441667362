import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ServiceLevel } from '@enerkey/clients/facility';

import { TopbarState } from '../components/topbar/topbar.functions';
import { adminTopbarState } from '../modules/admin/config';
import { analyticsTopbarState } from '../modules/analytics/analytics-topbar-state';
import { apiExportTopbarState } from '../modules/api-export/api-export-topbar-state';
import { ccTopbarState } from '../modules/configuration-control/config';
import { documentsTopbarState } from '../modules/documents/documents-topbar-state';
import { energyManagementTopbarState } from '../modules/energy-management/energy-management-topbar-state';
import { erTopbarState } from '../modules/energy-reporting/config';
import { manualQaTopbarState } from '../modules/manual-qa/manual-qa-topbar-state';
import { mrcTopbarState } from '../modules/mrc/config';
import { reportingTopbarState } from '../modules/reporting/reporting-topbar.state';
import { supervisionTopbarState } from '../modules/supervision/supervision.states';
import { sustainabilityTopbarState } from '../modules/sustainability/sustainability.states';
import { webhookTopbarState } from '../modules/webhook/webhook-topbar-state';
import { ProfileService } from '../shared/services/profile.service';
import { UserService } from './user-service';
import { customerAdminTopbarState } from '../modules/customer-admin/customer-admin-topbar-state';

// determines the order of tabs displayed in the topbar
const states: TopbarState[] = [
  reportingTopbarState,
  erTopbarState,
  sustainabilityTopbarState,
  mrcTopbarState,
  energyManagementTopbarState,
  documentsTopbarState,
  adminTopbarState,
  customerAdminTopbarState,
  manualQaTopbarState,
  ccTopbarState,
  webhookTopbarState,
  analyticsTopbarState,
  supervisionTopbarState,
  apiExportTopbarState,
];

@Injectable({
  providedIn: 'root'
})
export class TopbarService {
  public readonly tabs$: Observable<TopbarState[]>;

  public constructor(
    profileService: ProfileService,
    userService: UserService
  ) {
    this.tabs$ = profileService.profile$.pipe(
      map(() => states
        .filter(state => userService.hasAccess(state.data?.auth))
        // hide reporting tab if profile has service level S until ENE-2385 is done
        .filter(state => userService.getServiceLevel() !== ServiceLevel.Small
          ? true
          : state.name !== 'reporting')),
      shareReplay(1)
    );
  }
}
