import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { IntlService } from '@progress/kendo-angular-intl';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { Bookmark, ReportingBookmark, RollingDateType } from '@enerkey/clients/settings';

import { BookmarkService, State } from '../../services/bookmark.service';
import { DialogService } from '../../shared/services/dialog.service';
import { ToasterService } from '../../shared/services/toaster.service';

import {
  bookmarksRows,
  variableBookmarkTranslation
} from '../../constants/bookmark.constants';
import {
  isTopical
} from './reporting-bookmark.functions';

export type BookmarkPartition = {
  page: (Bookmark | ReportingBookmark)[],
  other: (Bookmark | ReportingBookmark)[]
}

export type PartitionedBookmarks = {
  sticky: Bookmark[],
  saved: {
    user: BookmarkPartition,
    shared: BookmarkPartition,
  }
}

@Component({
  templateUrl: './bookmarks-modal.component.html',
  styleUrls: ['./bookmarks-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarksModalComponent extends ModalBase<void> implements OnDestroy {
  public readonly currentState$: Observable<State>;
  public readonly bookmarks$: Observable<PartitionedBookmarks>;
  public readonly variableBookmarkType$: Observable<RollingDateType>;
  public readonly bookmarkInputField: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  public readonly dateAdjustableField: UntypedFormControl = new UntypedFormControl(false);
  public readonly VariableBookmarkTranslation = variableBookmarkTranslation;
  public readonly bookmarksRows = bookmarksRows;

  // Loading...
  public readonly loading$: Observable<boolean>;
  public readonly createLoading$: Observable<boolean>;
  public readonly removeLoading$: Observable<boolean>;
  public readonly shareLoading$: Observable<boolean>;

  private readonly _destroy$ = new Subject<void>();
  private readonly _createLoading$ = new LoadingSubject();
  private readonly _removeLoading$ = new LoadingSubject();
  private readonly _shareLoading$ = new LoadingSubject();

  public constructor(
    private readonly bookmarkService: BookmarkService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly toasterService: ToasterService,
    private readonly intlService: IntlService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this.bookmarkService.loading$.pipe(takeUntil(this._destroy$));
    this.createLoading$ = this._createLoading$.asObservable();
    this.removeLoading$ = this._removeLoading$.asObservable();
    this.shareLoading$ = this._shareLoading$.asObservable();
    this.setNameSuggestion();

    this.variableBookmarkType$ = this.bookmarkService.variableBookmarkType$.pipe(takeUntil(this._destroy$));
    this.currentState$ = this.bookmarkService.currentState$.pipe(takeUntil(this._destroy$));
    this.bookmarks$ = combineLatest([
      this.bookmarkService.currentState$,
      this.bookmarkService.bookmarks$
    ]).pipe(
      map(([state, bookmarks]) => {
        const userBookmarks = [...bookmarks.user, ...bookmarks.reporting.user].sortBy('created', 'desc');
        const sharedBookmarks = [...bookmarks.shared, ...bookmarks.reporting.shared]
          .sortByMany(['createdByCurrentUser', 'desc'], ['created', 'desc']);
        return {
          sticky: bookmarks.sticky.filter(b => b.state === state?.name),
          saved: {
            user: {
              page: userBookmarks.filter(b => this.isForCurrentState(b, state)),
              other: userBookmarks.filter(b => !this.isForCurrentState(b, state)),
            },
            shared: {
              page: sharedBookmarks.filter(b => this.isForCurrentState(b, state)),
              other: sharedBookmarks.filter(b => !this.isForCurrentState(b, state))
            }
          }
        };
      }),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._createLoading$.complete();
    this._removeLoading$.complete();
    this._shareLoading$.complete();
  }

  public showBookmark(bookmark: Bookmark | ReportingBookmark): void {
    this.bookmarkService.showBookmark(bookmark);
    super.dismissModal();
  }

  public createBookmark(): void {
    this.currentState$.pipe(
      take(1),
      switchMap(state => this.bookmarkService.createBookmark(
        this.bookmarkInputField.value, state, this.dateAdjustableField.value
      ).pipe(
        indicate(this._createLoading$)
      ))
    ).subscribe({
      next: () => {
        this.toasterService.success('BOOKMARK.BOOKMARK_SAVED');
        this.bookmarkInputField.reset();
        this.dateAdjustableField.setValue(false);
      },
      error: e => {
        if (e?.message?.startsWith('BOOKMARK.')) {
          this.toasterService.error(e.message);
        } else {
          this.toasterService.error('BOOKMARK.BOOKMARK_SAVE_FAILED');
        }
      }
    });
  }

  public shareBookmark(bookmark: Bookmark | ReportingBookmark): void {
    const isReportingBookmark = bookmark instanceof ReportingBookmark;
    this.bookmarkService.setShareStatus(bookmark.id, true, isReportingBookmark).pipe(
      indicate(this._shareLoading$)
    ).subscribe({
      next: () => {
        this.toasterService.success('BOOKMARK_WIDGET.SHARE_SUCCESSFULL');
      },
      error: () => this.toasterService.error('BOOKMARK_WIDGET.SHARE_FAILED')
    });
  }

  public unShareBookmark(bookmark: Bookmark | ReportingBookmark): void {
    const isReportingBookmark = bookmark instanceof ReportingBookmark;
    this.bookmarkService.setShareStatus(bookmark.id, false, isReportingBookmark).pipe(
      indicate(this._shareLoading$)
    ).subscribe({
      next: () => {
        this.toasterService.success('BOOKMARK_WIDGET.UNSHARE_SUCCESSFULL');
      },
      error: () => this.toasterService.error('BOOKMARK_WIDGET.UNSHARE_FAILED')
    });
  }

  public removeBookmark(bookmark: Bookmark | ReportingBookmark): void {
    const isReportingBookmark = bookmark instanceof ReportingBookmark;
    this.dialogService.getConfirmationModal({
      title: 'BOOKMARK_WIDGET.REMOVE_BOOKMARK_TITLE',
      text: this.translateService.instant(
        'BOOKMARK_WIDGET.REMOVE_BOOKMARK',
        { title: bookmark.title }
      ),
      isDelete: true,
      translate: true
    }).subscribe({
      next: () => {
        this.bookmarkService.removeBookmark(bookmark.id, isReportingBookmark).pipe(
          indicate(this._removeLoading$)
        ).subscribe({
          next: () => {
            this.toasterService.success('BOOKMARK_WIDGET.REMOVE_SUCCESSFULL');
          },
          error: () => {
            this.toasterService.error('BOOKMARK_WIDGET.REMOVE_FAILED');
          }
        });
      }
    });
  }

  public setNameSuggestion(): void {
    this.bookmarkService.bookmarks$.subscribe(bookmarks => {
      const base = this.translateService.instant('BOOKMARK.BOOKMARK');
      const date = this.intlService.formatDate(new Date(), 'd');
      let suggestion = `${base} ${date}`;
      const existingTitles = [
        ...bookmarks.user.map(b => b.title),
        ...bookmarks.shared.map(b => b.title),
        ...bookmarks.reporting.user.map(b => b.title),
        ...bookmarks.reporting.shared.map(b => b.title)
      ];
      const duplicates = existingTitles.filter(t => t?.includes(suggestion));
      if (duplicates.length) {
        suggestion += ` (${duplicates.length})`;
      }
      this.bookmarkInputField.setValue(suggestion);
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private isForCurrentState(bookmark: Bookmark | ReportingBookmark, state: State): boolean {
    if (bookmark instanceof ReportingBookmark) {
      return isTopical(bookmark, state);
    }
    return bookmark.state === state?.name;
  }
}
