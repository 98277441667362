import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ReportingSeriesCollection } from '../../../../modules/reporting/shared/reporting-series-collection';
import { MeterAmounts } from '../../../../modules/reporting/shared/reporting-shared-types';

@Component({
  selector: 'quantity-chart-header',
  templateUrl: './quantity-chart-header.component.html',
  styleUrls: ['./quantity-chart-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuantityChartHeaderComponent {
  @Input() public seriesCollection: ReportingSeriesCollection;
  @Input() public metersAmount: MeterAmounts;
}
