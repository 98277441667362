import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';

import { ActionOutViewModel, ActionSlimViewModel, ActionType, AttachmentsClient } from '@enerkey/clients/attachments';
import { LogLiteDto } from '@enerkey/clients/alarm';
import { ModalService } from '@enerkey/foundation-angular';

import { UserService } from '../../../../services/user-service';
import { Service } from '../../../../constants/service';
import { EmActionEditService } from '../../../energy-management/components/em-action-edit/em-action-edit-service';
import { getNewActionDefaults } from '../../../energy-management/constants/em-shared.constant';
import { ActionOperationType } from '../../../energy-management/constants/em-actions.constants';
import { ALARMS_LOG_GRID_REFRESH } from '../../../alarms-log/shared/alarms-log-grid-refresh';
import { AlarmDetailsModalComponent } from '../../../../shared/alarms-shared/components/alarm-details-modal/alarm-details-modal.component';
import { NoteProcessedData, toNoteData } from './quantity-graph.functions';

@Component({
  selector: 'quantity-graph-actions',
  templateUrl: './quantity-graph-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./quantity-graph-actions.component.scss']
})
export class QuantityGraphActionsComponent {
  @Input({ transform: toNoteData }) public notesData: NoteProcessedData;

  @Output() public readonly addedAction = new EventEmitter<ActionOutViewModel>();

  public readonly actionsAllowed: boolean = false;
  public readonly commentsAllowed: boolean = false;
  public readonly alarmsAllowed: boolean = false;

  public constructor(
    private readonly userService: UserService,
    @Inject('emActionEditService') private readonly emActionEditService: EmActionEditService,
    private readonly attachmentClient: AttachmentsClient,
    private readonly modalService: ModalService
  ) {
    this.actionsAllowed = this.userService.hasService(Service.Actions);
    this.commentsAllowed = this.userService.hasService(Service.Comments);
    this.alarmsAllowed = this.userService.hasService(Service.Alarms);
  }

  public addAction(): void {
    const actionType = ActionType.KE;
    const defaults = getNewActionDefaults(actionType);

    defaults.reportingObjectId = this.notesData.facilityId;
    defaults.quantityIds = [this.notesData.quantityId];

    if (this.notesData.meterId) {
      defaults.meterIds = [this.notesData.meterId];
    }

    this.emActionEditService.getModal(defaults).then((_result: ActionOutViewModel) => {
      this.addedAction.emit();
    })
      .catch(
        /* istanbul ignore next */
        () => { }
      );
  }

  public showAction(action: ActionSlimViewModel): void {
    this.attachmentClient.getAction(
      action.id
    ).subscribe(model => {
      const modalRef = this.emActionEditService.getModal(model);
      modalRef
        .then((_result: { operation?: ActionOperationType, action?: ActionOutViewModel }) => {
          this.addedAction.emit();
        })
        .catch(
          /* istanbul ignore next */
          () => { }
        );
    });
  }

  public openAlarmModal(log: LogLiteDto): void {
    const refreshToken = new Subject();
    const injector = Injector.create({
      providers: [
        {
          provide: ALARMS_LOG_GRID_REFRESH,
          useValue: refreshToken
        }
      ]
    });
    const modalInstance = this.modalService.open(AlarmDetailsModalComponent, { injector });
    modalInstance.componentInstance.getAlarmByLogId(log.id);
  }
}
