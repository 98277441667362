<form [formGroup]="formGroup">
  <facility-select
    class="searchInput"
    formControlName="facilityId"
    *labelBefore="'FACILITIES.FACILITY' | translate"
  ></facility-select>
  <quantity-dropdown
    formControlName="selectedQuantityId"
    [quantities]="quantities$ | async"
    [grouped]="false"
    [loading]="loadingQuantities$ | async"
    [disableWhenLoading]="true"
    *labelBefore="'FACILITIES.QUANTITIES' | translate"
    #quantityDropdownComponent
  ></quantity-dropdown>
  <unit-select
    formControlName="unitKey"
    *labelBefore="'FACILITIES.UNIT' | translate"
  ></unit-select>
  <ek-combo
    *labelBefore="'FACILITIES_TOOLBAR.RESOLUTION' | translate"
    formControlName="resolution"
    [items]="resolutionSelectOptions"
    class="mb-7"
  ></ek-combo>
  <widget-timeframe-select
    formControlName="timeFrameOption"
    *labelBefore="'TIMEFRAME_OPTIONS.TITLE' | translate"
    [widgetType]="widgetType"
  ></widget-timeframe-select>
  <ek-combo
    *labelBefore="'FACILITIES.SIDEBAR.TEMPERATURE' | translate"
    formControlName="temperature"
    [items]="temperatureSelectOptions"
    class="mb-7"
  ></ek-combo>
</form>
