/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { forkJoin, from, Subscription } from 'rxjs';
import { concatMap, finalize, switchMap, toArray } from 'rxjs/operators';
import { IAngularStatic, ITimeoutService } from 'angular';
import { startOfDay } from 'date-fns';

import {
  MeteringClient,
  MeterTagMassOperationDTO,
  MeterUpdateWithProperties
} from '@enerkey/clients/metering';
import {
  FacilityClient
} from '@enerkey/clients/facility';
import { MeterManagementMeter } from '@enerkey/clients/meter-management';

import { ofVoid } from '@enerkey/rxjs';

import { MassImportSpreadsheet } from '../../../../shared/base-classes/mass-import-spreadsheet';
import { MeterSpreadsheetField, MeterSpreadsheetFieldsService } from '../../services/meter-spreadsheet-fields-service';
import { SpreadsheetMessages } from '../../../../shared/interfaces/spreadsheet-messages';
import { ToasterType } from '../../../../constants/toaster-type';
import WizardStep from '../../../../components/wizard/wizard-step';
import { MeterSpreadsheetService } from '../../services/meter-spreadsheet-service';
import { localToUtc, oleToDate } from '../../../../shared/date.functions';
import { Utils } from '../../../../services/utils';
import { parseMeterTagsFromString } from '../meter-create/meter-tags.functions';

declare const angular: IAngularStatic;

const inject = [
  'MeterSpreadsheetService', 'MeterSpreadsheetFieldsService', 'MeteringClient',
  'utils', 'KendoFunctions', 'FacilityClient', '$window', '$timeout', '$element',
];

export type MeterTagData = {
  tags?: string[];
  meterId?: number;
};

type ChangedMeterTags = {
  meterId: number,
  tagToMap: {
    added: string[],
    removed: string[],
  }
}

class MeterMassEditController extends MassImportSpreadsheet {
  public currentStep: WizardStep;
  public steps: WizardStep[];
  public spreadsheetElementId = 'spreadsheet';
  public onSpreadsheetCreate: Function;
  public historySelect = false;
  public powerUserMode = false;
  public onEditsDone: Function;
  public handleModalClose: Function;
  protected spreadsheet: kendo.ui.Spreadsheet;
  protected messages: SpreadsheetMessages;
  protected numberOfColumns: number;
  protected spreadsheetOptions: kendo.ui.SpreadsheetOptions;
  private selectedMeters: MeterManagementMeter[];
  private sheetInitialized = false;
  private fields: MeterSpreadsheetField[];
  private subscription: Subscription;
  private processedIndexes: number[];
  private resizeFn: (() => void) & _.Cancelable;
  private meterIdColumnIndex: number;
  private dateObject: Date;

  public constructor(
    private meterSpreadsheetService: MeterSpreadsheetService,
    private meterSpreadsheetFieldsService: MeterSpreadsheetFieldsService,
    private meteringClient: MeteringClient,
    private utils: Utils,
    private KendoFunctions: any,
    private facilityClient: FacilityClient,
    private $window: ng.IWindowService,
    protected $timeout: ITimeoutService,
    protected $element: JQuery
  ) {
    super();
  }

  public $onInit(): void {
    this.fields = this.meterSpreadsheetFieldsService.getFields();
    this.totalRowCount = this.selectedMeters.length + 1;
    this.numberOfColumns = this.fields.length;
    this.meterIdColumnIndex = this.fields.findIndex((field: MeterSpreadsheetField) => field.value === 'id');
    this.messages = this.getMessages();
    this.steps = this.getSteps();
  }

  public override $onDestroy(): void {
    super.$onDestroy();
    jQuery('body').children('.k-calendar-container').remove();
    angular.element(this.$window).off('resize', this.resizeFn);
  }

  public onClose(): void {
    this.handleModalClose();
  }

  public setPowerUserMode(): void {
    this.meterSpreadsheetService.setPowerUserMode(this.powerUserMode);
  }

  private initSpreadsheet(): void {
    this.meterSpreadsheetService.getSpreadsheetEditOptions(this.selectedMeters)
      .then((result: kendo.ui.SpreadsheetOptions) => {
        this.spreadsheetOptions = result;
        this.sheetInitialized = true;
        this.spreadsheet = null;
        result.render = (e: kendo.ui.SpreadsheetRenderEvent) => {
          e.sender.unbind('render');
          this.lockRange(this.meterIdColumnIndex);
          if (this.totalRowCount + 1 < this.spreadsheetOptions.rows) {
            this.lockRows(this.totalRowCount + 1, this.spreadsheetOptions.rows, !this.powerUserMode);
          }
          this.onSpreadsheetCreate({ spreadsheet: this.spreadsheet });
          this.resizeKendo();
        };
      });
  }

  private getMessages(): SpreadsheetMessages {
    return {
      errorStatus: this.utils.localizedString('ADMIN.SPREADSHEET.ERROR'),
      conflictStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CONFLICT'),
      savedStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVED'),
      savingStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVING'),
      canceledStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CANCELED'),
      noChange: this.utils.localizedString('ADMIN.SPREADSHEET.NO_CHANGE')
    };
  }

  private getSteps(): WizardStep[] {
    return [
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.TYPE_SELECT'),
        isReturnable: true,
        buttonDisabledFn: this.isButtonDisabled.bind(this),
        clickFn: this.initSpreadsheet.bind(this)
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.IMPORT'),
        isReturnable: true,
        buttonDisabledFn: this.isButtonDisabled.bind(this),
        clickFn: this.saveValues.bind(this)
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.SAVE'),
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.STOP_SAVING'),
        isReturnable: false,
        clickFn: this.stopSaving.bind(this)
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.READY'),
        hideButton: true
      }
    ];
  }

  private isButtonDisabled(): boolean {
    return !this.isInitialized;
  }

  private isInitialized(): boolean {
    return this.sheetInitialized;
  }

  private saveValues(): void {
    if (!this.validateSheet()) {
      this.utils.popUp(ToasterType.ERROR, this.utils.localizedString('ADMIN.METERS.ERRORS.INVALID_SPREADSHEET'));
    } else {
      this.processedIndexes = [];

      const dataRows = this.getDataRows().filter(row => row[1] !== null);
      const enegiaIds = dataRows.unique(row => row[1] as number);
      this.facilityClient.getFacilityIdsForEnegiaIds(enegiaIds).pipe(
        finalize(() => this.setFinalStep()),
        switchMap((facilityIds: { [key: string]: number }) => {
          const toBeUpdatedMeters = this.getToBeUpdatedMeters(facilityIds, dataRows);
          const tagChanges = this.checkTagChanges(toBeUpdatedMeters.tagsToBeUpdated, this.selectedMeters);
          const date = this.historySelect ? this.dateObject : undefined;
          const updateMetersRequest = this.meteringClient.updateMeters(date, toBeUpdatedMeters.meterToBeUpdated);
          const tagChangeRequests = from(tagChanges).pipe(
            concatMap(change => {
              const addRequest = Array.hasItems(change.tagToMap.added)
                ? this.meteringClient.addMeterTags(
                  new MeterTagMassOperationDTO({ meterIds: [change.meterId], tagNames: change.tagToMap.added })
                )
                : ofVoid();

              const removeRequest = Array.hasItems(change.tagToMap.removed)
                ? this.meteringClient.removeMeterTags(
                  new MeterTagMassOperationDTO({ meterIds: [change.meterId], tagNames: change.tagToMap.removed })
                )
                : ofVoid();
              return forkJoin([addRequest, removeRequest]);
            }),
            toArray()
          );
          return forkJoin([tagChangeRequests, updateMetersRequest]);
        })
      ).subscribe(
        () => this.handleSuccess(),
        () => this.handleError()
      );
    }
  }
  private checkTagChanges(tagsToBeUpdated: MeterTagData[], selectedMeters: any[]): ChangedMeterTags[] {
    const changedTags: ChangedMeterTags[] = [];

    selectedMeters.forEach(selectedMeter => {
      const meterTagData = tagsToBeUpdated.find(tagData => tagData.meterId === selectedMeter.id);

      if (meterTagData) {
        const newTags = meterTagData.tags;
        const oldTags: string[] = selectedMeter.tags;

        const addedTags = newTags.filter(tag => !oldTags.includes(tag));
        const removedTags = oldTags.filter(tag => !newTags.includes(tag));

        if (addedTags.length > 0 || removedTags.length > 0) {
          changedTags.push({
            meterId: selectedMeter.id,
            tagToMap: {
              added: addedTags,
              removed: removedTags,
            }
          });
        }
      }
    });
    return changedTags;
  }

  private getToBeUpdatedMeters(
    facilitiesInformation: { [key: string]: number },
    dataRows: any[]
  ): {meterToBeUpdated: MeterUpdateWithProperties[], tagsToBeUpdated: MeterTagData[]} {
    const toBeUpdatedMeters: MeterUpdateWithProperties[] = [];
    const tagsToBeUpdated: MeterTagData[] = [];
    dataRows.forEach((row, index) => {
      const meterUpdateUnit: Partial<MeterUpdateWithProperties> = {};
      const tagToUpdate: MeterTagData = {};
      this.fields.forEach(({ value }, fIndex) => {
        switch (value) {
          case 'facilityId': // facilityId is calculated using enegiaId field below.
          case 'status':
            break;
          case 'enegiaId': {
            const facilityId = facilitiesInformation[row[fIndex]];
            meterUpdateUnit.facilityId = facilityId;
            break;
          }
          case 'automaticReadingStartTime':
          case 'automaticReadingEndTime':
          case 'deactivationTime': {
            const time = oleToDate(row[fIndex] as number, true);
            meterUpdateUnit[value] = time ? startOfDay(localToUtc(time)) : null;
            break;
          }
          case 'twoTimeMeasurement':
          case 'linkMeterFixedCosts':
          case 'costMeter':
          case 'qualityAssurance':
          case 'automaticModeling':
            meterUpdateUnit[value] = Boolean(row[fIndex] as number);
            break;
          case 'id':
          case 'quantityId':
          case 'factor':
          case 'costFactorSourceMeterId':
            meterUpdateUnit[value] = row[fIndex] as number;
            break;
          case 'meteringType':
            meterUpdateUnit[value] = row[fIndex];
            break;
          case 'tags':
          {
            tagToUpdate[value] = parseMeterTagsFromString(row[fIndex]);
            tagToUpdate.meterId = row[0] as number;
            break;
          }
          default:
            (meterUpdateUnit[value] as any) = row[fIndex] as string;
        }
      });
      this.processedIndexes.push(index);
      tagsToBeUpdated.push(tagToUpdate);
      toBeUpdatedMeters.push(meterUpdateUnit as MeterUpdateWithProperties);
    });
    return { meterToBeUpdated: toBeUpdatedMeters, tagsToBeUpdated: tagsToBeUpdated };
  }

  private handleSuccess(): void {
    this.processedIndexes.forEach(id => this.setSuccessStatus(id));
    this.onEditsDone({ value: true });
    this.utils.popUp(
      ToasterType.SUCCESS,
      this.utils.localizedString('ADMIN.METERS.SUCCESS.EDIT_SUCCESS')
    );
  }

  private handleError(): void {
    this.processedIndexes.forEach(index => this.setErrorStatus(index));
    this.utils.popUpGeneralError('SAVE', 'METERS');
  }

  private setFinalStep(): void {
    this.currentStep = this.steps[this.steps.length - 1];
  }

  private stopSaving(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.processedIndexes.forEach(id => this.setCanceledStatus(id));
    }
  }

  private resizeKendo(): void {
    this.spreadsheet = this.getSpreadsheetInstance();
    this.resizeFn = _.debounce(() => {
      this.KendoFunctions.resizeKendoComponent(this.spreadsheet, this.spreadSheetRowClass);
    }, 200);

    angular.element(this.$window).on('resize', this.resizeFn);

    this.$timeout(() => this.KendoFunctions.resizeKendoComponent(this.spreadsheet, this.spreadSheetRowClass));
  }
}

MeterMassEditController.$inject = inject;

export default MeterMassEditController;
