<modal-view-header
  [headingText]="this.titleKey | translate"
></modal-view-header>
<div class="modal-body">
  <div class="info-container">
    <div class="metergroup-title">{{ controls.name.value }}</div>
    <div
      class="metergroup-description"
      *ngIf="controls.description.value?.length"
    >
      {{ controls.description.value }}
    </div>
  </div>

  <div class="horizontal-border"></div>
  <div class="message-container">
    <div *ngIf="!controls.allMetersAreIncluded.value; else confirmationMessage">
      <p>{{ 'ADMIN.METERGROUPS.DIALOG.CANNOT_DELETE_METERGROUP_INVALID_ACCESS' | translate }}.</p>
      <p>{{ 'ADMIN.METERGROUPS.DIALOG.TO_HIDE_METERGROUP_DESELECT_ALL_AND_SAVE' | translate }}.</p>
    </div>

    <ng-template #confirmationMessage>
      <div class="warning-container" *ngIf="showMeterCountWarning">
        <strong class="warning-label">{{ 'ADMIN.METERGROUPS.DIALOG.WARNING' | translate }}:</strong>
        <div class="warning-text">
          <p>{{ 'ADMIN.METERGROUPS.DIALOG.METERGROUP_CONTAINS_HIDDEN_METERS' | translate }}.</p>
          <p>{{ 'ADMIN.METERGROUPS.DIALOG.ALL_METERS_WILL_BE_REMOVED' | translate }}.</p>
        </div>
      </div>
      <div
        class="confirm-label"
        [class.confirm-label-warning]="showMeterCountWarning"
      >
        {{ 'ADMIN.METERGROUPS.DIALOG.CONFIRM_DELETION' | translate }}
      </div>
    </ng-template>
  </div>
</div>

<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <ng-container *ngIf="controls.allMetersAreIncluded?.value; else okButton">
      <button
        class="button"
        [loading]="loading$ | async"
        [disabled]="!formGroup.valid || (loading$ | async)"
        (click)="submit()"
      >
        {{ 'ACTIONS.DELETE' | translate }}
      </button>
      <button
        class="button button--secondary"
        type="button"
        (click)="onCloseModalClick()"
      >
        {{ 'CANCEL' | translate }}
      </button>
    </ng-container>

    <ng-template #okButton>
      <button
        class="button"
        [loading]="loading$ | async"
        (click)="onCloseModalClick()"
      >
        {{ 'ADMIN.METERGROUPS.DIALOG.OK' | translate }}
      </button>
    </ng-template>
  </div>
</div>
