export const METER_TAG_REGEX = /^[\p{L}\p{N}]+([_\-. ][\p{L}\p{N}]+)*$/u;

export function parseMeterTagsFromString(tagsString: string): string[] {
  return !tagsString
    ? []
    : tagsString
      .split(',')
      .mapFilter(
        tag => tag.trim(),
        tag => METER_TAG_REGEX.test(tag) && tag.length <= 50
      )
      .unique();
}
