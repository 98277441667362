import {
  animate,
  animateChild,
  AnimationMetadata,
  group,
  query,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

import { ReportTypeOptionsService } from '../../services/report-type-options.service';

export enum SidebarSize { COLLAPSED = 'collapsed', EXPANDED = 'expanded' }

// Button text is rotated 270 deg so `up` points left and `down` points right
type ButtonIconClass = 'fa fa-angle-double-up' | 'fa fa-angle-double-down';

const expandTransition: AnimationMetadata[] = [
  animate('250ms ease-in'),
  query('@fadeInOut', group([
    animateChild()
  ]), { optional: true }),
];

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition('* => expanded', [
        style({ opacity: 0 }),
        animate('100ms ease-in', style({ opacity: 1 }))
      ]),
      transition('expanded => collapsed', [
        animate('100ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('hostSize', [
      state('collapsed', style({
        width: 'var(--reporting-sidebar-button-width)'
      })),
      state('expanded', style({
        width: 'var(--reporting-sidebar-width)'
      })),
      transition('collapsed => expanded', expandTransition),
      transition('expanded => collapsed', [
        group([
          query('@fadeInOut', group([
            animateChild()
          ]), { optional: true }),
        ]),
        animate('250ms ease-in'),
      ]),
    ])
  ]
})
export class SidebarComponent implements OnDestroy, OnInit {
  @Input() public isModal: boolean;
  @Input() public isMeterReport: boolean;
  @Output() public readonly isCollapsed = new EventEmitter<boolean>();

  public hostSizeAnimation: SidebarSize;
  public buttonIconClass: ButtonIconClass = 'fa fa-angle-double-up';
  public buttonTitle: string = 'SIDEBAR.HIDE';

  public readonly isCollapsed$: Observable<boolean>;
  public readonly hideSearchForm$: Observable<boolean>;

  private readonly _isCollapsed$ = new BehaviorSubject(false);
  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly reportTypeOptionsService: ReportTypeOptionsService
  ) {
    this.isCollapsed$ = this._isCollapsed$.asObservable();
    this.hideSearchForm$ = this.reportTypeOptionsService.disableAllSettings$;
  }

  @HostListener('document:keydown.escape', ['$event'])
  public escPress(event: KeyboardEvent): void {
    if (!this._isCollapsed$.value && !event.defaultPrevented) {
      this._isCollapsed$.next(true);
      event.preventDefault();
    }
  }

  public ngOnInit(): void {
    this._isCollapsed$
      .pipe(takeUntil(this.destroy$))
      .subscribe(collapsed => {
        this.hostSizeAnimation = collapsed ? SidebarSize.COLLAPSED : SidebarSize.EXPANDED;
        this.isCollapsed.emit(collapsed);
        this.buttonIconClass = collapsed ? 'fa fa-angle-double-down' : 'fa fa-angle-double-up';
        this.buttonTitle = collapsed ? 'SIDEBAR.SHOW' : 'SIDEBAR.HIDE';
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._isCollapsed$.complete();
  }

  public toggleSidebar(): void {
    this._isCollapsed$.next(!this._isCollapsed$.value);
  }
}
