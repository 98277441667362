import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  combineLatest,
  map,
  Observable,
  shareReplay,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs';

import { indicate } from '@enerkey/rxjs';

import { FacilityService } from '../../../../shared/services/facility.service';
import { ThresholdService } from '../../../../shared/services/threshold.service';
import { ReportEventService } from '../../services/report-events.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { ReportingDistributionType } from '../../shared/reporting-search-form-value';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { ReportingGridConfig } from '../report-base';
import { ReportingSeriesByFacility } from '../../shared/reporting-series-collection';
import { ForecastMeterReportService } from '../../services/forecast-meter-report.service';
import { ReportModalMetersService } from '../../services/report-modal-meters.service';
import { MeterReportBase } from '../meter-report-base';
import { ReportingGridService } from '../../services/reporting-grid.service';
import { ReportType } from '../../shared/report-type';
import { QuantityService } from '../../../../shared/services/quantity.service';

@Component({
  selector: 'forecast-meter-report',
  templateUrl: '../meter-report-base.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForecastMeterReportComponent extends MeterReportBase {
  public readonly consumptionData$: Observable<ReportingSeriesByFacility>;
  public readonly gridConfig$: Observable<Record<number, ReportingGridConfig>>;

  protected readonly reportType = ReportType.Forecast;

  public constructor(
    private readonly forecastMeterReportService: ForecastMeterReportService,
    private readonly reportingGridService: ReportingGridService,
    private readonly quantityService: QuantityService,
    reportModalMetersService: ReportModalMetersService,
    thresholdService: ThresholdService,
    facilityService: FacilityService,
    reportingSearchService: ReportingSearchService,
    reportActionsService: ReportEventService
  ) {
    super(reportModalMetersService, facilityService, reportingSearchService, reportActionsService);

    this.consumptionData$ = combineLatest({
      params: this.searchParams$,
      threshold: thresholdService.threshold$,
      meters: this.debouncedSelectedMeters$,
      averageQuantities: this.quantityService.getAverageAggregatesQuantities()
    }).pipe(
      withLatestFrom(this.facilityIds$),
      switchMap(([{ params, threshold, meters, averageQuantities }, facilityIds]) =>
        this.forecastMeterReportService.getForecastData(
          params, threshold, facilityIds[0], meters, averageQuantities
        ).pipe(
          indicate(this._loading$)
        )),
      takeUntil(this._destroy$),
      shareReplay(1)
    );

    this.gridConfig$ = this.consumptionData$.pipe(
      withLatestFrom(this.selectedMeters$, this.searchParams$),
      map(([data, meters, searchParams]) => {
        const meterIds = meters.meterIds;
        const gridColumns = this.reportingGridService.getColumns(data, meterIds);
        const gridData = this.reportingGridService.getData(data, searchParams, meterIds);
        return meterIds.toRecord(
          mId => mId,
          mId => ({
            data: gridData[mId],
            columns: gridColumns[mId],
            aggregates: undefined
          })
        );
      }),
      shareReplay(1)
    );
  }

  protected override transformParams(params: ReportingSearchParams): ReportingSearchParams {
    return new ReportingSearchParams({
      ...params.formValue,
      distributionType: ReportingDistributionType.None,
    });
  }
}
