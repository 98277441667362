import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MeterGroupTreeItem } from '../../services/report-modal-meter-group.service';

@Component({
  selector: 'metergroup-report-header',
  templateUrl: './metergroup-report-header.component.html',
  styleUrl: './metergroup-report-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetergroupReportHeaderComponent {
  @Input() public meterGroup: MeterGroupTreeItem;
}
