import {
  ReportingUnit as bookmarkUnit,
  DistributionType,
  ExcludeIncomplete,
  ReportType,
  VisibleSections
} from '@enerkey/clients/settings';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { ReportingChildState } from '../modules/reporting/reporting.states';
import { ReportingDistributionType } from '../modules/reporting/shared/reporting-search-form-value';
import { Comparability } from '../shared/ek-inputs/comparability-select/comparability-select.component';

/** Reports that do not require any search params input from user */
export const staticReportTypes = [
  ReportType.FacilityOverview,
  ReportType.YearlyTrend,
];

export type ReportName = ReportingChildState | 'reporting.overview';

export const reportingBookmarkStateType: Record<ReportName, ReportType> = {
  'reporting.table': ReportType.Table,
  'reporting.period': ReportType.Period,
  'reporting.sum': ReportType.Sum,
  'reporting.trend': ReportType.Trend,
  // 'reporting.comparison': ReportType.Comparison, // report not yet implemented
  'reporting.forecast': ReportType.Forecast,
  'reporting.overview': ReportType.FacilityOverview,
  'reporting.yearly-trend': ReportType.YearlyTrend,
};

/** Maps bookmark's visible sections to facility sections used in reporting params `sections` */
export const facilityInfoSections: Partial<Record<VisibleSections, string>> = {
  [VisibleSections.FacilityInformation]: VisibleSections.FacilityInformation,
  [VisibleSections.TechnicalSpecifications]: VisibleSections.TechnicalSpecifications,
  [VisibleSections.Properties]: VisibleSections.Properties,
  [VisibleSections.Co2Factors]: VisibleSections.Co2Factors,
  [VisibleSections.EnergyCompany]: VisibleSections.EnergyCompany,
  [VisibleSections.ManagerCompany]: 'managerCompany',
  [VisibleSections.FacilityManagementCompany]: 'facilityManagementCompany',
  [VisibleSections.MaintenanceCompany]: 'maintenanceCompany',
  [VisibleSections.OwnerCompany]: 'ownerCompany',
  [VisibleSections.ExternalIds]: VisibleSections.ExternalIds,
  [VisibleSections.MainMeterCounts]: VisibleSections.MainMeterCounts,
  [VisibleSections.SubMeterCounts]: VisibleSections.SubMeterCounts,
  [VisibleSections.Tags]: VisibleSections.Tags,
};

export const bookmarkReportingUnit: Record<bookmarkUnit, ReportingUnit> = {
  [bookmarkUnit.Default]: ReportingUnit.Default,
  [bookmarkUnit.MWh]: ReportingUnit.MWh,
  [bookmarkUnit.KWh]: ReportingUnit.KWh,
};

export const bookmarkDistributionType: Record<DistributionType, ReportingDistributionType> = {
  [DistributionType.None]: ReportingDistributionType.None,
  [DistributionType.DayNight]: ReportingDistributionType.DayNight,
  [DistributionType.Weekday]: ReportingDistributionType.Weekday,
};

export const bookmarkComparability: Record<ExcludeIncomplete, Comparability> = {
  [ExcludeIncomplete.None]: Comparability.All,
  [ExcludeIncomplete.Quantity]: Comparability.ByQuantity,
  [ExcludeIncomplete.All]: Comparability.ByQuantities
};
