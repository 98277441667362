<div>
  <div>
    <h1>{{ meterGroup.name }}</h1>
  </div>
  <div class="card reporting-info-card">
    <div class="header-group">
      <div class="header-icon header-icon--bg-round header-icon--bg-white">
        <i class="fa fa-home"></i>
      </div>
      <h3 class="header-group__label">{{'REPORTING.METER_GROUP.METER_GROUP_DETAILS' | translate}}</h3>
    </div>
    <div class="metergroup-info-grid">
      <div>
        <div>
          <span class="bold">{{ 'FACILITIES_REPORT.DESCRIPTION' | translate}}</span>
        </div>
        <span>{{ meterGroup.description }}</span>
      </div>
    </div>
  </div>
</div>
