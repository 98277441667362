import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { from, map, Observable, of, startWith, switchMap, take, tap } from 'rxjs';

import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';
import { Quantities } from '@enerkey/clients/metering';
import { ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import {
  FacilityWeeklyConsumptionWidgetOptions,
  Temperature
} from '../facility-weekly-consumption-widget/facility-weekly-consumption-widget.component';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { QuantityService } from '../../../../shared/services/quantity.service';
import { WidgetType } from '../../shared/widget-type';
import { QuantityDropdownComponent } from '../../../../shared/ek-inputs/quantity-dropdown/quantity-dropdown.component';
import TimeFrameOptions from '../../../../constants/time-frame';

type ChangeFn = (options: FacilityWeeklyConsumptionWidgetOptions) => void;

type FacilityWeeklyConsumptionWidgetOptionsForm = {
  facilityId: number;
  selectedQuantityId: Quantities;
  unitKey: ReportingUnit;
  resolution: RequestResolution.P7D | RequestResolution.PT1H;
  timeFrameOption: TimeFrameOptions;
  temperature: Temperature;
}

const RESOLUTIONS = [
  RequestResolution.P7D,
  RequestResolution.PT1H,
] as const;

const TEMPERATURES = [
  Temperature.YES,
  Temperature.INVERTED,
  Temperature.NO,
] as const;

const temperatureTranslationkeys: Record<typeof TEMPERATURES[number], string> = {
  [Temperature.YES]: 'YES',
  [Temperature.INVERTED]: 'YES_INVERTED',
  [Temperature.NO]: 'NO'
};

const resolutionTranslationkeys: Record<typeof RESOLUTIONS[number], string> = {
  [RequestResolution.P7D]: 'TIMESPAN.WEEK',
  [RequestResolution.PT1H]: 'TIMESPAN.HOUR',
};

@Component({
  selector: 'facility-weekly-consumption-widget-options',
  templateUrl: './facility-weekly-consumption-widget-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FacilityWeeklyConsumptionWidgetOptionsComponent),
    multi: true,
  },
  {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => FacilityWeeklyConsumptionWidgetOptionsComponent)
  }]
})
export class FacilityWeeklyConsumptionWidgetOptionsComponent implements ControlValueAccessor, OnInit, Validator {
  @Input() public initialState: FacilityWeeklyConsumptionWidgetOptions;

  @ViewChild(QuantityDropdownComponent) public quantityDropdown: QuantityDropdownComponent;

  public controls: ControlsOf<FacilityWeeklyConsumptionWidgetOptionsForm>;
  public formGroup: UntypedFormGroup;
  public quantities$: Observable<Quantities[]>;

  public readonly resolutionSelectOptions: ComboItem<RequestResolution.P7D | RequestResolution.PT1H>[];
  public readonly temperatureSelectOptions: ComboItem<Temperature>[];
  public readonly widgetType: WidgetType = WidgetType.FacilityWeeklyConsumption;
  public readonly loadingQuantities$: Observable<boolean>;
  private readonly _loadingQuantities = new LoadingSubject();

  private _onChange: ChangeFn;

  public constructor(private readonly quantityService: QuantityService) {

    this.resolutionSelectOptions = RESOLUTIONS.map(value => ({
      value,
      text: resolutionTranslationkeys[value]
    }));

    this.temperatureSelectOptions = TEMPERATURES.map(value => ({
      value,
      text: temperatureTranslationkeys[value]
    }));

    this.loadingQuantities$ = this._loadingQuantities.asObservable();

  }

  public ngOnInit(): void {
    this.controls = formControlsFrom<FacilityWeeklyConsumptionWidgetOptionsForm>(
      this.optionsToForm(this.initialState), {
        facilityId: Validators.required,
        selectedQuantityId: Validators.required
      }
    );

    this.formGroup = new UntypedFormGroup(this.controls);

    this.formGroup.valueChanges.subscribe((formValue: FacilityWeeklyConsumptionWidgetOptionsForm) => {
      this._onChange?.(this.formToOptions(formValue));
    });

    this.quantities$ = this.formGroup.get('facilityId').valueChanges.pipe(
      startWith(this.formGroup.value.facilityId),
      switchMap(facilityId => facilityId ?
        from(this.quantityService.getSignificantQuantitiesForFacility(facilityId)).pipe(
          take(1),
          map(quantities => quantities.map(q => q.ID)),
          indicate(this._loadingQuantities)
        ) :
        of([]).pipe(indicate(this._loadingQuantities))),
      tap(quantities => {
        const quantityValue = this.formGroup.get('selectedQuantityId').value;
        if (Number.isFinite(quantityValue)) {
          if (!quantities.includes(quantityValue)) {
            this.formGroup.get('selectedQuantityId').reset();
          } else {
            setTimeout(() => this.quantityDropdown.writeValue(quantityValue));
          }
        }
      })
    );
  }

  public writeValue(value: FacilityWeeklyConsumptionWidgetOptionsForm): void {
    this.formGroup.setValue(this.optionsToForm(value));
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

  public validate(_: AbstractControl): ValidationErrors | null {
    if (this.formGroup.invalid) {
      return {
        required: true
      };
    }
    return null;
  }

  private optionsToForm(options: FacilityWeeklyConsumptionWidgetOptions): FacilityWeeklyConsumptionWidgetOptionsForm {
    return { ...options };
  }

  private formToOptions(formValue: FacilityWeeklyConsumptionWidgetOptionsForm): FacilityWeeklyConsumptionWidgetOptions {
    return { ...formValue };
  }
}

