import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ColumnVisibilityChangeEvent } from '@progress/kendo-angular-treelist';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@enerkey/foundation-angular';

import { Meter, MeteringClient, MeterTagDTO } from '@enerkey/clients/metering';

import { absoluteChangeKey, relativeChangeKey } from '../../constants/table-report-constants';
import {
  MeterInfoColumnVisibilityByQuantity,
  MeterTableReportService
} from '../../services/meter-table-report.service';
import { ReportingMeterTreeMeter, ReportModalMetersService } from '../../services/report-modal-meters.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import {
  getMeterTableReportColumns,
  getTableReportGridData,
  MeterTableReportColumnGroup,
  MeterTableReportData
} from '../../shared/meter-table-report.functions';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { ReportingSeriesCollection } from '../../shared/reporting-series-collection';
import { REPORT_MODAL_PARAMS, ReportingModalParams } from '../report-modal/report-modal.component';
import { MeterTagEditModalComponent } from '../../../admin/components/meter-tag-edit-modal/meter-tag-edit-modal.component';
import { MeterEditModalComponent } from '../meter-edit-modal/meter-edit-modal.component';
import { UserService } from '../../../../services/user-service';
import { Roles } from '../../../admin/constants/roles';
import { QuantityService } from '../../../../shared/services/quantity.service';

interface MeterTableReportQuantity {
  quantityId: number;
  isNormalized: boolean;
  isAggregateUnsupported: boolean;
  isPercentSerie: boolean;
  treeListConfig: MeterTableReportTreelistData;
}

export class MeterTableReportMeter extends ReportingMeterTreeMeter {
  public constructor(
    meter: ReportingMeterTreeMeter,
    subMeters: ReportingMeterTreeMeter[],
    public readonly values: MeterTableReportData,
    public readonly tags: { [key: string]: boolean }
  ) {
    super(meter, subMeters);
  }
}
type TagWithId = { tagId: string, tagName: string }
interface MeterTableReportTreelistData {
  treeListData: MeterTableReportMeter[];
  treeListColumns: MeterTableReportColumnGroup[];
  aggregates: { field: string, aggregate: string }[],
  uniqueTags: TagWithId[]
}

const countAggregate = { field: 'meterTreeVisibleName', aggregate: 'count' };

@Component({
  selector: 'meter-table-report',
  templateUrl: './meter-table-report.component.html',
  styleUrls: ['./meter-table-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterTableReportComponent implements OnDestroy {
  public readonly meters$: Observable<MeterTableReportQuantity[]>;

  public readonly searchParams$: Observable<ReportingSearchParams>;
  public collapsedMeters: number[] = [];
  public uniqueTags: TagWithId[] = [];
  public readonly absoluteChangeKey = absoluteChangeKey;
  public readonly relativeChangeKey = relativeChangeKey;

  public readonly meterInfoColumns$: Observable<MeterInfoColumnVisibilityByQuantity>;
  public readonly gridFilters$: Observable<{[quantityId: number]: CompositeFilterDescriptor}>;
  public readonly gridSorts$: Observable<{[quantityId: number]: SortDescriptor[]}>;

  public readonly hasMeterUpdateAccess: boolean;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private reportModalMetersService: ReportModalMetersService,
    reportingSearchService: ReportingSearchService,
    private meterTableReportService: MeterTableReportService,
    userService: UserService,
    @Inject(REPORT_MODAL_PARAMS) modalParams: ReportingModalParams,
    private readonly modalService: ModalService,
    private readonly meterClient: MeteringClient,
    private readonly quantityService: QuantityService,
    private readonly translateService: TranslateService
  ) {
    this.hasMeterUpdateAccess = userService.hasRole(Roles.FACILITY_UPDATE) &&
                                userService.hasRole(Roles.METER_MANAGER_USER);

    this.searchParams$ = reportingSearchService.searchParameters$.pipe(
      map(params => new ReportingSearchParams({
        ...params.formValue,
        showConsumption: true
      }))
    );
    this.meters$ = combineLatest([
      reportModalMetersService.meterTrees$,
      reportModalMetersService.selectedMeters$,
      this.searchParams$,
      this.quantityService.normalizedQuantityIds$,
      this.quantityService.getAverageAggregatesQuantities()
    ]).pipe(
      tap(() => {
        this.collapsedMeters = [];
      }),
      switchMap(([meterTrees, meters, params, normalizedQuantityIds, unsupportedQuantitiesAggregate]) =>
        forkJoin({
          data: this.meterTableReportService.getData(params, modalParams.facilityId, meters),
          metersTags: meters.meters?.length
            ? this.meterClient.getTagsForMeters(meters.meters.map(meter => meter.id))
            : of([])
        }).pipe(
          map(({ data, metersTags }) => {
            const quantities: MeterTableReportQuantity[] = [];
            const meterTreesByQuantityId = meters.meters.toGroupsBy(t => t.quantityId);
            for (const [quantityId, quantityMeters] of meterTreesByQuantityId) {
              const meterSeriesByMeterId = quantityMeters.toRecord(
                m => m.id,
                m => data[m.id]
              );
              const meterSeriesCollectionsFlat = Object.values(meterSeriesByMeterId).flat();
              const meterTagsByMeterId = quantityMeters.toRecord(
                m => m.id,
                m => metersTags.filter(t => t.meterId === m.id)
              );
              const treeListData = getTableReportGridData(meterSeriesByMeterId, meters.meterIds);
              const isAggregateUnsupported = unsupportedQuantitiesAggregate.includes(quantityId);
              const isPercentSerie = params.distributionAsPercent;

              if (params.measured) {
                quantities.push({
                  quantityId,
                  isNormalized: false,
                  isAggregateUnsupported,
                  isPercentSerie,
                  treeListConfig: this.getTreeListConfig(
                    meterSeriesCollectionsFlat,
                    meterTrees,
                    quantityMeters,
                    treeListData.measured,
                    meterTagsByMeterId
                  ),
                });
              }

              if (params.normalized && normalizedQuantityIds.includes(quantityId)) {
                quantities.push({
                  quantityId,
                  isNormalized: true,
                  isAggregateUnsupported,
                  isPercentSerie,
                  treeListConfig: this.getTreeListConfig(
                    meterSeriesCollectionsFlat,
                    meterTrees,
                    quantityMeters,
                    treeListData.normalized,
                    meterTagsByMeterId
                  ),
                });
              }
            }
            return quantities;
          })
        ))
    );

    this.meterInfoColumns$ = this.meterTableReportService.meterInfoColumnVisibility$.pipe(takeUntil(this._destroy$));
    this.gridFilters$ = this.meterTableReportService.gridFilters$.pipe(takeUntil(this._destroy$));
    this.gridSorts$ = this.meterTableReportService.gridSorts$.pipe(takeUntil(this._destroy$));
  }

  public trackByMethod(_index: number, quantity: MeterTableReportQuantity): string {
    return `${quantity.quantityId}${quantity.isNormalized}`;
  }

  public editMeter(dataItem: MeterTableReportMeter): void {
    const modalRef = this.modalService.open(MeterEditModalComponent);
    modalRef.componentInstance.selectedMeter = new Meter({
      id: dataItem.id,
      name: dataItem.name,
      reportingObjectId: null,
      meteringType: null,
      quantityId: null,
      factor: null,
      twoTimeMeasurement: null,
      customerMeterIdentifier: null,
    });

    modalRef.result.then(result => {
      if (result) {
        this.reportModalMetersService.updateMeterTrees();
      }
    }, _);
  }

  public editTags(dataItem: MeterTableReportMeter): void {
    const modalRef = this.modalService.open(MeterTagEditModalComponent);
    modalRef.componentInstance.selectedMeter = new Meter({
      id: dataItem.id,
      name: dataItem.name,
      reportingObjectId: null,
      meteringType: null,
      quantityId: null,
      factor: null,
      twoTimeMeasurement: null,
      customerMeterIdentifier: null,
    });
  }

  public columnChange(event: ColumnVisibilityChangeEvent, quantityId: number): void {
    this.meterTableReportService.setGridMeterInfoColumns(quantityId, event.columns);
  }

  public filterChange(event: CompositeFilterDescriptor, quantityId: number): void {
    this.meterTableReportService.setGridFilters(quantityId, event);
  }

  public sortChange(event: SortDescriptor[], quantityId: number): void {
    this.meterTableReportService.setGridSorts(quantityId, event);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private hasMatchingTag(tags: MeterTagDTO[], tagToCheck: string): boolean {
    return tags?.some(tag => tag.tagId === tagToCheck);
  }

  private getVisibleMeters(
    tree: ReportingMeterTreeMeter,
    selectedMeterIds: number[],
    data: Record<number, MeterTableReportData>,
    meterTagsByMeterId: Record<number, MeterTagDTO[]>
  ): MeterTableReportMeter {
    const visibleChildren = tree.subMeters?.mapFilter(
      t => this.getVisibleMeters(t, selectedMeterIds, data, meterTagsByMeterId),
      t => t
    );
    if (Array.hasItems(visibleChildren) || selectedMeterIds.includes(tree.id)) {
      const tags = this.uniqueTags.reduce((acc: { [key: string]: boolean }, tag: TagWithId) => {
        acc[tag.tagId] = this.hasMatchingTag(meterTagsByMeterId[tree.id], tag.tagId);
        return acc;
      }
      , {});
      return new MeterTableReportMeter(
        tree,
        visibleChildren,
        data[tree.id],
        tags
      );
    }
  }

  private getTreeListConfig(
    seriesCollections: ReportingSeriesCollection[],
    meterTrees: ReportingMeterTreeMeter[],
    quantityMeters: ReportingMeterTreeMeter[],
    dataByMeterId: Record<number, MeterTableReportData>,
    meterTagsByMeterId: Record<number, MeterTagDTO[]>
  ): MeterTableReportQuantity['treeListConfig'] {
    const uniqueSeries = seriesCollections
      .flatMap(c => c.series)
      .filter(s => s.isShownInGrid)
      .sortBy(s => s.isChangeVisible, 'desc')
      .uniqueByMany(
        s => s.options.serieType,
        s => s.serieStart
      )
    ;
    const treeListColumns = getMeterTableReportColumns(uniqueSeries);
    const allMeterTags = Object.values(meterTagsByMeterId).flat();
    this.uniqueTags = allMeterTags.map(tag => ({ tagName: tag.tagName, tagId: tag.tagId })).uniqueBy('tagId');

    const quantityAggregates = treeListColumns
      .flatMap(g => g.series)
      .flatMap(series => series.flatMap(s => ['value', absoluteChangeKey, relativeChangeKey].map(fieldSuffix =>
        (['sum', 'min', 'max', 'average'].map(aggregate =>
          ({
            field: `${s.field}.${fieldSuffix}`,
            aggregate: aggregate
          })))).flat()));

    const visibleTrees = this.translateMeterTreePropeties(meterTrees.mapFilter(
      t => this.getVisibleMeters(t, quantityMeters.map(m => m.id), dataByMeterId, meterTagsByMeterId),
      t => t
    ));

    return {
      treeListData: visibleTrees,
      treeListColumns,
      uniqueTags: this.uniqueTags,
      aggregates: [
        countAggregate,
        ...quantityAggregates
      ]
    };
  }

  private translateMeterTreePropeties(meterTree: MeterTableReportMeter[]): MeterTableReportMeter[] {
    return meterTree.map(tree => {
      tree.measurementMethod = this.translateService.instant(tree.measurementMethod);
      tree.meterTypeName = this.translateService.instant(tree.meterTypeName);

      if (Array.hasItems(tree.subMeters)) {
        this.translateMeterTreePropeties(tree.subMeters as MeterTableReportMeter[]);
      }

      return tree;
    });
  }
}
