import { EnerkeyNg2StateDeclaration } from '../../shared/routing';
import { ContractsComponent } from './components/contracts/contracts.component';
import { contractTabState } from './contract-tab-state';

export const contractsState: EnerkeyNg2StateDeclaration = {
  ...contractTabState,
  parent: 'adminBase',
  url: '/contract/',
  views: {
    'admin-content@adminBase': {
      component: ContractsComponent
    }
  },
};
