import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { FacilityClient } from '@enerkey/clients/facility';
import { ContractClient, CreateContractProduct, SwaggerException } from '@enerkey/clients/contract';

import { WizardStep } from '../../../../shared/interfaces/wizard-step';
import {
  cellName,
  DEFAULT_COLOR,
  HEADER_BACKGROUND,
} from '../../../../shared/spreadsheet.functions';
import {
  contractSheetColumns,
  ContractSheetOptions,
  ContractSpreadsheetColumns,
} from './contract-sheet-options';
import { ContractMassAddRow, RowValues } from '../../models/mass-add-row';
import {
  ContractMassAddForm,
  ContractMassAddOptionsComponent,
} from './contract-add-options.component';
import { dateToOle, localToUtc, oleToDate } from '../../../../shared/date.functions';
import { removeSpreadsheetOrphans } from '../../../../shared/ek-kendo/kendo.functions';
import { ToasterService } from '../../../../shared/services/toaster.service';

/**
 * References for the ugly spreadsheet hacks:
 * - https://docs.telerik.com/kendo-ui/knowledge-base/view-only-spreadsheet-widget
 * - https://docs.telerik.com/kendo-ui/controls/data-management/spreadsheet/how-to/get-flagged-cells
 * - https://www.telerik.com/forums/get-all-cells-or-rows-of-a-spreadsheet-workbook#oAp4YU2asUuHn16dNERb5g
 */

enum MassAddStep {
  Prefill,
  Import,
  Validate,
  Save,
  Done
}

type Cell = kendo.ui.SpreadsheetSheetRowCell;

@Component({
  selector: 'contract-mass-add',
  templateUrl: './contract-mass-add.component.html',
  styleUrls: ['./contract-mass-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'large' })
export class ContractMassAddComponent extends ModalBase implements OnDestroy {

  public readonly Steps = MassAddStep;
  public readonly wizardSteps: WizardStep<MassAddStep>[];
  public activeStep: WizardStep<MassAddStep>;

  public readonly createCompleted: EventEmitter<void> = new EventEmitter<void>();

  public options: ContractSheetOptions = null;
  public skipEmptyEnegiaIds: boolean = true;
  public optionsValid: boolean = true;

  public invalidRows: string = '';
  public isSheetValid: boolean = false;
  public isSheetEmpty: boolean = true;

  public get spreadsheet(): kendo.ui.Spreadsheet {
    return this._spreadsheet;
  }

  public get sheetElement(): JQuery {
    return this._sheetElement;
  }

  private get editableRange(): kendo.spreadsheet.Range {
    const { rowCount, columnCount } = this.sheetSize;
    return this.spreadsheet.activeSheet().range(1, 0, rowCount - 1, columnCount);
  }

  private get sheetSize(): { rowCount: number; columnCount: number } {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const sheet = this.spreadsheet.activeSheet();
    const rowCount: number = (sheet as any)._rows._count;
    const columnCount: number = (sheet as any)._columns._count;
    return { rowCount, columnCount };
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }

  private _sheetElement: JQuery = null;
  private _spreadsheet: kendo.ui.Spreadsheet = null;

  @ViewChildren(ContractMassAddOptionsComponent)
  private readonly prefillOptsQuery: QueryList<ContractMassAddOptionsComponent>;

  public constructor(
    activeModal: NgfActiveModal,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly contractClient: ContractClient,
    private readonly facilityClient: FacilityClient,
    private readonly toaster: ToasterService
  ) {
    super(activeModal);
    this.wizardSteps = this.initWizardSteps();
    this.activeStep = this.wizardSteps[0];
  }

  public ngOnDestroy(): void {
    this.clearSpreadsheet();
    this.createCompleted.complete();
  }

  public close(): void {
    super.closeModal();
  }

  /* istanbul ignore next */
  public onSkipEmptyChanged(): void {
    setTimeout(() => this.validate());
  }

  private clearSpreadsheet(): void {
    if (this.spreadsheet) {
      this.spreadsheet.destroy();
      this.sheetElement.empty();
      this.sheetElement.removeClass();

      this._spreadsheet = null;
      removeSpreadsheetOrphans();
    }
  }

  private initSpreadsheet(): void {
    const formValue: ContractMassAddForm = this.prefillOptsQuery.first.formGroup.value;

    this.options = new ContractSheetOptions(
      formValue.rowCount,
      this.getHeaderCells(),
      this.getBodyCells()
    );

    this.clearSpreadsheet();
    this._sheetElement = jQuery('#spreadsheet');
    this._sheetElement.kendoSpreadsheet(this.options);
    this._spreadsheet = this._sheetElement.data('kendoSpreadsheet');
    this._sheetElement.find('.k-spreadsheet-action-bar').hide();

    this.editableRange.values(this.getPrefilledRows(formValue));
  }

  private initWizardSteps(): WizardStep<MassAddStep>[] {
    return [
      {
        id: MassAddStep.Prefill,
        text: this.translate.instant('ADMIN.SPREADSHEET.SETUP.TITLE'),
        onEnter: () => this.clearSpreadsheet(),
        onExit: () => this.initSpreadsheet(),
        canContinue: () => this.optionsValid,
      },
      {
        id: MassAddStep.Import,
        text: this.translate.instant('ADMIN.SPREADSHEET.IMPORT'),
        buttonText: this.translate.instant('ADMIN.SPREADSHEET.VALIDATE'),
        onEnter: () => {
          this.editableRange.enable(true);
        }
      },
      {
        id: MassAddStep.Validate,
        text: this.translate.instant('ADMIN.SPREADSHEET.VALIDATE'),
        buttonText: this.translate.instant('ADMIN.SPREADSHEET.SAVE'),
        canContinue: () => this.isSheetValid && !this.isSheetEmpty,
        onEnter: () => {
          this.validate();
          this.editableRange.enable(false);
        },
      },
      {
        id: MassAddStep.Save,
        text: this.translate.instant('ADMIN.SPREADSHEET.SAVE'),
        buttonText: this.translate.instant('ADMIN.SPREADSHEET.SAVING'),
        onEnter: () => this.createContracts(),
        canContinue: false,
        canReturn: false
      },
      {
        id: MassAddStep.Done,
        text: this.translate.instant('DONE'),
        buttonText: this.translate.instant('MODALS.CLOSE'),
        canReturn: false,
      },
    ];
  }

  private getHeaderCells(): Cell[] {
    return contractSheetColumns.map<Cell>(cell => ({
      value: this.translate.instant(cell.key),
      enable: false,
      textAlign: 'center',
      background: HEADER_BACKGROUND,
      color: DEFAULT_COLOR,
    }));
  }

  private getBodyCells(): Cell[] {
    return contractSheetColumns.map<Cell>(cell => ({
      format: cell.format,
      enable: true,
      color: DEFAULT_COLOR,
    }));
  }

  private createContracts(): void {
    const rows = this.getRows();
    const enegiaIds = rows.unique('enegiaId');

    this.facilityClient.getFacilityIdsForEnegiaIds(enegiaIds).pipe(
      switchMap(facilityIds => this.contractClient.addContracts(
        rows.map(row => new CreateContractProduct({
          facilityId: facilityIds[row.enegiaId],
          contractId: row.contractId,
          productId: row.productId,
          unitCount: row.unitCount,
          unitPrice: row.unitPrice,
          fromDate: this.momentFromOle(row.fromDate),
          toDate: this.momentFromOle(row.toDate),
        }))
      ))
    ).subscribe({
      next: () => {
        this.createCompleted.emit();
        this.toaster.success('ADMIN.SPREADSHEET.READY');

        this.activeStep = this.wizardSteps.find(step => step.id === MassAddStep.Done);
        this.changeDetector.markForCheck();
      },
      error: err => {
        this.toaster.error(
          (SwaggerException.isSwaggerException(err) ? err.message : err?.toString()) ?? undefined,
          'ADMIN.SPREADSHEET.ERROR'
        );

        this.activeStep = this.wizardSteps.find(step => step.id === MassAddStep.Import);
        this.changeDetector.markForCheck();
      },
    });
  }

  private getRows(): ContractMassAddRow[] {
    const sheet = this.spreadsheet.activeSheet();
    const { rowCount, columnCount } = this.sheetSize;

    const rows: ContractMassAddRow[] = [];

    for (let rowIndex = 1; rowIndex < rowCount; rowIndex++) {
      const range = sheet.range(rowIndex, 0, 1, columnCount);
      const rowData = new ContractMassAddRow(rowIndex, range);

      if (this.shouldSkipRow(rowData)) {
        continue;
      }

      rows.push(rowData);
    }

    return rows;
  }

  private validate(): void {
    const sheet = this.spreadsheet.activeSheet();
    const { rowCount, columnCount } = this.sheetSize;

    const overlaps: ContractMassAddRow[] = [];

    let sheetEmpty: boolean = true;

    for (let rowIndex = 1; rowIndex < rowCount; rowIndex++) {
      const range = sheet.range(rowIndex, 0, 1, columnCount);
      const rowData = new ContractMassAddRow(rowIndex, range);

      if (this.shouldSkipRow(rowData)) {
        range.validation(null);
        continue;
      }

      sheetEmpty = false;

      if (rowData.checkOverlaps) {
        overlaps.push(rowData);
      }

      // Add required number validation for all cells except the dates
      sheet.range(rowIndex, 0, 1, columnCount - 2).validation({
        dataType: 'number',
        comparerType: 'greaterThanOrEqualTo',
        from: '0',
        allowNulls: false,
        messageTemplate: this.translate.instant('ADMIN.CONTRACTS.MASS_ADD.IS_REQUIRED'),
      });

      // Add date validators
      const fromCell = cellName(rowIndex, ContractSpreadsheetColumns.indexOf('fromDate'));
      const toCell = cellName(rowIndex, ContractSpreadsheetColumns.indexOf('toDate'));

      sheet.range(fromCell).validation({
        dataType: 'date',
        comparerType: 'greaterThanOrEqualTo',
        from: '0',
        allowNulls: false,
        messageTemplate: this.translate.instant('ADMIN.CONTRACTS.MASS_ADD.IS_REQUIRED'),
      });

      sheet.range(toCell).validation({
        dataType: 'date',
        comparerType: 'greaterThan',
        from: fromCell,
        allowNulls: true,
        messageTemplate: this.translate.instant('ADMIN.CONTRACTS.MASS_ADD.INVALID_RANGE'),
      });
    }

    this.updateOverlaps(overlaps);

    this.isSheetEmpty = sheetEmpty;
    setTimeout(() => this.updateValidity());
  }

  private updateOverlaps(overlaps: ContractMassAddRow[]): void {
    const sheet = this.spreadsheet.activeSheet();
    const dateColumns = [
      ContractSpreadsheetColumns.indexOf('fromDate'),
      ContractSpreadsheetColumns.indexOf('toDate'),
    ];

    for (const rows of overlaps.toGroupsBy('recordKey').values()) {
      rows.removeBy(row => {
        let datesValid: boolean = true;
        row.range.forEachCell((_r: number, columnIndex: number, cell: Cell) => {
          if (dateColumns.includes(columnIndex) && !this.cellValid(cell)) {
            datesValid = false;
          }
        });
        return !datesValid;
      });

      // Take only groups with valid time ranges and more than 1
      // from/to-pair per enegia/product/contract ID
      if (rows.length < 2) {
        continue;
      }

      let previous: number = Number.NEGATIVE_INFINITY;
      let previousRow: number = null;

      for (const row of rows.sortBy('fromDate')) {
        if (previous >= row.fromDate) {
          const lastCell = cellName(previousRow, ContractSpreadsheetColumns.indexOf('toDate'));

          row.getCell(sheet, 'fromDate').validation({
            dataType: 'date',
            comparerType: 'greaterThan',
            from: previous,
            allowNulls: false,
            messageTemplate: `${this.translate.instant('ADMIN.CONTRACTS.MASS_ADD.OVERLAP')} (${lastCell})`,
          });
        }

        previous = row.toDate || Number.POSITIVE_INFINITY;
        previousRow = row.rowIndex;
      }
    }
  }

  private updateValidity(): void {
    let isValid: boolean = true;
    const rows: number[] = [];

    this.editableRange.forEachCell((rowIndex: number, _c: number, cell: Cell) => {
      if (!this.cellValid(cell)) {
        isValid = false;
        rows.push(rowIndex + 1);
      }
    });

    this.isSheetValid = isValid;
    this.invalidRows = rows.unique().join(', ');
    this.changeDetector.markForCheck();
  }

  private cellValid(cell: Cell): boolean {
    return !(cell.validation && !(cell.validation as Record<string, unknown>).value);
  }

  private shouldSkipRow(rowData: ContractMassAddRow): boolean {
    return rowData.isEmpty || (!rowData.enegiaId && this.skipEmptyEnegiaIds);
  }

  private getPrefilledRows(formValue: ContractMassAddForm): RowValues[] {
    const rowValues: RowValues = [
      formValue.enegiaId,
      formValue.unitCount,
      formValue.unitPrice,
      formValue.productId,
      formValue.contractId,
      formValue.fromDate && dateToOle(localToUtc(formValue.fromDate)),
      formValue.toDate && dateToOle(localToUtc(formValue.toDate)),
    ];

    return Array.from({ length: formValue.rowCount }).map(() => rowValues);
  }

  private momentFromOle(ole: number): moment.Moment {
    if (!Number.isFinite(ole)) {
      return null;
    }

    return moment(oleToDate(ole));
  }
}
