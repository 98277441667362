import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, forkJoin, of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { addDays } from 'date-fns';
import moment from 'moment';

import { ofVoid } from '@enerkey/rxjs';
import { formGroupFrom } from '@enerkey/ts-utils';
import { ModalBase, ModalOptions, ModalService, NgfActiveModal } from '@enerkey/foundation-angular';
import { FacilityClient } from '@enerkey/clients/facility';
import { ContractClient, IContractProduct, UpdateContractProduct } from '@enerkey/clients/contract';

import { kendoNumericTextboxOptions } from '../../models/constants';
import { WizardStep } from '../../../../shared/interfaces/wizard-step';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { localToUtc } from '../../../../shared/date.functions';
import {
  ContractFields,
  ContractInputDialogComponent,
} from '../contract-input-dialog/contract-input-dialog.component';

type TerminateContractFormControls = {
  terminateDate: UntypedFormControl; // Date
  terminateContractIds: UntypedFormControl; // number[]
  newContracts: UntypedFormArray; // RecreateContract[]
}

interface RecreateContract {
  readonly id: number;
  readonly facilityId: number;
  readonly facilityName: string;
  contractId: string;
  productId: string;
  unitPrice: number;
  unitCount: number;
}

enum ContractTerminateStep {
  TerminateDate,
  SelectContracts,
  RecreateContracts,
  SavingChanges,
  Done,
}

@Component({
  selector: 'contract-terminate-modal',
  templateUrl: './contract-terminate-modal.component.html',
  styleUrls: ['./contract-terminate-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'large' })
export class ContractTerminateModalComponent extends ModalBase implements OnDestroy {

  public readonly ContractTerminateStep = ContractTerminateStep;
  public readonly numberInputOpts: Partial<NumericTextBoxComponent> = kendoNumericTextboxOptions;

  public readonly formGroup: UntypedFormGroup;
  public readonly formControls: TerminateContractFormControls;

  public wizardSteps: WizardStep[];
  public activeStep: WizardStep;

  public previewContracts: IContractProduct[] = [];
  public previewFacilities: Record<number, string> = {};
  public checkedContracts: Record<number, boolean> = {};
  public minTerminateDate: Date = null;

  public get formArray(): UntypedFormArray {
    return this.formControls.newContracts;
  }

  public get allContractsChecked(): boolean {
    switch (this.previewContracts.count(c => this.checkedContracts[c.id])) {
      case 0:
        return false;
      case this.previewContracts.length:
        return true;
      default:
        return undefined;
    }
  }

  public set allContractsChecked(value: boolean) {
    this.checkedContracts = value
      ? Object.fromEntries(this.previewContracts.map(c => ([c.id, true])))
      : {};
  }

  public get terminateDate(): Date {
    return this.formControls.terminateDate.value;
  }

  public get newFromDate(): Date {
    return addDays(this.terminateDate, 1);
  }

  private readonly _destroy = new Subject<void>();

  public constructor(
    activeModal: NgfActiveModal,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly contractClient: ContractClient,
    private readonly facilityClient: FacilityClient,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly toaster: ToasterService
  ) {
    super(activeModal);

    this.formControls = {
      terminateDate: new UntypedFormControl(null, Validators.required),
      terminateContractIds: new UntypedFormControl([]),
      newContracts: new UntypedFormArray([]),
    };
    this.formGroup = new UntypedFormGroup(this.formControls);

    this.wizardSteps = [
      {
        id: ContractTerminateStep.TerminateDate,
        text: this.translateService.instant('ADMIN.CONTRACTS.SET_TERMINATION_DATE'),
        canContinue: () => this.formGroup.controls.terminateDate.valid,
      },
      {
        id: ContractTerminateStep.SelectContracts,
        text: this.translateService.instant('ADMIN.CONTRACTS.SELECT_RECREATE'),
      },
      {
        id: ContractTerminateStep.RecreateContracts,
        text: this.translateService.instant('ADMIN.CONTRACTS.NEW_INFORMATION'),
        canContinue: () => this.formGroup.valid,
        buttonText: this.translateService.instant('MODALS.SAVE'),
      },
      {
        id: ContractTerminateStep.SavingChanges,
        onEnter: () => this.saveChanges(),
        text: this.translateService.instant('MODALS.SAVE'),
        canContinue: false,
      },
      {
        id: ContractTerminateStep.Done,
        text: this.translateService.instant('DONE'),
      },
    ];

    this.activeStep = this.wizardSteps[0];
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  public close(): void {
    super.closeModal();
  }

  public stepChange(step: WizardStep): void {
    switch (step.id) {
      case ContractTerminateStep.TerminateDate:
      case ContractTerminateStep.SelectContracts:
        this.formControls.newContracts.clear();
        break;
      case ContractTerminateStep.RecreateContracts:
        this.setForm();
        break;
    }
  }

  public setContractProducts(contractProductIds: number[]): void {
    this.contractClient.getContractProductsByIds(contractProductIds).pipe(
      switchMap(contracts => forkJoin([
        of(contracts),
        this.facilityClient.getFacilities(contracts.unique(c => c.facilityId))
      ])),
      takeUntil(this._destroy)
    ).subscribe(([contracts, facilities]) => {
      this.previewFacilities = Object.fromEntries(facilities.toMap('id', 'displayName'));
      this.previewContracts = contracts;
      this.checkedContracts = {};
      this.setMinTerminateDate();
      this.changeDetector.detectChanges();
    });
  }

  public createNewChanged(contractId: number, checked: boolean): void {
    this.checkedContracts[contractId] = checked;
  }

  public rowEdit(field: keyof ContractFields): void {
    const modal = this.modalService.open(ContractInputDialogComponent);
    modal.componentInstance.editedField = field;
    modal.result.then(
      (value: string | number | Date) => {
        for (let i = 0; i < this.formArray.length; i++) {
          this.formArray.at(i).patchValue({ [field]: value });
        }
      },
      () => { }
    );
  }

  private setMinTerminateDate(): void {
    const fromDates = (this.previewContracts || []).filterMap(
      c => !!c.fromDate,
      c => c.fromDate
    );

    this.minTerminateDate = Array.hasItems(fromDates)
      ? addDays(fromDates.reduce((a, b) => a.isAfter(b) ? a : b).toDate(), 1)
      : null;
  }

  private setForm(): void {
    this.formControls.newContracts.clear();

    this.formControls.terminateContractIds.setValue(
      this.previewContracts.mapFilter(
        c => c.id,
        id => !this.checkedContracts[id]
      )
    );

    const formContracts = this.previewContracts
      .filter(c => c.id in this.checkedContracts)
      .sortByMany('facilityId', 'contractId', 'productId');

    for (const contract of formContracts) {
      this.formControls.newContracts.push(
        formGroupFrom<RecreateContract>(
          {
            id: contract.id,
            facilityId: contract.facilityId,
            contractId: contract.contractId,
            productId: contract.productId,
            unitCount: contract.unitCount,
            unitPrice: contract.unitPrice,
            facilityName: this.previewFacilities[contract.facilityId],
          },
          {
            facilityId: Validators.required,
            contractId: Validators.required,
            productId: Validators.required,
            unitCount: Validators.min(0),
            unitPrice: Validators.min(0),
          }
        )
      );
    }

    this.changeDetector.detectChanges();
  }

  private saveChanges(): void {
    const terminateOn = moment(localToUtc(this.formControls.terminateDate.value));
    const contractIds: number[] = this.formControls.terminateContractIds.value;
    const recreateContracts: RecreateContract[] = this.formControls.newContracts.value;

    const terminate$ = !Array.hasItems(contractIds)
      ? ofVoid()
      : this.contractClient.terminateContractProducts(terminateOn, contractIds);

    const create$ = !Array.hasItems(recreateContracts)
      ? of([] as number[])
      : this.contractClient.terminateAndRecreateContractProducts(
        terminateOn,
        recreateContracts.map(
          c => new UpdateContractProduct({ ...c, fromDate: moment(terminateOn).add(1, 'days') })
        )
      );

    forkJoin([terminate$, create$]).pipe(
      catchError(() => {
        this.toaster.error('ADMIN.CONTRACTS.TERMINATE_FAILED');
        return EMPTY;
      }),
      takeUntil(this._destroy)
    ).subscribe(() => {
      this.toaster.success('DONE');
      this.activeStep = this.wizardSteps[4];
      this.changeDetector.detectChanges();
    });
  }
}
