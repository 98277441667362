/* eslint-disable quote-props */
// https://support.microsoft.com/en-us/office/number-format-codes-5026bbd6-04bc-48cd-bf33-80f18b4eae68
export const EXCEL_FORMATS: { [key: string]: string } = {
  p0: '0%', // Percentage without decimal
  p1: '0.0%', // Percentage with one decimal
  p2: '0.00%', // Percentage with two decimals
  n0: '#,##0', // Number without decimal
  n1: '#,##0.0', // Number with one decimal
  n2: '#,##0.00', // Number with two decimals
  c0: '$#,##0', // Currency without decimal
  c1: '$#,##0.0', // Currency with one decimal
  c2: '$#,##0.00', // Currency with two decimals
  d: 'yyyy-mm-dd', // Date in ISO format
  g: 'yyyy-mm-dd HH:MM', // General date/time pattern in ISO format
  G: 'yyyy-mm-dd HH:MM', // General date/time pattern in ISO format
  t: 'HH:MM', // Short time pattern
  T: 'HH:MM:SS', // Long time pattern
  m: 'mm-dd', // Month and day pattern
  y: 'yyyy-mm', // Year and month pattern
  '#,#.0': '#,##0.0', // Number with one decimal
} as const;
