import { Injectable, OnDestroy, Type } from '@angular/core';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@enerkey/foundation-angular';
import { indicate, ofVoid } from '@enerkey/rxjs';
import { Dashboard, SettingsClient, UpdateDashboard } from '@enerkey/clients/settings';
import { RequestResolution } from '@enerkey/clients/reporting';

import { WidgetType } from '../shared/widget-type';
import { ToasterService } from '../../../shared/services/toaster.service';
import { WidgetSettingsGeneralComponent } from '../components/widget-settings-general/widget-settings-general.component';
import { EnerkeyWidget } from '../shared/enerkey-widget';
import { ProfileService } from '../../../shared/services/profile.service';
import { DashboardEditModalComponent, DashboardType } from
  '../components/dashboard-edit-modal/dashboard-edit-modal.component';
import { WidgetBase } from '../shared/widget-base.interface';
import { WidgetDefinition, WidgetDefinitionsService, WidgetGroup } from './widget-definitions.service';
import { UserService } from '../../../services/user-service';
import { EditableWidgetSettings } from '../shared/editable-widget-settings';
import { DashboardOperation } from '../shared/dashboard-operation';
import { FacilityService } from '../../../shared/services/facility.service';
import { FacilityWeeklyConsumptionWidgetOptions } from '../components/facility-weekly-consumption-widget/facility-weekly-consumption-widget.component';

@Injectable()
export class DashboardService implements OnDestroy {
  public readonly addWidget$: Observable<EnerkeyWidget[]>;
  public readonly dashboards$: Observable<Dashboard[]>;
  public readonly activeDashboard$: Observable<Dashboard>;
  public readonly dashboardsLoading$: Observable<boolean>;
  public readonly usedWidgetAmounts$: Observable<Record<string, number>>;

  private readonly _profileDashboards$: Observable<Dashboard[]>;
  private readonly _addWidget$ = new Subject<EnerkeyWidget[]>();
  private readonly _saveDashboard$ = new Subject<Dashboard>();
  private readonly _activeDashboard$ = new ReplaySubject<Dashboard>(null);
  private readonly _dashboardsLoading$ = new BehaviorSubject<boolean>(true);
  private readonly _usedWidgetAmount$ = new BehaviorSubject<Record<string, number>>({});
  private readonly _changedDashboards$ = new Subject<Dashboard[]>();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly settingsClient: SettingsClient,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly widgetDefinitionService: WidgetDefinitionsService,
    private readonly userService: UserService,
    private readonly profileService: ProfileService,
    private readonly facilityService: FacilityService
  ) {
    this.dashboardsLoading$ = this._dashboardsLoading$.asObservable();

    this._profileDashboards$ = profileService.profile$.pipe(
      take(1),
      switchMap(() => this.settingsClient.getDashboards(this.userService.profileId).pipe(
        indicate(this._dashboardsLoading$),
        takeUntil(this._destroy$)
      )),
      switchMap(
        dashboards => Array.hasItems(dashboards)
          ? of(dashboards)
          : this.settingsClient.addDashboard(
            this.userService.profileId, new UpdateDashboard({ isDefault: true, widgets: [] })
          ).pipe(
            map(dashboard => [dashboard])
          )
      ),
      shareReplay(1)
    );

    this.dashboards$ = merge(
      this._changedDashboards$,
      this._profileDashboards$
    ).pipe(
      shareReplay(1)
    );

    this.addWidget$ = this._addWidget$.asObservable();

    this.activeDashboard$ = merge(
      this._profileDashboards$.pipe(
        map(dashboards => dashboards[0])
      ),
      this._activeDashboard$.pipe(distinctUntilChanged((d1, d2) => Object.compareObjByKeys(d1, d2, 'id', 'title')))
    ).pipe(shareReplay(1));

    this.usedWidgetAmounts$ = this._usedWidgetAmount$.asObservable();

    this._saveDashboard$.pipe(
      withLatestFrom(this.profileService.profileId$),
      debounceTime(10),
      switchMap(([dashboard, profileId]) => this.updateDashboard(dashboard, profileId))
    ).subscribe({
      error: () => this.toasterService.generalError('SAVE', 'WIDGETS')
    });

  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._addWidget$.complete();
    this._saveDashboard$.complete();
    this._activeDashboard$.complete();
    this._dashboardsLoading$.complete();
    this._usedWidgetAmount$.complete();
    this._changedDashboards$.complete();
  }

  public selectDashboard(dashboardId?: number): void {
    combineLatest([
      this.facilityService.filteredProfileFacilityIds$,
      this.dashboards$.pipe(take(1))
    ]).subscribe(([facilityIds, dashboards]) => {
      let activeDashboard: Dashboard;
      if (!dashboardId) {
        activeDashboard = dashboards[0];
      } else {
        activeDashboard = dashboards.find(d => d.id === dashboardId);
      }
      if (facilityIds) {
        activeDashboard.widgets?.forEach(widget => {
          if (widget?.dataModelOptions?.facilityId && !facilityIds.includes(widget?.dataModelOptions?.facilityId)) {
            widget.dataModelOptions.facilityId = null;
          }
        });
      }
      this._activeDashboard$.next(activeDashboard);
    });
  }

  public addWidget(widgetType: WidgetType): void {
    const widgetDefinition = this.widgetDefinitionService.getWidgetDefinition(widgetType);
    (widgetDefinition.defaultOptions?.() ?? ofVoid()).subscribe(defaultOptions => {
      const modalRef = this.modalService.open(WidgetSettingsGeneralComponent);
      modalRef.componentInstance.title = '';
      modalRef.componentInstance.defaultTitle = widgetDefinition.defaultTitle;
      modalRef.componentInstance.isNew = true;
      modalRef.componentInstance.widgetType = widgetType;
      modalRef.componentInstance.dataModelOptions = defaultOptions;
      modalRef.result
        .then(widget => {
          this._addWidget$.next([this.getNewWidget(widgetDefinition, widget.dataModelOptions, widget.title)]);
        })
        .catch(() => { });
    });
  }

  public editDashboard(dashboardId: number): void {
    this.dashboards$.pipe(take(1)).subscribe(dashboards => {
      const dashboardToEdit = dashboards.find(d => d.id === dashboardId);
      const modalRef = this.modalService.open(DashboardEditModalComponent);
      modalRef.componentInstance.title = dashboardToEdit.title;
      modalRef.componentInstance.isSettings = true;
      modalRef.result
        .then(operation => {
          if (operation.deleteDashboard) {
            this.deleteDashboard(dashboardToEdit.id);
          } else {
            const editedDashboard = dashboardToEdit.clone();
            editedDashboard.title = operation.title;
            this.updateDashboard(editedDashboard).subscribe();
          }
        })
        .catch(() => { });
    });
  }

  public saveDashboard(dashboard: Dashboard): void {
    this._saveDashboard$.next(dashboard);
  }

  public getWidgetComponent(widgetType: WidgetType): Type<WidgetBase> {
    return this.widgetDefinitionService.getWidgetDefinition(widgetType).component;
  }

  public getDefaultWidgets(): Observable<EnerkeyWidget[]> {
    return this.widgetDefinitionService.getDefaultWidgetDefinitions().pipe(
      switchMap(widgetDefs => forkJoin(widgetDefs.map(widgetDef => (widgetDef.defaultOptions?.() ?? ofVoid()).pipe(
        map(options => this.getNewWidget(widgetDef, options, null))
      ))))
    );
  }

  public getFacilityWidgets(): Observable<EnerkeyWidget[]> {
    return this.widgetDefinitionService.widgetDefinitions$.pipe(
      switchMap(widgetDefs => forkJoin(widgetDefs
        .filter(res => res.widgetGroupType === WidgetGroup.OneFacility)
        .map(widgetDef =>
          (widgetDef.defaultOptions?.() ?? ofVoid()).pipe(
            map(options => this.getNewWidget(widgetDef, options, null))
          ))))
    );
  }

  public setUsedWidgetAmounts(widgets: EnerkeyWidget[]): void {
    const widgetMap = widgets.toGroupsBy('widgetType');
    const amountMap = [...widgetMap].toRecord(
      ([widgetType]) => widgetType,
      ([_w, widgetsOfType]) => widgetsOfType.length
    );
    this._usedWidgetAmount$.next(amountMap);
  }

  public addDashboard(): void {
    const dashboardModalRef = this.modalService.open(DashboardEditModalComponent);
    dashboardModalRef.componentInstance.title = this.translateService.instant('DASHBOARD.EXTRA_DASHBOARD');
    dashboardModalRef.componentInstance.isSettings = false;

    dashboardModalRef.result.then(operation => {
      const widgets$ = this.getWidgetsForOperation(operation);

      widgets$
        .pipe(
          concatMap(widgets => this.createAndUpdateDashboard(operation, widgets)),
          catchError(() => {
            this.toasterService.generalError('SAVE', 'WIDGETS');
            return EMPTY;
          }),
          takeUntil(this._destroy$)
        )
        .subscribe({
          next: dashboard => {
            this._addWidget$.next(dashboard.widgets);
          },
        });
    })
      .catch(() => { });
  }

  private getWidgetsForOperation(operation: DashboardOperation): Observable<EnerkeyWidget[]> {
    switch (operation.dashboardTypes) {
      case DashboardType.EmptyDashboard:
        return of([]);
      case DashboardType.PortfolioDashboard:
        return this.getDefaultWidgets();
      case DashboardType.FacilityDashboard:
        return this.getFacilityWidgets().pipe(
          map(widgets =>
            widgets.map(widget => ({
              ...widget,
              dataModelOptions: {
                ...(widget.dataModelOptions as EditableWidgetSettings<unknown>),
                facilityId: operation.facilityId,
              },
            })))
        );
      default:
        return of([]);
    }
  }

  private createAndUpdateDashboard(operation: DashboardOperation, widgets: EnerkeyWidget[]): Observable<Dashboard> {
    const newDashboard = new UpdateDashboard({
      isDefault: false,
      title: operation.title,
      widgets: widgets,
    });
    return this.createDashboard(newDashboard);
  }

  private getNewWidget(
    widgetDef: WidgetDefinition<unknown>,
    options: unknown,
    title: string
  ): EnerkeyWidget {
    widgetDef = this.overrideDefaultWidgetDefination(widgetDef, options);
    return {
      title: title,
      dataModelOptions: options,
      x: 0,
      y: 0,
      cols: widgetDef.defaultSize?.x ?? 2,
      rows: widgetDef.defaultSize?.y ?? 2,
      widgetType: widgetDef.widgetType
    };
  }

  private overrideDefaultWidgetDefination(
    widgetDef: WidgetDefinition<unknown>,
    options: unknown
  ): WidgetDefinition<unknown> {
    if (
      widgetDef.widgetType === WidgetType.FacilityWeeklyConsumption &&
      (options as FacilityWeeklyConsumptionWidgetOptions)?.resolution === RequestResolution.PT1H
    ) {
      widgetDef = { ...widgetDef, defaultSize: { x: 2, y: 2 } };
    }
    return widgetDef;
  }

  private updateDashboard(dashboard: Dashboard, profileId?: number): Observable<unknown> {
    return forkJoin([
      this.dashboards$.pipe(take(1)),
      this.settingsClient.updateDashboard(profileId ?? this.userService.profileId, dashboard)
    ]).pipe(
      tap(([dashboards, editedDashboard]) => {
        const editedDashboardIndex = dashboards.findIndex(d => d.id === editedDashboard.id);
        dashboards[editedDashboardIndex] = editedDashboard;
        this._changedDashboards$.next([...dashboards]);
        this._activeDashboard$.next(editedDashboard);
      })
    );
  }

  private createDashboard(dashboard: Dashboard, profileId?: number): Observable<Dashboard> {
    return forkJoin([
      this.dashboards$.pipe(take(1)),
      this.settingsClient.addDashboard(profileId ?? this.userService.profileId, dashboard)
    ]).pipe(
      tap(([dashboards, newDashboard]) => {
        this._changedDashboards$.next([...dashboards.filter(d => d.id), newDashboard]);
        this._activeDashboard$.next(newDashboard);
      }),
      map(([_, newDashboard]) => newDashboard)
    );
  }

  private deleteDashboard(dashboardId: number): void {
    this.settingsClient.deleteDashboard(this.userService.profileId, dashboardId).pipe(
      switchMap(() => this.dashboards$.pipe(take(1)))
    ).subscribe({
      next: dashboards => {
        this._changedDashboards$.next(dashboards.filter(d => d.id !== dashboardId));
        this.selectDashboard();
      }
    });
  }
}
