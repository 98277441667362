import { IDaisyClient } from './daisy-client';

export class DaisyClientMock implements IDaisyClient {

  public getChatResponse(): never {
    throw new Error('Method not implemented.');
  }
  public getConversationSummariesResponse(): never {
    throw new Error('Method not implemented.');
  }
  public getConversationWithIdResponse(): never {
    throw new Error('Method not implemented.');
  }
  public deleteConversationWithId(): never {
    throw new Error('Method not implemented.');
  }
  public anonymous(): never {
    throw new Error('Method not implemented.');
  }
}
