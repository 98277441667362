import { Injectable } from '@angular/core';
import { AggregateDescriptor, CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { BehaviorSubject, Observable } from 'rxjs';
import { ColumnVisibilityChangeEvent } from '@progress/kendo-angular-grid';

import { TableReportFieldNamePipe } from '../pipes/table-report-field-name.pipe';
import { QuantitySeriesByType } from '../shared/table-report-functions';
import { KendoGridFacilityPropertyColumnComponent } from '../../../shared/ek-kendo/components/kendo-grid-facility-property-column/kendo-grid-facility-property-column.component';
import { GridStateForBookmark } from '../../../services/bookmark.service';

export const initialGridState: State = { skip: 0, take: 50, sort: [{ field: 'Name', dir: 'asc' }] };

@Injectable({
  providedIn: 'root'
})
export class TableReportService {
  public readonly gridState$: Observable<State>;
  public readonly gridColumnVisibility$: Observable<ColumnVisibilityChangeEvent>;
  public readonly gridFilter$: Observable<CompositeFilterDescriptor>;

  private readonly _gridState$ = new BehaviorSubject<State>(initialGridState);
  private readonly _gridColumnVisibility$ = new BehaviorSubject<ColumnVisibilityChangeEvent>(null);
  private readonly _gridFilter$ = new BehaviorSubject<CompositeFilterDescriptor>(null);
  private readonly _visibleColumnsFields$ = new BehaviorSubject<string[]>([]);

  public constructor(
    private readonly tableReportFieldNamePipe: TableReportFieldNamePipe
  ) {
    this.gridState$ = this._gridState$.asObservable();
    this.gridColumnVisibility$ = this._gridColumnVisibility$.asObservable();
    this.gridFilter$ = this._gridFilter$.asObservable();
  }
  public get gridState(): State {
    return this._gridState$.value;
  }

  public setGridState(state: State): void {
    this._gridState$.next(state);
  }

  public setGridColumnVisibility(event: ColumnVisibilityChangeEvent): void {
    this._gridColumnVisibility$.next(event);
    const columns = event.columns as KendoGridFacilityPropertyColumnComponent[];
    this._visibleColumnsFields$.next(columns.filterMap(
      column => !column.hidden && !!column.field,
      column => column.field
    ));
  }

  public setVisibleColumns(columns: string[]): void {
    this._visibleColumnsFields$.next(columns);
  }

  public setGridFilter(): void {
    this._gridFilter$.next(this._gridState$.value.filter);
  }

  public isColumnVisible(columnField: string): boolean {
    return this._visibleColumnsFields$.value.includes(columnField);
  }

  public resetGridState(): void {
    this._gridState$.next(initialGridState);
    this._gridColumnVisibility$.next(null);
    this._gridFilter$.next(null);
    this._visibleColumnsFields$.next([]);
  }

  public getGridStateForBookmark(): GridStateForBookmark {
    return {
      group: this._gridState$.value.group,
      filter: this._gridState$.value.filter,
      sort: this._gridState$.value.sort,
      visibleColumns: this._visibleColumnsFields$.value
    };
  }

  public getQuantityAggregates(columns: QuantitySeriesByType[]): AggregateDescriptor[] {
    const aggregates: AggregateDescriptor[] = [];
    for (const quantity of columns) {
      aggregates.push(...this.getValueTypeAggregates(quantity));
    }
    return aggregates;
  }

  private getValueTypeAggregates(quantity: QuantitySeriesByType): AggregateDescriptor[] {
    const aggregates: AggregateDescriptor[] = [];
    for (const series of Object.values(quantity.series)) {
      for (const serie of series.series.values()) {
        const field = this.tableReportFieldNamePipe.transform(serie);
        aggregates.push({ field: `${field}.visibleValue`, aggregate: 'sum' });
        aggregates.push({ field: `${field}.absoluteChange`, aggregate: 'sum' });
        aggregates.push({ field: `${field}.relativeChange`, aggregate: 'sum' });

        aggregates.push({ field: `${field}.visibleValue`, aggregate: 'min' });
        aggregates.push({ field: `${field}.absoluteChange`, aggregate: 'min' });
        aggregates.push({ field: `${field}.relativeChange`, aggregate: 'min' });

        aggregates.push({ field: `${field}.visibleValue`, aggregate: 'max' });
        aggregates.push({ field: `${field}.absoluteChange`, aggregate: 'max' });
        aggregates.push({ field: `${field}.relativeChange`, aggregate: 'max' });

        aggregates.push({ field: `${field}.visibleValue`, aggregate: 'average' });
        aggregates.push({ field: `${field}.absoluteChange`, aggregate: 'average' });
        aggregates.push({ field: `${field}.relativeChange`, aggregate: 'average' });
      }
    }

    return aggregates;
  }
}
