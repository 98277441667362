import { ConfigFn } from './api-url-config.interface';

export const ENERKEY_CONFIG: ConfigFn<'-dev'> = (urlPrefix: string, urlSuffix: string) => ({
  env: 'development',

  portalProxyUrl: `https://${urlPrefix}.${urlSuffix}`,

  helpUrl: `https://help-dev.${urlSuffix}/`,
  issuer: 'https://identity-dev.enerkey.com',
  idSrv: `https://identity-dev.${urlSuffix}`,

  apiAlarm: `https://${urlPrefix}.${urlSuffix}/api/eg-alarmapi-dev`,
  apiAttachmentManagement: `https://${urlPrefix}.${urlSuffix}/api/eg-attachmentsapi-dev`,
  apiConfiguration: `https://${urlPrefix}.${urlSuffix}/api/eg-configurationapi-dev`,
  apiConsumption: `https://${urlPrefix}.${urlSuffix}/api/eg-consumptionapi-dev`,
  apiContact: `https://${urlPrefix}.${urlSuffix}/api/eg-contactapi-dev`,
  apiContract: `https://${urlPrefix}.${urlSuffix}/api/eg-contractapi-dev`,
  apiCostFactor: `https://${urlPrefix}.${urlSuffix}/api/eg-costfactorsupplyapi-dev`,
  apiCustomerAdmin: `https://${urlPrefix}.${urlSuffix}/api/eg-api-customeradminapi-dev`,
  apiDatahub: `https://${urlPrefix}.${urlSuffix}/api/eg-datahubapi-dev`,
  apiEmailConsumptionReport: `https://${urlPrefix}.${urlSuffix}/api/eg-api-emailconsumptionreport-dev`,
  apiEnergyreporting: `https://${urlPrefix}.${urlSuffix}/api/eg-energyreportingapi-dev`,
  apiErrorTicket: `https://${urlPrefix}.${urlSuffix}/api/eg-errorticketapi-dev`,
  apiFacility: `https://${urlPrefix}.${urlSuffix}/api/eg-facilityapi-dev`,
  apiManualQA: `https://${urlPrefix}.${urlSuffix}/api/eg-manualqualityassuranceapi-dev`,
  apiMeterManagement: `https://${urlPrefix}.${urlSuffix}/api/eg-metermanagement-dev`,
  apiMetering: `https://${urlPrefix}.${urlSuffix}/api/eg-meteringapi-dev`,
  apiMrcOutput: `https://${urlPrefix}.${urlSuffix}/api/eg-manualmeasurementsapi-dev`,
  apiNews: `https://${urlPrefix}.${urlSuffix}/api/eg-newsapi-dev`,
  apiProvisioning: `https://${urlPrefix}.${urlSuffix}/api/eg-provisioningapi-dev`,
  apiReporting: `https://${urlPrefix}.${urlSuffix}/api/eg-api-reporting-dev`,
  apiUserManagement: `https://${urlPrefix}.${urlSuffix}/api/eg-usermanagementapi-core-dev`,
  apiInes: `https://${urlPrefix}.${urlSuffix}/api/eg-inesapi-dev`,
  apiInesReports: `https://${urlPrefix}.${urlSuffix}/api/eg-jupyter-dev`,
  apiWeather: `https://${urlPrefix}.${urlSuffix}/api/eg-weatherapi-dev`,
  apiWebhook: `https://${urlPrefix}.${urlSuffix}/api/eg-webhookapi-dev`,
  apiScheduler: `https://${urlPrefix}.${urlSuffix}/api/eg-schedulerapi-dev`,
  apiSettings: `https://${urlPrefix}.${urlSuffix}/api/eg-api-settings-dev`,
  apiSustainability: `https://${urlPrefix}.${urlSuffix}/api/eg-api-sustainability-dev`,

  telemetry: {
    applicationInsights: 'InstrumentationKey=9bc4573e-7de4-4145-8a50-84505d6bcc1f;IngestionEndpoint=https://westeurope-3.in.applicationinsights.azure.com/',
  }
});
