import { CompositeFilterDescriptor, FilterDescriptor, isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  addMonths,
  addQuarters,
  addYears,
  differenceInCalendarMonths,
  differenceInCalendarQuarters,
  differenceInCalendarYears,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subMonths,
  subQuarters,
  subYears
} from 'date-fns';

import {
  AggregationType,
  ChangeType,
  GridFilter,
  GridState,
  GroupedColumn,
  ICreateReportingBookmark,
  ReportingBookmark,
  ReportType,
  RollingDateType,
  Sort,
  SortedColumn,
  VisibleSections,
} from '@enerkey/clients/settings';
import { EnergyTargetType } from '@enerkey/clients/reporting';

import { ReportingParams } from '../../modules/reporting/reporting.states';

import { GridStateForBookmark, State } from '../../services/bookmark.service';
import { getDefaultReportingParams } from '../../modules/reporting/services/reporting.search.functions';
import {
  RESOLUTIONS,
  SelectableResolution,
} from '../../modules/reporting/components/reporting-search-form/reporting-search-form.component';
import { DurationName } from '../../modules/reporting/shared/reporting-search-form-value';
import { ValueType } from '../../shared/ek-inputs/value-type-select/value-type-select.component';
import {
  durationStringToRequestDuration,
  durationToISOString
} from '../../modules/reporting/shared/duration-to-string';
import { isCost, isEmission } from '../../modules/reportingobjects/shared/relational-value-functions';

import {
  bookmarkComparability,
  bookmarkDistributionType,
  bookmarkReportingUnit,
  facilityInfoSections,
  reportingBookmarkStateType,
  ReportName
} from '../../constants/reporting-bookmark.constants';
import { localToUtc } from '../../shared/date.functions';

/** Checks whether the reporting bookmark is topical for the current state (=page).
 * Takes into account if the bookmark is for modal report or not.
 */
export function isTopical(bookmark: ReportingBookmark, state: State): boolean {
  const isSameGrouping = state?.modal
    ? bookmark.grouping === state?.stateParams?.grouping
    : true;
  return bookmark.reportType === reportingBookmarkStateType[state?.name as ReportName]
    && bookmark.isModal === state?.modal
    && isSameGrouping;
}

/** Returns the reporting state name for the reporting bookmark to use for transition,
 * e.g. `"reporting.table"` for table report. */
export function getBookmarkReportState(reportType: ReportType): string {
  return recordKeyByValue(reportingBookmarkStateType, reportType);
}

/** Converts reporting bookmark properties to reporting parameters to show the report */
export function getReportingParams(bookmark: ReportingBookmark, filteredFacilityIds: number[]): ReportingParams {
  const defaults = getDefaultReportingParams();
  return {
    facilityIds: getFacilityIds(bookmark.facilityIds, filteredFacilityIds),
    sections: getFacilityInfoSections(bookmark.visibleSections) ?? [],
    grids: bookmark.visibleSections?.includes(VisibleSections.Tables) ?? true,
    charts: bookmark.visibleSections?.includes(VisibleSections.Graphs) ?? true,
    periods: bookmark.starts ? getPeriods(bookmark.starts, bookmark.rollingDate) : [],
    quantityIds: bookmark.quantityIds ?? defaults.quantityIds,
    durationName: getDuration(bookmark.timeFrame)?.name ?? defaults.durationName,
    durationLength: getDuration(bookmark.timeFrame)?.length ?? defaults.durationLength,
    resolution: getResolution(bookmark.resolution) ?? defaults.resolution,
    change: {
      relative: bookmark.changeType === ChangeType.Relative || bookmark.changeType === ChangeType.Both,
      absolute: bookmark.changeType === ChangeType.Absolute || bookmark.changeType === ChangeType.Both,
    },
    valueType: getValueType(bookmark.measured, bookmark.normalized) ?? defaults.valueType,
    showConsumption: bookmark.showConsumption ?? defaults.showConsumption,
    specificIds: bookmark.relationalUnitIds?.filter(id => !isCost(id) && !isEmission(id)) ?? defaults.specificIds,
    costIds: bookmark.relationalUnitIds?.filter(id => isCost(id)) ?? defaults.costIds,
    emissionIds: bookmark.relationalUnitIds?.filter(id => isEmission(id)) ?? defaults.emissionIds,
    targetTypes: bookmark.consumptionTargetSeriesTypes as EnergyTargetType[] ?? defaults.targetTypes,
    reportingUnit: bookmarkReportingUnit[bookmark.reportingUnit] ?? defaults.reportingUnit,
    distributionType: bookmarkDistributionType[bookmark.distributionType] ?? defaults.distributionType,
    distributionAsPercent: bookmark.distributionAsPercent ?? defaults.distributionAsPercent,
    temperature: bookmark.temperature ?? defaults.temperature,
    comparability: bookmarkComparability[bookmark.excludeIncomplete] ?? defaults.comparability,
    minMaxAvg: getMinMaxAvg(bookmark.aggregationTypes),
    showSummedConsumption: bookmark.showSummedConsumption ?? defaults.showSummedConsumption,
  };
}

/** Converts reporting parameters to reporting bookmark properties to save them in bookmark */
export function getBookmarkFromParams(params: ReportingParams): ICreateReportingBookmark {
  return {
    distributionAsPercent: params.distributionAsPercent,
    measured: params.valueType === ValueType.Measured || params.valueType === ValueType.Both,
    normalized: params.valueType === ValueType.Normalized || params.valueType === ValueType.Both,
    showConsumption: params.showConsumption,
    temperature: params.temperature,
    changeType: getChangeType(params.change),
    distributionType: recordKeyByValue(bookmarkDistributionType, params.distributionType),
    excludeIncomplete: recordKeyByValue(bookmarkComparability, params.comparability),
    reportingUnit: recordKeyByValue(bookmarkReportingUnit, params.reportingUnit),
    actionsImpactTypes: null, // not yet implemented in new reporting
    aggregationTypes: getAggregationTypes(params.minMaxAvg),
    consumptionTargetSeriesTypes: params.targetTypes ?? [],
    eventTypes: null, // not yet implemented in new reporting
    facilityIds: params.facilityIds ?? [],
    quantityIds: params.quantityIds ?? [],
    relationalUnitIds: [...params.specificIds ?? [], ...params.emissionIds ?? [], ...params.costIds ?? []],
    starts: params.periods?.map(p => localToUtc(new Date(p))),
    visibleSections: getVisibleSections(params),
    timeFrame: durationToISOString({ [params.durationName]: params.durationLength }),
    resolution: params.resolution,
    showSummedConsumption: params.showSummedConsumption,
  };
}

export function previousPeriodStart(period: 'month' | 'quarter' | 'year'): Date {
  const now = new Date();
  switch (period) {
    case 'month':
      return startOfMonth(subMonths(now, 1));
    case 'quarter':
      return startOfQuarter(subQuarters(now, 1));
    case 'year':
      return startOfYear(subYears(now, 1));
  }
}

/** Converts the bookmark grid filter object to filter descriptors understood by the Kendo grid */
export function getFilterDescriptorsRecursive(gridFilter: GridFilter): CompositeFilterDescriptor | FilterDescriptor {
  if (gridFilter?.logic && gridFilter?.filters?.length) {
    return {
      logic: gridFilter.logic as 'and' | 'or',
      filters: gridFilter.filters.map(filter => getFilterDescriptorsRecursive(filter))
    };
  } else if (gridFilter?.field) {
    return {
      field: gridFilter.field,
      operator: gridFilter.operator,
      value: gridFilter.value
    };
  }
  return null;
}

/** Maps the grid state from table reports to the bookmark grid state object */
export function mapGridStateForBookmark(gridState: GridStateForBookmark): GridState {
  const groupedColumns = gridState.group?.map(descriptor => new GroupedColumn({
    columnIdentifier: descriptor.field,
    sort: descriptor.dir === 'desc' ? Sort.Descending : Sort.Ascending
  })) ?? [];

  const sortedColumns = gridState.sort?.map(descriptor => new SortedColumn({
    columnIdentifier: descriptor.field,
    sort: descriptor.dir === 'desc' ? Sort.Descending : Sort.Ascending
  })) ?? [];

  return new GridState({
    groupedColumns,
    gridFilters: mapGridFiltersRecursive(gridState.filter),
    sortedColumns,
    visibleColumns: gridState.visibleColumns ?? []
  });
}

/** Maps the filter descriptors of the Kendo grid to reporting bookmark grid filter object */
function mapGridFiltersRecursive(filter: CompositeFilterDescriptor | FilterDescriptor): GridFilter {
  if (!filter) {
    return new GridFilter({});
  }

  if (isCompositeFilterDescriptor(filter)) {
    return new GridFilter({
      logic: filter.logic,
      filters: filter.filters.map(f => mapGridFiltersRecursive(f))
    });
  }

  return new GridFilter({
    field: filter.field as string,
    operator: filter.operator as string,
    value: filter.value
  });
}

function getFacilityIds(facilityIds: number[], filteredFacilityIds: number[]): number[] {
  if (facilityIds?.length && filteredFacilityIds?.length) {
    return facilityIds.filter(id => filteredFacilityIds.includes(id));
  }
  return facilityIds ?? [];
}

function getFacilityInfoSections(visibleSections: VisibleSections[]): string[] {
  return visibleSections?.filterMap(
    section => !!facilityInfoSections[section],
    section => facilityInfoSections[section]
  );
}

function getPeriods(starts: Date[], rollingDate: RollingDateType): string[] {
  let periods = starts.map(start => start.toISOString());
  if (rollingDate === RollingDateType.Month) {
    const previousMonthStart = previousPeriodStart('month');
    const monthsDiff = differenceInCalendarMonths(previousMonthStart, starts[0]);
    periods = starts.map(start => addMonths(start, monthsDiff).toISOString());
  }
  if (rollingDate === RollingDateType.Quarter) {
    const previousQuarterStart = previousPeriodStart('quarter');
    const quartersDiff = differenceInCalendarQuarters(previousQuarterStart, starts[0]);
    periods = starts.map(start => addQuarters(start, quartersDiff).toISOString());
  }
  if (rollingDate === RollingDateType.Year) {
    const previousYearStart = previousPeriodStart('year');
    const yearsDiff = differenceInCalendarYears(previousYearStart, starts[0]);
    periods = starts.map(start => addYears(start, yearsDiff).toISOString());
  }
  return periods;
}

function getDuration(bookmarkTimeFrame: string): { name: DurationName, length: number } {
  if (!bookmarkTimeFrame) {
    return null;
  }
  const duration = durationStringToRequestDuration(bookmarkTimeFrame);
  const name = Object.keys(duration).find(key => duration[key as DurationName] > 0) as DurationName;
  const length = duration[name];
  return { name, length };
}

function getResolution(bookmarkResolution: string): SelectableResolution {
  if (RESOLUTIONS.includes(bookmarkResolution as SelectableResolution)) {
    return bookmarkResolution as SelectableResolution;
  }
  return null;
}

function getValueType(measured: boolean, normalized: boolean): ValueType {
  if (measured && normalized) {
    return ValueType.Both;
  }
  if (measured) {
    return ValueType.Measured;
  }
  if (normalized) {
    return ValueType.Normalized;
  }
}

function getMinMaxAvg(bookmarkAggregationTypes: AggregationType[]): { min: boolean, max: boolean, average: boolean } {
  const aggregations = { min: false, max: false, average: false };
  bookmarkAggregationTypes?.forEach(type => {
    switch (type) {
      case AggregationType.Min:
        aggregations.min = true;
        break;
      case AggregationType.Max:
        aggregations.max = true;
        break;
      case AggregationType.Average:
        aggregations.average = true;
        break;
    }
  });
  return aggregations;
}

function getChangeType(change: { relative: boolean, absolute: boolean }): ChangeType {
  if (change?.relative && change?.absolute) {
    return ChangeType.Both;
  }
  if (change?.relative) {
    return ChangeType.Relative;
  }
  if (change?.absolute) {
    return ChangeType.Absolute;
  }
  return ChangeType.None;
}

function getAggregationTypes(aggregations: { min: boolean, max: boolean, average: boolean }): AggregationType[] {
  const types = [];
  if (aggregations?.min) {
    types.push(AggregationType.Min);
  }
  if (aggregations?.max) {
    types.push(AggregationType.Max);
  }
  if (aggregations?.average) {
    types.push(AggregationType.Average);
  }
  return types;
}

function getVisibleSections(params: ReportingParams): VisibleSections[] {
  const visibleSections = [];
  params.sections?.forEach(section => {
    const visibleSection = recordKeyByValue(facilityInfoSections, section);
    if (visibleSection) {
      visibleSections.push(visibleSection);
    }
  });
  if (params.sections?.includes('MeterDetails')) {
    visibleSections.push(VisibleSections.MeterDetails);
  }
  if (params.charts || params.charts === undefined) {
    visibleSections.push(VisibleSections.Graphs);
  }
  if (params.grids || params.grids === undefined) {
    visibleSections.push(VisibleSections.Tables);
  }
  return visibleSections;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function recordKeyByValue<T extends Record<keyof any, any>>(record: T, value: T[keyof T]): keyof T {
  const key = Object.keys(record).find(k => record[k] === value);
  return Number.isFinite(+key) ? +key : key;
}
