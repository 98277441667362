<ek-dropdown
  [width]="580"
  class="latest-actions-container"
>
  <button
    *ekDropdownToggle="let toggle"
    (click)="toggle()"
    class="button icon"
    [title]="titleTranslationKey | translate"
  >
    <i class="fas fa-bell"></i>
    <ng-template #commentsIcon>
      <i class="fa fa-comment"></i>
    </ng-template>
  </button>
  <div class="latest-actions-dropdown">
    <div class="title-row">{{ titleTranslationKey | translate }}</div>
    <div class="table-header">
      {{ 'FACILITIES_REPORT.LATEST_ACTIONS_POPUP_TABLE_TITLE' | translate }}
    </div>
    <table class="alarms-table latest-actions-dropdown__table">
      <tr>
        <th>
          {{ 'FACILITIES_REPORT.OVERVIEW.ALARM_EXECUTED_AT' | translate }}
        </th>
        <th>
          {{ 'FACILITIES_REPORT.OVERVIEW.ALARM_TYPE' | translate }}
        </th>
      </tr>
      <tr *ngFor="let alarm of alarms">
        <td>{{ alarm.executedAt | kendoDate:'d' }}</td>
        <td class="ellipsis">
          <span>
            <a
              class="reported-description"
              (click)="openAlarmModal(alarm.id)"
            >
              {{ alarm.alarmTypeId | alarmTypeName }}
            </a>
          </span>
        </td>
        <td>
          <alarm-log-status [status]="alarm.status"></alarm-log-status>
        </td>
      </tr>
    </table>
  </div>
  <div>
    <button 
      class="button button--link button--external-link view-all-link"
      (click)="goToAlarms()"
    >
      {{ 'FACILITIES_REPORT.GO_TO_ACTIONS' | translate }}
    </button>
  </div>
</ek-dropdown>
