import { ICustomerAdminClient } from './customer-admin-client';

export class CustomerAdminClientMock implements ICustomerAdminClient {
  public getUsersUnderCustomerAdmin(): never {
    throw new Error('Method not implemented.');
  }
  public getSubscriptionsForUser(): never {
    throw new Error('Method not implemented.');
  }
  public insertOrUpdateSubscription(): never {
    throw new Error('Method not implemented.');
  }
  public getFaciltiesUnderCustomeradmin(): never {
    throw new Error('Method not implemented.');
  }
  public insertOrUpdateFacilitySubscription(): never {
    throw new Error('Method not implemented.');
  }
}
