<kendo-grid
  #kendoGrid
  [data]="gridData"
  [loading]="isLoading$ | async"
  [kendoGridSelectBy]="selectKey"
  [selectedKeys]="selection"
  [selectable]="gridSelectableSettings"
  [sortable]="gridSortSettings"
  [groupable]="gridGroupableSettings"
  [filterable]="'menu'"
  [resizable]="true"
  [pageable]="true"
  [group]="state.group"
  [filter]="state.filter"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  (dataStateChange)="dataStateChange($event)"
  class="grid-full-height"
>
  <kendo-grid-excel
    [fileName]="excelFileName"
    [excelDataSource]="gridRows"
  ></kendo-grid-excel>
  <ng-template kendoGridToolbarTemplate>
    <kendo-grid-grouped-column-chooser></kendo-grid-grouped-column-chooser>
    <button kendoGridExcelCommand>{{ 'ADMIN.GET_EXCEL' | translate }}</button>

    <ek-dropdown>
      <button
        type="button"
        kendoButton
        *ekDropdownToggle="let toggle"
        (click)="toggle()"
      >
        {{ 'GRID_SHORTCUTS.TITLE' | translate }}
      </button>
      <ek-dropdown-item (click)="resetState()">
        {{ 'GRID_SHORTCUTS.DEFAULT' | translate }}
      </ek-dropdown-item>

      <hr>

      <ek-dropdown-heading>
        {{ 'GRID_SHORTCUTS.GROUP' | translate }}
      </ek-dropdown-heading>
      <ek-dropdown-item (click)="groupByColumn('facility.displayName')">
        {{ 'ADMIN.FACILITY_NAME' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item (click)="groupByColumn('contractId')">
        {{ 'ADMIN.CONTRACTS.CONTRACT_ID' | translate }}
      </ek-dropdown-item>

      <ek-dropdown-heading>
        {{ 'GRID_SHORTCUTS.COPY' | translate }}
      </ek-dropdown-heading>
      <ek-dropdown-item
        (click)="copyColumn('enegiaId')"
        [disabled]="!gridData?.data?.length"
      >
        {{ 'ADMIN.ENEGIAID' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        (click)="copyColumn('contractId')"
        [disabled]="!gridData?.data?.length"
      >
        {{ 'ADMIN.CONTRACTS.CONTRACT_ID' | translate }}
      </ek-dropdown-item>
    </ek-dropdown>
  </ng-template>

  <kendo-grid-checkbox-column [width]="24">
    <kendo-grid-checkbox-header *kendoGridHeaderTemplate></kendo-grid-checkbox-header>
  </kendo-grid-checkbox-column>

  <kendo-grid-column-group title="{{ 'ADMIN.CONTRACTS.CONTRACT_INFO' | translate }}">
    <kendo-grid-column
      field="facility.displayName"
      title="{{ 'ADMIN.FACILITY_NAME' | translate }}"
      [width]="150"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
      >
        <span *ngIf="!dataItem?.facility?.isDeleted; else isDeleted">
          {{ dataItem.facility.displayName }}
        </span>
        <ng-template #isDeleted>
          <span
            class="facility-deleted"
            [ngfTooltip]="'ADMIN.SPREADSHEET.DELETED' | translate"
            [placement]="'right'"
          >
            {{ dataItem.facility.displayName }}
          </span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="facility.enegiaId"
      title="{{ 'ADMIN.ENEGIAID' | translate }}"
      [width]="80"
    ></kendo-grid-column>

    <kendo-grid-column
      field="facility.externalFacilityId"
      title="{{ 'FACILITYINFORMATION.EXTERNALFACILITYID' | translate }}"
      [width]="100"
    ></kendo-grid-column>

    <kendo-grid-column
      field="contractId"
      title="{{ 'ADMIN.CONTRACTS.CONTRACT_ID' | translate }}"
      [width]="80"
    ></kendo-grid-column>

    <kendo-grid-column
      field="productId"
      title="{{ 'ADMIN.CONTRACTS.PRODUCT_ID' | translate }}"
      [width]="80"
    >
      <grid-checkbox-filter
        *kendoGridFilterMenuTemplate="let filter = filter; let filterService = filterService"
        [field]="'productId'"
        [filter]="filter"
        [filterService]="filterService"
        [dataSource]="gridRows"
        [textSelector]="productNameSelector"
      ></grid-checkbox-filter>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'ADMIN.CONTRACTS.PRODUCT_NAME' | translate }}"
      [width]="120"
      field="productName"
    ></kendo-grid-column>

    <kendo-grid-column
      field="fromDate"
      title="{{ 'ADMIN.CONTRACTS.FROM_DATE' | translate }}"
      format="d"
      filter="date"
      [width]="80"
    ></kendo-grid-column>

    <kendo-grid-column
      field="toDate"
      title="{{ 'ADMIN.CONTRACTS.TO_DATE' | translate }}"
      format="d"
      filter="date"
      [width]="80"
    ></kendo-grid-column>

    <kendo-grid-column
      field="createdBy"
      title="{{ 'ADMIN.CONTRACTS.CREATED_BY' | translate }}"
      [width]="100"
      [hidden]="true"
    ></kendo-grid-column>

    <kendo-grid-column
      field="created"
      title="{{ 'ADMIN.CONTRACTS.CREATED_DATE' | translate }}"
      format="d"
      filter="date"
      [width]="80"
      [hidden]="true"
    ></kendo-grid-column>

    <kendo-grid-column
      field="lastModifiedBy"
      title="{{ 'ADMIN.CONTRACTS.MODIFIED_BY' | translate }}"
      [width]="100"
      [hidden]="true"
    ></kendo-grid-column>

    <kendo-grid-column
      field="lastModified"
      title="{{ 'ADMIN.CONTRACTS.MODIFIED_DATE' | translate }}"
      format="d"
      filter="date"
      [width]="80"
      [hidden]="true"
    ></kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group [title]="'CONTACTMANAGER.COMPANY.COMPANY' | translate">
    <kendo-grid-column
      field="company.name"
      title="{{ 'CONTACTMANAGER.COMPANY.NAME' | translate }}"
      [width]="120"
      [hidden]="true"
    ></kendo-grid-column>

    <kendo-grid-column
      field="company.companyOvt"
      title="{{ 'CONTACTMANAGER.COMPANY.OVT' | translate }}"
      [width]="100"
      [hidden]="true"
    ></kendo-grid-column>

    <kendo-grid-column
      field="company.phoneNumber"
      title="{{ 'CONTACTMANAGER.COMPANY.PHONENUMBER' | translate }}"
      [width]="100"
      [hidden]="true"
    ></kendo-grid-column>

    <kendo-grid-column
      field="company.streetAddress"
      title="{{ 'CONTACTMANAGER.COMPANY.STREETADDRESS' | translate }}"
      [width]="100"
      [hidden]="true"
    ></kendo-grid-column>

    <kendo-grid-column
      field="company.postalCode"
      title="{{ 'CONTACTMANAGER.COMPANY.POSTALCODE' | translate }}"
      [width]="100"
      [hidden]="true"
    ></kendo-grid-column>

    <kendo-grid-column
      field="company.city"
      title="{{ 'CONTACTMANAGER.COMPANY.CITY' | translate }}"
      [width]="100"
      [hidden]="true"
    ></kendo-grid-column>

    <kendo-grid-column
      field="company.country"
      title="{{ 'CONTACTMANAGER.COMPANY.COUNTRY' | translate }}"
      [width]="100"
      [hidden]="true"
    ></kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="{{ 'ADMIN.CONTRACTS.MONTHLY' | translate }}">
    <kendo-grid-column
      field="unitCount"
      title="{{ 'ADMIN.CONTRACTS.UNIT_COUNT' | translate }}"
      [width]="80"
      filter="numeric"
      [hidden]="!!activeBillingPeriod"
    >
      <ng-template kendoGridFooterTemplate>
        {{ aggregateResult?.unitCount?.sum | kendoNumber }}
      </ng-template>
      <ng-template
        kendoGridGroupFooterTemplate
        let-aggregates
      >
        {{ aggregates?.unitCount?.sum | kendoNumber }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="unitPrice"
      title="{{ 'ADMIN.CONTRACTS.UNIT_PRICE' | translate }}"
      [width]="80"
      filter="numeric"
      [format]="currencyFormat"
      [hidden]="!!activeBillingPeriod"
    >
      <ng-template
        kendoGridFooterTemplate
        let-column="column"
      >
        {{ aggregateResult?.unitPrice?.sum | kendoNumber:column.format }}
      </ng-template>
      <ng-template
        kendoGridGroupFooterTemplate
        let-aggregates
        let-column="column"
      >
        {{ aggregates?.unitPrice?.sum | kendoNumber:column.format }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="cost"
      title="{{ 'ADMIN.CONTRACTS.COST' | translate }}"
      [width]="80"
      filter="numeric"
      [format]="currencyFormat"
      [hidden]="!!activeBillingPeriod"
    >
      <ng-template
        kendoGridFooterTemplate
        let-column="column"
      >
        {{ aggregateResult?.cost?.sum | kendoNumber:column.format }}
      </ng-template>
      <ng-template
        kendoGridGroupFooterTemplate
        let-aggregates
        let-column="column"
      >
        {{ aggregates?.cost?.sum | kendoNumber:column.format }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group
    *ngIf="activeBillingPeriod"
    title="{{ 'ADMIN.CONTRACTS.BILLING_PERIOD' | translate }} ({{ activeBillingPeriod.display }})"
  >
    <kendo-grid-column
      field="billingInfo.unitCount"
      title="{{ 'ADMIN.CONTRACTS.UNIT_COUNT' | translate }}"
      [width]="60"
      filter="numeric"
    >
      <ng-template kendoGridFooterTemplate>
        {{ aggregateResult && aggregateResult['billingInfo.unitCount']?.sum | kendoNumber }}
      </ng-template>
      <ng-template
        kendoGridGroupFooterTemplate
        let-aggregates
      >
        {{ aggregates && aggregates['billingInfo.unitCount']?.sum | kendoNumber }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="billingInfo.cost"
      title="{{ 'ADMIN.CONTRACTS.COST' | translate }}"
      [width]="60"
      filter="numeric"
      [format]="currencyFormat"
    >
      <ng-template
        kendoGridFooterTemplate
        let-column="column"
      >
        {{ aggregateResult && aggregateResult['billingInfo.cost']?.sum | kendoNumber:column.format }}
      </ng-template>
      <ng-template
        kendoGridGroupFooterTemplate
        let-aggregates
        let-column="column"
      >
        {{ aggregates && aggregates['billingInfo.cost']?.sum | kendoNumber:column.format }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="billingInfo.changes"
      title="{{ 'ADMIN.CONTRACTS.CHANGED' | translate }}"
      [width]="60"
      filter="boolean"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
      >
        {{ (dataItem.billingInfo.changes ? 'YES' : 'NO' ) | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="billingInfo.monthsActive"
      title="{{ 'ADMIN.CONTRACTS.ACTIVE_MONTHS' | translate }}"
      [width]="60"
      filter="numeric"
      [hidden]="true"
    ></kendo-grid-column>
  </kendo-grid-column-group>

  <facility-properties-column-group
    [facilityField]="'facility'"
    [except]="['displayName', 'enegiaId', 'externalFacilityId']"
    [gridData]="$any(gridData)"
  ></facility-properties-column-group>

  <ng-container *ngIf="columnConfig">
    <kendo-grid-column-group title="{{ columnConfig.totals.key | translate }}">
      <kendo-grid-column
        *ngFor="let totalCount of columnConfig.totals.columns"
        [field]="totalCount.field"
        [title]="totalCount.key | translate"
        filter="numeric"
        [width]="70"
      >
        <ng-template kendoGridFooterTemplate>
          {{ aggregateResult[totalCount.field]?.sum }}
        </ng-template>
        <ng-template kendoGridHeaderTemplate>
          <div title="{{ totalCount.key | translate }}">
            {{ totalCount.key | translate | abbreviate }}
          </div>
        </ng-template>
        <ng-template
          kendoGridGroupFooterTemplate
          let-aggregates
        >
          {{ aggregates[totalCount.field]?.sum }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-column-group>

    <ng-container *ngFor="let rootCountGroup of [columnConfig.main, columnConfig.sub]">
      <kendo-grid-column-group
        *ngIf="rootCountGroup.groups.length"
        title="{{ rootCountGroup.key | translate }}"
      >
        <kendo-grid-column-group
          *ngFor="let innerCountGroup of rootCountGroup.groups"
          title="{{ innerCountGroup.key | quantityName | async }}"
        >
          <!-- Add {{ rootCountGroup.key | translate }} to title for better Column chooser usage -->
          <kendo-grid-column
            *ngFor="let mainQtyCountColumn of innerCountGroup.columns"
            [field]="mainQtyCountColumn.field"
            [title]="mainQtyCountColumn.key | quantityMeteringType | async"
            filter="numeric"
            [width]="70"
          >
            <ng-template kendoGridFooterTemplate>
              {{ aggregateResult[mainQtyCountColumn.field]?.sum }}
            </ng-template>
            <ng-template kendoGridHeaderTemplate>
              <div title="{{ mainQtyCountColumn.key | quantityMeteringType | async}}">
                {{ mainQtyCountColumn.key | quantityMeteringType | async | abbreviate }}
              </div>
            </ng-template>
            <ng-template
              kendoGridGroupFooterTemplate
              let-aggregates
            >
              {{ aggregates[mainQtyCountColumn.field]?.sum }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>
      </kendo-grid-column-group>
    </ng-container>

    <kendo-grid-column-group
      *ngIf="columnConfig.quantities.columns.length"
      title="{{ columnConfig.quantities.key | translate }}"
    >
      <kendo-grid-column
        *ngFor="let quantityColumn of columnConfig.quantities.columns"
        field="{{ quantityColumn.field }}"
        title="{{ quantityColumn.key | quantityName | async }}"
        filter="numeric"
        [width]="70"
      >
        <ng-template kendoGridFooterTemplate>
          {{ aggregateResult[quantityColumn.field]?.sum }}
        </ng-template>
        <ng-template kendoGridHeaderTemplate>
          <div title="{{ quantityColumn.key | quantityName | async }}">
            {{ quantityColumn.key | quantityName | async | abbreviate }}
          </div>
        </ng-template>
        <ng-template
          kendoGridGroupFooterTemplate
          let-aggregates
        >
          {{ aggregates[quantityColumn.field]?.sum }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-column-group>
  </ng-container>
</kendo-grid>
