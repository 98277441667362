import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AggregateResult, State } from '@progress/kendo-data-query';
import { ExcelExportEvent, PageChangeEvent, PagerSettings } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';

import { RequestResolution } from '@enerkey/clients/reporting';

import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { absoluteChangeKey, relativeChangeKey } from '../../constants/table-report-constants';
import { ReportingGridColumnGroup, ReportingGridData } from '../../services/reporting-grid.service';
import { ReportType } from '../../shared/report-type';

type ExcelRow = {
  type: string;
  cells: {
    value: number | string;
    background?: string;
    format?: string;
  }[];
}

@Component({
  selector: 'reporting-grid',
  templateUrl: './reporting-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportingGridComponent implements OnChanges {
  @Input() public data: ReportingGridData[];
  @Input() public gridColumns: ReportingGridColumnGroup[];
  @Input() public searchParams: ReportingSearchParams;
  @Input() public aggregates: AggregateResult;
  @Input() public modalReportType: ReportType;
  @Input() public reportName: string;

  public readonly currentDate = new Date();

  public readonly absoluteChangeKey = absoluteChangeKey;
  public readonly relativeChangeKey = relativeChangeKey;
  public state: State = { skip: 0, take: 50, sort: [{ field: 'Name', dir: 'asc' }] };

  public pageSize = 30;
  public pageSettings: PagerSettings | boolean = false;

  public constructor(
    private readonly translate: TranslateService
  ) { }

  public ngOnChanges(): void {
    switch (this.searchParams.resolution) {
      case RequestResolution.PT15M:
        this.pageSize = 24;
        this.pageSettings = this.data?.length > this.pageSize ? { pageSizes: [24, 48, 72, 96] } : false;
        break;
      case RequestResolution.PT1H:
        this.pageSize = 24;
        this.pageSettings = this.data?.length > this.pageSize ? { pageSizes: [12, 24, 48, 72] } : false;
        break;
      case RequestResolution.P1D:
        this.pageSize = 31;
        this.pageSettings = this.data?.length > this.pageSize ? { pageSizes: [10, 31, 50, 100] } : false;
        break;
      case RequestResolution.P1M:
        this.pageSize = 12;
        this.pageSettings = this.data?.length > this.pageSize ? { pageSizes: [12, 24, 48, 72] } : false;
        break;
      default:
        this.pageSize = 30;
        this.pageSettings = this.data?.length > this.pageSize ? { pageSizes: [10, 30, 50, 100] } : false;
    }

    this.state = { ...this.state, skip: 0 };
  }

  public pageChange(event: PageChangeEvent): void {
    this.state = { ...this.state, skip: event.skip };
  }

  public onExcelExport(event: ExcelExportEvent): void {
    const rows = event.workbook.sheets[0]?.rows;
    if (!rows || rows.length === 0) {
      return;
    }

    const sumRowIndex = rows.findIndex((row: ExcelRow) => row?.type === 'footer');
    if (sumRowIndex === -1) { // if there is no row for sum values min, max and average should not be calculated
      return;
    }

    const numColumns = rows[2].cells.length;
    const minValues = Array(numColumns).fill(Number.MAX_VALUE);
    const maxValues = Array(numColumns).fill(Number.MIN_VALUE);
    const sumValues = Array(numColumns).fill(0);
    const countValues = Array(numColumns).fill(0);
    const formats = Array(numColumns).fill(null);

    for (let j = 1; j < rows[2].cells.length; j++) {
      formats[j] = rows[2].cells[j].format;
    }

    for (const row of rows) {
      if (row.type === 'data') {
        for (let j = 1; j < row.cells.length; j++) { // Start from index 1 to skip the first cell
          const cellValue = parseFloat(row.cells[j]?.value);
          if (!isNaN(cellValue)) {
            minValues[j] = Math.min(minValues[j], cellValue);
            maxValues[j] = Math.max(maxValues[j], cellValue);
            sumValues[j] += cellValue;
            countValues[j]++;
          }
        }
      }
    }

    // Handles the case when no min/max values are found
    for (let j = 1; j < numColumns; j++) {
      if (minValues[j] === Number.MAX_VALUE) {
        minValues[j] = 0;
      }
      if (maxValues[j] === Number.MIN_VALUE) {
        maxValues[j] = 0;
      }
    }

    const avgValues = sumValues.map((sum, index) => countValues[index] > 0 ? sum / countValues[index] : 0);

    const minRow = this.getFooterRow(minValues, formats, 'min');
    const maxRow = this.getFooterRow(maxValues, formats, 'max');
    const avgRow = this.getFooterRow(avgValues, formats, 'avg');

    rows.push(minRow, maxRow, avgRow);
  }

  private getFooterRow(rowValues: number[], formats: string[], rowType: string): ExcelRow {
    // Slice the arrays to exclude the first element containing the row label
    const numberRowValues = rowValues.slice(1);
    const numberFormats = formats.slice(1);

    const cells = numberRowValues.map((value, index) => ({
      value: value,
      background: '#dfdfdf',
      format: value === 0 ? null : numberFormats[index]
    }));

    return {
      type: 'footer',
      cells: [
        {
          value: this.getFooterLabel(rowType),
          background: '#dfdfdf'
        },
        ...cells
      ]
    };
  }

  private getFooterLabel(rowType: string): string {
    switch (rowType) {
      case 'min':
        return this.translate.instant('FACILITIES_REPORT.MIN');
      case 'max':
        return this.translate.instant('FACILITIES_REPORT.MAX');
      case 'avg':
        return this.translate.instant('FACILITIES_REPORT.AVERAGE');
      default:
        return '';
    }
  }
}
