<form [formGroup]="formGroup">
  <div>
    <kendo-numerictextbox
      formControlName="rowCount"
      integerInput
      *labelBefore="'ADMIN.CONTRACTS.MASS_ADD.ROW_COUNT' | translate; class: 'inline'"
    ></kendo-numerictextbox>
  </div>
  <div>
    <kendo-numerictextbox
      formControlName="enegiaId"
      integerInput
      *labelBefore="'ADMIN.ENEGIAID' | translate; class: 'inline'"
    ></kendo-numerictextbox>
  </div>
  <div>
    <kendo-numerictextbox
      formControlName="unitCount"
      [decimals]="0"
      [format]="'0'"
      [step]="numberInputOpts.step"
      [autoCorrect]="numberInputOpts.autoCorrect"
      [spinners]="numberInputOpts.spinners"
      *labelBefore="'ADMIN.CONTRACTS.UNIT_COUNT' | translate; class: 'inline'"
    ></kendo-numerictextbox>
  </div>
  <div>
    <kendo-numerictextbox
      formControlName="unitPrice"
      [decimals]="numberInputOpts.decimals"
      [format]="numberInputOpts.format"
      [step]="numberInputOpts.step"
      [autoCorrect]="numberInputOpts.autoCorrect"
      [spinners]="numberInputOpts.spinners"
      *labelBefore="'ADMIN.CONTRACTS.UNIT_PRICE' | translate; class: 'inline'"
    ></kendo-numerictextbox>
  </div>
  <div>
    <input
      type="text"
      formControlName="productId"
      class="inline"
      *labelBefore="'ADMIN.CONTRACTS.PRODUCT_ID' | translate; class: 'inline'"
    >
  </div>
  <div>
    <input
      type="text"
      formControlName="contractId"
      class="inline"
      *labelBefore="'ADMIN.CONTRACTS.CONTRACT_ID' | translate; class: 'inline'"
    >
  </div>
  <div>
    <kendo-datepicker
      formControlName="fromDate"
      *labelBefore="'ADMIN.CONTRACTS.FROM_DATE' | translate; class: 'inline'"
    ></kendo-datepicker>
  </div>
  <div>
    <kendo-datepicker
      formControlName="toDate"
      *labelBefore="'ADMIN.CONTRACTS.TO_DATE' | translate; class: 'inline'"
    ></kendo-datepicker>
  </div>
</form>
