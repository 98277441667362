<ng-container *ngIf="aggregates"> 
    <div> {{ aggregates[column.field]?.sum | kendoNumber:column.format }} </div>
    <ng-container *ngIf="!parentItem">
      <div>
        {{ aggregates[column.field]?.min | kendoNumber:column.format }}
      </div>
      <div>
        {{ aggregates[column.field]?.max | kendoNumber:column.format }}
      </div>
      <div>
        {{ aggregates[column.field]?.average | kendoNumber:column.format }}
      </div>
    </ng-container>
</ng-container>
