import { ISettingsClient } from './settings-client';

export class SettingsClientMock implements ISettingsClient {
  public getReportingBookmarks(): never {
    throw new Error('Method not implemented.');
  }
  public createReportingBookmark(): never {
    throw new Error('Method not implemented.');
  }
  public migrateOldBookmarks(): never {
    throw new Error('Method not implemented.');
  }
  public deleteReportingBookmark(): never {
    throw new Error('Method not implemented.');
  }
  public setReportingBookmarkSharing(): never {
    throw new Error('Method not implemented.');
  }
  public subscribeForService(): never {
    throw new Error('Method not implemented.');
  }
  public unsubscribeForService(): never {
    throw new Error('Method not implemented.');
  }
  public getBookmarks(): never {
    throw new Error('Method not implemented.');
  }
  public createBookmark(): never {
    throw new Error('Method not implemented.');
  }
  public setBookmarkSharing(): never {
    throw new Error('Method not implemented.');
  }
  public deleteBookmark(): never {
    throw new Error('Method not implemented.');
  }
  public copySettings(): never {
    throw new Error('Method not implemented.');
  }
  public getDashboards(): never {
    throw new Error('Method not implemented.');
  }
  public addDashboard(): never {
    throw new Error('Method not implemented.');
  }
  public updateDashboard(): never {
    throw new Error('Method not implemented.');
  }
  public deleteDashboard(): never {
    throw new Error('Method not implemented.');
  }
  public getNotificationEmailPreferences(): never {
    throw new Error('Method not implemented.');
  }
  public setNotificationEmailPreferences(): never {
    throw new Error('Method not implemented.');
  }
  public getActiveFilter(): never {
    throw new Error('Method not implemented.');
  }
  public setActiveFilter(): never {
    throw new Error('Method not implemented.');
  }
  public getSavedFilters(): never {
    throw new Error('Method not implemented.');
  }
  public saveFilter(): never {
    throw new Error('Method not implemented.');
  }
  public deleteSavedFilter(): never {
    throw new Error('Method not implemented.');
  }
  public getCalendarMode(): never {
    throw new Error('Method not implemented.');
  }
  public setCalendarMode(): never {
    throw new Error('Method not implemented.');
  }
  public getCurrentProfile(): never {
    throw new Error('Method not implemented.');
  }
  public setCurrentProfile(): never {
    throw new Error('Method not implemented.');
  }
  public getProfileHistory(): never {
    throw new Error('Method not implemented.');
  }
  public getThreshold(): never {
    throw new Error('Method not implemented.');
  }
  public setThreshold(): never {
    throw new Error('Method not implemented.');
  }
}
