import {
  add,
  addDays,
  addMilliseconds,
  addMonths,
  addYears,
  subMilliseconds,
  subMinutes
} from 'date-fns';

import { RequestResolution } from '@enerkey/clients/reporting';

import { ConsumptionLike } from './reporting-series';

export function getRequestStartAndEnd(
  periodStart: Date,
  duration: Duration
): { start: Date, end: Date } {
  const start = periodStart;
  let end = add(periodStart, duration);
  const startOffset = start.getTimezoneOffset();
  const endOffset = end.getTimezoneOffset();
  if (startOffset !== endOffset && end.getHours() !== 0) {
    // Fix issue where adding duration changes timezone because of daylight saving time
    end = subMinutes(end, endOffset - startOffset);
  }

  return {
    start,
    end
  };
}

/** Calculates the aggregate incomplete or modeled value from an array of consumptions */
export function getIncompleteOrModeledAggregate(
  consumption: ConsumptionLike[],
  type: 'incomplete' | 'modeled'
): number {
  const numbers: number[] = consumption.mapFilter(
    c => c?.[type],
    c => Number.isFinite(c)
  );
  return numbers.length > 0 ? numbers.reduce((total, v) => total + v, 0) / numbers.length : null;
}

/**
 * Returns a date that is resolution's period later.
 * @throws Error if resolution is invalid.
 */
function addResolution(date: Date, resolution: RequestResolution): Date {
  switch (resolution) {
    case RequestResolution.P1D: return addDays(date, 1);
    case RequestResolution.P7D: return addDays(date, 7);
    case RequestResolution.P1M: return addMonths(date, 1);
    case RequestResolution.P2M: return addMonths(date, 2);
    case RequestResolution.P3M: return addMonths(date, 3);
    case RequestResolution.P4M: return addMonths(date, 4);
    case RequestResolution.P5M: return addMonths(date, 5);
    case RequestResolution.P6M: return addMonths(date, 6);
    case RequestResolution.P7M: return addMonths(date, 7);
    case RequestResolution.P8M: return addMonths(date, 8);
    case RequestResolution.P9M: return addMonths(date, 9);
    case RequestResolution.P10M: return addMonths(date, 10);
    case RequestResolution.P11M: return addMonths(date, 11);
    case RequestResolution.P1Y: return addYears(date, 1);
    default: throw Error(`Invalid request resolution '${resolution}'`);
  }
}

/**
 * Creates minute intervals between start and end dates.
 * Use for 15 min and 1 hour resolutions instead of `addResolution`
 * to get exact amount of intervals regardless of daylight saving time changes.
 */
function createMinuteIntervals(start: Date, end: Date, intervalMinutes: number): { start: Date, end: Date }[] {
  const intervalInMs = intervalMinutes * 60 * 1000 - 1; // Interval in ms minus 1 ms to calculate interval end time
  const intervals: { start: Date, end: Date }[] = [];

  let currentDay = new Date(start);
  currentDay.setHours(0, 0, 0, 0);

  while (currentDay < end) {
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += intervalMinutes) {
        const intervalStart = new Date(currentDay);
        intervalStart.setHours(hour, minute, 0, 0);
        intervals.push({
          start: intervalStart,
          end: addMilliseconds(intervalStart, intervalInMs)
        });
      }
    }
    currentDay = addDays(currentDay, 1);
  }

  return intervals;
}

export function getIntervalsByResolution({ start, timeframe, end, resolution }: {
  start: Date,
  timeframe?: Duration,
  resolution: RequestResolution,
  end?: Date,
}): { start: Date, end: Date }[] {
  end ??= add(start, timeframe);
  let periods: { start: Date, end: Date }[] = [];

  if (resolution === RequestResolution.PT15M) {
    periods = createMinuteIntervals(start, end, 15);
  } else if (resolution === RequestResolution.PT1H) {
    periods = createMinuteIntervals(start, end, 60);
  } else {
    do {
      const periodEnd = addResolution(start, resolution);
      periods.push({
        start: new Date(start),
        end: subMilliseconds(new Date(periodEnd), 1)
      });
      start = periodEnd;
    } while (start < end);
  }

  return periods;
}

/**
 * Modifies the consumptions so that a consumption's value is sum of previous values.
 * Does no validations for incomplete/modeled or null values.
 */
export function convertToCumulative<T extends { value?: number; incomplete?: number; }>(
  consumptions: T[]
): void {
  if (consumptions.length === 0) {
    return;
  }

  let current: number = null;
  let currentIncomplete = 0;

  for (const item of consumptions) {
    if (item.value) {
      current += item.value;
    }
    currentIncomplete = Math.max(item.incomplete ?? 1, currentIncomplete);
    item.incomplete = currentIncomplete;
    item.value = current;
  }
}
