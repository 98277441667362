import { ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';

import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { ValueType } from '../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { ReportingDistributionType, ReportingSearchFormValue } from '../shared/reporting-search-form-value';

const tooLong15MinResoPeriodError = 'REPORTING.ERRORS.TOO_LONG_FIFTEEN_MINUTE_PERIOD';

const warningValidationFunctions: {
  fn: (params: ReportingSearchFormValue) => boolean;
  type: 'info' | 'warning',
  message: string
}[] = [
  {
    fn: unavailable15MinResolutionOptions,
    type: 'info',
    message: 'REPORTING.ERRORS.OPTIONS_NOT_AVAILABLE_FIFTEEN_MINUTE_DATA',
  },
  {
    fn: unavailableHourAnd15MinResolutionOptions,
    type: 'info',
    message: 'REPORTING.ERRORS.OPTIONS_NOT_AVAILABLE_FOR_HOUR_AND_FIFTEEN_MINUTE_DATA',
  },
  {
    fn: unavailableDayHourResolutionOptions,
    type: 'info',
    message: 'REPORTING.ERRORS.OPTIONS_NOT_AVAILABLE_FOR_LESS_THAN_MONTH_RESOLUTION',
  }
];

function unavailable15MinResolutionOptions(params: ReportingSearchFormValue): boolean {
  if (params.resolution === RequestResolution.PT15M) {
    return params.temperature;
  }
  return false;
}

function unavailableHourAnd15MinResolutionOptions(params: ReportingSearchFormValue): boolean {
  const { resolution, distributionType, minMaxAvg } = params;
  if (resolution === RequestResolution.PT1H || resolution === RequestResolution.PT15M) {
    return distributionType !== ReportingDistributionType.None
      || Object.values(minMaxAvg).some(Boolean);
  }
  return false;
}

function unavailableDayHourResolutionOptions(params: ReportingSearchFormValue): boolean {
  if ([
    RequestResolution.PT15M,
    RequestResolution.PT1H,
    RequestResolution.P1D,
    RequestResolution.P7D
  ].includes(params.resolution)) {
    return params.valueType !== ValueType.Measured
      || Array.hasItems(params.targetTypes);
  }
  return false;
}

export interface ReportingSearchParamWarning {
  message: string;
  type: 'warning' | 'info';
}

export function getDefaultReportingParams(): Omit<ReportingSearchFormValue, 'periods'> {
  return {
    quantityIds: [],
    durationName: 'years',
    durationLength: 1,
    resolution: RequestResolution.P1M,
    change: {
      absolute: false,
      relative: true,
    },
    valueType: ValueType.Measured,
    showConsumption: true,
    showSummedConsumption: true,
    specificIds: [],
    costIds: [],
    emissionIds: [],
    targetTypes: [],
    relatedQuantities: [],
    reportingUnit: ReportingUnit.Default,
    distributionType: ReportingDistributionType.None,
    distributionAsPercent: false,
    temperature: false,
    comparability: Comparability.All,
    minMaxAvg: {
      min: false,
      max: false,
      average: false,
    },
  };
}

export function getSearchParamWarnings(params: ReportingSearchFormValue): ReportingSearchParamWarning[] {
  const warnings: ReportingSearchParamWarning[] = [];
  warningValidationFunctions.forEach(({ fn, type, message }) => {
    if (fn(params)) {
      warnings.push({ type, message });
    }
  });
  return warnings;
}

export function getSearchParamErrorMessage(params: ReportingSearchFormValue): string {
  if (params.resolution === RequestResolution.PT15M) {
    if (!['days', 'weeks'].includes(params.durationName)) {
      return tooLong15MinResoPeriodError;
    }
    if (params.durationName === 'days' && params.durationLength > 14) {
      return tooLong15MinResoPeriodError;
    }
    if (params.durationName === 'weeks' && params.durationLength > 2) {
      return tooLong15MinResoPeriodError;
    }
  }
  return null;
}
