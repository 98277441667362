<ng-template #topbarTemplate>
  <ek-dropdown>
    <ek-dropdown-item (click)="openProductNameModal()">
      {{ 'ADMIN.CONTRACTS.PRODUCT_IDS_NAMES' | translate }}
    </ek-dropdown-item>

    <hr>

    <ek-dropdown-heading>
      {{ 'GRID.MASS_OPERATIONS' | translate }}
    </ek-dropdown-heading>
    <ek-dropdown-item
      (click)="openEditModal()"
      [disabled]="!gridComponent.selection.length"
    >
      {{ 'ADMIN.CONTRACTS.MANAGE' | translate }}
    </ek-dropdown-item>
    <ek-dropdown-item
      (click)="openTerminateModal()"
      [disabled]="!gridComponent.selection.length"
    >
      {{ 'ADMIN.CONTRACTS.TERMINATE_AND_CREATE' | translate }}
    </ek-dropdown-item>
  </ek-dropdown>
  <button
    class="button"
    (click)="openMassCreateModal()"
  >
    <i class="fas fa-plus"></i>
    {{ 'ADMIN.CONTRACTS.MASS_ADD.ACTION' | translate }}
  </button>
</ng-template>

<div class="row">
  <div class="large-2 medium-3 columns">
    <contract-search></contract-search>
  </div>

  <div class="large-10 medium-9 columns">
    <contract-grid #gridComponent></contract-grid>
  </div>
</div>
