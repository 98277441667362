<div
  class="reporting-sidebar"
  [ngClass]="{ 'isModal': isModal }"
  [@hostSize]="hostSizeAnimation"
>
  <button
  [attr.title]="buttonTitle | translate"
  (click)="toggleSidebar()"
  class="button"
  >
    <div class="button-text">
      <i
        [class]="buttonIconClass"
      ></i>
      &nbsp;
      {{ buttonTitle | translate }}
    </div>
  </button>
  <reporting-search-form
    [hidden]="(hideSearchForm$ | async)"
    [isMeterReport]="isMeterReport"
    class="reporting-sidebar-container"
    [@fadeInOut]="hostSizeAnimation"
  ></reporting-search-form>
  <static-settings
    *ngIf="hideSearchForm$ | async"
    class="reporting-sidebar-container"
    [@fadeInOut]="hostSizeAnimation"
  >
  </static-settings>
</div>
