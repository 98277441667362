import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { EnergyTargetType } from '@enerkey/clients/reporting';
import { getStringEnumValues } from '@enerkey/ts-utils';

import { EkMultiComponent } from '../../../../shared/ek-inputs/ek-multi/ek-multi.component';
import { TargetCategory, targetSeriesDefinitions } from '../../shared/target-series-definitions';
import { UserService } from '../../../../services/user-service';
import { Service } from '../../../../constants/service';
import { Roles } from '../../../admin/constants/roles';

@Component({
  selector: 'target-multi-select',
  templateUrl: '../../../../shared/ek-inputs/multi-select/multi-select.component.html',
  styleUrls: ['../../../../shared/ek-inputs/multi-select/multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TargetMultiSelectComponent,
    multi: true
  }],
})
export class TargetMultiSelectComponent extends EkMultiComponent {
  public override placeholderKey = 'MULTISELECT.SEARCH_SELECTABLE_ITEMS';
  public override overflowThreshold = 3;

  public constructor(changeDetectorRef: ChangeDetectorRef, private readonly userService: UserService) {
    super(changeDetectorRef);

    const hasCostTargetAccess = this.userService.hasService(Service.CostReportingMeterBased) &&
      this.userService.hasRole(Roles.COSTREADER);

    this.items = getStringEnumValues(EnergyTargetType).filterMap(
      target => !hasCostTargetAccess
        ? targetSeriesDefinitions[target].targetCategory !== TargetCategory.COST
        : true,
      target => ({
        value: target,
        text: targetSeriesDefinitions[target].translationKey
      })
    );
  }
}
