import { Injectable } from '@angular/core';
import { ColumnBase, ColumnComponent } from '@progress/kendo-angular-grid';
import { AggregateDescriptor } from '@progress/kendo-data-query';
import { from, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { FacilityColumnsProperties, FacilityProperty } from '../../../shared/interfaces/facility-columns-properties';
import ErCacheService from './er-cache-service';
import { ProfileService } from '../../../shared/services/profile.service';
import { nonAggregableKeys } from '../../reporting/constants/table-report-constants';
import { FacilityPropertyColumns } from '../../reporting/components/table-report/table-report.component';

export type FacilityPropertyWithField = FacilityProperty & { field: string };

export type FacilityColumnsPropertiesWithFields =
  Omit<FacilityColumnsProperties, 'Items'> &
  { Items: FacilityPropertyWithField[] };

export interface GroupingPropertyOption {
  itemName: string;
  itemProperty: string;
  groupName: string;
  groupProperty: string;
  id: string;
}

export interface FacilityPropertyName {
  groupName: string;
  propertyName: string;
}

export type FacilityPropertyNames = Record<string, Record<string, FacilityPropertyName>>;

@Injectable({
  providedIn: 'root'
})
export class FacilityPropertiesService {
  public nonAggregableKeys: Set<string> = nonAggregableKeys;
  public readonly facilityProperties$: Observable<FacilityColumnsPropertiesWithFields[]>;
  public readonly groupableFacilityProperties$: Observable<GroupingPropertyOption[]>;
  public readonly facilityPropertyNames$: Observable<FacilityPropertyNames>;
  private readonly facilityPropertiesFlat$: Observable<FacilityPropertyWithField[]>;

  public constructor(
    profileService: ProfileService,
    erCacheService: ErCacheService
  ) {
    this.facilityProperties$ = profileService.profile$.pipe(
      switchMap(() => from(erCacheService.getCache())),
      map(cache => cache.facilitiesProperties),
      map(groups => groups.map(group => ({
        ...group,
        Items: group.Items.map(item => ({
          ...item,
          field: `${group.Property}.${item.Property}`
        }))
      }))),
      shareReplay(1)
    );

    this.facilityPropertyNames$ = this.facilityProperties$.pipe(
      map(groups => groups.toRecord(
        group => group.Property,
        group => group.Items.toRecord<string, FacilityPropertyName>(
          item => item.Property,
          item => ({
            propertyName: item.Name,
            groupName: group.Name
          })
        )
      ))
    );

    this.groupableFacilityProperties$ = this.facilityProperties$.pipe(
      map(groups => groups.map(group => ({
        ...group,
        Items: group.Items.filter(item => item.Groupable && item.Type === 'string')
      }))),
      map(groups => groups
        .filter(group => Array.hasItems(group.Items))
        .flatMap(group => group.Items.map(item => ({
          itemName: item.Name,
          itemProperty: item.Property,
          groupName: group.Name,
          groupProperty: group.Property,
          id: `${group.Property}.${item.Property}`
        })))),
      shareReplay(1)
    );

    this.facilityPropertiesFlat$ = this.facilityProperties$.pipe(
      map(groups => groups.flatMap(group => group.Items)),
      shareReplay(1)
    );
  }

  public getPropertyAggregates(columns: ColumnBase[]): Observable<AggregateDescriptor[]> {
    return this.facilityPropertiesFlat$.pipe(
      take(1),
      map(properties => columns.filter(column => !column.hidden).reduce<AggregateDescriptor[]>(
        (aggregates, column) => {
          const columnComponent = column as ColumnComponent;
          const foundProperty = properties.find(p => p.field === columnComponent.field);
          if (
            foundProperty?.Type === 'number' &&
            !this.nonAggregableKeys.has(columnComponent.field)
          ) {
            aggregates.push({ field: columnComponent.field, aggregate: 'sum' });
            aggregates.push({ field: columnComponent.field, aggregate: 'min' });
            aggregates.push({ field: columnComponent.field, aggregate: 'max' });
            aggregates.push({ field: columnComponent.field, aggregate: 'average' });
          }
          return aggregates;
        }, []
      ))
    );
  }

  /**
   * It return the sum of the properties even if the facilites properties are not added in the table
   * only to utilize for the calculation of specific consumptions with initial values
   * @param columns - all the facilities properties
   * @returns - the sum of the properties
   */
  public getPropertySum(columns: FacilityPropertyColumns[]): Observable<AggregateDescriptor[]> {
    const propertyColumn = columns.filter(c => c.Property === 'Properties').flatMap(c => c.Items);
    return this.facilityPropertiesFlat$.pipe(
      take(1),
      map(properties => propertyColumn.reduce<AggregateDescriptor[]>(
        (aggregates, column) => {
          const foundProperty = properties.find(p => p.field === column.field);
          if (
            foundProperty?.Type === 'number' &&
            !this.nonAggregableKeys.has(column.field)
          ) {
            aggregates.push({ field: column.field, aggregate: 'sum' });

          }
          return aggregates;
        }, []
      ))
    );
  }
}
