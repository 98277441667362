import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output,
  SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { combineLatest, Observable, of, startWith, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, takeUntil, tap } from 'rxjs/operators';

import { MeterGroupDto } from '@enerkey/clients/metering';
import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';

import { MeterGroup, MeterGroupBaseData } from '../../../models/meter-groups.model';
import { MeterGroupsService } from '../../../services/meter-groups/meter-groups.service';

@Component({
  selector: 'meter-groups-sidebar',
  templateUrl: './meter-groups-sidebar.component.html',
  styleUrls: ['./meter-groups-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
export class MeterGroupsSidebarComponent implements OnChanges, OnDestroy {
  @Input() public editMode: boolean = false;
  @Output() public readonly meterGroupChange = new EventEmitter<MeterGroupDto>();
  @Output() public readonly facilityChange = new EventEmitter<number>();
  @Output() public readonly searchClick = new EventEmitter<void>();

  public formGroup: FormGroup;
  public controls: ControlsOf<{
    facilityId: number,
    meterGroupId: number,
    meterGroupDescription: string,
    facilityFilter: boolean,
    meterGroupFilter: boolean
  }>;

  protected readonly facilityIdFilterState$: Observable<number | null>;
  protected readonly meterGroupIdFilterState$: Observable<number[] | null>;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private meterGroupService: MeterGroupsService,
    private cdr: ChangeDetectorRef
  ) {

    this.controls = formControlsFrom(
      {
        facilityId: undefined,
        meterGroupId: undefined,
        meterGroupDescription: undefined,
        facilityFilter: false,
        meterGroupFilter: false
      }
    );

    this.formGroup = new FormGroup(this.controls);

    this.controls.facilityFilter.disable();
    this.controls.meterGroupFilter.disable();

    this.controls.facilityId.valueChanges.pipe(
      tap(facilityId => this.facilityChange.emit(facilityId)),
      takeUntil(this._destroy$)
    ).subscribe();

    this.facilityIdFilterState$ = combineLatest([
      this.controls.facilityId.valueChanges,
      this.controls.meterGroupFilter.valueChanges
    ]).pipe(
      map(([facilityId, meterGroupFilterEnabled]) => meterGroupFilterEnabled ? facilityId : null),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.meterGroupIdFilterState$ = combineLatest([
      this.controls.meterGroupId.valueChanges,
      this.controls.facilityFilter.valueChanges
    ]).pipe(
      map(([meterGroupId, facilityFilterEnabled]) => facilityFilterEnabled ? meterGroupId : null),
      switchMap(meterGroupId => !Number.isFinite(meterGroupId) ?
        of(null) :
        this.meterGroupService.getBaseData().pipe(
          map(baseData => this.getFacilityIdsFromMeterGroup(baseData, meterGroupId))
        )),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    const distinctFormControlValueChanges = [
      this.controls.facilityId,
      this.controls.meterGroupId,
      this.controls.facilityFilter,
      this.controls.meterGroupFilter
    ].map(control => control.valueChanges.pipe(
      startWith(control.value),
      distinctUntilChanged()
    ));

    combineLatest(distinctFormControlValueChanges).pipe(
      tap(([facilityId, meterGroupId, facilityFilter, meterGroupFilter]) => this.setCheckboxStates(
        facilityId, meterGroupId, facilityFilter, meterGroupFilter
      )),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.editMode) { return; }
    if (changes.editMode.currentValue === true) {
      this.controls.facilityId.disable();
      this.controls.meterGroupId.disable();
    } else {
      this.controls.facilityId.enable();
      this.controls.meterGroupId.enable();
    }

    this.setCheckboxStates(
      this.controls.facilityId.value,
      this.controls.meterGroupId.value,
      this.controls.facilityFilter.value,
      this.controls.meterGroupFilter.value
    );

    this.cdr.markForCheck();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onMeterGroupChange(meterGroup: MeterGroup): void {
    this.controls.meterGroupDescription.setValue(meterGroup?.description ?? '');
    this.meterGroupChange.emit(meterGroup ? MeterGroupDto.fromJS(meterGroup) : null);
  }

  public setCheckboxStates(
    facilityId: number,
    meterGroupId: number,
    isFacilityFilterChecked: boolean,
    isMeterGroupFilterChecked: boolean
  ): void {
    const isMeterGroupIdDefined = Number.isFinite(meterGroupId);
    const isFacilityIdDefined = Number.isFinite(facilityId);
    const isEditMode = this.editMode === true;

    if (isMeterGroupIdDefined && !isMeterGroupFilterChecked && !isEditMode) {
      this.controls.facilityFilter.enable();
    } else {
      this.controls.facilityFilter.disable();

      if (!isEditMode) {
        this.controls.facilityFilter.setValue(false, { emitEvent: false, onlySelf: true });
      }
    }

    if (isFacilityIdDefined && !isFacilityFilterChecked && !isEditMode) {
      this.controls.meterGroupFilter.enable();
    } else {
      this.controls.meterGroupFilter.disable();

      if (!isEditMode) {
        this.controls.meterGroupFilter.setValue(false, { emitEvent: false, onlySelf: true });
      }
    }
  }

  private getFacilityIdsFromMeterGroup(data: MeterGroupBaseData, meterGroupId: number): number[] {
    const meterGroup = data.meterGroups.find(mg => mg.id === meterGroupId);
    const meterGroupMeterIds = meterGroup?.meters?.map(meter => meter.meterId);

    if (!meterGroupMeterIds?.length) { return []; }

    const meters = Object
      .values(data.meters)
      .map(m => m.filter(meter => meterGroupMeterIds.includes(meter.id)))
      .flat();

    const facilityIds = meters.map(meter => meter.facilityId);
    return [...new Set(facilityIds)];
  }
}
