import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest, map, Observable, shareReplay, switchMap, takeUntil, withLatestFrom } from 'rxjs';

import { indicate } from '@enerkey/rxjs';

import { FacilityService } from '../../../../shared/services/facility.service';
import { ReportEventService } from '../../services/report-events.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { ReportBase, ReportingGridConfig } from '../report-base';
import { ReportingSeriesByFacility } from '../../shared/reporting-series-collection';
import { sumKey, SumReportService } from '../../services/sum-report.service';
import { ThresholdService } from '../../../../shared/services/threshold.service';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { ReportingGridService } from '../../services/reporting-grid.service';
import { ReportType } from '../../shared/report-type';
import { QuantityService } from '../../../../shared/services/quantity.service';

@Component({
  selector: 'sum-report',
  templateUrl: './sum-report.component.html',
  styleUrls: ['../report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SumReportComponent extends ReportBase {
  public readonly consumptionData$: Observable<ReportingSeriesByFacility>;
  public readonly gridConfig$: Observable<ReportingGridConfig>;

  public readonly sumKey = sumKey;

  protected readonly reportType = ReportType.Sum;

  public constructor(
    private readonly sumReportService: SumReportService,
    private readonly reportingGridService: ReportingGridService,
    private readonly thresholdService: ThresholdService,
    private readonly quantityService: QuantityService,
    facilityService: FacilityService,
    reportingSearchService: ReportingSearchService,
    reportEventService: ReportEventService
  ) {
    super(facilityService, reportingSearchService, reportEventService);

    this.consumptionData$ = combineLatest([
      this.searchParams$,
      this.facilityIds$,
      this.thresholdService.threshold$
    ]).pipe(
      switchMap(([params, facilityIds, threshold]) => this.sumReportService.getData(
        params, facilityIds, threshold
      ).pipe(
        indicate(this._loading$)
      )),
      takeUntil(this._destroy$),
      shareReplay(1)
    );

    this.gridConfig$ = combineLatest({
      data: this.consumptionData$,
      unsupportedQuantitiesAggregate: this.quantityService.getAverageAggregatesQuantities()
    }).pipe(
      withLatestFrom(this.searchParams$),
      map(([{ data, unsupportedQuantitiesAggregate }, searchParams]) => {
        const gridColumns = this.reportingGridService.getColumns(data, [sumKey]);
        const gridData = this.reportingGridService.getData(data, searchParams, [sumKey]);
        const aggregates = this.reportingGridService.getAggregates(
          [sumKey],
          gridColumns,
          gridData,
          ReportType.Sum,
          unsupportedQuantitiesAggregate,
          searchParams
        );
        return {
          data: gridData[sumKey],
          columns: gridColumns[sumKey],
          aggregates: aggregates[sumKey]
        };
      })
    );
  }

  protected override transformParams(params: ReportingSearchParams): ReportingSearchParams {
    return params;
  }
}
