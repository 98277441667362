<ng-container *ngIf="pageOptions$ | async as pages">
  <page-changer
    *ngIf="pages > 1"
    [pages]="pages"
    (pageChange)="pageChange($event)"
  ></page-changer>
</ng-container>
<div *ngFor="let meterGroup of (selectedMeterGroups$ | async) | slice : (skipStart$ | async) : (skipEnd$ | async)">
   <metergroup-report-header [meterGroup]="meterGroup"></metergroup-report-header>
</div>
