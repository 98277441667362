import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormRecord } from '@angular/forms';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ReportingSearchService } from '../../services/reporting-search.service';
import {
  FacilityColumnsPropertiesWithFields,
  FacilityPropertiesService
} from '../../../energy-reporting/services/facility-properties.service';
import { ReportTypeOptionsService } from '../../services/report-type-options.service';

@Component({
  selector: 'reporting-visible-sections-select',
  templateUrl: './reporting-visible-sections-select.component.html',
  styleUrls: ['./reporting-visible-sections-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportingVisibleSectionsSelectComponent implements AfterViewInit {
  @Input() public isMeterReport: boolean;

  public sectionForm: FormRecord<FormControl<boolean>>;

  public readonly chartVisibilityControl = new FormControl(true);
  public readonly gridVisibilityControl = new FormControl(true);
  public readonly meterInfoVisibilityControl = new FormControl(true);

  public readonly facilityPropertyGroups$: Observable<FacilityColumnsPropertiesWithFields[]>;
  public readonly showCharts$: Observable<boolean>;
  public readonly showGrids$: Observable<boolean>;
  public readonly showFacilityOrMeterInfo$: Observable<boolean>;

  public constructor(
    private readonly facilityPropertiesService: FacilityPropertiesService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly reportingSearchService: ReportingSearchService,
    disabledParamsService: ReportTypeOptionsService
  ) {
    this.showFacilityOrMeterInfo$ = disabledParamsService.showMeterOrFacilityInfo$;

    this.facilityPropertyGroups$ = this.facilityPropertiesService.facilityProperties$.pipe(
      take(1)
    );

    this.reportingSearchService.chartVisibility$.subscribe(visible => {
      this.chartVisibilityControl.setValue(visible, { emitEvent: false });
    });

    this.reportingSearchService.gridVisibility$.subscribe(visible => {
      this.gridVisibilityControl.setValue(visible, { emitEvent: false });
    });

    this.reportingSearchService.meterInfoVisibility$.subscribe(visible => {
      this.meterInfoVisibilityControl.setValue(visible, { emitEvent: false });
    });

    this.chartVisibilityControl.valueChanges.subscribe({
      next: (isVisible: boolean) => {
        this.reportingSearchService.setChartsVisibility(isVisible);
      }
    });

    this.gridVisibilityControl.valueChanges.subscribe({
      next: (isVisible: boolean) => {
        this.reportingSearchService.setGridsVisibility(isVisible);
      }
    });

    this.meterInfoVisibilityControl.valueChanges.subscribe({
      next: (isVisible: boolean) => {
        this.reportingSearchService.setMeterInfoVisibility(isVisible);
      }
    });
  }

  public ngAfterViewInit(): void {
    this.facilityPropertyGroups$.pipe(take(1)).subscribe(groups => {
      this.sectionForm = new FormRecord({});
      groups.forEach(group => {
        this.sectionForm.addControl(group.Property, new FormControl(false));
      });
      this.changeDetectorRef.detectChanges();

      this.reportingSearchService.visibleSections$.subscribe(visibleSections => {
        for (const control of Object.values(this.sectionForm.controls)) {
          control.setValue(false, { emitEvent: false });
        }
        for (const section of visibleSections) {
          this.sectionForm.get(section)?.setValue(true, { emitEvent: false });
        }
      });

      this.sectionForm.valueChanges.subscribe(value => {
        const visibleSections: string[] = [];
        for (const [key, visible] of Object.entries(value)) {
          if (visible) {
            visibleSections.push(key);
          }
        }
        this.reportingSearchService.toggleSections(visibleSections);
      });
    });
  }
}
