<modal-view-header [headingText]="'ADMIN.CONTRACTS.PRODUCT_IDS_NAMES' | translate"></modal-view-header>

<div class="modal-body">
  <kendo-grid
    [kendoGridBinding]="productNames$ | async"
    [loading]="loading$ | async"
    [height]="500"
    [filterable]="'menu'"
    [resizable]="true"
    [pageable]="false"
    [sortable]="true"
  >
    <kendo-grid-excel
      [fileName]="'ADMIN.CONTRACTS.PRODUCT_IDS_NAMES' | translate"
      [excelDataSource]="productNames$ | async"
    ></kendo-grid-excel>
    <ng-template kendoGridToolbarTemplate>
      <button
        kendoGridExcelCommand
        [disabled]="loading$ | async"
      >
        {{ 'ADMIN.GET_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-column
      field="id"
      [title]="'ADMIN.CONTRACTS.PRODUCT_ID' | translate"
      [style]="{ 'text-align': 'right' }"
    > </kendo-grid-column>
    <kendo-grid-column
      field="name"
      [title]="'ADMIN.CONTRACTS.PRODUCT_NAME' | translate"
    > </kendo-grid-column>
  </kendo-grid>
</div>

<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button button--secondary"
      type="button"
      (click)="close()"
    >
      {{ 'MODALS.CLOSE' | translate }}
    </button>
  </div>
</div>
