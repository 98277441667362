import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';

import { assertUnreachable } from '@enerkey/ts-utils';

import { AjsModalService } from '../../services/modal/modal.service';
import { TopbarSearchService } from '../../services/topbar-search.service';
import { EnerkeySearchable, SearchableFacility, SearchableType } from './searchable-item-types';
import { BookmarkService } from '../../services/bookmark.service';

type SearchableTypeField = keyof Pick<EnerkeySearchable, 'searchableType'>;
type SearchableTitleField = keyof Pick<EnerkeySearchable, 'title'>;

@Component({
  selector: 'topbar-search-input',
  templateUrl: './topbar-search-input.component.html',
  styleUrls: ['./topbar-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarSearchInputComponent implements AfterViewInit, OnDestroy {
  @Output() public readonly closeClick = new EventEmitter<void>();
  @ViewChild(ComboBoxComponent) public searchComponent: ComboBoxComponent;

  public readonly titleField: SearchableTitleField = 'title';
  public readonly searchItems$: Observable<EnerkeySearchable[] | GroupResult[]>;

  /**
   * Limit amount of displayed items to avoid freezing browser
   */
  private readonly maxItems = 100;
  private readonly groupByField: SearchableTypeField = 'searchableType';
  private readonly filter$ = new BehaviorSubject('');

  public constructor(
    topbarSearchService: TopbarSearchService,
    private readonly angularjsModalService: AjsModalService,
    private readonly bookmarkService: BookmarkService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    this.searchItems$ = combineLatest([
      topbarSearchService.searchItems$,
      this.filter$.pipe(debounceTime(100))
    ]).pipe(
      map(([items, filter]) => this.filterItems(items, filter).slice(0, this.maxItems)),
      map(items => groupBy(items, [{ field: this.groupByField }])),
      tap(() => {
        setTimeout(() => {
          changeDetectorRef.detectChanges();
        });
      })
    );
  }

  public ngOnDestroy(): void {
    this.filter$.complete();
  }

  @HostListener('document:keydown.escape', ['$event'])
  public escPress(): void {
    this.close();
  }

  public ngAfterViewInit(): void {
    this.searchComponent.focus();
  }

  public close(): void {
    this.closeClick.emit();
  }

  public filterChange(value: string): void {
    this.filter$.next(value);
  }

  public onItemSelect(item: EnerkeySearchable): void {
    if (!item) {
      return;
    }

    switch (item.searchableType) {
      case SearchableType.Facility: {
        this.openFacilityReport(item);
        break;
      }
      case SearchableType.Bookmark: {
        this.bookmarkService.showBookmark(item.bookmark);
        break;
      }
      /* istanbul ignore next */
      default: {
        assertUnreachable(item);
      }
    }

    this.close();
  }

  private openFacilityReport(item: SearchableFacility): void {
    this.angularjsModalService.getModalWithComponent('report-modal', {
      reportType: 'modal.report',
      reportParams: { facilityId: [item.facilityId] }
    });
  }

  private filterItems(items: EnerkeySearchable[], filterValue: string): EnerkeySearchable[] {
    if (!filterValue) {
      return items;
    }
    return items.filter(
      item =>
        item.title.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.extraText?.toLowerCase().includes(filterValue.toLowerCase())
    );
  }
}
