import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ColumnComponent } from '@progress/kendo-angular-treelist';
import { AggregateDescriptor, AggregateResult } from '@progress/kendo-data-query';

import { MeterTableReportService } from '../../services/meter-table-report.service';
import { isAverageQuantityAggregate, isDistributionPerCentSerie } from '../../services/reporting-grid.service';

@Component({
  selector: 'meter-table-report-aggregates',
  templateUrl: './meter-table-report-aggregates.component.html',
  styleUrls: ['./meter-table-report-aggregates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterTableReportAggregatesComponent implements OnChanges {
  @Input() public aggregates: AggregateResult;
  @Input() public column: ColumnComponent;
  @Input() public parentItem: boolean;
  @Input() public isAggregateUnsupported: boolean;
  @Input() public isPercentSerie: boolean;

  public constructor(private meterTableReportService: MeterTableReportService) {}

  public ngOnChanges(): void {
    if (this.aggregates) {
      this.aggregates = this.getAggregateValue();
    }
  }

  private getAggregateValue(): AggregateResult {
    const changeAggregates = this.meterTableReportService.calculateChangeAggregates(this.aggregates);
    const aggregateResult: AggregateResult = {};
    for (const [key, value] of Object.entries(changeAggregates)) {
      aggregateResult[key] = {};
      const [, serieType, ,] = key.split('.');

      const isDistPerCentSerie = isDistributionPerCentSerie(serieType, this.isPercentSerie);

      const isAvgAggregateUnsupported = isAverageQuantityAggregate(serieType, this.isAggregateUnsupported);

      for (const [k, v] of Object.entries(value) as [AggregateDescriptor['aggregate'], number][]) {
        aggregateResult[key][k] = (isAvgAggregateUnsupported || isDistPerCentSerie) && k === 'sum' ? null : v;
      }
    }
    return aggregateResult;
  }
}
