<modal-view-header headingText="{{ 'ADMIN.CONTRACTS.MANAGE' | translate }}">
</modal-view-header>

<div class="modal-body">
  <form
    #editForm="ngForm"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
  >
    <div class="grid-wrapper">
      <div>
        <label>{{ 'ADMIN.FACILITYID' | translate }}</label>
      </div>
      <div>
        <label>{{ 'ADMIN.FACILITY_NAME' | translate }}</label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('contractId')"
          [disabled]="loading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.CONTRACT_ID' | translate }}
        </contract-editable-label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('productId')"
          [disabled]="loading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.PRODUCT_ID' | translate }}
        </contract-editable-label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('unitCount')"
          [disabled]="loading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.UNIT_COUNT' | translate }}
        </contract-editable-label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('unitPrice')"
          [disabled]="loading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.UNIT_PRICE' | translate }}
        </contract-editable-label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('fromDate')"
          [disabled]="loading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.FROM_DATE' | translate }}
        </contract-editable-label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('toDate')"
          [disabled]="loading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.TO_DATE' | translate }}
        </contract-editable-label>
      </div>
      <div>
      </div>
      <ng-container
        *ngFor="let contract of formArray.controls; let idx = index"
        formArrayName="contracts"
      >
        <ng-container [formGroupName]="idx">
          <input
            type="hidden"
            formControlName="id"
          >
          <div>
            <input
              type="text"
              formControlName="facilityId"
              readonly
            >
          </div>
          <div>
            <input
              type="text"
              formControlName="facilityName"
              readonly
            >
          </div>
          <div>
            <input
              type="text"
              formControlName="contractId"
            >
          </div>
          <div>
            <product-input
              formControlName="productId"
            ></product-input>
          </div>
          <div>
            <kendo-numerictextbox
              formControlName="unitCount"
              [decimals]="0"
              [format]="'0'"
              [step]="numberInputOpts.step"
              [autoCorrect]="numberInputOpts.autoCorrect"
              [spinners]="numberInputOpts.spinners"
            ></kendo-numerictextbox>
          </div>
          <div>
            <kendo-numerictextbox
              formControlName="unitPrice"
              [decimals]="numberInputOpts.decimals"
              [format]="numberInputOpts.format"
              [step]="numberInputOpts.step"
              [autoCorrect]="numberInputOpts.autoCorrect"
              [spinners]="numberInputOpts.spinners"
            ></kendo-numerictextbox>
          </div>
          <div>
            <kendo-datepicker
              formControlName="fromDate"
            ></kendo-datepicker>
          </div>
          <div>
            <kendo-datepicker
              formControlName="toDate"
            ></kendo-datepicker>
          </div>
          <div>
            <input
              type="checkbox"
              formControlName="remove"
              (change)="deleteToggled(idx, $any($event.target).checked)"
              kendoCheckBox
              *labelWrap="'DELETE' | translate"
            >
          </div>
        </ng-container>
      </ng-container>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="row">
    <div class="modal-footer-buttons float-right">
      <button
        class="button button--primary"
        [disabled]="(loading$ | async) || !formGroup.valid"
        (click)="editForm.ngSubmit.emit()"
      >
        {{ 'SUBMIT' | translate }}
      </button>
      <button
        class="button button--secondary"
        type="button"
        (click)="dismiss()"
      >
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </div>
</div>
