<kendo-chart
  (mouseleave)="mouseleave()"
  (seriesClick)="seriesClicked($event)"
  (plotAreaClick)="plotAreaClicked($event)"
  [transitions]="false"
  [zoomable]="{ mousewheel: false, selection: { lock: 'y' } }"
>
  <kendo-chart-legend
    position="top"
    [visible]="true"
    [labels]="legendLabels"
    [margin]="0"
  ></kendo-chart-legend>
  <kendo-chart-tooltip [shared]="true">
    <ng-template
      kendoChartSharedTooltipTemplate
      let-category="category"
      let-points="points"
    >
      <chart-tooltip
        [points]="points"
        [category]="category"
        [resolution]="labelSettings.resolution"
        [amountOfPeriods]="labelSettings.amountOfPeriods"
      ></chart-tooltip>
    </ng-template>
  </kendo-chart-tooltip>
  <kendo-chart-series>
    <ng-container *ngFor="let serie of series; let index = index">
      <kendo-chart-series-item
        *ngIf="serie.isShownInChart"
        [style]="'smooth'"
        [width]="serie.chartOptions?.lineWidth"
        [markers]="{ visible: serie.chartOptions?.showMarkers ?? false }"
        [type]="serie.chartOptions?.serieType"
        [data]="serie.values"
        [color]="serie.options.color"
        [opacity]="serie.chartOptions?.lineOpacity"
        [name]="serie.options.serieTitle"
        [field]="'visibleValue'"
        [dashType]="serie.chartOptions?.dashType"
        [axis]="serie.chartAxisName"
        [spacing]="0"
        [missingValues]="'gap'"
        [categoryField]="'index'"
      ></kendo-chart-series-item>
    </ng-container>
  </kendo-chart-series>
  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item
      [justified]="true"
      [majorTicks]="{ size: 4, width: 1, step: labelSettings.step }"
      [majorGridLines]="{ step: labelSettings.step }"
      [line]="{ color: chartLineColor }"
      [axisCrossingValue]="[0, 1000000, 1000000, 1000000, 1000000]"
      [labels]="{
        background: axisBackground,
        position: 'start',
        color: axisColor,
        font: axisFont,
        step: labelSettings.step,
        content: labelContentFn,
        visual: labelVisualFn }"
    ></kendo-chart-category-axis-item>
  </kendo-chart-category-axis>

  <kendo-chart-value-axis>
    <kendo-chart-value-axis-item
      *ngFor="let axis of (series | chartValueAxisOptions)"
      [name]="axis.name"
      [labels]="{ background: axisBackground, font: axisFont, color: axis.color ?? axisColor, format: axis.format }"
      [majorTicks]="{ color: axis.color ?? chartLineColor }"
      [line]="{ color: axis.color ?? chartLineColor }"
      [narrowRange]="false"
      [min]="axis.min"
      [max]="axis.max"
      [reverse]="axis.reverse"
    ></kendo-chart-value-axis-item>
  </kendo-chart-value-axis>
</kendo-chart>
