import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable, shareReplay } from 'rxjs';

import { bignumber } from 'mathjs';

import { MeteringClient } from '@enerkey/clients/metering';

import { MeterManagementClient } from '@enerkey/clients/meter-management';

import { REPORT_MODAL_PARAMS, ReportingModalParams } from '../components/report-modal/report-modal.component';

export class MeterGroupTreeItem {
  public readonly className = 'meter-group';

  public constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly meters: MeterGroupTreeMeterItem[] = [],
    public readonly description: string,
    public readonly facilityId: number
  ) { }
}

export class MeterGroupTreeMeterItem {
  public readonly className = 'meter';
  public readonly percentage: number;

  public constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly weight: number
  ) {
    this.percentage = bignumber(this.weight).mul(100).abs().toNumber();
  }
}

@Injectable()
export class ReportModalMeterGroupService {
  public readonly selectedMeterGroups$: Observable<MeterGroupTreeItem[]>;
  public readonly meterGroupsTree$: Observable<MeterGroupTreeItem[]>;
  public readonly checkedKeys$: Observable<string[]>;

  private readonly _selectedMeterGroups$ = new BehaviorSubject<MeterGroupTreeItem[]>([]);
  private readonly _meterGroupsTree = new BehaviorSubject<MeterGroupTreeItem[]>([]);
  private readonly _checkedKeys$ = new BehaviorSubject<string[]>([]);

  public constructor(
    private readonly meteringClient: MeteringClient,
    private readonly meterMangementClient: MeterManagementClient,
    @Inject(REPORT_MODAL_PARAMS) private modalParams: ReportingModalParams
  ) {
    this.selectedMeterGroups$ = this._selectedMeterGroups$.asObservable();
    this.meterGroupsTree$ = this._meterGroupsTree.asObservable();
    this.checkedKeys$ = this._checkedKeys$.asObservable();

    this.getMeterGroupsTree();
  }

  public setSelectedMeterGroups(selectedMeterGroups: MeterGroupTreeItem[]): void {
    this._selectedMeterGroups$.next(selectedMeterGroups);
  }

  public setCheckedKeys(checkedKeys: string[]): void {
    this._checkedKeys$.next(checkedKeys);
  }

  public getMeterGroupsTree(): void {
    const facilityId = this.modalParams.facilityId;
    const meterGroups = this.meteringClient.getMeterGroups();
    const facilityMeters = this.meterMangementClient.getMetersByFacility([facilityId]);

    forkJoin([meterGroups, facilityMeters]).pipe(
      map(([groups, meters]) => {
        const meterResults = Object.values(meters).flat();

        return groups.map(
          group => {
            const groupMeters = group.meters
              .map(gm => {
                const meter = meterResults.find(m => m.id === gm.meterId);
                return meter ? new MeterGroupTreeMeterItem(meter.id, meter.name, gm.weight) : undefined;
              })
              .filter(m => m !== undefined);

            // Only return groups that have meters
            if (groupMeters.length > 0) {
              return new MeterGroupTreeItem(group.id, group.name, groupMeters, group.description, facilityId);
            }
            return null; // Exclude groups with no meters
          }
        )
          .filter(group => group !== null); // Remove null groups
      }),
      shareReplay(1)
    ).subscribe(
      res => this._meterGroupsTree.next(res)
    );
  }
}
