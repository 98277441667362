import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { getStringEnumValues } from '@enerkey/ts-utils';

import { TimeFrameOptions } from '../../../../constants/time-frame';
import { EkComboComponent } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { WidgetType } from '../../shared/widget-type';

const timeframeTranslationKeys: Record<TimeFrameOptions, string> = {
  [TimeFrameOptions.YEAR_BY_YEAR_CALENDAR]: 'TIMEFRAME_OPTIONS.YEARBYYEARCALENDAR',
  [TimeFrameOptions.YEAR_BY_YEAR_FLOATING]: 'TIMEFRAME_OPTIONS.YEARBYYEARFLOATING',
  [TimeFrameOptions.YEAR_FULL_QUARTERS]: 'TIMEFRAME_OPTIONS.YEARFULLQUARTERS',
  [TimeFrameOptions.QUARTER_BY_QUARTER_MATCH_CURRENT]: 'TIMEFRAME_OPTIONS.QUARTERBYQUARTERMATCHCURRENT',
  [TimeFrameOptions.QUARTER_BY_QUARTER_MATCH_PREVIOUS]: 'TIMEFRAME_OPTIONS.QUARTERBYQUARTERMATCHPREVIOUS',
  [TimeFrameOptions.QUARTER_BY_QUARTER_LAST_CURRENT]: 'TIMEFRAME_OPTIONS.QUARTERBYQUARTERLASTCURRENT',
  [TimeFrameOptions.QUARTER_BY_QUARTER_LAST_PREVIOUS]: 'TIMEFRAME_OPTIONS.QUARTERBYQUARTERLASTPREVIOUS',
  [TimeFrameOptions.MONTH_BY_MONTH_MATCH_CURRENT]: 'TIMEFRAME_OPTIONS.MONTHBYMONTHMATCHCURRENT',
  [TimeFrameOptions.MONTH_BY_MONTH_MATCH_PREVIOUS]: 'TIMEFRAME_OPTIONS.MONTHBYMONTHMATCHPREVIOUS',
  [TimeFrameOptions.MONTH_BY_MONTH_LAST_CURRENT]: 'TIMEFRAME_OPTIONS.MONTHBYMONTHLASTCURRENT',
  [TimeFrameOptions.MONTH_BY_MONTH_LAST_PREVIOUS]: 'TIMEFRAME_OPTIONS.MONTHBYMONTHLASTPREVIOUS',
  [TimeFrameOptions.TWELVE_MONTHS_FLOATING]: 'TIMEFRAME_OPTIONS.YEARBYYEARFLOATING',
  [TimeFrameOptions.SIX_MONTHS_FLOATING]: 'TIMEFRAME_OPTIONS.SIXMONTHSFLOATING',
  [TimeFrameOptions.THREE_MONTHS_FLOATING]: 'TIMEFRAME_OPTIONS.THREEMONTHSFLOATING'
};

const WEEKLYCONSUMPTIONTIMEFRAMEOPTIONS: readonly TimeFrameOptions[] = [
  TimeFrameOptions.YEAR_BY_YEAR_CALENDAR,
  TimeFrameOptions.TWELVE_MONTHS_FLOATING,
  TimeFrameOptions.SIX_MONTHS_FLOATING,
  TimeFrameOptions.THREE_MONTHS_FLOATING
];

// These options will be excluded from the dropdown if the WidgetType is not FacilityWeeklyConsumption
const EXCLUDEDCONSUMPTIONTIMEFRAMEOPTIONS: readonly TimeFrameOptions[] = [
  TimeFrameOptions.TWELVE_MONTHS_FLOATING,
  TimeFrameOptions.SIX_MONTHS_FLOATING,
  TimeFrameOptions.THREE_MONTHS_FLOATING
];

@Component({
  selector: 'widget-timeframe-select',
  templateUrl: '../../../../shared/ek-inputs/ek-combo/ek-combo.component.html',
  styleUrls: ['../../../../shared/ek-inputs/ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WidgetTimeframeSelectComponent),
    multi: true
  }]
})
export class WidgetTimeframeSelectComponent extends EkComboComponent<TimeFrameOptions> implements AfterViewInit {
  @Input() public widgetType: WidgetType;

  public ngAfterViewInit(): void {
    this.items = getStringEnumValues(TimeFrameOptions).filterMap(
      option => this.widgetType === WidgetType.FacilityWeeklyConsumption ?
        WEEKLYCONSUMPTIONTIMEFRAMEOPTIONS.includes(option) :
        !EXCLUDEDCONSUMPTIONTIMEFRAMEOPTIONS.includes(option),
      option => ({
        value: option,
        text: timeframeTranslationKeys[option]
      })
    );
  }
}
