<div class="facility-header">
  <h1>{{ facility.FacilityInformation.RealEstateId }} - {{ facility.Name }}</h1>
  <div class="facility-header-buttons">
    <button
      *ngIf="showOpenFacilityButton"
      (click)="openReport()"
      class="button icon"
      [title]="'FACILITIES_REPORT.INSPECT' | translate"
    >
      <i class="far fa-eye"></i>
    </button>
    <facilities-popup-actions
      *ngIf="showActions"
      [actions]="facilityActions | slice:0:5"
      [actionType]="PopupActionType.ACTION"
      [moduleType]="PopupModuleType.FACILITY_REPORT"
      [facilityId]="facility?.facilityId"
      (addedAction)="addedAction.emit()"
    ></facilities-popup-actions>
    <facilities-popup-actions
      *ngIf="showComments"
      [actions]="facilityComments | slice:0:5"
      [actionType]="PopupActionType.COMMENT"
      [moduleType]="PopupModuleType.FACILITY_REPORT"
      [facilityId]="facility?.facilityId"
      (addedAction)="addedAction.emit()"
    ></facilities-popup-actions>
    <ng-container *ngIf="events?.alarms | facilityReportAlarms:facility?.facilityId as alarms">
      <facilities-popup-alarms
        *ngIf="alarms.length"
        [alarms]="alarms | slice:0:5"
        [facilityId]="facility?.facilityId"
      ></facilities-popup-alarms>
    </ng-container>
    <facilities-popup-documents [facility]="facility"></facilities-popup-documents>
    <facilities-popup-external-links [facility]="facility"></facilities-popup-external-links>
  </div>
</div>
<facility-group-info
  *ngFor="let section of visibleSections$ | async"
  [groupName]="section"
  [facility]="facility"
></facility-group-info>
