import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import * as datefns from 'date-fns';

import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';

import { BillingPeriod } from '../../models/contract-search-params';

type DateFn = (date: Date) => Date;
type DateCalcFn = (date: Date, n: number) => Date;

type Preset = { text: string; range: BillingPeriod };
type PresetGroup = { text: string; items: [Preset, Preset, Preset] };
type Presets = [PresetGroup, PresetGroup, PresetGroup];

export function getDefaultBillingPeriod(): BillingPeriod {
  return {
    from: datefns.startOfMonth(new Date()),
    to: datefns.endOfMonth(new Date()),
  };
}

@Component({
  selector: 'billing-period-picker',
  templateUrl: './billing-period-picker.component.html',
  styleUrls: ['./billing-period-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BillingPeriodPickerComponent),
    multi: true,
  }]
})
export class BillingPeriodPickerComponent implements ControlValueAccessor, OnDestroy {
  public readonly datePickerOptions: Partial<DatePickerComponent> = {
    bottomView: 'year',
    topView: 'year',
    format: 'MMM yyyy',
    navigation: false,
  };

  public readonly presets: Presets;

  public readonly formGroup: UntypedFormGroup;
  public readonly controls: ControlsOf<BillingPeriod>;

  private _onChange: (value: BillingPeriod) => void;
  private readonly disposable = new Subscription();

  public constructor() {
    this.presets = this.initializePresets();

    this.controls = formControlsFrom<BillingPeriod>(getDefaultBillingPeriod());
    this.formGroup = new UntypedFormGroup(this.controls);

    this.disposable.add(
      this.formGroup.valueChanges.subscribe((value: BillingPeriod) => {
        if (this._onChange) {
          this._onChange(value);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.disposable.unsubscribe();
  }

  public writeValue(value: BillingPeriod | null): void {
    this.formGroup.setValue(value || { from: null, to: null });
  }

  public registerOnChange(fn: (value: BillingPeriod) => void): void {
    this._onChange = fn;
  }

  /* istanbul ignore next */
  public registerOnTouched(): void { }

  public setDisabledState(isDisabled: boolean): void {
    if (this.formGroup.disabled !== isDisabled) {
      this.formGroup[isDisabled ? 'disable' : 'enable']();
    }
  }

  public presetClicked(range: BillingPeriod): void {
    this.formGroup.setValue(range);
  }

  public isActive(value: BillingPeriod): boolean {
    const current: BillingPeriod = this.formGroup.value;

    return datefns.isSameMonth(current.from, value.from)
      && datefns.isSameMonth(current.to, value.to);
  }

  private initializePresets(): Presets {
    return [
      this.getGroup('TIMESPAN.MONTH', datefns.startOfMonth, datefns.endOfMonth, datefns.addMonths),
      this.getGroup('TIMESPAN.QUARTER', datefns.startOfQuarter, datefns.endOfQuarter, datefns.addQuarters),
      this.getGroup('TIMESPAN.YEAR', datefns.startOfYear, datefns.endOfYear, datefns.addYears),
    ];
  }

  /**
   * Get timespan group.
   * @param text translation key of the whole group
   * @param startfn function to select the start of the range (ie. start of month)
   * @param endfn function to select the end of the range (ie. end of month)
   * @param addfn function to add one unit to the range (ie. add one month)
   */
  private getGroup(text: string, startfn: DateFn, endfn: DateFn, addfn: DateCalcFn): PresetGroup {
    const d = new Date();
    return {
      text,
      items: [
        { text: 'TIMESPAN.PREVIOUS', range: { from: startfn(addfn(d, -1)), to: endfn(addfn(d, -1)) } },
        { text: 'TIMESPAN.CURRENT', range: { from: startfn(d), to: endfn(d) } },
        { text: 'TIMESPAN.NEXT', range: { from: startfn(addfn(d, 1)), to: endfn(addfn(d, 1)) } },
      ]
    };
  }

}
