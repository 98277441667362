import { setHours, startOfDay } from 'date-fns';

import {
  Coordinate, EtCurveAnalyticResult, EtCurveData, EtCurveResultDto, DeviationType as InesDeviationType,
  OperationalHours, Resolution
} from '@enerkey/clients/ines';
import {
  EtCurveParametersCons, EtCurveParametersConsStatus, EtCurveRequestResolution, MetaData
} from '@enerkey/clients/ines-reports';

import { ISODuration } from '../../../shared/isoduration';
import { DeviationType, EnergyMeasurementType, EtCurveAdminParams, Point } from '../models/et-curve.model';

export class EtCurveModelConverter {
  public static deviationTypeToInesDeviationType(deviationType: DeviationType): InesDeviationType {
    switch (deviationType) {
      case DeviationType.PERCENT: return InesDeviationType.Percent;
      case DeviationType.KWH_M2: return InesDeviationType.SpecificCons;
      default: throw Error(`Invalid deviationType "${deviationType}"`);
    }
  }

  public static inesDeviationTypeToDeviationType(inesDeviationType: InesDeviationType): DeviationType {
    switch (inesDeviationType) {
      case InesDeviationType.Percent: return DeviationType.PERCENT;
      case InesDeviationType.SpecificCons: return DeviationType.KWH_M2;
      default: throw Error(`Invalid inesDeviationType "${inesDeviationType}"`);
    }
  }

  public static isoDurationToResolution(isoDuration: ISODuration): Resolution {
    switch (isoDuration) {
      case ISODuration.PT1H: return Resolution.Hourly;
      case ISODuration.P1D: return Resolution.Daily;
      case ISODuration.P7D: return Resolution.Weekly;
    }
  }

  public static isoDurationToEtCurveRequestResolution(isoDuration: ISODuration): EtCurveRequestResolution {
    switch (isoDuration) {
      case ISODuration.PT1H: return EtCurveRequestResolution.PT1H;
      case ISODuration.P1D: return EtCurveRequestResolution.P1D;
      case ISODuration.P7D: return EtCurveRequestResolution.P1W;
      default: return EtCurveRequestResolution.P1D;
    }
  }

  public static resolutionToIsoDuration(resolution: Resolution): ISODuration {
    switch (resolution) {
      case Resolution.Hourly: return ISODuration.PT1H;
      case Resolution.Daily: return ISODuration.P1D;
      case Resolution.Weekly: return ISODuration.P7D;
    }
  }

  public static inesPropertyIdToDataTypeAndAreaId(propertyId: number): {
    datatype: EnergyMeasurementType,
    specificId: number | null
  } {
    if ([-1, null, undefined].some(v => v === propertyId)) {
      return { datatype: EnergyMeasurementType.ENERGY_MEASUREMENT, specificId: null };
    }

    return { datatype: EnergyMeasurementType.SPECIFIC_ENERGY, specificId: propertyId };
  }

  public static dataTypeAndAreaIdToInesPropertyId(datatype: EnergyMeasurementType, specificId: number | null): number {
    if (datatype === EnergyMeasurementType.ENERGY_MEASUREMENT) {
      return -1;
    }

    return specificId;
  }

  public static etCurveParameterConsToPoints(etCurveData: Partial<EtCurveParametersCons>): Point[] {
    return [etCurveData.point1, etCurveData.point2, etCurveData.point3, etCurveData.point4].reduce((acc, point) =>
      point?.length === 2 ? [...acc, { temperature: point[0], value: point[1] }] : acc, []);
  }

  public static etCurveAnalyticResultToAdminParams(etCurve: EtCurveAnalyticResult): Partial<EtCurveAdminParams> {
    const invertHours = etCurve.operationalHours === OperationalHours.NonOperational;
    const opsHours = etCurve.operationalHours === OperationalHours.Operational;
    const cTimeFrom = EtCurveModelConverter.inesHourToDate(opsHours ? etCurve.etCurveData.startHour : 0);
    const cTimeTo = EtCurveModelConverter.inesHourToDate(opsHours ? etCurve.etCurveData.endHour : 0);

    return {
      id: etCurve.id,
      facilityId: etCurve.facilityId,
      quantity: etCurve.quantityId,
      name: etCurve.name,
      resolution: EtCurveModelConverter.resolutionToIsoDuration(etCurve.etCurveData.resolution),
      filterWeekend: etCurve.filterWeekend,
      startDate: etCurve.validFrom,
      opsHours: opsHours,
      calculationOpsHours: opsHours,
      calculationTimeFrom: cTimeFrom,
      calculationTimeTo: cTimeTo,
      calculationInvert: invertHours,
      showTimeFrom: cTimeFrom,
      showTimeTo: cTimeTo,
      invert: invertHours,
      deviation: etCurve.etCurveData.upperDeviation,
      deviationType: EtCurveModelConverter.inesDeviationTypeToDeviationType(etCurve.etCurveData.deviationType),
      xAxis0: etCurve.etCurveData.point1.x,
      yAxis0: etCurve.etCurveData.point1.y,
      xAxis1: etCurve.etCurveData.point2.x,
      yAxis1: etCurve.etCurveData.point2.y,
      xAxis2: etCurve.etCurveData.point3.x,
      yAxis2: etCurve.etCurveData.point3.y,
      xAxis3: etCurve.etCurveData.point4.x,
      yAxis3: etCurve.etCurveData.point4.y
    };
  }

  public static etCurveAnalyticResultToLoadingParams(
    etCurveResult: EtCurveAnalyticResult
  ): Partial<EtCurveAdminParams> {
    const etCurve = EtCurveModelConverter.etCurveAnalyticResultToAdminParams(etCurveResult);
    return {
      id: etCurve.id,
      name: etCurve.name,
      resolution: etCurve.resolution,
      filterWeekend: etCurve.filterWeekend,
      startDate: etCurve.startDate,
      calculationOpsHours: etCurve.calculationOpsHours,
      calculationTimeFrom: etCurve.calculationTimeFrom,
      calculationTimeTo: etCurve.calculationTimeTo,
      calculationInvert: etCurve.calculationInvert,
      deviation: etCurve.deviation,
      deviationType: etCurve.deviationType,
      xAxis0: etCurve.xAxis0,
      yAxis0: etCurve.yAxis0,
      xAxis1: etCurve.xAxis1,
      yAxis1: etCurve.yAxis1,
      xAxis2: etCurve.xAxis2,
      yAxis2: etCurve.yAxis2,
      xAxis3: etCurve.xAxis3,
      yAxis3: etCurve.yAxis3,
      opsHours: etCurve.calculationOpsHours,
      showTimeFrom: etCurve.calculationTimeFrom,
      showTimeTo: etCurve.calculationTimeTo,
      invert: etCurve.calculationInvert
    } as Partial<EtCurveAdminParams>;
  }

  public static etCurveAdminParamsToEtCurveResultDto(
    params: EtCurveAdminParams,
    metaData?: MetaData
  ): EtCurveResultDto {
    return new EtCurveResultDto({
      name: params.name,
      facilityId: params.facilityId,
      quantityId: params.quantity,
      filterWeekend: params.filterWeekend,
      validFrom: params.startDate,
      validTo: null,
      isActive: true,
      propertyId: EtCurveModelConverter.dataTypeAndAreaIdToInesPropertyId(params.datatype, params.specificId),
      propertyValue: params.specificValue,
      operationalHours: EtCurveModelConverter.etCurveAdminParamsToOperationalHours(params),
      etCurveData: EtCurveData.fromJS({
        point1: Coordinate.fromJS({ x: params.xAxis0, y: params.yAxis0 }),
        point2: Coordinate.fromJS({ x: params.xAxis1, y: params.yAxis1 }),
        point3: Coordinate.fromJS({ x: params.xAxis2, y: params.yAxis2 }),
        point4: Coordinate.fromJS({ x: params.xAxis3, y: params.yAxis3 }),
        resolution: EtCurveModelConverter.isoDurationToResolution(params.resolution),
        deviationType: EtCurveModelConverter.deviationTypeToInesDeviationType(params.deviationType),
        lowerDeviation: -(params.deviation),
        upperDeviation: params.deviation,
        invertHours: params.invert === true,
        startHour: params.opsHours ? params.calculationTimeFrom.getHours() : undefined,
        endHour: params.opsHours ? params.calculationTimeTo.getHours() : undefined,
        meta: {
          meterIds: metaData?.meterIds,
          dhValueCount: metaData?.dhValueCount,
          eleValueCount: metaData?.eleValueCount,
          waterValueCount: metaData?.waterValueCount,
          temperatureValueCount: metaData?.temperatureValueCount,
          windValueCount: metaData?.windValueCount,
          start: metaData?.start,
          end: metaData?.end
        }
      }),
    });
  }

  public static etCurveAdminParamsToEtCurveParametersCons(formValues: EtCurveAdminParams): EtCurveParametersCons {
    const point1 = [formValues.xAxis0, formValues.yAxis0].filter(a => Number.isFinite(a));
    const point2 = [formValues.xAxis1, formValues.yAxis1].filter(a => Number.isFinite(a));
    const point3 = [formValues.xAxis2, formValues.yAxis2].filter(a => Number.isFinite(a));
    const point4 = [formValues.xAxis3, formValues.yAxis3].filter(a => Number.isFinite(a));

    return (point1.length === 2 || point2.length === 2 || point3.length === 2 || point4.length === 2) ?
      {
        facilityId: formValues.facilityId,
        status: EtCurveParametersConsStatus.Success,
        consTemp: [],
        point1: point1.length === 2 ? point1 : [],
        point2: point2.length === 2 ? point2 : [],
        point3: point3.length === 2 ? point3 : [],
        point4: point4.length === 2 ? point4 : [],
        meta: undefined
      } as EtCurveParametersCons
      : undefined;
  }

  private static inesHourToDate(hour: number): Date {
    return setHours(startOfDay(new Date()), hour);
  }

  private static etCurveAdminParamsToOperationalHours(params: EtCurveAdminParams): OperationalHours {
    if (params.opsHours && !params.invert) { return OperationalHours.Operational; }
    if (params.opsHours && params.invert) { return OperationalHours.NonOperational; }
    return OperationalHours.Total;
  }
}
