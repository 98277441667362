import { SelectionItem } from '@progress/kendo-angular-treelist';

import { bignumber } from 'mathjs';

import { MeterManagementMeter } from '@enerkey/clients/meter-management';
import {
  MeterGroupBulkUpdateResultCollectionDto, MeterGroupDto, MeterGroupMeterDto, Quantities
} from '@enerkey/clients/metering';

import { quantityTranslations } from '../../../constants/quantity.constant';
import { ExtendedFacilityInformation } from '../../../shared/interfaces/extended-facility-information';
import { MeterGroupCategory, MeterGroupCategoryQuantities, MeterGroupError } from './meter-groups.model';

export abstract class MeterGroupGridItemBase {
  public abstract clone(children?: MeterGroupGridItemBase[]): MeterGroupGridItemBase;

  protected constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly children: MeterGroupGridItemBase[] = [],
    public isActive: boolean = false
  ) {}
}

export class MeterGroupGridItem extends MeterGroupGridItemBase {

  public static fromMeterGroupDto(
    meterGroup: MeterGroupDto,
    children: MeterGroupGridItemBase[] = []
  ): MeterGroupGridItem {
    return new MeterGroupGridItem(meterGroup.id, meterGroup.name, children, undefined, meterGroup.description);
  }

  public static generateTreeList(
    meterGroups: MeterGroupDto[],
    facilities: ExtendedFacilityInformation[] = [],
    meters: Record<number, MeterManagementMeter[]> = {}
  ): MeterGroupGridItem[] {
    const category = MeterGroupCategory.ENERGY;
    const quantityIds = MeterGroupCategoryQuantities.getByCategory(category);
    const meterGroupMetersMap = getActiveMeterGroupMetersMap(meterGroups);

    return meterGroups.map(meterGroup => MeterGroupGridItem.fromMeterGroupDto(
      meterGroup,
      facilities.map(facility => FacilityGridItem.fromExtendedFacilityInformation(
        facility,
        meters[facility.facilityId]
          .filter(meter => quantityIds.includes(meter.quantityId as Quantities))
          .map(meter => MeterGridItem
            .fromMeterManagementMeter(meter, meterGroupMetersMap[meterGroup.id]?.[meter.id])
            .withFacilityInformation(facility.facilityId, facility.name)
            .withMeterGroupInformation(meterGroup.id, meterGroup.name))
      ))
    ));
  }

  public constructor(
    public override readonly id: number,
    public override readonly name: string,
    public override readonly children: MeterGroupGridItemBase[],
    public override readonly isActive: boolean = false,
    public readonly description: string = undefined
  ) {
    super(id, name, children, isActive);
  }

  public override clone(children: MeterGroupGridItemBase[] = this.children): MeterGroupGridItem {
    return new MeterGroupGridItem(this.id, this.name, children?.map(c => c.clone()), this.isActive, this.description);
  }
}

export class FacilityGridItem extends MeterGroupGridItemBase {

  public static fromExtendedFacilityInformation(
    facility: ExtendedFacilityInformation,
    children: MeterGroupGridItemBase[] = []
  ): FacilityGridItem {
    return new FacilityGridItem(facility.facilityId, facility.name, children);
  }

  public static generateTreeList(
    facilities: ExtendedFacilityInformation[] = [],
    meterGroups: MeterGroupDto[] = [],
    meters: Record<number, MeterManagementMeter[]> = {}
  ): FacilityGridItem[] {
    const category = MeterGroupCategory.ENERGY;
    const quantityIds = MeterGroupCategoryQuantities.getByCategory(category);
    const meterGroupMetersMap = getActiveMeterGroupMetersMap(meterGroups);

    return facilities.map(facility => FacilityGridItem.fromExtendedFacilityInformation(
      facility,
      meterGroups.map(meterGroup => MeterGroupGridItem.fromMeterGroupDto(
        meterGroup,
        meters[facility.facilityId]
          .filter(meter => quantityIds.includes(meter.quantityId as Quantities))
          .map(meter => MeterGridItem
            .fromMeterManagementMeter(meter, meterGroupMetersMap[meterGroup.id]?.[meter.id])
            .withFacilityInformation(facility.facilityId, facility.name)
            .withMeterGroupInformation(meterGroup.id, meterGroup.name))
      ))
    ));
  }

  public constructor(
    public override readonly id: number,
    public override readonly name: string,
    public override readonly children: MeterGroupGridItemBase[],
    public override readonly isActive: boolean = false
  ) {
    super(id, name, children, isActive);
  }

  public override clone(children: MeterGroupGridItemBase[] = this.children): FacilityGridItem {
    return new FacilityGridItem(this.id, this.name, children?.map(c => c.clone()), this.isActive);
  }
}

export class MeterGridItem extends MeterGroupGridItemBase {

  public static fromMeterManagementMeter(meter: MeterManagementMeter, mgMeter?: MeterGroupMeterDto): MeterGridItem {
    const quantity = `${quantityTranslations[meter.quantityId as Quantities]}`;
    const quantityGroupId = 0;
    const hasDefinedWeight = Number.isFinite(mgMeter?.meterId) && Number.isFinite(mgMeter.weight);
    const percentage = hasDefinedWeight ? bignumber(mgMeter.weight).mul(100).abs().toNumber() : undefined;
    const subtract = hasDefinedWeight ? mgMeter.weight < 0 : undefined;

    return new MeterGridItem(
      meter.id,
      meter.name,
      quantity,
      quantityGroupId,
      meter.meteringType,
      percentage,
      subtract,
      Number.isFinite(mgMeter?.meterId)
    );
  }

  public facilityId: number | undefined;
  public facilityName: string | undefined;

  public meterGroupId: number | undefined;
  public meterGroupName: string | undefined;

  public constructor(
    public override readonly id: number,
    public override readonly name: string,
    public readonly quantity: string,
    public readonly quantityGroupId: number,
    public readonly meterTypeId: number,
    public readonly percentage: number,
    public readonly subtract: boolean,
    public override readonly isActive: boolean
  ) {
    super(id, name, undefined, isActive);
  }

  public get meterId(): number { return this.id; }

  public override clone(): MeterGridItem {
    return new MeterGridItem(
      this.id,
      this.name,
      this.quantity,
      this.quantityGroupId,
      this.meterTypeId,
      this.percentage,
      this.subtract,
      this.isActive
    )
      .withFacilityInformation(this.facilityId, this.facilityName)
      .withMeterGroupInformation(this.meterGroupId, this.meterGroupName);
  }

  public withFacilityInformation(facilityId: number, facilityName: string): MeterGridItem {
    this.facilityId = facilityId;
    this.facilityName = facilityName;

    return this;
  }

  public withMeterGroupInformation(meterGroupId: number, meterGroupName: string): MeterGridItem {
    this.meterGroupId = meterGroupId;
    this.meterGroupName = meterGroupName;

    return this;
  }
}

export class MeterGroupSelectionItem implements SelectionItem {
  public constructor(public readonly itemKey: string) {}
}

export class MeterGroupWithMeters {
  public constructor(
    public readonly meterGroupId: number,
    public readonly meters: MeterGroupMeter[]
  ) {}

  public withActiveMetersOnly(): MeterGroupWithMeters {
    return new MeterGroupWithMeters(
      this.meterGroupId,
      this.meters.filter(meter => meter.active)
    );
  }
}

export type MeterGroupMeter = {
  id: number;
  weight: number;
  active?: boolean;
}

export type MeterGroupMeterUpdateActions = {
  add: MeterGroupWithMeters[];
  remove: MeterGroupWithMeters[];
}

export type MeterGroupMeterUpdateActionsResult = {
  added: MeterGroupBulkUpdateResultCollectionDto | MeterGroupError,
  removed: MeterGroupBulkUpdateResultCollectionDto | MeterGroupError
}

function getActiveMeterGroupMetersMap(
  meterGroups: MeterGroupDto[]
): Record<number, Record<number, MeterGroupMeterDto>> {
  return meterGroups
    .filter(meterGroup => meterGroup.meters?.length)
    .reduce((acc, meterGroup) => {
      if (!acc[meterGroup.id]) { acc[meterGroup.id] = {}; }

      meterGroup.meters.forEach(meter => {
        if (!acc[meterGroup.id][meter.meterId]) { acc[meterGroup.id][meter.meterId] = meter; }
      });

      return acc;
    }, {} as Record<number, Record<number, MeterGroupMeterDto>>);
}
