import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { LogLiteDto } from '@enerkey/clients/alarm';

import { ModalService } from '@enerkey/foundation-angular';

import { StateLocationService } from '../../../../services/state-location.service';

import { AlarmDetailsModalComponent } from '../../../../shared/alarms-shared/components/alarm-details-modal/alarm-details-modal.component';

@Component({
  selector: 'facilities-popup-alarms',
  templateUrl: './facilities-popup-alarms.component.html',
  styleUrls: ['./facilities-popup-alarms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilitiesPopupAlarmsComponent implements OnInit {
  @Input() public alarms: LogLiteDto[];
  @Input() public facilityId: number;
  @Input() public meterId: number;

  public titleTranslationKey = 'FACILITIES_REPORT.LATEST_ALARMS_POPUP_TITLE';

  public constructor(
    private readonly stateLocationService: StateLocationService,
    private readonly modalService: ModalService
  ) { }

  public ngOnInit(): void {
    if (this.meterId) {
      this.titleTranslationKey = 'FACILITIES_REPORT.LATEST_METER_ALARMS_POPUP_TITLE';
    }
  }

  public goToAlarms(): void {
    const params = {
      reportingObjectId: this.facilityId,
      automaticSearch: true,
      meterId: this.meterId
    };
    this.stateLocationService.openStateInNewTab('energy-management.alarms', params);
  }

  public openAlarmModal(alarmId: number): void {
    const modalInstance = this.modalService.open(AlarmDetailsModalComponent);
    modalInstance.componentInstance.getAlarmByLogId(alarmId);
  }
}
