import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';

import { ReportingMeterSelection } from '../shared/reporting-meter-selection';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ReportingMeterDataService } from './reporting-meter-data.service';
import { ReportingSeriesService } from './reporting-series.service';
import { ReportType } from '../shared/report-type';

@Injectable({
  providedIn: 'root'
})
export class PeriodMeterReportService {
  public constructor(
    private readonly reportingMeterDataService: ReportingMeterDataService,
    private readonly reportingSeriesService: ReportingSeriesService
  ) {
  }

  public getData(
    params: ReportingSearchParams,
    facilityId: number,
    meters: ReportingMeterSelection,
    incompleteThreshold: number,
    averageQuantities: Quantities[]
  ): Observable<ReportingSeriesByFacility> {
    return forkJoin({
      measured: forkJoin([
        this.reportingMeterDataService.getMeasuredValues(
          ReportType.Period,
          params,
          facilityId,
          meters,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'column', lineOpacity: 0.85
            })),
          }
        ),
      ]),
      normalized: forkJoin([
        this.reportingMeterDataService.getNormalizedValues(
          ReportType.Period,
          params,
          facilityId,
          meters,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'column', lineOpacity: 0.85
            })),
          }
        ),
      ]),
      meterBasedCosts: forkJoin([
        this.reportingMeterDataService.getMeterBasedCosts(
          params,
          [facilityId],
          meters,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line', lineOpacity: 0.85
            })),
          }
        ),
      ]),
      nationalCosts: forkJoin([
        this.reportingMeterDataService.getNationalBasedCostsForMeter(
          params,
          [facilityId],
          meters,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line', lineOpacity: 0.85
            })),
          }
        ),
      ]),
      temperatures: this.reportingMeterDataService.getTemperature(params, [facilityId], meters),
      measuredTargets: this.reportingMeterDataService.getMeasuredTargetsForMeter(params, facilityId, meters),
      normalizedTargets: this.reportingMeterDataService.getNormalizedTargetsForMeter(params, facilityId, meters),
    }).pipe(
      map(({
        measured,
        normalized,
        meterBasedCosts,
        nationalCosts,
        temperatures,
        measuredTargets,
        normalizedTargets
      }) => this.reportingSeriesService.mapValuesById({
        ids: meters.meterIds,
        measured: measured.flat(),
        normalized: normalized.flat(),
        incompleteThreshold,
        meterBasedCosts: meterBasedCosts.flat(),
        nationalCosts: nationalCosts.flat(),
        comparability: params.comparability,
        periods: params.periods,
        resolution: params.resolution,
        searchPeriods: params.searchPeriods,
        temperatures: temperatures,
        measuredTargets: measuredTargets,
        normalizedTargets: normalizedTargets,
        averageQuantities
      }))
    );
  }
}
