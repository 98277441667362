<ul>
  <li *ngFor="let preset of presets">
    {{ preset.text | translate }}:
    <ng-container *ngFor="let item of preset.items; first as isFirst">
      <span *ngIf="!isFirst"> | </span>
      <a
        [class.active]="isActive(item.range)"
        (click)="presetClicked(item.range)"
      >{{ item.text | translate | lowercase }}</a>
    </ng-container>
  </li>
</ul>

<div class="range">
  <div *ngIf="controls">
    <kendo-datepicker
      [formControl]="$any(controls.from)"
      [bottomView]="datePickerOptions.bottomView"
      [topView]="datePickerOptions.topView"
      [format]="datePickerOptions.format"
      [navigation]="datePickerOptions.navigation"
      *labelBefore="'TIMESPAN.START' | translate"
    ></kendo-datepicker>
  </div>

  <div>
    <kendo-datepicker
      [formControl]="$any(controls.to)"
      [bottomView]="datePickerOptions.bottomView"
      [topView]="datePickerOptions.topView"
      [format]="datePickerOptions.format"
      [navigation]="datePickerOptions.navigation"
      *labelBefore="'TIMESPAN.END' | translate"
    ></kendo-datepicker>
  </div>
</div>
