import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgForm, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import moment from 'moment';

import { indicate, ofVoid } from '@enerkey/rxjs';
import { formGroupFrom } from '@enerkey/ts-utils';
import {
  ContractClient,
  IContractProduct,
  UpdateContractProduct,
} from '@enerkey/clients/contract';
import { ModalBase, ModalOptions, ModalService, NgfActiveModal } from '@enerkey/foundation-angular';

import { ContractRow } from '../../models/contract-row';
import { kendoNumericTextboxOptions } from '../../models/constants';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { localToUtc } from '../../../../shared/date.functions';
import {
  ContractFields,
  ContractInputDialogComponent,
} from '../contract-input-dialog/contract-input-dialog.component';

export interface ContractEditForm {
  id: number;
  contractId: string;
  productId: string;
  fromDate: Date;
  toDate: Date;
  unitPrice: number;
  unitCount: number;

  facilityId: number;
  facilityName: string;
  remove: boolean;
}

@Component({
  selector: 'contract-edit-modal',
  templateUrl: './contract-edit-modal.component.html',
  styleUrls: ['./contract-edit-modal.component.scss']
})
@ModalOptions({ size: 'large' })
export class ContractEditModalComponent extends ModalBase implements OnDestroy {

  @ViewChild('editForm', { static: true }) public readonly editForm: NgForm;

  public readonly numberInputOpts: Partial<NumericTextBoxComponent> = kendoNumericTextboxOptions;

  public readonly formGroup: UntypedFormGroup;
  public readonly formArray: UntypedFormArray;

  public readonly loading$: Observable<boolean>;

  private get contracts(): ContractEditForm[] {
    return this._contracts;
  }

  private set contracts(value: ContractEditForm[]) {
    this._contracts = value ?? [];
    this.updateFormControls();
  }

  private _contracts: ContractEditForm[] = [];

  private readonly _loading = new Subject<boolean>();

  public constructor(
    activeModal: NgfActiveModal,
    private readonly contractClient: ContractClient,
    private readonly toaster: ToasterService,
    private readonly modalService: ModalService
  ) {
    super(activeModal);
    this.formArray = new UntypedFormArray([]);
    this.formGroup = new UntypedFormGroup({ contracts: this.formArray });

    this.loading$ = this._loading.asObservable();
  }

  public ngOnDestroy(): void {
    this._loading.complete();
  }

  public onSubmit(): void {
    const values: ContractEditForm[] = this.formGroup.value.contracts;

    this.getManageTasks(values)
      .pipe(indicate(this._loading))
      .subscribe(() => super.closeModal());
  }

  public dismiss(): void {
    super.dismissModal();
  }

  public rowEdit(field: keyof ContractFields): void {
    const modal = this.modalService.open(ContractInputDialogComponent);
    modal.componentInstance.editedField = field;
    modal.result.resolved(value => {
      for (let i = 0; i < this.formArray.length; i++) {
        this.formArray.at(i).patchValue({ [field]: value });
      }
    });
  }

  public deleteToggled(index: number, checked: boolean): void {
    const row = this.formArray.at(index);
    const action = checked ? 'disable' : 'enable';

    row.get('contractId')[action]();
    row.get('productId')[action]();
    row.get('unitCount')[action]();
    row.get('unitPrice')[action]();
    row.get('fromDate')[action]();
    row.get('toDate')[action]();
  }

  public setContracts(rows: ContractRow[]): void {
    this.contractClient.getContractProductsByIds(rows.map(row => row.id)).pipe(
      indicate(this._loading)
    ).subscribe(
      result => {
        this.contracts = result.joinLeft(
          rows,
          c => c.facilityId,
          r => r.facility.id,
          (c, r) => this.toEditForm(c, r.facility.displayName)
        );
      }
    );
  }

  private getManageTasks(values: ContractEditForm[]): Observable<unknown> {
    const deletes$ = this.contractClient.deleteContractProducts(
      values.filterMap(c => c.remove, c => c.id)
    ).pipe(
      catchError(() => {
        this.toaster.error('ADMIN.CONTRACTS.DELETE_FAILED');
        return ofVoid();
      })
    );

    const edits$ = this.contractClient.updateContractProducts(
      values.filterMap(c => !c.remove, c => this.toUpdateContract(c))
    ).pipe(
      catchError(() => {
        this.toaster.error('ADMIN.CONTRACTS.EDIT_FAILED');
        return ofVoid();
      })
    );

    return forkJoin([deletes$, edits$]);
  }

  private toUpdateContract(form: ContractEditForm): UpdateContractProduct {
    return new UpdateContractProduct({
      id: form.id,
      facilityId: form.facilityId,
      productId: form.productId,
      contractId: form.contractId,
      unitCount: form.unitCount,
      unitPrice: form.unitPrice,
      fromDate: form.fromDate ? moment(localToUtc(form.fromDate)) : null,
      toDate: form.toDate ? moment(localToUtc(form.toDate)) : null,
    });
  }

  private toEditForm(
    contract: IContractProduct,
    facilityName: string
  ): ContractEditForm {
    return {
      id: contract.id,
      contractId: contract.contractId,
      productId: contract.productId,
      unitCount: contract.unitCount,
      unitPrice: contract.unitPrice,
      fromDate: contract.fromDate?.toDate(),
      toDate: contract.toDate?.toDate(),
      facilityId: contract.facilityId,
      facilityName,
      remove: false,
    };
  }

  private updateFormControls(): void {
    this.formArray.clear();

    for (const contract of this.contracts.sortByMany('facilityId', 'id')) {
      this.formArray.push(formGroupFrom<ContractEditForm>(
        contract,
        {
          contractId: Validators.required,
          productId: Validators.required,
          unitCount: Validators.min(0),
          unitPrice: Validators.min(0),
          fromDate: Validators.required,
        }
      ));
    }
  }
}
