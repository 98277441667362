import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ExcelModule as TreeListExcelModule, TreeListModule } from '@progress/kendo-angular-treelist';
import { GridsterModule } from 'angular-gridster2';

import { AccordionModule, NgfTabsetModule, NgfTooltipModule } from '@enerkey/foundation-angular';
import { EkFormsModule } from '@enerkey/ek-forms';

import { ReportingBaseComponent } from './components/reporting-base/reporting-base.component';
import { reportingStates } from './reporting.states';
import { KendoGridChangeColumnComponent } from './components/kendo-grid-change-column/kendo-grid-change-column.component';
import { ReportingChartComponent } from './components/reporting-chart/reporting-chart.component';
import { TableReportComponent } from './components/table-report/table-report.component';
import { KendoGridQuantityConsumptionColumnComponent } from './components/kendo-grid-quantity-consumption-column/kendo-grid-quantity-consumption-column.component';
import { AlarmsSharedModule } from '../../shared/alarms-shared/alarms-shared.module';
import { CommonPipesModule } from '../../shared/common-pipes/common-pipes.module';
import { EkDropdownModule } from '../../shared/ek-dropdown/ek-dropdown.module';
import { EkInputsModule } from '../../shared/ek-inputs/ek-inputs.module';
import { EnergyManagementSharedModule } from '../../shared/energy-management-shared/energy-management-shared.module';
import { EnergyReportingSharedModule } from '../../shared/energy-reporting-shared/energy-reporting-shared.module';
import { ForecastReportComponent } from './components/forecast-report/forecast-report.component';
import { ChartNotePopupComponent } from './components/chart-note-popup/chart-note-popup.component';
import { FacilitiesPopupExternalLinksComponent } from './components/facilities-popup-external-links/facilities-popup-external-links.component';
import { FacilitiesPopupAlarmsComponent } from './components/facilities-popup-alarms/facilities-popup-alarms.component';
import { EkLoadingModule } from '../../shared/ek-loading/ek-loading.module';
import { ReportColumnHeaderComponent } from './components/report-column-header/report-column-header.component';
import { EkKendoModule } from '../../shared/ek-kendo/ek-kendo.module';
import { SharedModule } from '../../shared/shared.module';
import { PeriodIndicatorColorPipe } from './pipes/period-indicator-color.pipe';
import { ShouldShowQuantitySectionPipe } from './pipes/should-show-quantity-section.pipe';
import { FacilityReportHeaderComponent } from './components/facility-report-header/facility-report-header.component';
import { ReportingGridColumnGroupTitlePipe } from './pipes/reporting-grid-column-group-title.pipe';
import { TimeFrameTitlePipe } from './pipes/time-frame-title.pipe';
import { ConsumptionValueFlagClassPipe } from './pipes/consumption-value-flag-class.pipe';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ReportingSearchFormComponent } from './components/reporting-search-form/reporting-search-form.component';
import { ReportingVisibleSectionsSelectComponent } from './components/reporting-visible-sections-select/reporting-visible-sections-select.component';
import { DerivedValueSelectComponent } from './components/derived-value-select/derived-value-select.component';
import { TargetMultiSelectComponent } from './components/target-multi-select/target-multi-select.component';
import { ReportingGridComponent } from './components/reporting-grid/reporting-grid.component';
import { ReportingChartMinMaxPipe } from './pipes/reporting-chart-min-max.pipe';
import { PeriodReportComponent } from './components/period-report/period-report.component';
import { ChartSeriesStackGroupPipe } from './pipes/chart-series-stack-group.pipe';
import { TrendReportComponent } from './components/trend-report/trend-report.component';
import { SumReportComponent } from './components/sum-report/sum-report.component';
import { SumReportFacilitiesCardComponent } from './components/sum-report-facilities-card/sum-report-facilities-card.component';
import { ReportModalComponent } from './components/report-modal/report-modal.component';
import { ModalQuantityReportsComponent } from './components/modal-quantity-reports/modal-quantity-reports.component';
import { FacilityOverviewComponent } from './components/facility-overview/facility-overview.component';
import { OverviewCardContentComponent } from './components/overview-card-content/overview-card-content.component';
import { OverviewReportActionsComponent } from './components/overview-report-actions/overview-report-actions.component';
import { OverviewReportCommentsComponent } from './components/overview-report-comments/overview-report-comments.component';
import { OverviewReportFacilityInfoComponent } from './components/overview-report-facility-info/overview-report-facility-info.component';
import { OverviewCardFieldComponent } from './components/overview-card-field/overview-card-field.component';
import { ReportingGridColumnFooterComponent } from './components/reporting-grid-column-footer/reporting-grid-column-footer.component';
import { ModalMeterReportsComponent } from './components/modal-meter-reports/modal-meter-reports.component';
import { ModalReportTypeComponent } from './components/modal-report-type/modal-report-type.component';
import { MeterTreeComponent } from './components/meter-tree/meter-tree.component';
import { PeriodMeterReportComponent } from './components/period-meter-report/period-meter-report.component';
import { MeterInfoComponent } from './components/meter-info/meter-info.component';
import { MeterReportHeaderComponent } from './components/meter-report-header/meter-report-header.component';
import { MeterReportActionsPipe } from './pipes/meter-report-actions.pipe';
import { MeterReportAlarmsPipe } from './pipes/meter-report-alarms.pipe';
import { SumMeterReportComponent } from './components/sum-meter-report/sum-meter-report.component';
import { ForecastMeterReportComponent } from './components/forecast-meter-report/forecast-meter-report.component';
import { TrendMeterReportComponent } from './components/trend-meter-report/trend-meter-report.component';
import { SumReportMetersCardComponent } from './components/sum-report-meters-card/sum-report-meters-card.component';
import { MeterTableReportComponent } from './components/meter-table-report/meter-table-report.component';
import { ReportChangeColumnHeaderComponent } from './components/report-change-column-header/report-change-column-header.component';
import { TableReportValueFormatPipe } from './pipes/table-report-value-format.pipe';
import { TableReportFieldNamePipe } from './pipes/table-report-field-name.pipe';
import { ReportContentComponent } from './components/report-content/report-content.component';
import { ChangeValueFlagAggregatePipe } from './pipes/change-value-flag-aggregate.pipe';
import { ValueFlagCellComponent } from './components/value-flag-cell/value-flag-cell.component';
import { QuantityTitlePipe } from './pipes/quantity-title.pipe';
import { TableReportFacilityNameColumnComponent } from './components/table-report-facility-name-column/table-report-facility-name-column.component';
import { TimePeriodHistoryDropdownComponent } from './components/time-period-history-dropdown/time-period-history-dropdown.component';
import { SelectableResolutionTitlePipe } from './pipes/selectable-resolution-title.pipe';
import { AdminSharedModule } from '../../shared/admin-shared/admin-shared.module';
import { MeterEditModalComponent } from './components/meter-edit-modal/meter-edit-modal.component';
import { MeterTableReportAggregatesComponent } from './components/meter-table-report-aggregates/meter-table-report-aggregates.component';
import { QuantityGraphActionsComponent } from './components/quantity-graph-actions/quantity-graph-actions.component';
import { StaticSettingsComponent } from './components/static-settings/static-settings.component';
import { FacilityReportAlarmsPipe } from './pipes/facility-report-alarms.pipe';

@NgModule({
  declarations: [
    ForecastReportComponent,
    ReportingChartComponent,
    KendoGridChangeColumnComponent,
    KendoGridQuantityConsumptionColumnComponent,
    ReportingBaseComponent,
    TableReportComponent,
    ChartNotePopupComponent,
    FacilitiesPopupExternalLinksComponent,
    FacilitiesPopupAlarmsComponent,
    ReportColumnHeaderComponent,
    PeriodIndicatorColorPipe,
    ShouldShowQuantitySectionPipe,
    FacilityReportHeaderComponent,
    ReportingGridColumnGroupTitlePipe,
    TimeFrameTitlePipe,
    ConsumptionValueFlagClassPipe,
    SidebarComponent,
    ReportingSearchFormComponent,
    ReportingVisibleSectionsSelectComponent,
    DerivedValueSelectComponent,
    TargetMultiSelectComponent,
    ReportingGridComponent,
    ReportingChartMinMaxPipe,
    PeriodReportComponent,
    ChartSeriesStackGroupPipe,
    TrendReportComponent,
    SumReportComponent,
    SumReportFacilitiesCardComponent,
    ReportModalComponent,
    ModalQuantityReportsComponent,
    FacilityOverviewComponent,
    OverviewCardContentComponent,
    OverviewReportActionsComponent,
    OverviewReportCommentsComponent,
    OverviewReportFacilityInfoComponent,
    OverviewCardFieldComponent,
    ReportingGridColumnFooterComponent,
    ModalMeterReportsComponent,
    ModalReportTypeComponent,
    MeterTreeComponent,
    PeriodMeterReportComponent,
    MeterInfoComponent,
    MeterReportHeaderComponent,
    MeterReportActionsPipe,
    MeterReportAlarmsPipe,
    SumMeterReportComponent,
    ForecastMeterReportComponent,
    TrendMeterReportComponent,
    SumReportMetersCardComponent,
    MeterTableReportComponent,
    ReportChangeColumnHeaderComponent,
    TableReportValueFormatPipe,
    TableReportFieldNamePipe,
    ReportContentComponent,
    ChangeValueFlagAggregatePipe,
    ValueFlagCellComponent,
    QuantityTitlePipe,
    TableReportFacilityNameColumnComponent,
    TimePeriodHistoryDropdownComponent,
    SelectableResolutionTitlePipe,
    MeterEditModalComponent,
    MeterTableReportAggregatesComponent,
    QuantityGraphActionsComponent,
    StaticSettingsComponent,
    FacilityReportAlarmsPipe
  ],
  imports: [
    SharedModule,
    CommonModule,
    TranslateModule,
    ChartsModule,
    EkInputsModule,
    GridModule,
    IntlModule,
    InputsModule,
    ExcelModule,
    TreeListExcelModule,
    EkFormsModule,
    DateInputsModule,
    EkDropdownModule,
    CommonPipesModule,
    EnergyReportingSharedModule,
    EnergyManagementSharedModule,
    AlarmsSharedModule,
    NgfTooltipModule,
    PopupModule,
    EkLoadingModule,
    EkKendoModule,
    ButtonsModule,
    NgfTabsetModule,
    GridsterModule,
    TreeViewModule,
    TreeListModule,
    UIRouterUpgradeModule.forChild({
      states: reportingStates
    }),
    AdminSharedModule,
    AccordionModule
  ]
})
export class ReportingModule {
}
