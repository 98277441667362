import moment from 'moment';

import { hasChanges, isFirstChange } from '@enerkey/angular-utils';

import { getMeterInfoString } from './manual-qa-meter-facility-info.functions';
import { Roles } from '../../../admin/constants/roles';
import { getDefaultReportingParams } from '../../../reporting/services/reporting.search.functions';

const $inject = [
  'utils',
  '$state',
  'facilities',
  'ManualQaApiReaderTypeService',
  'StateLocationService',
  'TerminalService',
  'UserService',
  'reportingModalsService'
];

const translations = [
  'MQA.INSPECT.TWO_TIME',
  'MQA.INSPECT.ONE_TIME',
  'MQA.INSPECT.FACTOR',
  'MQA.INSPECT.AUTOMATIC_MODELING',
  'MQA.INSPECT.NO_AUTOMATIC_MODELING'
];

class ManualQaMeterFacilityInfoController {
  constructor(
    utils,
    $state,
    facilityService,
    manualQaApiReaderTypeService,
    stateLocationService,
    terminalService,
    userService,
    reportingModalsService
  ) {
    this.utils = utils;
    this.$state = $state;
    this.facilityService = facilityService;
    this.manualQaApiReaderTypeService = manualQaApiReaderTypeService;
    this.stateLocationService = stateLocationService;
    this.terminalService = terminalService;
    this.userService = userService;
    this.reportingModalsService = reportingModalsService;

    this.facilities = {};
    this.readerTypeInformation = null;
    this.isLinkDisabled = true;
    this.showReadingStartTime = false;
  }

  $onInit() {
    this.translations = this.utils.mapLocalizationTagArrayToLocalizedObject(translations);
    this.getFacilities();
    this.updateInformation();
  }

  $onChanges(changes) {
    if (hasChanges(changes, 'meter') && !isFirstChange(changes, 'meter')) {
      this.updateInformation();
    }

    if (hasChanges(changes, 'timeFrame')) {
      this.updateReadingStart();
    }
  }

  updateInformation() {
    this.getReaderType();
    this.meterInfoString = getMeterInfoString(this.meter, this.translations);
    this.updateLinkState();
  }

  updateLinkState() {
    if (!this.meter) {
      this.isLinkDisabled = true;
    } else {
      const facilityId = this.meter.getFacility().getId();
      this.isLinkDisabled = !(
        this.facilities[facilityId] || this.userService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES)
      );
    }
  }

  updateReadingStart() {
    if (this.meter && this.timeFrame) {
      // TODO: re-assess after ER client actually uses moments and not strings
      this.showReadingStartTime = this.timeFrame.contains(moment(this.meter.automaticReadingStartTime));
    } else {
      this.showReadingStartTime = false;
    }
  }

  goToMeterTree(meter) {
    const searchParams = {
      formValue: {
        ...getDefaultReportingParams(),
        periods: [this.timeFrame.fromDate.toDate()],
        durationName: 'months',
        resolution: 'PT1H',
      }
    };
    this.reportingModalsService.openReport(
      meter.getFacility().getId(),
      searchParams,
      'period',
      [meter.getId()]
    );
  }

  goToFacilityReport(meter) {
    const searchParams = {
      formValue: {
        ...getDefaultReportingParams(),
        periods: [this.timeFrame.fromDate.toDate()],
        durationName: 'months',
        resolution: 'PT1H',
        quantityIds: [meter.getQuantity().getId()],
      }
    };
    this.reportingModalsService.openReport(
      meter.getFacility().getId(),
      searchParams
    );
  }

  getFacilities() {
    this.facilityService.getFacilities().then(facilities => {
      this.facilities = facilities.reduce((result, facility) => ({ ...result, [facility.FacilityId]: facility }), {});
    });
  }

  getReaderType() {
    if (!this.meter) {
      return;
    }

    this.readerTypeInformation = null;

    return this.manualQaApiReaderTypeService
      .getReaderTypeInformation(this.meter)
      .then(readerTypeInformation => {
        this.readerTypeInformation = readerTypeInformation;
      });
  }

  showTerminalStatus(terminalName) {
    this.terminalService.getTerminalStatusModal(terminalName);
  }
}

ManualQaMeterFacilityInfoController.$inject = $inject;

export default ManualQaMeterFacilityInfoController;
