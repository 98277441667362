import { IInesClient } from './ines-client';

export class InesClientMock implements IInesClient {
  public getElectricityBaseloadOptimisationInsights(): never {
    throw new Error('Method not implemented.');
  }
  public getElectricityBaseloadOptimisationInsight(): never {
    throw new Error('Method not implemented.');
  }
  public getElectricityPeakShavingInsights(): never {
    throw new Error('Method not implemented.');
  }
  public getElectricityPeakShavingInsight(): never {
    throw new Error('Method not implemented.');
  }
  public deleteEtCurveResults(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingPeakShavingInsights(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingPeakShavingInsight(): never {
    throw new Error('Method not implemented.');
  }
  public getSolarPowerInsights(): never {
    throw new Error('Method not implemented.');
  }
  public getSolarPowerInsight(): never {
    throw new Error('Method not implemented.');
  }
  public getVentilationEmbeddedReport(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatBalanceInsight(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingPowerInsight(): never {
    throw new Error('Method not implemented.');
  }
  public facilityRunCalculation(): never {
    throw new Error('Method not implemented.');
  }
  public getVentilationInsight(): never {
    throw new Error('Method not implemented.');
  }
  public getElectricityDemandResponseInsight(): never {
    throw new Error('Method not implemented.');
  }
  public setAnalyticsState(): never {
    throw new Error('Method not implemented.');
  }
  public getProfileAnalyticsReport(): never {
    throw new Error('Method not implemented.');
  }
  public saveAnalyticsResult(): never {
    throw new Error('Method not implemented.');
  }
  public getBenchmarkModelsAvailability(): never {
    throw new Error('Method not implemented.');
  }
  public getBenchmarkModel(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatBalanceInsights(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingPowerInsights(): never {
    throw new Error('Method not implemented.');
  }
  public getVentilationInsights(): never {
    throw new Error('Method not implemented.');
  }
  public getElectricityDemandResponseInsights(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingOptimizationInsight(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingOptimizationInsights(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingCoolingInsights(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingCoolingInsight(): never {
    throw new Error('Method not implemented.');
  }
  public getEtCurve(): never {
    throw new Error('Method not implemented.');
  }
  public saveEtCurveResult(): never {
    throw new Error('Method not implemented.');
  }
  public updateEtCurveResult(): never {
    throw new Error('Method not implemented.');
  }
  public getLatestEtCurve(): never {
    throw new Error('Method not implemented.');
  }
  public checkEtCurveExists(): never {
    throw new Error('Method not implemented.');
  }
}
