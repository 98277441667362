import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

import { ProfileService } from '../../../../shared/services/profile.service';
import { UserService } from '../../../../services/user-service';
import { Service } from '../../../../constants/service';
import { StateLocationService } from '../../../../services/state-location.service';

@Component({
  selector: 'try-new-reporting-banner',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './try-new-reporting-banner.component.html',
  styleUrl: './try-new-reporting-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TryNewReportingBannerComponent implements OnDestroy {
  public showBanner$: Observable<boolean>;

  private _showBanner$ = new BehaviorSubject<boolean>(false);
  private _destroy$ = new Subject<void>();

  public constructor(
    private readonly profileService: ProfileService,
    private readonly userService: UserService,
    private readonly stateLocationService: StateLocationService
  ) {
    this.showBanner$ = this._showBanner$.asObservable();

    this.profileService.profile$.pipe(takeUntil(this._destroy$))
      .subscribe(() => this._showBanner$.next(this.userService.hasService(Service.ReportingBeta)));
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._showBanner$.complete();
  }

  public openNewReporting(): void {
    this.stateLocationService.openStateInNewTab('reporting');
  }

  public closeBanner(): void {
    this._showBanner$.next(false);
  }
}
