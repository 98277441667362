import { isWithinInterval } from 'date-fns';

import { LogLiteDto, LogStatus } from '@enerkey/clients/alarm';
import { ActionSlimViewModel, Investigation } from '@enerkey/clients/attachments';
import { Quantities } from '@enerkey/clients/metering';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { getIntervalsByResolution } from '../shared/reporting.functions';

export interface ReportEventContainer {
  value: number;
  events: {
    actions: ReportingActionEvent[];
    comments: ReportingActionEvent[];
    alarms: LogLiteDto[];
  };
}

export type ReportingActionEvent =
  Pick<ActionSlimViewModel, 'actionType'
  | 'effectStartsAt'
  | 'effectStopsAt'
  | 'reportedDescription'
  | 'reportingObjectId'
  | 'meterIds'
  | 'investigation'
  | 'quantityIds'> & { startMatch: boolean };

export function getChartEvents(
  {
    actions, comments, alarms, facilityIds, params
  }: {
    actions: ActionSlimViewModel[],
    comments: ActionSlimViewModel[],
    alarms: LogLiteDto[],
    facilityIds: number[],
    params: ReportingSearchParams
  }
): Record<number, Partial<Record<Quantities, ReportEventContainer[]>>> {
  const noteActions = getPeriodActions(actions, params);
  const noteComments = getPeriodActions(comments, params);
  const noteAlarms = getPeriodAlarms(alarms, params);

  const noteKeys = [
    ...Object.keys(noteActions),
    ...Object.keys(noteComments),
    ...Object.keys(noteAlarms),
  ].map(key => Number(key)).unique();

  return facilityIds.toRecord(
    fId => fId,
    fId => params.quantityIds.toRecord(
      qId => qId,
      qId => noteKeys.map(
        key => ({
          value: key,
          events: {
            actions: noteActions[key]?.filter(n => n.reportingObjectId === fId)
              .filter(n => !Array.hasItems(n.quantityIds) || n.quantityIds.includes(qId)) ?? [],
            comments: noteComments[key]?.filter(n => n.reportingObjectId === fId)
              .filter(n => !Array.hasItems(n.quantityIds) || n.quantityIds.includes(qId)) ?? [],
            alarms: noteAlarms[key]
              ?.filter(n => n.facilityId === fId && n.quantityId === qId) ?? []
          }
        })
      ).sortBy('value')
    )
  );
}

export function getActionIconColor(actions: ReportingActionEvent[]): string {
  let highestValue = Math.min(
    ...actions.filterMap(
      note => note.investigation > 0,
      note => note.investigation
    )
  );
  // If there are no actions with investigation set, Math.min will return Infinity. Use grey color for those.
  highestValue = highestValue > Investigation.InvestigationDone ? null : highestValue;
  let colorName: string;
  switch (highestValue) {
    case Investigation.InvestigationRequired:
      colorName = '--enerkey-investigation-required';
      break;
    case Investigation.UnderInvestigation:
      colorName = '--enerkey-under-investigation';
      break;
    case Investigation.InvestigationDone:
      colorName = '--enerkey-investigation-done';
      break;
    default:
      colorName = '--enerkey-no-investigation-required';
  }
  return colorName;
}

export function getAlarmIconColor(actions: LogLiteDto[]): string {
  let highestValue = Math.min(...actions.filterMap(
    note => Number.isFinite(note.status),
    note => note.status
  ));
  highestValue = highestValue > 3 ? null : highestValue;
  let colorName: string;
  switch (highestValue) {
    case LogStatus.InvestigationRequired:
      colorName = '--enerkey-investigation-required';
      break;
    case LogStatus.UnderInvestigation:
      colorName = '--enerkey-under-investigation';
      break;
    case LogStatus.InvestigationDone:
      colorName = '--enerkey-investigation-done';
      break;
    default:
      colorName = '--enerkey-no-investigation-required';
  }
  return colorName;
}

function getPeriodActions(
  actions: ActionSlimViewModel[],
  params: ReportingSearchParams
): Record<number, ReportingActionEvent[]> {
  const periods = params.periods.map(p => getIntervalsByResolution({
    start: p,
    timeframe: params.duration,
    resolution: params.resolution
  }));
  const noteActions: Record<number, ReportingActionEvent[]> = {};
  for (let i = 0; i < Math.max(...periods.map(p => p.length)); i++) {
    const matchingPeriods = periods.filterMap(
      p => p[i],
      p => p[i]
    );

    const startMatching = actions.filter(
      action => matchingPeriods.some(p => isWithinInterval(action.effectStartsAt, p))
    );
    const endMatching = actions.filter(
      action => matchingPeriods.some(p => isWithinInterval(action.effectStopsAt, p))
    );

    if (Array.hasItems(startMatching)) {
      noteActions[i] = [];
      noteActions[i].push(...startMatching.map(action => ({
        ...action,
        startMatch: true
      })));
    }
    if (Array.hasItems(endMatching)) {
      noteActions[i] ??= [];
      noteActions[i].push(...endMatching.map(action => ({
        ...action,
        startMatch: false
      })));
    }
  }
  return noteActions;
}

function getPeriodAlarms(
  alarms: LogLiteDto[],
  params: ReportingSearchParams
): Record<number, LogLiteDto[]> {
  const periods = params.periods.map(p => getIntervalsByResolution({
    start: p,
    timeframe: params.duration,
    resolution: params.resolution
  }));
  const noteAlarms: Record<number, LogLiteDto[]> = {};
  for (let i = 0; i < periods[0].length; i++) {
    const matchingPeriods = periods.map(p => p[i]);

    const matchingAlarms = alarms.filter(
      alarm => matchingPeriods.some(p => isWithinInterval(alarm.executedAt, p))
    );

    if (Array.hasItems(matchingAlarms)) {
      noteAlarms[i] = matchingAlarms;
    }
  }
  return noteAlarms;
}
