import angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import EnerkeyServicesModule from '../../../services';
import EnergyManagementConstantsModule from '../../energy-management/constants';
import EnergyManagementModelsModule from '../../energy-management/models';
import EnergyReportingServicesModule from '../services';
import EnergyReportingModelsModule from '../models';
import erModalReportTypeComponent from './er-modal-report-type/er-modal-report-type.component';
import facilitiesReportComponent from './facilities-report/facilities-report.component';
import reportForecastSettingsComponent from './report-forecast-settings/report-forecast-settings.component';
import reportTrendSettingsComponent from './report-trend-settings/report-trend-settings.component';
import meterDetailsListComponent from './meter-details-list-component';
import meterDetailsListItemComponent from './meter-details-list-item-component';
import metersReportComponent from './meters-report/meters-report.component';
import metersReportComparisonComponent from './meters-report-comparison/meters-report-comparison.component';
import metersReportSumComponent from './meters-report-sum/meters-report-sum.component';
import metersReportTreelistComponent from './meters-report-treelist/meters-report-treelist.component';
import reportModalComponent from './report-modal/report-modal.component';
import ManualQaInspectModalModule from '../../manual-qa/components/manual-qa-inspect-modal';
import facilityDropdownComponent from './facility-dropdown/facility-dropdown-component';
import { actionsPopupButtonsComponent } from './actions-popup-buttons/actions-popup-buttons.component';
import { gridReportActionsPopupComponent } from './grid-report-actions-popup/grid-report-actions-popup.component';
import { alarmsPopupTableComponent } from './alarms-popup-table/alarms-popup-table.component';
import { gridReportNotesPopupComponent } from './grid-report-notes-popup/grid-report-notes-popup.component';
import { periodReportActionsPopupComponent } from './period-report-actions-popup/period-report-actions-popup.component';
import { periodReportAlarmsPopupComponent } from './period-report-alarms-popup/period-report-alarms-popup.component';
import { OverviewCardContentComponent } from './overview-card-content/overview-card-content.component';
import { TimeFrameQuickSelectComponent } from './time-frame-quick-select/time-frame-quick-select.component';
import { AlarmLogStatusComponent } from '../../../shared/alarms-shared/components/alarm-log-status/alarm-log-status.component';
import {
  SidebarQuantityPickerComponent
} from '../../../shared/energy-reporting-shared/components/sidebar-quantity-picker/sidebar-quantity-picker.component';
import { SimpleTableReportComponent } from './simple-table-report/simple-table-report.component';
import { FacilityOverviewComponent } from './facility-overview/facility-overview.component';
import { FacilitiesPopupExternalLinksComponent } from './facilities-popup-external-links/facilities-popup-external-links.component';
import { FacilitiesPopupDocumentsComponent } from '../../../shared/energy-reporting-shared/components/facilities-popup-documents/facilities-popup-documents.component';
import { TryNewReportingBannerComponent } from './try-new-reporting-banner/try-new-reporting-banner.component';

export default angular
  .module('app.modules.energy-reporting.components', [
    EnerkeyServicesModule.name,
    EnergyManagementConstantsModule.name,
    EnergyManagementModelsModule.name,
    EnergyReportingServicesModule.name,
    EnergyReportingModelsModule.name,
    ManualQaInspectModalModule.name
  ])
  .component('actionsPopupButtons', actionsPopupButtonsComponent)
  .component('alarmsPopupTable', alarmsPopupTableComponent)
  .component('erModalReportType', erModalReportTypeComponent)
  .component('facilitiesReport', facilitiesReportComponent)
  .component('gridReportActionsPopup', gridReportActionsPopupComponent)
  .component('gridReportNotesPopup', gridReportNotesPopupComponent)
  .component('meterDetailsListComponent', meterDetailsListComponent)
  .component('meterDetailsListItemComponent', meterDetailsListItemComponent)
  .component('metersReport', metersReportComponent)
  .component('metersReportComparison', metersReportComparisonComponent)
  .component('metersReportSum', metersReportSumComponent)
  .component('metersReportTreelist', metersReportTreelistComponent)
  .component('periodReportActionsPopup', periodReportActionsPopupComponent)
  .component('periodReportAlarmsPopup', periodReportAlarmsPopupComponent)
  .component('reportForecastSettings', reportForecastSettingsComponent)
  .component('reportModal', reportModalComponent)
  .component('reportTrendSettings', reportTrendSettingsComponent)
  .component('facilityDropdown', facilityDropdownComponent)
  .directive('overviewCardContent', downgradeComponent({ component: OverviewCardContentComponent }))
  .directive('timeFrameQuickSelect', downgradeComponent({ component: TimeFrameQuickSelectComponent }))
  .directive('alarmLogStatus', downgradeComponent({ component: AlarmLogStatusComponent }))
  .directive('sidebarQuantityPicker', downgradeComponent({ component: SidebarQuantityPickerComponent }))
  .directive('simpleTableReport', downgradeComponent({ component: SimpleTableReportComponent }))
  .directive('facilityOverview', downgradeComponent({ component: FacilityOverviewComponent }))
  .directive('facilitiesPopupExternalLinks', downgradeComponent({ component: FacilitiesPopupExternalLinksComponent }))
  .directive('facilitiesPopupDocuments', downgradeComponent({ component: FacilitiesPopupDocumentsComponent }))
  .directive('tryNewReportingBanner', downgradeComponent({ component: TryNewReportingBannerComponent }))
;
