import {
  ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange,
  SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { mergeWith, takeUntil, tap } from 'rxjs/operators';

import { MeterGroupDto } from '@enerkey/clients/metering';
import { StringKeys } from '@enerkey/ts-utils';

import { SelectComponent } from '../../../../../shared/select/select.component';
import { MeterGroup } from '../../../models/meter-groups.model';
import { MeterGroupsService } from '../../../services/meter-groups/meter-groups.service';

@Component({
  selector: 'meter-groups-dropdown-list',
  templateUrl: 'meter-groups-dropdown-list.component.html',
  styleUrls: ['meter-groups-dropdown-list.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MeterGroupsDropdownListComponent),
    multi: true
  }]
})
export class MeterGroupsDropdownListComponent
  extends SelectComponent<MeterGroup, 'id'>
  implements OnInit, OnDestroy, OnChanges {
  @Input() public defaultItemText: string;
  @Input() public showDefaultText: boolean = true;
  @Input() public facilityId?: number;
  @Output() public readonly meterGroupChange = new EventEmitter<MeterGroup>();

  public override textField: StringKeys<MeterGroup> = 'name';
  public override valueField: 'id' = 'id' as const;
  public override defaultItem: MeterGroup;

  public meterGroups$: Observable<MeterGroupDto[]>;

  private readonly _destroy$ = new Subject<void>();
  private readonly _meterGroups$ = new BehaviorSubject<MeterGroupDto[]>(null);

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private meterGroupsService: MeterGroupsService
  ) {
    super(changeDetectorRef);
    this.meterGroups$ = this._meterGroups$.asObservable();
  }

  public ngOnInit(): void {
    if (this.showDefaultText) {
      this.defaultItem = {
        id: undefined,
        name: this.defaultItemText ?? this.translateService.instant('ADMIN.METERGROUPS.SELECT_A_METER_GROUP'),
        quantityGroupId: undefined,
        description: '',
        allMetersAreIncluded: true,
        totalMeterCount: 0
      };
    }

    this.meterGroups$.pipe(
      tap((meterGroups: MeterGroupDto[]) => {
        this.sourceData = meterGroups?.map((meterGroup: MeterGroupDto) => ({
          id: meterGroup.id,
          name: meterGroup.name,
          description: meterGroup.description,
          quantityGroupId: meterGroup.quantityGroupId,
          allMetersAreIncluded: meterGroup.allMetersAreIncluded,
          totalMeterCount: meterGroup.totalMeterCount
        })) ?? [];
      }),
      takeUntil(this._destroy$)
    ).subscribe();

    this.meterGroupsService.meterGroupCreate$.pipe(
      mergeWith(this.meterGroupsService.meterGroupUpdate$, this.meterGroupsService.meterGroupDelete$),
      tap((meterGroup: MeterGroupDto | void) => this.loadMeterGroups(meterGroup ? meterGroup.id : undefined)),
      takeUntil(this._destroy$)
    ).subscribe();

    this.loadMeterGroups();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.facilityId !== undefined) { this.onFacilityIdChange(changes.facilityId); }
  }

  public override valueChanged(item: MeterGroup['id']): void {
    super.valueChanged(item);
    const meterGroup = this.sourceData.find((sourceItem: MeterGroup) => sourceItem.id === item);
    this.meterGroupChange.emit(meterGroup);
  }

  private loadMeterGroups(meterGroupId?: number, facilityId?: number): void {
    this.meterGroupsService.getMeterGroupsOnCurrentProfile(facilityId).pipe(
      tap((meterGroups: MeterGroupDto[]) => {
        this._meterGroups$.next(meterGroups);

        const isActiveMeterGroupInList = meterGroups.some(meterGroup => meterGroup.id === this.value);
        const isMeterGroupInList = Number.isFinite(meterGroupId) &&
          meterGroups.some(meterGroup => meterGroup.id === meterGroupId);

        if (isMeterGroupInList) {
          this.valueChanged(meterGroupId);
        } else if (!isActiveMeterGroupInList && this.value !== null) {
          this.valueChanged(null);
        }
      }),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  private onFacilityIdChange(facilityIdChange: SimpleChange): void {
    const newFacilityId = facilityIdChange.currentValue as number;
    const oldFacilityId = facilityIdChange.previousValue as number;
    const isFirstChange = facilityIdChange.firstChange;

    if (!isFirstChange && newFacilityId !== oldFacilityId) {
      this.loadMeterGroups(undefined, newFacilityId);
    }
  }
}
