import { ContractSheetCell, ContractSpreadsheetColumns } from '../components/contract-mass-add/contract-sheet-options';

export type RowValues = [number, number, number, string, string, number, number];

export class ContractMassAddRow implements Record<ContractSheetCell, string | number> {
  public readonly enegiaId: RowValues[0];
  public readonly unitCount: RowValues[1];
  public readonly unitPrice: RowValues[2];
  public readonly productId: RowValues[3];
  public readonly contractId: RowValues[4];
  public readonly fromDate: RowValues[5];
  public readonly toDate: RowValues[6];

  private readonly values: RowValues;

  public get checkOverlaps(): boolean {
    return [
      this.enegiaId,
      this.contractId,
      this.productId,
      this.fromDate,
    ].every(x => x !== undefined && x !== null);
  }

  public get recordKey(): string {
    return `${this.enegiaId}__${this.productId}__${this.contractId}`;
  }

  public get isEmpty(): boolean {
    return this.values.every(v => v === null || v === undefined);
  }

  public constructor(
    public readonly rowIndex: number,
    public readonly range: kendo.spreadsheet.Range
  ) {
    this.values = range.values()[0];

    this.enegiaId = this.values[0];
    this.unitCount = this.values[1];
    this.unitPrice = this.values[2];
    this.productId = this.values[3];
    this.contractId = this.values[4];
    this.fromDate = this.values[5];
    this.toDate = this.values[6];
  }

  public getCell(sheet: kendo.spreadsheet.Sheet, arg: number | ContractSheetCell): kendo.spreadsheet.Range {
    const columnIndex = typeof arg === 'number' ? arg : ContractSpreadsheetColumns.indexOf(arg);
    return sheet.range(this.rowIndex, columnIndex);
  }
}
