import { MeterManagementMeter } from '@enerkey/clients/meter-management';
import { MeterGroupDto, Quantities } from '@enerkey/clients/metering';

import { ExtendedFacilityInformation } from '../../../shared/interfaces/extended-facility-information';

export type MeterGroup = {
  id: number;
  name: string;
  description: string;
  quantityGroupId: number;
  allMetersAreIncluded: boolean;
  totalMeterCount: number;
}

export type MeterGroupBaseData = {
  meterGroups: MeterGroupDto[];
  facilities: ExtendedFacilityInformation[];
  meters: Record<string, MeterManagementMeter[]>;
}

export enum MeterGroupCategory {
  ENERGY
}

export class MeterGroupCategoryQuantities {
  private static ENERGY_QUANTITIES = new MeterGroupCategoryQuantities(MeterGroupCategory.ENERGY, [
    Quantities.Electricity,
    Quantities.DistrictHeating,
    Quantities.Steam,
    Quantities.DistrictCooling,
    Quantities.CoolingOwnProduction,
    Quantities.HeatingOwnProduction,
    Quantities.OwnElectricity,
    Quantities.PelletMWh,
    Quantities.NaturalGasMWh,
    Quantities.OilMWh,
    Quantities.LiquidGasMWh,
    Quantities.NaturalGasOtherMWh,
    Quantities.BiogasMWh,
    Quantities.WoodChipsMWh,
    Quantities.HeavyFuelOilMWh
  ]);

  public static getByCategory(category: MeterGroupCategory): Quantities[] {
    switch (category) {
      case MeterGroupCategory.ENERGY: { return MeterGroupCategoryQuantities.ENERGY_QUANTITIES.quantities; }
      default: throw new Error('Unknown category');
    }
  }

  private constructor(
    public readonly category: MeterGroupCategory,
    public readonly quantities: Quantities[]
  ) {}
}

export abstract class MeterGroupError extends Error {}
export class MeterGroupFormValidationError extends MeterGroupError {}
export class MeterGroupNotFoundDeletionError extends MeterGroupError {}
export class MeterGroupAddMetersError extends MeterGroupError {}
export class MeterGroupRemoveMetersError extends MeterGroupError {}
