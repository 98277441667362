import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { startOfMonth } from 'date-fns';

import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';

import { BillingPeriod, ContractSearchExtraParams, ContractSearchParams } from '../../models/contract-search-params';
import { ContractSearchService } from '../../services/contract-search.service';
import { getDefaultBillingPeriod } from '../billing-period-picker/billing-period-picker.component';

enum RangeType {
  Active = 'active',
  All = 'all',
  Billing = 'billing'
}

export type ContractSearchForm = ContractSearchParams & ContractSearchExtraParams & { rangeType: RangeType };

@Component({
  selector: 'contract-search',
  templateUrl: './contract-search.component.html',
  styleUrls: ['./contract-search.component.scss'],
})
export class ContractSearchComponent {
  public static readonly RangeType: typeof RangeType = RangeType;

  public readonly allRangeTypes: { key: string; type: RangeType }[] = [
    { type: RangeType.Active, key: 'ADMIN.CONTRACTS.ACTIVE' },
    { type: RangeType.All, key: 'MULTISELECT.SELECT_ALL' },
    { type: RangeType.Billing, key: 'ADMIN.CONTRACTS.BILLING_PERIOD' },
  ];

  public readonly searchControls: ControlsOf<ContractSearchForm>;
  public readonly searchGroup: UntypedFormGroup;

  /** from `single-input-enabled.directive` */
  public isSubmitDisabled: boolean = true;

  /** Whether DateInput controls are enabled */
  public customRange: boolean = false;

  public showBillingPeriodPicker: boolean = false;

  public readonly loading$: Observable<boolean>;

  private readonly defaultFormState: Readonly<ContractSearchForm> = {
    currentProfile: false,
    profileName: null,
    profileIds: null,

    facilityName: '',
    facilityIds: null,
    companyCodes: null,
    enegiaIds: null,
    contractIds: null,

    meterCounts: false,

    range: getDefaultBillingPeriod(),
    rangeType: RangeType.Active
  };

  public constructor(
    private readonly contractService: ContractSearchService
  ) {
    this.loading$ = this.contractService.loading$;
    this.searchControls = formControlsFrom(this.defaultFormState);
    this.searchGroup = new UntypedFormGroup(this.searchControls);

    this.searchControls.rangeType.valueChanges.subscribe((value: RangeType) => {
      this.showBillingPeriodPicker = value === RangeType.Billing;
    });
  }

  public getCurrentProfile(): void {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const key: any = 'currentProfile';
    const value: any = true;
    const meterCounts: boolean = this.searchControls.meterCounts.value;
    const range: BillingPeriod = this.getRange();
    this.contractService.onSearch({ key, value, meterCounts, range });
  }

  public onSearch(): void {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const key: any = Object.keys(this.searchGroup.value)[0];
    const value: any = this.searchGroup.get(key).value;
    const meterCounts: boolean = this.searchControls.meterCounts.value;
    const range: BillingPeriod = this.getRange();

    this.contractService.onSearch({ key, value, meterCounts, range });
  }

  public onReset(): void {
    this.searchGroup.reset(this.defaultFormState);
  }

  private getRange(): BillingPeriod {
    const rangeType: RangeType = this.searchControls.rangeType.value;

    switch (rangeType) {
      case RangeType.Billing:
        return this.searchControls.range.value;
      case RangeType.Active:
        return { from: startOfMonth(new Date()), to: null };
      case RangeType.All:
      default:
        return { from: null, to: null };
    }
  }
}
