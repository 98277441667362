import { add, addDays, addHours, addMinutes, addMonths, addYears, subMilliseconds } from 'date-fns';

import { RequestResolution } from '.';

/**
 * Returns a date that is resolution's period later.
 * @throws Error if resolution is invalid.
 */
function addResolution(date: Date, resolution: RequestResolution): Date {
  switch (resolution) {
    case RequestResolution.PT15M: return addMinutes(date, 15);
    case RequestResolution.PT1H: return addHours(date, 1);
    case RequestResolution.P1D: return addDays(date, 1);
    case RequestResolution.P7D: return addDays(date, 7);
    case RequestResolution.P1M: return addMonths(date, 1);
    case RequestResolution.P2M: return addMonths(date, 2);
    case RequestResolution.P3M: return addMonths(date, 3);
    case RequestResolution.P4M: return addMonths(date, 4);
    case RequestResolution.P5M: return addMonths(date, 5);
    case RequestResolution.P6M: return addMonths(date, 6);
    case RequestResolution.P7M: return addMonths(date, 7);
    case RequestResolution.P8M: return addMonths(date, 8);
    case RequestResolution.P9M: return addMonths(date, 9);
    case RequestResolution.P10M: return addMonths(date, 10);
    case RequestResolution.P11M: return addMonths(date, 11);
    case RequestResolution.P1Y: return addYears(date, 1);
    default: throw Error(`Invalid request resolution '${resolution}'`);
  }
}

export function getIntervalsByResolution({ start, timeframe, end, resolution }: {
  start: Date,
  timeframe?: Duration,
  resolution: RequestResolution,
  end?: Date,
}): { start: Date, end: Date }[] {
  end ??= add(start, timeframe);
  const periods: { start: Date, end: Date }[] = [];

  do {
    const periodEnd = addResolution(start, resolution);
    periods.push({
      start: new Date(start),
      end: subMilliseconds(new Date(periodEnd), 1)
    });
    start = periodEnd;
  } while (start < end);

  return periods;
}

/**
 * Modifies the consumptions so that a consumption's value is sum of previous values.
 * Does no validations for incomplete/modeled or null values.
 */
export function convertToCumulative<T extends { value?: number; incomplete?: number; }>(
  consumptions: T[]
): void {
  if (consumptions.length === 0) {
    return;
  }

  let current: number = null;
  let currentIncomplete = 0;

  for (const item of consumptions) {
    if (item.value) {
      current += item.value;
    }
    currentIncomplete = Math.max(item.incomplete ?? 1, currentIncomplete);
    item.incomplete = currentIncomplete;
    item.value = current;
  }
}
