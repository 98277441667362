import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';

import { AttachmentsClient, DocumentCompleteViewModel } from '@enerkey/clients/attachments';

import { REPORT_MODAL_PARAMS, ReportingModalParams } from '../components/report-modal/report-modal.component';
import { ReportType } from '../shared/report-type';
import { UserService } from '../../../services/user-service';
import { BookmarkService } from '../../../services/bookmark.service';
import { ReportingSearchService } from './reporting-search.service';
import { ReportingParams } from '../reporting.states';
import { ReportModalMetersService } from './report-modal-meters.service';

export enum QuantityOrMeterReport {
  Meter = 'meter',
  Quantity = 'quantity'
}

@Injectable()
export class ReportModalService implements OnDestroy {
  public readonly reportType$: Observable<ReportType>;
  public readonly meterReportType$: Observable<ReportType>;
  public readonly quantityOrMeterReport$: Observable<QuantityOrMeterReport>;
  public readonly facilityMeterMaps$: Observable<DocumentCompleteViewModel[]>;

  private readonly _reportType$ = new ReplaySubject<ReportType>(1);
  private readonly _meterReportType$ = new BehaviorSubject<ReportType>(ReportType.Period);
  private readonly _quantityOrMeterReport$ = new BehaviorSubject(QuantityOrMeterReport.Quantity);
  private readonly _destroy$ = new Subject<void>();

  private readonly reportingParams$: Observable<ReportingParams>;

  public constructor(
    attachmentsClient: AttachmentsClient,
    userService: UserService,
    @Inject(REPORT_MODAL_PARAMS) modalParams: ReportingModalParams,
    bookmarkService: BookmarkService,
    reportingSearchService: ReportingSearchService,
    reportModalMetersService: ReportModalMetersService
  ) {
    this.reportType$ = this._reportType$.asObservable();
    this.meterReportType$ = this._meterReportType$.asObservable();
    this.quantityOrMeterReport$ = this._quantityOrMeterReport$.asObservable();

    this.facilityMeterMaps$ = attachmentsClient.getMetermap(userService.profileId, modalParams.facilityId).pipe(
      takeUntil(this._destroy$),
      shareReplay(1)
    );

    this._reportType$.next(modalParams.initialReportType);

    this.reportingParams$ = combineLatest([
      reportingSearchService.searchParameters$,
      reportingSearchService.visibleSections$,
      reportingSearchService.chartVisibility$,
      reportingSearchService.gridVisibility$,
      reportingSearchService.meterInfoVisibility$,
      reportingSearchService.facilityIds$,
      reportModalMetersService.selectedMeters$
    ]).pipe(
      switchMap(([searchParams, sections, charts, grids, meterInfo, facilityIds, meters]) =>
        of({
          ...searchParams.formValue,
          periods: searchParams.formValue.periods.map(p => p.toISOString()),
          sections: [...sections, ...(meterInfo ? ['MeterDetails'] : [])],
          charts,
          grids,
          facilityIds,
          meterIds: meters.meterIds,
        })),
      takeUntil(this._destroy$)
    );

    combineLatest([
      this.reportType$,
      this.meterReportType$,
      this.quantityOrMeterReport$,
      this.reportingParams$
    ]).pipe(takeUntil(this._destroy$))
      .subscribe(([quantityReportType, meterReportType, quantityOrMeterReport, reportingParams]) => {
        const reportType = quantityOrMeterReport === QuantityOrMeterReport.Meter ? meterReportType : quantityReportType;
        bookmarkService.handleReportingModalChange({
          name: `reporting.${reportType}`,
          modal: true,
          stateParams: reportingParams
        }, quantityOrMeterReport);
      });

    if (modalParams.meterIds?.length) {
      this.changeMeterOrQuantityReport(QuantityOrMeterReport.Meter);
      this.moveToMeterReportOfType(modalParams.initialReportType);
    }

    if (modalParams.presentation) {
      reportingSearchService.toggleSections(modalParams.presentation.sections);
      reportingSearchService.setChartsVisibility(modalParams.presentation.charts);
      reportingSearchService.setGridsVisibility(modalParams.presentation.grids);
      reportingSearchService.setMeterInfoVisibility(modalParams.presentation.meterInfo);
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public moveToReportOfType(reportType: ReportType): void {
    this._reportType$.next(reportType);
  }

  public moveToMeterReportOfType(reportType: ReportType): void {
    this._meterReportType$.next(reportType);
  }

  public changeMeterOrQuantityReport(reportType: QuantityOrMeterReport): void {
    this._quantityOrMeterReport$.next(reportType);
  }
}
