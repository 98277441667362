import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  SimpleProfileViewModel,
  UserManagementClient,
} from '@enerkey/clients/user-management';

import { ProfileSearchComponent } from '../profile-search/profile-search.component';

@Component({
  selector: 'profile-search-select',
  templateUrl: './profile-search-select.component.html',
  styleUrls: ['./profile-search-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProfileSearchSelectComponent),
    multi: true
  }],
})
export class ProfileSearchSelectComponent implements ControlValueAccessor {
  @ViewChild(ProfileSearchComponent) public profileSelect: ProfileSearchComponent;
  @Input() public set isDisabled(disabled: boolean) {
    this._isDisabled = disabled;
    this.setDisabledState(disabled);
  }
  public get isDisabled(): boolean {
    return this._isDisabled;
  }

  public readonly profileControl = new FormControl<SimpleProfileViewModel>({ value: null, disabled: true });

  public profiles$: Observable<SimpleProfileViewModel[]>;
  public excludedProfiles$: Observable<number[]>;

  public _profiles$ = new BehaviorSubject<SimpleProfileViewModel[]>([]);

  protected readonly userManagementClient: UserManagementClient;
  protected readonly changeDetectorRef: ChangeDetectorRef;

  private _onChange: (value: number[]) => void;
  private _isDisabled = false;

  public constructor(
    userManagementClient: UserManagementClient,
    changeDetectorRef: ChangeDetectorRef
  ) {

    this.userManagementClient = userManagementClient;
    this.changeDetectorRef = changeDetectorRef;

    this.profiles$ = this._profiles$.asObservable();
    this.excludedProfiles$ = this.profiles$.pipe(
      map(profiles => profiles.map(profile => profile.id))
    );

    this.profileControl.valueChanges.pipe(
      filter(value => !!value)
    ).subscribe((profile: SimpleProfileViewModel) => {
      this.profileSelect.sourceData = [];
      this.profileSelect.items = [];
      this.profileSelect.reset();
      this.profileControl.setValue(null);
      this._profiles$.next([...this._profiles$.value, profile].uniqueBy('id'));
      this._onChange(this._profiles$.value.map(item => item.id));
      this.changeDetectorRef.detectChanges();
    });
  }

  public writeValue(value: number[]): void {
    const profiles$: Observable<SimpleProfileViewModel[]> = Array.hasItems(value)
      ? forkJoin(value.map(id => this.userManagementClient.getProfile(id)))
      : of([]);
    profiles$.subscribe(
      profiles => {
        this._profiles$.next(profiles);
        this.profileControl.enable();
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  public registerOnChange(fn: (value: number[]) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void {
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.profileControl.disable();
    } else {
      this.profileControl.enable();
    }
  }

  public removeProfile(profileId: number): void {
    this._profiles$.next(this._profiles$.value.filter(p => p.id !== profileId));
    this._onChange(this._profiles$.value.map(p => p.id));
  }
}
