import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { map, Observable } from 'rxjs';

import { ConsumptionElementResponse } from '@enerkey/clients/reporting';

import { ConsumptionValueFlagClassPipe } from '../../pipes/consumption-value-flag-class.pipe';

@Component({
  selector: 'value-flag-cell',
  templateUrl: './value-flag-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConsumptionValueFlagClassPipe]
})
export class ValueFlagCellComponent implements OnInit {
  @Input({ required: true }) public consumption: ConsumptionElementResponse;
  @Input({ required: true }) public visibleValue: number;
  @Input({ required: true }) public format: string;

  public hasFlag$: Observable<boolean>;

  public readonly tooltipNumberFormat: NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
  };

  public constructor(private readonly valueFlagPipe: ConsumptionValueFlagClassPipe) { }

  public ngOnInit(): void {
    this.hasFlag$ = this.valueFlagPipe.transform(this.consumption).pipe(
      map(flags => flags.length > 0 || this.consumption?.incomplete === -1)
    );
  }
}
