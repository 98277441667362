import {
  ChangeDetectionStrategy, Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  AxisLabelContentArgs,
  CategoryAxisItemComponent,
  ChartComponent,
  LegendLabels,
  PlotAreaClickEvent,
  SeriesClickEvent
} from '@progress/kendo-angular-charts';
import { Align } from '@progress/kendo-angular-popup';
import { Element, Layout, Text } from '@progress/kendo-drawing';

import { Quantities } from '@enerkey/clients/metering';
import { RequestResolution } from '@enerkey/clients/reporting';
import { AxisLabelVisualArgsOf } from '@enerkey/ts-utils';

import { chartLineColor } from '../../../../constants/chart-constants';
import { PeriodLabelService } from '../../../../modules/reporting/services/period-label.service';
import {
  ReportEventContainer
} from '../../../../modules/reporting/services/reporting-chart-event.functions';
import { ReportingSeries, ReportSeriesDataPoint } from '../../../../modules/reporting/shared/reporting-series';
import { ReportingChartLabelSettings } from '../../pipes/chart-categories.pipe';

const axisBackground = '#ffffffde';
const axisColor = '#232323';
const axisFont = '12px "Open Sans"';

@Component({
  selector: 'widget-consumption-chart',
  templateUrl: './widget-consumption-chart.component.html',
  styleUrls: ['./widget-consumption-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetConsumptionChartComponent {
  @Input() public series: ReportingSeries[];
  @Input() public quantityId: Quantities;
  @Input() public labelSettings: ReportingChartLabelSettings;

  @Output() public readonly seriesClick = new EventEmitter<ReportSeriesDataPoint>();

  @ViewChild(ChartComponent) public chart: ChartComponent;
  @ViewChild(CategoryAxisItemComponent) public categoryAxisItemComponent: CategoryAxisItemComponent;

  public showTooltip = false;
  public tooltipContent: ReportEventContainer['events'];

  public anchorElement: ElementRef;

  public readonly chartLineColor = chartLineColor;
  public readonly RequestResolution = RequestResolution;
  public readonly axisColor = axisColor;
  public readonly axisFont = axisFont;
  public readonly axisBackground = axisBackground;

  public readonly popupAlign: Align = {
    horizontal: 'left',
    vertical: 'top'
  };

  public readonly legendLabels: LegendLabels = {
    color: axisColor,
    font: axisFont,
  };

  public constructor(
    private readonly periodLabelService: PeriodLabelService
  ) {
    this.labelContentFn = this.labelContentFn.bind(this);
    this.labelVisualFn = this.labelVisualFn.bind(this);
  }

  public mouseleave(): void {
    this.showTooltip = false;
  }

  public seriesClicked(event: SeriesClickEvent): void {
    if (!event.originalEvent.event.shiftKey) {
      this.seriesClick.emit(event.dataItem);
    }
  }

  public plotAreaClicked(event: PlotAreaClickEvent): void {
    if (event.originalEvent.event.shiftKey) {
      // Reset chart zoom
      this.categoryAxisItemComponent.notifyChanges({});
    }
  }

  public labelContentFn(e: AxisLabelContentArgs): string {
    const timestamps = this.series.map(s => s.values[e.index]?.timestamp).filter(t => t);
    return this.periodLabelService.getChartCategoryLabel({
      timestamps,
      resolution: this.labelSettings.resolution,
      amountOfPeriods: this.labelSettings.amountOfPeriods,
      specialDayLabelFormat: this.labelSettings.specialDayLabelFormat,
      index: e.index,
      useShortFormat: true,
    });
  }

  public labelVisualFn(e: AxisLabelVisualArgsOf<ReportSeriesDataPoint>): Element {
    if (![
      RequestResolution.P1D,
      RequestResolution.PT1H,
      RequestResolution.PT15M
    ].includes(this.labelSettings.resolution)) {
      return e.createVisual();
    }
    if (!this.labelSettings.specialDayLabelFormat) {
      return e.createVisual();
    }
    const isSunday = e.dataItem.timestamp.getDay() === 0;
    if (!isSunday) {
      return e.createVisual();
    }
    const layout = new Layout(e.rect, { orientation: 'vertical', alignContent: 'center' });
    layout.append(new Text(
      e.text,
      [0, 0],
      {
        ...e.options,
        fill: { color: 'red' }
      }
    ));
    layout.reflow();
    return layout;
  }
}
