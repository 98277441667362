import {
  FacilityMeterCountsDto,
  MeterCountPerQuantityDto,
  MeteringType,
  MeterTypeCountsDto,
} from '@enerkey/clients/meter-management';
import { MeteringType as MeteringTypes_ } from '@enerkey/clients/metering';
import { assertType, Helpers, TypeEq } from '@enerkey/ts-utils';

// Compiler check to ensure the enums are consistent across both clients
assertType<TypeEq<MeteringType[keyof MeteringType], MeteringTypes_[keyof MeteringTypes_]>>();

type MeteringTypeId = MeteringType;
type QuantityId = number;

interface MeterCountColumn {
  /** Translation key for the column's full name. */
  readonly key: string;

  /** Path to the column's property. */
  readonly field: string;
}

interface MeterCountColumnGroup {
  /** Translation key for the column's full name. */
  readonly key: string;

  /** Child columns. */
  readonly columns: Readonly<MeterCountColumn[]>;

  /** Child column groups. */
  readonly groups: Readonly<MeterCountColumnGroup[]>;
}

/** Represents a facility's meter counts by type. */
class MeterTypeCounts {

  /**
  * Returns columns for total meter counts by meter type.
  * @param field property name
  * @param pre translation key prefix
  */
  public static toColumns(field: string, pre: string): MeterCountColumn[] {
    const post = 'METERSTOTALCOUNT';

    return [
      { field: `${field}.all`, key: `${pre}${post}` },
      { field: `${field}.manual`, key: `${pre}MANUAL${post}` },
      { field: `${field}.automatic`, key: `${pre}AUTOMATIC${post}` },
      { field: `${field}.virtual`, key: `${pre}VIRTUAL${post}` },
    ];
  }

  public all: number;
  public manual: number;
  public automatic: number;
  public virtual: number;

  public constructor(counts: MeterTypeCountsDto) {
    if (counts) {
      this.all = counts.metersTotalCount;
      this.automatic = counts.automaticMetersTotalCount;
      this.manual = counts.manualMetersTotalCount;
      this.virtual = counts.virtualMetersTotalCount;
    }
  }
}

/** Represents the total meter counts for a facility. */
class TotalMeterCounts {

  /**
  * Returns columns for total meter counts for all meters, and main/submeters by type.
  */
  public static toColumns(field: string): MeterCountColumnGroup {
    const keyPrefix = 'METER_COUNTS';
    return {
      key: 'GROUPS.METER_COUNT_TOTALS',
      groups: [],
      columns: [
        { field: `${field}.total`, key: `${keyPrefix}.TOTALMETERCOUNT` },
        ...MeterTypeCounts.toColumns(`${field}.main`, `${keyPrefix}.MAIN`),
        ...MeterTypeCounts.toColumns(`${field}.sub`, `${keyPrefix}.SUB`),
      ]
    };
  }

  public total: number;
  public main: MeterTypeCounts;
  public sub: MeterTypeCounts;

  public constructor(counts: FacilityMeterCountsDto) {
    this.total = counts.totalMeterCount;
    this.main = new MeterTypeCounts(counts.mainMeterCounts);
    this.sub = new MeterTypeCounts(counts.subMeterCounts);
  }

}

/** Represents a facility's meter counts by quantity, grouped by meter type. */
class QuantityMeterCounts implements Record<QuantityId, Partial<Record<MeteringTypeId, number>>> {

  /**
   * Returns columns from total quantity meter counts for every quantity ID in the list.
   */
  public static toColumns(
    field: string,
    key: string,
    quantityCounts: QuantityMeterCounts[]
  ): MeterCountColumnGroup {
    const allQuantityTypes = quantityCounts.reduce((accum, quantityCount) => {
      for (const qty of Object.integerKeys(quantityCount)) {
        accum.getOrAdd(qty, () => []).push(...Object.keys(quantityCount[qty]));
      }
      return accum;
    }, new Map<number, string[]>());

    const groups: MeterCountColumnGroup[] = allQuantityTypes
      .getEntries()
      .sortBy(0)
      .map(([quantityId, meteringTypeIds]) => ({
        key: `${quantityId}`, // Pipe w/ Quantity full Name
        columns: meteringTypeIds.unique().sortBy(Helpers.identity).map(typeId => ({
          key: `${quantityId},${typeId}`, // Pipe w/ Quantity/Type full
          field: `${field}.${quantityId}.${typeId}`
        })),
        groups: []
      }));

    return { key, groups, columns: [] };
  }

  public constructor(meterTypeCounts: MeterTypeCountsDto) {
    if (meterTypeCounts?.meterCountsByType) {
      for (const { quantityId, count, meteringType } of meterTypeCounts.meterCountsByType) {
        if (!(quantityId in this)) {
          this[quantityId] = {};
        }
        this[quantityId][meteringType] = count;
      }
    }
  }

  [quantityId: number]: Partial<Record<MeteringTypeId, number>>;
}

/** Represents a facility's total meter counts by quantity. */
class QuantityTotalCounts implements Record<QuantityId, number> {

  /**
   * Returns columns from total quantity meter counts for every quantity ID in the list.
   */
  public static toColumns(
    field: string,
    quantityTotalCounts: QuantityTotalCounts[]
  ): MeterCountColumnGroup {
    const quantityColumns = quantityTotalCounts
      .flatMap(Object.integerKeys)
      .unique()
      .sortBy(Helpers.identity)
      .map(quantityId => ({
        field: `${field}.${quantityId}`,
        key: `${quantityId}`, // Pipes: QtyName
      }));

    return {
      key: 'GROUPS.QUANTITIES_METER_COUNTS',
      columns: quantityColumns,
      groups: []
    };
  }

  public constructor(perQuantity: MeterCountPerQuantityDto[]) {
    if (perQuantity) {
      for (const { quantityId, count } of perQuantity) {
        if (Number.isInteger(quantityId) && Number.isInteger(count)) {
          this[quantityId] = count;
        }
      }
    }
  }

  [quantityId: number]: number;
}

/** Represents the complete meter counts information for a facility. */
export class MeterCounts {
  public readonly facilityId: number;
  public readonly totals: TotalMeterCounts;
  public readonly quantityTotals: QuantityTotalCounts;
  public readonly mainMeterCounts: QuantityMeterCounts;
  public readonly subMeterCounts: QuantityMeterCounts;

  public constructor(counts: FacilityMeterCountsDto) {
    this.facilityId = counts.facilityId;
    this.totals = new TotalMeterCounts(counts);
    this.quantityTotals = new QuantityTotalCounts(counts.meterCountsPerQuantity);
    this.mainMeterCounts = new QuantityMeterCounts(counts.mainMeterCounts);
    this.subMeterCounts = new QuantityMeterCounts(counts.subMeterCounts);
  }
}

/**
 * Used to determine visible columns and their fields etc. depending on the grid data.
 */
export class MeterCountColumnConfig {

  public static from(counts: MeterCounts[], field: string): MeterCountColumnConfig | null {
    return Array.hasItems(counts)
      ? new MeterCountColumnConfig(field, counts.unique())
      : null;
  }

  public readonly totals: MeterCountColumnGroup;

  public readonly main: MeterCountColumnGroup;
  public readonly sub: MeterCountColumnGroup;

  public readonly quantities: MeterCountColumnGroup;

  public get allColumns(): MeterCountColumn[] {
    return [
      this.totals,
      this.main,
      this.sub,
      this.quantities
    ].flatMap(group => this.getColumnsRecursive(group));
  }

  /**
   * @param field key of the MeterCounts-property
   * @param counts list of all MeterCounts
   */
  private constructor(
    public readonly field: string,
    counts: MeterCounts[]
  ) {
    this.totals = TotalMeterCounts.toColumns(`${this.field}.totals`);

    this.main = QuantityMeterCounts.toColumns(
      `${this.field}.mainMeterCounts`,
      'GROUPS.MAINMETERCOUNTS',
      counts.map(x => x.mainMeterCounts)
    );

    this.sub = QuantityMeterCounts.toColumns(
      `${this.field}.subMeterCounts`,
      'GROUPS.SUBMETERCOUNTS',
      counts.map(x => x.subMeterCounts)
    );

    this.quantities = QuantityTotalCounts.toColumns(
      `${this.field}.quantityTotals`,
      counts.map(x => x.quantityTotals)
    );
  }

  private getColumnsRecursive(outer: MeterCountColumnGroup): MeterCountColumn[] {
    return [
      ...outer.columns,
      ...outer.groups.flatMap(inner => this.getColumnsRecursive(inner))
    ];
  }
}
