import { Injectable } from '@angular/core';
import {
  isAfter,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from 'date-fns';

import { assertUnreachable } from '@enerkey/ts-utils';
import { CalendarMode } from '@enerkey/clients/settings';
import { RequestResolution } from '@enerkey/clients/reporting';

import { DurationName } from '../shared/reporting-search-form-value';
import { ThresholdService } from '../../../shared/services/threshold.service';
import { SelectableResolution } from '../components/reporting-search-form/reporting-search-form.component';
import { ReportingTimePeriodPreset } from '../components/time-period-quick-select/time-period-quick-select.component';

export type TimePeriodFields = {
  durationName: DurationName;
  durationLength: number;
  resolution: SelectableResolution;
  periods: Date[];
}

@Injectable({
  providedIn: 'root'
})
export class ReportingTimePeriodService {

  public constructor(private readonly thresholdService: ThresholdService) { }

  public getDefaultYear(): number {
    return this.defaultStartDate.getFullYear();
  }

  public getDefaultPeriodsForDuration(timeframe: DurationName): Date[] {
    switch (timeframe) {
      case 'years':
        return this.getYearPeriods();
      case 'months':
        return this.getMonthPeriods();
      case 'weeks':
        return this.getWeekPeriods();
      case 'days':
        return this.getDayPeriods();
      /* istanbul ignore next */
      default:
        assertUnreachable(timeframe);
    }
  }

  public getPresetFormValue(preset: ReportingTimePeriodPreset): TimePeriodFields {
    switch (preset) {
      case 'calendarYear': {
        const inspectionStart = subYears(startOfYear(new Date()), 1);
        return {
          durationName: 'years',
          durationLength: 1,
          resolution: RequestResolution.P1M,
          periods: [inspectionStart, subYears(inspectionStart, 1)],
        };
      }
      case 'rollingYear': {
        const inspectionStart = subYears(startOfMonth(new Date()), 1);
        return {
          durationName: 'years',
          durationLength: 1,
          resolution: RequestResolution.P1M,
          periods: [inspectionStart, subYears(inspectionStart, 1)],
        };
      }
      case 'threeMonths': {
        const inspectionStart = subMonths(startOfMonth(new Date()), 3);
        return {
          durationName: 'months',
          durationLength: 3,
          resolution: RequestResolution.P1M,
          periods: [inspectionStart, subYears(inspectionStart, 1)],
        };
      }
    }
  }

  public getYearPeriods(): Date[] {
    const defaultComparisonPeriod = this.thresholdService.currentCalendarMode === CalendarMode.CalendarYear
      ? startOfYear(subYears(this.defaultStartDate, 1))
      : subYears(this.defaultStartDate, 1);
    return [
      this.defaultStartDate,
      defaultComparisonPeriod,
    ];
  }

  private getMonthPeriods(): Date[] {
    const previousMonthStart = subMonths(startOfMonth(new Date()), 1);
    return [
      previousMonthStart,
      subYears(previousMonthStart, 1),
    ];
  }

  private getWeekPeriods(): Date[] {
    const previousWeekMonday = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
    return [
      previousWeekMonday,
      subYears(previousWeekMonday, 1),
    ];
  }

  private getDayPeriods(): Date[] {
    const yesterday = subDays(startOfDay(new Date()), 1);
    return [
      yesterday,
    ];
  }

  private get defaultStartDate(): Date {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startYear = isAfter(currentDate, new Date(currentYear, 1, 5))
      ? currentYear
      : currentYear - 1
    ;

    if (this.thresholdService.currentCalendarMode === CalendarMode.CalendarYear) {
      return startOfYear(new Date(startYear, 0, 1));
    }

    return subYears(startOfMonth(new Date()), 1);
  }
}
