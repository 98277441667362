import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { MeteringType, MeterManagementMeter } from '@enerkey/clients/meter-management';
import { MeteringClient, SubMeter } from '@enerkey/clients/metering';

import { REPORT_MODAL_PARAMS, ReportingModalParams } from '../components/report-modal/report-modal.component';
import { ReportingMeterSelection } from '../shared/reporting-meter-selection';

const meteringTypeNameTranslations: Record<MeteringType, string> = {
  [MeteringType.Hourly]: 'METER_COUNTS.HOURLY',
  [MeteringType.ManualReading]: 'METER_COUNTS.MANUAL_READINGS',
  [MeteringType.Constant]: 'METER_COUNTS.CONSTANT',
  [MeteringType.Virtual]: 'METER_COUNTS.VIRTUAL',
  [MeteringType.ManualConsumption]: 'METER_COUNTS.MANUAL_CONSUMPTIONS',
};

export class ReportingMeterTreeMeter extends MeterManagementMeter {
  public readonly meterTreeVisibleName: string;
  public measurementMethod: string;
  public meterTypeName: string;

  public constructor(
    meter: MeterManagementMeter,
    public readonly subMeters?: ReportingMeterTreeMeter[]
  ) {
    super(meter);

    this.meterTreeVisibleName = `${meter.name} (${meter.id})`;
    this.measurementMethod = `METERS.MEASUREMENT_METHOD.${this.twoTimeMeasurement ? 'TWO_TIME' : 'ONE_TIME'}`;
    this.meterTypeName = meteringTypeNameTranslations[meter.meteringType];
  }
}

@Injectable()
export class ReportModalMetersService implements OnDestroy {
  public readonly metersFlat$: Observable<ReportingMeterTreeMeter[]>;
  public readonly meterTrees$: Observable<ReportingMeterTreeMeter[]>;
  public readonly selectedMeters$: Observable<ReportingMeterSelection>;

  private readonly _selectedMeters = new BehaviorSubject<ReportingMeterSelection>(new ReportingMeterSelection([]));
  private readonly _meterTrees$ = new BehaviorSubject<ReportingMeterTreeMeter[]>([]);

  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly meteringClient: MeteringClient,
    @Inject(REPORT_MODAL_PARAMS) private modalParams: ReportingModalParams
  ) {
    this.selectedMeters$ = this._selectedMeters.asObservable();
    this.meterTrees$ = this._meterTrees$.asObservable();

    this.updateMeterTrees();
    this.metersFlat$ = this.meterTrees$.pipe(
      map(trees => trees.flatMap(t => this.getTreeMeters(t))),
      shareReplay(1)
    );

    if (modalParams.meterIds?.length) {
      this.selectMeters(modalParams.meterIds);
    }
  }

  public selectMeters(meterIds: number[]): void {
    this.metersFlat$.pipe(takeUntil(this.destroy$)).subscribe({
      next: metersFlat => {
        this._selectedMeters.next(new ReportingMeterSelection(metersFlat.filter(m => meterIds.includes(m.id))));
      }
    });
  }

  public updateMeterTrees(): void {
    const facilityId = this.modalParams.facilityId;
    const hierarchies$ = this.meteringClient.getMeterHierarchyIdsForReportingObjects([facilityId]).pipe(
      switchMap(response => this.meteringClient.getMeterHierarchies(response[0].meterHierarchyIds)),
      shareReplay(1)
    );
    const metersById$ = this.meteringClient.getMetersForFacilityIds([facilityId]).pipe(
      tap(x => x),
      map(metersByFacility => metersByFacility[facilityId]),
      map(meters => meters.toRecord(m => m.id)),
      shareReplay(1)
    );
    forkJoin([
      hierarchies$,
      metersById$
    ]).pipe(
      map(([hierarchies, meters]) => hierarchies.sortBy(h => h.mainMeter.ordinal).map(
        h => this.getMeterHierarchy(h.mainMeter, meters)
      ).sortBy('quantityId'))
    ).subscribe(newMeterTrees => {
      this._meterTrees$.next(newMeterTrees);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getMeterHierarchy(
    meter: SubMeter,
    metersById: Record<number, MeterManagementMeter>
  ): ReportingMeterTreeMeter {
    return new ReportingMeterTreeMeter(
      metersById[meter.meterId],
      Array.hasItems(meter.subMeters)
        ? meter.subMeters.sortBy('ordinal').map(sm => this.getMeterHierarchy(sm, metersById))
        : undefined
    );
  }

  private getTreeMeters(tree: ReportingMeterTreeMeter): ReportingMeterTreeMeter[] {
    const meters: ReportingMeterTreeMeter[] = [];
    meters.push(tree);
    if (Array.hasItems(tree.subMeters)) {
      tree.subMeters.forEach(t => {
        meters.push(...this.getTreeMeters(t));
      });
    }
    return meters;
  }
}
