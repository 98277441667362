import { Injectable, OnDestroy } from '@angular/core';
import moment from 'moment';
import { combineLatest, EMPTY, forkJoin, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { startOfYear, subYears } from 'date-fns';

import { ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';

import { QuantityService } from '../../../shared/services/quantity.service';
import { RelationalValueId } from '../../reportingobjects/constants/facilities-properties';
import { ErTimeFrameService, TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';
import { ColorService } from '../../../shared/services/color.service';
import { ValueType } from '../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { TimeFrameOptions } from '../../../constants/time-frame';
import { EmissionsWidgetData, FacilityWiseEmissions } from '../shared/facility-wise-emissions';
import { PeriodReportService } from '../../reporting/services/period-report.service';
import { ReportingSearchParams } from '../../reporting/shared/reporting-search-params';
import { getDefaultReportingParams } from '../../reporting/services/reporting.search.functions';

import { FacilityService } from '../../../shared/services/facility.service';
import { ExtendedFacilityInformation } from '../../../shared/interfaces/extended-facility-information';

export enum EmissionFactorType {
  Facility = 'Facility',
  Location = 'Location'
}

export interface EmissionsFacilityWiseWidgetOptions {
  factorOption: EmissionFactorType;
  facilityId: number;
}

const relationalValueIds: Record<EmissionFactorType, RelationalValueId> = {
  [EmissionFactorType.Facility]: RelationalValueId.co2Factor,
  [EmissionFactorType.Location]: RelationalValueId.co2NationalReference,
};

@Injectable()
export class EmissionsFacilityWiseWidgetService implements OnDestroy {
  public readonly relationalValueId$: Observable<RelationalValueId>;
  public readonly years$: Observable<{ current: string; previous: string; first: string }>;
  public readonly emissionTitle$: Observable<string>;
  public readonly selectedFacility$: Observable<ExtendedFacilityInformation>;
  private readonly quantitie$: Observable<number[]>;

  private readonly start$: Observable<TimeFrameResult>;
  private readonly dataModelOptions$ = new ReplaySubject<EmissionsFacilityWiseWidgetOptions>(1);

  private readonly _destroy$ = new Subject<void>();

  private readonly lastYear: number;
  private readonly yearsToShow = 2;

  public constructor(
    private readonly quantityService: QuantityService,
    private readonly timeFrameService: ErTimeFrameService,
    private readonly colorService: ColorService,
    private readonly periodReportService: PeriodReportService,
    private readonly facilityService: FacilityService
  ) {

    const defaultTimeFrame = this.timeFrameService.getTimeFrameAndResParams(
      TimeFrameOptions.YEAR_BY_YEAR_CALENDAR, 'None'
    ).Start[0].value;
    const comparisonPeriodsToGet = 2;

    this.start$ = of(this.timeFrameService.getStartFromResolution(
      'P1Y',
      moment(defaultTimeFrame).subtract(comparisonPeriodsToGet, 'years'),
      moment(defaultTimeFrame).add(1, 'years')
    ));

    this.years$ = this.start$.pipe(
      map(start => ({
        current: start.Start[start.Start.length - 1].key,
        previous: start.Start[start.Start.length - 2].key,
        first: start.Start[0].key,
      }))
    );

    this.relationalValueId$ = this.dataModelOptions$.pipe(
      map(options => relationalValueIds[options.factorOption]),
      shareReplay(1)
    );

    this.emissionTitle$ = this.relationalValueId$.pipe(
      map(
        relationalValueId => relationalValueId === RelationalValueId.co2Factor
          ? 'EMISSIONS_WIDGET.FACTOR.FACILITY'
          : 'EMISSIONS_WIDGET.FACTOR.LOCATION'
      )
    );

    this.quantitie$ = from(this.quantityService.getSignificantQuantitiesForProfile()).pipe(
      map(quantities => quantities.filterMap(q => q.Emission && !Array.hasItems(q.SumOf), q => q.ID))
    );

    this.lastYear = new Date().getFullYear() - this.yearsToShow;

    this.selectedFacility$ = combineLatest([
      this.facilityService.profileFacilities$,
      this.dataModelOptions$
    ]).pipe(
      switchMap(([profileFacilities, options]) =>
        profileFacilities.filter(data => data.facilityId === options.facilityId)),
      shareReplay(1)
    );

  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.dataModelOptions$.complete();
  }

  public setDataModelOptions(options: EmissionsFacilityWiseWidgetOptions): void {
    this.dataModelOptions$.next(options);
  }

  public getEmissionsData(threshold: number): Observable<EmissionsWidgetData> {
    return forkJoin([
      this.dataModelOptions$.pipe(take(1)),
      this.quantitie$.pipe(take(1)),
      this.relationalValueId$.pipe(take(1)),
      this.start$.pipe(take(1)),
    ]).pipe(
      switchMap(([options, quantities, relationalValueId, start]) => {
        if (!options?.facilityId) {
          return EMPTY;
        }
        return this.getEmissionData(
          options, quantities, relationalValueId, threshold, start
        );
      })
    );
  }

  private getEmissionData(options: EmissionsFacilityWiseWidgetOptions,
    quantities: number[],
    relationalValueId: number,
    incompleteThreshold: number,
    start: TimeFrameResult): Observable<EmissionsWidgetData> {
    const quantityColors = quantities.toMap(
      quantity => quantity,
      quantity => this.colorService.getPrimaryGraphColors(quantity, start.Start.length)
    );

    return this.periodReportService.getData(
      new ReportingSearchParams({
        ...getDefaultReportingParams(),
        quantityIds: quantities,
        periods: [startOfYear(subYears(new Date(this.lastYear, 0, 1), 0))],
        durationName: 'years',
        durationLength: start.Start.length,
        emissionIds: [relationalValueId],
        resolution: RequestResolution.P1Y,
        valueType: ValueType.Measured,
        comparability: Comparability.All,
        showConsumption: false,
        reportingUnit: ReportingUnit.Default,
      }),
      [options.facilityId],
      incompleteThreshold
    ).pipe(
      map(data => FacilityWiseEmissions.getWidgetData(data, quantityColors, start, options))
    );
  }

}
