import { UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';

export const customerAdminTopbarState: UIStateNg2<'customerAdmin'> = {
  name: 'customerAdmin',
  data: {
    auth: {
      roles: [Roles.CUSTOMER_ADMINSTRATOR]
    },
    translationKey: 'CUSTOMERADMIN.TAB_TITLE',
  },
};
