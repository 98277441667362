
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

const reportingTimePeriodPresets = [
  { value: 'calendarYear', text: 'TIMEFRAME_OPTIONS.YEARBYYEARCALENDAR' },
  { value: 'rollingYear', text: 'TIMEFRAME_OPTIONS.YEARBYYEARFLOATING' },
  { value: 'threeMonths', text: 'REPORTING.SEARCH.PERIOD_QUICK_THREEMONTHS' },
] as const;

export type ReportingTimePeriodPreset = typeof reportingTimePeriodPresets[number]['value'];

@Component({
  selector: 'time-period-quick-select',
  templateUrl: './time-period-quick-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePeriodQuickSelectComponent {
  @Output() public readonly timePeriodSelect = new EventEmitter<ReportingTimePeriodPreset>();

  public readonly quickSelectOptions = reportingTimePeriodPresets.map(i => ({
    ...i
  }));

  public click(item: ReportingTimePeriodPreset): void {
    this.timePeriodSelect.emit(item);
  }
}
