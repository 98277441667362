import { QuantityItem } from '@enerkey/clients/energy-reporting';
import { Quantities } from '@enerkey/clients/metering';
import { getEnumEntries } from '@enerkey/ts-utils';

import { ReportingUnit } from '@enerkey/clients/reporting';

import { Service } from './service';
import { CoefficientMethods } from '../modules/configuration/constants/coefficient-methods';

type DecimalByUnits = { [K in ReportingUnit]: {
  unit: string,
  decimalValue: number
}}

export const quantityTranslations: Readonly<Record<Quantities, string>> = {
  [Quantities.Undefined]: 'QUANTITIES.UNKNOWN',
  [Quantities.Electricity]: 'QUANTITIES.ELECTRICITY',
  [Quantities.NaturalGas]: 'QUANTITIES.NATURAL_GAS',
  [Quantities.DistrictHeating]: 'QUANTITIES.DISTRICT_HEATING',
  [Quantities.Water]: 'QUANTITIES.WATER',
  [Quantities.Oil]: 'QUANTITIES.OIL',
  [Quantities.HotWater]: 'QUANTITIES.HOT_WATER',
  [Quantities.IceAgeWater]: 'QUANTITIES.ICE_AGE_WATER',
  [Quantities.WasteWater]: 'QUANTITIES.WASTE_WATER',
  [Quantities.CoolingWater]: 'QUANTITIES.COOLING_WATER',
  [Quantities.CompressedAir]: 'QUANTITIES.COMPRESSED_AIR',
  [Quantities.DistrictCooling]: 'QUANTITIES.DISTRICT_COOLING',
  [Quantities.ReactivePower]: 'QUANTITIES.REACTIVE_POWER',
  [Quantities.DistrictHeatingWater]: 'QUANTITIES.DISTRICT_HEATING_WATER',
  [Quantities.DistrictHeatingOutflowWater]: 'QUANTITIES.DISTRICT_HEATING_OUTFLOW_WATER',
  [Quantities.DistrictHeatingInflowWater]: 'QUANTITIES.DISTRICT_HEATING_INFLOW_WATER',
  [Quantities.DistrictCoolingWater]: 'QUANTITIES.DISTRICT_COOLING_WATER',
  [Quantities.Steam]: 'QUANTITIES.STEAM',
  [Quantities.LiquefiedPetroleumGas]: 'QUANTITIES.LIQUEFIED_PETROLEUM_GAS',
  [Quantities.HeatingOwnProduction]: 'QUANTITIES.HEATING_OWN_PRODUCTION',
  [Quantities.CoolingOwnProduction]: 'QUANTITIES.COOLING_OWN_PRODUCTION',
  [Quantities.NaturalGasOther]: 'QUANTITIES.NATURAL_GAS_OTHER',
  [Quantities.BioGas]: 'QUANTITIES.BIO_GAS',
  [Quantities.Pellet]: 'QUANTITIES.PELLET',
  [Quantities.WoodChips]: 'QUANTITIES.WOOD_CHIPS',
  [Quantities.HeavyOil]: 'QUANTITIES.HEAVY_OIL',
  [Quantities.CarbonDioxide]: 'QUANTITIES.CARBON_DIOXIDE',
  [Quantities.Oxygen]: 'QUANTITIES.OXYGEN',
  [Quantities.Nitrogen]: 'QUANTITIES.NITROGEN',
  [Quantities.DryIce]: 'QUANTITIES.DRY_ICE',
  [Quantities.ReactivePowerPlus]: 'QUANTITIES.REACTIVE_POWER_PLUS',
  [Quantities.ReactivePowerMinus]: 'QUANTITIES.REACTIVE_POWER_MINUS',
  [Quantities.IndoorAirQuality]: 'QUANTITIES.INDOOR_AIR_QUALITY',
  [Quantities.IndoorPermanence]: 'QUANTITIES.INDOOR_PERMANENCE',
  [Quantities.TemperaturePermanence]: 'QUANTITIES.TEMPERATURE_PERMANENCE',
  [Quantities.CO2Permanence]: 'QUANTITIES.CO2_PERMANENCE',
  [Quantities.HumidityPermanence]: 'QUANTITIES.HUMIDITY_PERMANENCE',
  [Quantities.ParticulatesPermanence]: 'QUANTITIES.PARTICULATES_PERMANENCE',
  [Quantities.PressurePermanence]: 'QUANTITIES.PRESSURE_PERMANENCE',
  [Quantities.VOCPermanence]: 'QUANTITIES.VOC_PERMANENCE',
  [Quantities.IndoorTemperature]: 'QUANTITIES.INDOOR_TEMPERATURE',
  [Quantities.IndoorCO2]: 'QUANTITIES.INDOOR_CO2',
  [Quantities.IndoorHumidity]: 'QUANTITIES.INDOOR_HUMIDITY',
  [Quantities.IndoorParticulates]: 'QUANTITIES.INDOOR_PARTICULATES',
  [Quantities.IndoorPressure]: 'QUANTITIES.INDOOR_PRESSURE',
  [Quantities.IndoorVOC]: 'QUANTITIES.INDOOR_VOC',
  [Quantities.FacilityElectricity]: 'QUANTITIES.FACILITY_ELECTRICITY',
  [Quantities.Lighting]: 'QUANTITIES.LIGHTING',
  [Quantities.Refrigeration]: 'QUANTITIES.REFRIGERATION',
  [Quantities.CondensationHeat]: 'QUANTITIES.CONDENSATION_HEAT',
  [Quantities.HeatingElectricity]: 'QUANTITIES.HEATING_ELECTRICITY',
  [Quantities.Ventilation]: 'QUANTITIES.VENTILATION',
  [Quantities.CoolingSystem]: 'QUANTITIES.COOLING_SYSTEM',
  [Quantities.ChargingSystem]: 'QUANTITIES.CHARGING_SYSTEM',
  [Quantities.HeatPumps]: 'QUANTITIES.HEAT_PUMPS',
  [Quantities.PUE]: 'QUANTITIES.PUE',
  [Quantities.Percentage]: 'QUANTITIES.PERCENTAGE',
  [Quantities.Ratio]: 'QUANTITIES.RATIO',
  [Quantities.Custom]: 'QUANTITIES.CUSTOM',
  [Quantities.Percentage2]: 'QUANTITIES.PERCENTAGE2',
  [Quantities.Ratio2]: 'QUANTITIES.RATIO2',
  [Quantities.Custom2]: 'QUANTITIES.CUSTOM2',
  [Quantities.DieselTransport]: 'QUANTITIES.DIESEL_TRANSPORT',
  [Quantities.PetrolTransport]: 'QUANTITIES.PETROL_TRANSPORT',
  [Quantities.LNGTransport]: 'QUANTITIES.LNG_TRANSPORT',
  [Quantities.BiogasTransport]: 'QUANTITIES.BIOGAS_TRANSPORT',
  [Quantities.NaturalGasTransport]: 'QUANTITIES.NATURAL_GAS_TRANSPORT',
  [Quantities.ElectricityTransport]: 'QUANTITIES.ELECTRICITY_TRANSPORT',
  [Quantities.FuelOilTransport]: 'QUANTITIES.FUEL_OIL_TRANSPORT',
  [Quantities.EthanolE85Transport]: 'QUANTITIES.ETHANOL_E85_TRANSPORT',
  [Quantities.BioDieselTransport]: 'QUANTITIES.BIO_DIESEL_TRANSPORT',
  [Quantities.KmTransport]: 'QUANTITIES.KM_TRANSPORT',
  [Quantities.MetalWaste]: 'QUANTITIES.METAL_WASTE',
  [Quantities.PlasticWaste]: 'QUANTITIES.PLASTIC_WASTE',
  [Quantities.GlassWaste]: 'QUANTITIES.GLASS_WASTE',
  [Quantities.MixedNoRecyclingWaste]: 'QUANTITIES.MIXED_NO_RECYCLING_WASTE',
  [Quantities.Biowaste]: 'QUANTITIES.BIOWASTE',
  [Quantities.PaperWaste]: 'QUANTITIES.PAPER_WASTE',
  [Quantities.MoldedFiberWaste]: 'QUANTITIES.MOLDED_FIBER_WASTE',
  [Quantities.ElectricalAndElectronicWaste]: 'QUANTITIES.ELECTRICAL_AND_ELECTRONIC_WASTE',
  [Quantities.WoodWaste]: 'QUANTITIES.WOOD_WASTE',
  [Quantities.OtherWaste]: 'QUANTITIES.OTHER_WASTE',
  [Quantities.HazardousWaste]: 'QUANTITIES.HAZARDOUS_WASTE',
  [Quantities.OwnElectricity]: 'QUANTITIES.OWN_ELECTRICITY',
  [Quantities.COP]: 'QUANTITIES.COP',
  [Quantities.EER]: 'QUANTITIES.EER',
  [Quantities.SCOP]: 'QUANTITIES.SCOP',
  [Quantities.SEER]: 'QUANTITIES.SEER',
  [Quantities.PersonsInAvg]: 'QUANTITIES.PERSONS_IN_AVG',
  [Quantities.PersonsOutAvg]: 'QUANTITIES.PERSONS_OUT_AVG',
  [Quantities.PersonsOccupancyAvg]: 'QUANTITIES.PERSONS_OCCUPANCY_AVG',
  [Quantities.PersonsIn]: 'QUANTITIES.PERSONS_IN',
  [Quantities.PersonsOut]: 'QUANTITIES.PERSONS_OUT',
  [Quantities.PersonsOccupancy]: 'QUANTITIES.PERSONS_OCCUPANCY',
  [Quantities.PelletMWh]: 'QUANTITIES.PELLET_MWH',
  [Quantities.NaturalGasMWh]: 'QUANTITIES.NATURAL_GAS_MWH',
  [Quantities.OilMWh]: 'QUANTITIES.OIL_GAS_MWH',
  [Quantities.LiquidGasMWh]: 'QUANTITIES.LIQUID_GAS_MWH',
  [Quantities.NaturalGasOtherMWh]: 'QUANTITIES.NATURAL_GAS_OTHER_MWH',
  [Quantities.BiogasMWh]: 'QUANTITIES.BIOGAS_MWH',
  [Quantities.WoodChipsMWh]: 'QUANTITIES.WOOD_CHIPS_MWH',
  [Quantities.HeavyFuelOilMWh]: 'QUANTITIES.HEAVY_FUEL_OIL_MWH',
  [Quantities.RoomHeating]: 'QUANTITIES.ROOM_HEATING',
  [Quantities.VentilationHeating]: 'QUANTITIES.VENTILATION_HEATING',
  [Quantities.Fans]: 'QUANTITIES.FANS',
  [Quantities.Pumps]: 'QUANTITIES.PUMPS',
  [Quantities.TechnicalEquipment]: 'QUANTITIES.TECHNICAL_EQUIPMENT',
  [Quantities.RoomCooling]: 'QUANTITIES.ROOM_COOLING',
  [Quantities.VentilationCooling]: 'QUANTITIES.VENTILATION_COOLING',
  [Quantities.NetConsumption]: 'QUANTITIES.NET_CONSUMPTION',
  [Quantities.Purchased]: 'QUANTITIES.PURCHASED',
  [Quantities.Sold]: 'QUANTITIES.SOLD',
  [Quantities.Used]: 'QUANTITIES.USED',
  [Quantities.TenantsTotal]: 'QUANTITIES.TENANTS_TOTAL',
  [Quantities.VacanciesTotal]: 'QUANTITIES.VACANCIES_TOTAL',
  [Quantities.SharedAreaTotal]: 'QUANTITIES.SHARED_AREA_TOTAL',
  [Quantities.StreetHeating]: 'QUANTITIES.STREET_HEATING',
  [Quantities.SolarCellProduction]: 'QUANTITIES.SOLAR_CELL_PRODUCTION',
  [Quantities.EVCharging]: 'QUANTITIES.EV_CHARGING',
  [Quantities.Bakeries]: 'QUANTITIES.BAKERIES',
  // 1000 ->
  [Quantities.HeatingEnergy]: 'QUANTITIES.HEATING_ENERGY',
  [Quantities.TotalEnergy]: 'QUANTITIES.TOTAL_ENERGY',
  [Quantities.Transport]: 'QUANTITIES.TRANSPORT',
  [Quantities.WasteRecycle]: 'QUANTITIES.WASTE_RECYCLE',
  [Quantities.TotalWaste]: 'QUANTITIES.TOTAL_WASTE',
  [Quantities.HeatingPurchased]: 'QUANTITIES.HEATING_PURCHASED',
  [Quantities.TotalEnergyPurchased]: 'QUANTITIES.TOTAL_ENERGY_PURCHASED',
  [Quantities.TotalElectricity]: 'QUANTITIES.TOTAL_ELECTRICITY',
  [Quantities.TotalCooling]: 'QUANTITIES.TOTAL_COOLING',
  [Quantities.TotalOwnEnergy]: 'QUANTITIES.TOTAL_OWN_ENERGY',
  [Quantities.CoolingOfDistrictHeating]: 'ANALYTICS.HEATINGPOWER.REPORT.DISTRICT_HEATING_COOLING.TABLE_DESC_COOL',
  [Quantities.HeatingOfDistrictCooling]: 'QUANTITIES.HEATING_ENERGY'
};

export const quantityDecimals: Record<Quantities, DecimalByUnits> =
  {
    [Quantities.Undefined]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 0
      }
    },
    [Quantities.Electricity]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.ReactivePower]: {
      Default: {
        unit: 'kVar',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Water]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'm³',
        decimalValue: 2
      },
      MWh: {
        unit: 'm³',
        decimalValue: 2
      }
    },
    [Quantities.HotWater]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'm³',
        decimalValue: 2
      },
      MWh: {
        unit: 'm³',
        decimalValue: 2
      }
    },
    [Quantities.DistrictHeating]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.DistrictHeatingWater]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.DistrictHeatingOutflowWater]: {
      Default: {
        unit: '°C',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.DistrictHeatingInflowWater]: {
      Default: {
        unit: '°C',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Steam]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Oil]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.NaturalGas]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.DistrictCooling]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.DistrictCoolingWater]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.IceAgeWater]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'm³',
        decimalValue: 2
      },
      MWh: {
        unit: 'm³',
        decimalValue: 2
      }
    },
    [Quantities.WasteWater]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'm³',
        decimalValue: 2
      },
      MWh: {
        unit: 'm³',
        decimalValue: 2
      }
    },
    [Quantities.CoolingWater]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'm³',
        decimalValue: 2
      },
      MWh: {
        unit: 'm³',
        decimalValue: 2
      }
    },
    [Quantities.CompressedAir]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'm³',
        decimalValue: 2
      },
      MWh: {
        unit: 'm³',
        decimalValue: 2
      }
    },
    [Quantities.LiquefiedPetroleumGas]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.HeatingOwnProduction]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.CoolingOwnProduction]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.NaturalGasOther]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.BioGas]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 2
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Pellet]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.WoodChips]: {
      Default: {
        unit: 'm³',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.HeavyOil]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.CarbonDioxide]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.Oxygen]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.Nitrogen]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.DryIce]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.ReactivePowerPlus]: {
      Default: {
        unit: 'kVar',
        decimalValue: 0
      },
      kWh: {
        unit: 'kVar',
        decimalValue: 0
      },
      MWh: {
        unit: 'kVar',
        decimalValue: 0
      }
    },
    [Quantities.ReactivePowerMinus]: {
      Default: {
        unit: 'kVar',
        decimalValue: 0
      },
      kWh: {
        unit: 'kVar',
        decimalValue: 0
      },
      MWh: {
        unit: 'kVar',
        decimalValue: 0
      }
    },
    [Quantities.IndoorAirQuality]: {
      Default: {
        unit: '%',
        decimalValue: 2
      },
      kWh: {
        unit: '%',
        decimalValue: 2
      },
      MWh: {
        unit: '%',
        decimalValue: 2
      }
    },
    [Quantities.IndoorPermanence]: {
      Default: {
        unit: '%',
        decimalValue: 2
      },
      kWh: {
        unit: '%',
        decimalValue: 2
      },
      MWh: {
        unit: '%',
        decimalValue: 2
      }
    },
    [Quantities.TemperaturePermanence]: {
      Default: {
        unit: '°C',
        decimalValue: 2
      },
      kWh: {
        unit: '°C',
        decimalValue: 2
      },
      MWh: {
        unit: '°C',
        decimalValue: 2
      }
    },
    [Quantities.CO2Permanence]: {
      Default: {
        unit: '%',
        decimalValue: 2
      },
      kWh: {
        unit: '%',
        decimalValue: 2
      },
      MWh: {
        unit: '%',
        decimalValue: 2
      }
    },
    [Quantities.HumidityPermanence]: {
      Default: {
        unit: '%',
        decimalValue: 2
      },
      kWh: {
        unit: '%',
        decimalValue: 2
      },
      MWh: {
        unit: '%',
        decimalValue: 2
      }
    },
    [Quantities.ParticulatesPermanence]: {
      Default: {
        unit: '%',
        decimalValue: 2
      },
      kWh: {
        unit: '%',
        decimalValue: 2
      },
      MWh: {
        unit: '%',
        decimalValue: 2
      }
    },
    [Quantities.PressurePermanence]: {
      Default: {
        unit: '%',
        decimalValue: 2
      },
      kWh: {
        unit: '%',
        decimalValue: 2
      },
      MWh: {
        unit: '%',
        decimalValue: 2
      }
    },
    [Quantities.VOCPermanence]: {
      Default: {
        unit: '%',
        decimalValue: 2
      },
      kWh: {
        unit: '%',
        decimalValue: 2
      },
      MWh: {
        unit: '%',
        decimalValue: 2
      }
    },
    [Quantities.IndoorTemperature]: {
      Default: {
        unit: '°C',
        decimalValue: 0
      },
      kWh: {
        unit: '°C',
        decimalValue: 0
      },
      MWh: {
        unit: '°C',
        decimalValue: 2
      }
    },
    [Quantities.IndoorCO2]: {
      Default: {
        unit: 'ppm',
        decimalValue: 2
      },
      kWh: {
        unit: 'ppm',
        decimalValue: 2
      },
      MWh: {
        unit: 'ppm',
        decimalValue: 2
      }
    },
    [Quantities.IndoorHumidity]: {
      Default: {
        unit: '%',
        decimalValue: 2
      },
      kWh: {
        unit: '%',
        decimalValue: 2
      },
      MWh: {
        unit: '%',
        decimalValue: 2
      }
    },
    [Quantities.IndoorParticulates]: {
      Default: {
        unit: 'µg/m³',
        decimalValue: 2
      },
      kWh: {
        unit: 'µg/m³',
        decimalValue: 2
      },
      MWh: {
        unit: 'µg/m³',
        decimalValue: 2
      }
    },
    [Quantities.IndoorPressure]: {
      Default: {
        unit: 'Pascal',
        decimalValue: 2
      },
      kWh: {
        unit: 'Pascal',
        decimalValue: 2
      },
      MWh: {
        unit: 'Pascal',
        decimalValue: 2
      }
    },
    [Quantities.IndoorVOC]: {
      Default: {
        unit: 'μg/m3',
        decimalValue: 2
      },
      kWh: {
        unit: 'μg/m3',
        decimalValue: 2
      },
      MWh: {
        unit: 'μg/m3',
        decimalValue: 2
      }
    },
    [Quantities.FacilityElectricity]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Lighting]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Refrigeration]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.CondensationHeat]: {
      Default: {
        unit: 'kWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.HeatingElectricity]: {
      Default: {
        unit: 'kWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Ventilation]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.CoolingSystem]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.ChargingSystem]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.HeatPumps]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.PUE]: {
      Default: {
        unit: 'Index',
        decimalValue: 2
      },
      kWh: {
        unit: 'Index',
        decimalValue: 2
      },
      MWh: {
        unit: 'Index',
        decimalValue: 2
      }
    },
    [Quantities.Percentage]: {
      Default: {
        unit: '%',
        decimalValue: 2
      },
      kWh: {
        unit: '%',
        decimalValue: 2
      },
      MWh: {
        unit: '%',
        decimalValue: 2
      }
    },
    [Quantities.Ratio]: {
      Default: {
        unit: 'Index',
        decimalValue: 2
      },
      kWh: {
        unit: 'Index',
        decimalValue: 2
      },
      MWh: {
        unit: 'Index',
        decimalValue: 2
      }
    },
    [Quantities.Custom]: {
      Default: {
        unit: 'unit',
        decimalValue: 2
      },
      kWh: {
        unit: 'unit',
        decimalValue: 2
      },
      MWh: {
        unit: 'unit',
        decimalValue: 2
      }
    },
    [Quantities.Percentage2]: {
      Default: {
        unit: '%',
        decimalValue: 2
      },
      kWh: {
        unit: '%',
        decimalValue: 2
      },
      MWh: {
        unit: '%',
        decimalValue: 2
      }
    },
    [Quantities.Ratio2]: {
      Default: {
        unit: 'Index',
        decimalValue: 2
      },
      kWh: {
        unit: 'Index',
        decimalValue: 2
      },
      MWh: {
        unit: 'Index',
        decimalValue: 2
      }
    },
    [Quantities.Custom2]: {
      Default: {
        unit: 'unit',
        decimalValue: 2
      },
      kWh: {
        unit: 'unit',
        decimalValue: 2
      },
      MWh: {
        unit: 'unit',
        decimalValue: 2
      }
    },
    [Quantities.DieselTransport]: {
      Default: {
        unit: 'l',
        decimalValue: 0
      },
      kWh: {
        unit: 'l',
        decimalValue: 0
      },
      MWh: {
        unit: 'l',
        decimalValue: 2
      }
    },
    [Quantities.PetrolTransport]: {
      Default: {
        unit: 'l',
        decimalValue: 0
      },
      kWh: {
        unit: 'l',
        decimalValue: 0
      },
      MWh: {
        unit: 'l',
        decimalValue: 2
      }
    },
    [Quantities.LNGTransport]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 2
      }
    },
    [Quantities.BiogasTransport]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.NaturalGasTransport]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.ElectricityTransport]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.FuelOilTransport]: {
      Default: {
        unit: 'l',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.EthanolE85Transport]: {
      Default: {
        unit: 'l',
        decimalValue: 0
      },
      kWh: {
        unit: 'l',
        decimalValue: 0
      },
      MWh: {
        unit: 'l',
        decimalValue: 2
      }
    },
    [Quantities.BioDieselTransport]: {
      Default: {
        unit: 'l',
        decimalValue: 0
      },
      kWh: {
        unit: 'l',
        decimalValue: 0
      },
      MWh: {
        unit: 'l',
        decimalValue: 2
      }
    },
    [Quantities.KmTransport]: {
      Default: {
        unit: 'km',
        decimalValue: 0
      },
      kWh: {
        unit: 'km',
        decimalValue: 0
      },
      MWh: {
        unit: 'km',
        decimalValue: 0
      }
    },
    [Quantities.MetalWaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.PlasticWaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.GlassWaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.MixedNoRecyclingWaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.Biowaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.PaperWaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.MoldedFiberWaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.ElectricalAndElectronicWaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.WoodWaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.OtherWaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.HazardousWaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.OwnElectricity]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.COP]: {
      Default: {
        unit: 'Index',
        decimalValue: 2
      },
      kWh: {
        unit: 'Index',
        decimalValue: 2
      },
      MWh: {
        unit: 'Index',
        decimalValue: 2
      }
    },
    [Quantities.EER]: {
      Default: {
        unit: 'Index',
        decimalValue: 2
      },
      kWh: {
        unit: 'Index',
        decimalValue: 2
      },
      MWh: {
        unit: 'Index',
        decimalValue: 2
      }
    },
    [Quantities.SCOP]: {
      Default: {
        unit: 'Index',
        decimalValue: 2
      },
      kWh: {
        unit: 'Index',
        decimalValue: 2
      },
      MWh: {
        unit: 'Index',
        decimalValue: 2
      }
    },
    [Quantities.SEER]: {
      Default: {
        unit: 'Index',
        decimalValue: 2
      },
      kWh: {
        unit: 'Index',
        decimalValue: 2
      },
      MWh: {
        unit: 'Index',
        decimalValue: 2
      }
    },
    [Quantities.PersonsInAvg]: {
      Default: {
        unit: 'unit',
        decimalValue: 2
      },
      kWh: {
        unit: 'unit',
        decimalValue: 2
      },
      MWh: {
        unit: 'unit',
        decimalValue: 2
      }
    },
    [Quantities.PersonsOutAvg]: {
      Default: {
        unit: 'unit',
        decimalValue: 2
      },
      kWh: {
        unit: 'unit',
        decimalValue: 2
      },
      MWh: {
        unit: 'unit',
        decimalValue: 2
      }
    },
    [Quantities.PersonsOccupancyAvg]: {
      Default: {
        unit: 'unit',
        decimalValue: 2
      },
      kWh: {
        unit: 'unit',
        decimalValue: 2
      },
      MWh: {
        unit: 'unit',
        decimalValue: 2
      }
    },
    [Quantities.PersonsIn]: {
      Default: {
        unit: 'unit',
        decimalValue: 2
      },
      kWh: {
        unit: 'unit',
        decimalValue: 2
      },
      MWh: {
        unit: 'unit',
        decimalValue: 2
      }
    },
    [Quantities.PersonsOut]: {
      Default: {
        unit: 'unit',
        decimalValue: 2
      },
      kWh: {
        unit: 'unit',
        decimalValue: 2
      },
      MWh: {
        unit: 'unit',
        decimalValue: 2
      }
    },
    [Quantities.PersonsOccupancy]: {
      Default: {
        unit: 'unit',
        decimalValue: 2
      },
      kWh: {
        unit: 'unit',
        decimalValue: 2
      },
      MWh: {
        unit: 'unit',
        decimalValue: 2
      }
    },
    [Quantities.PelletMWh]: {
      Default: {
        unit: 'MWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 0
      }
    },
    [Quantities.NaturalGasMWh]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.OilMWh]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.LiquidGasMWh]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.NaturalGasOtherMWh]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.BiogasMWh]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.WoodChipsMWh]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.HeavyFuelOilMWh]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.RoomHeating]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.VentilationHeating]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Fans]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Pumps]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.TechnicalEquipment]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.RoomCooling]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.VentilationCooling]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.NetConsumption]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Purchased]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Sold]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Used]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.TenantsTotal]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 0
      }
    },
    [Quantities.VacanciesTotal]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 0
      }
    },
    [Quantities.SharedAreaTotal]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.StreetHeating]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.SolarCellProduction]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.EVCharging]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Bakeries]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.HeatingEnergy]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.TotalEnergy]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.Transport]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'kWh',
        decimalValue: 2
      }
    },
    [Quantities.WasteRecycle]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.TotalWaste]: {
      Default: {
        unit: 'kg',
        decimalValue: 0
      },
      kWh: {
        unit: 'kg',
        decimalValue: 0
      },
      MWh: {
        unit: 'kg',
        decimalValue: 0
      }
    },
    [Quantities.HeatingPurchased]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.TotalEnergyPurchased]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.TotalElectricity]: {
      Default: {
        unit: 'kWh',
        decimalValue: 0
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.TotalCooling]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.TotalOwnEnergy]: {
      Default: {
        unit: 'MWh',
        decimalValue: 2
      },
      kWh: {
        unit: 'kWh',
        decimalValue: 0
      },
      MWh: {
        unit: 'MWh',
        decimalValue: 2
      }
    },
    [Quantities.CoolingOfDistrictHeating]: {
      Default: {
        unit: '°C',
        decimalValue: 0
      },
      kWh: {
        unit: '°C',
        decimalValue: 0
      },
      MWh: {
        unit: '°C',
        decimalValue: 2
      }
    },
    [Quantities.HeatingOfDistrictCooling]: {
      Default: {
        unit: '°C',
        decimalValue: 0
      },
      kWh: {
        unit: '°C',
        decimalValue: 0
      },
      MWh: {
        unit: '°C',
        decimalValue: 2
      }
    }
  };

export enum QuantityGroup {
  EnergyTypes = 'QUANTITY_GROUPS.ENERGY_TYPES',
  Water = 'QUANTITY_GROUPS.WATER',
  UsageTypes = 'QUANTITY_GROUPS.USAGE_TYPES',
  IndoorAirQuality = 'QUANTITY_GROUPS.INDOOR_AIR_QUALITY',
  TransportFuels = 'QUANTITY_GROUPS.TRANSPORT_FUELS',
  Waste = 'QUANTITY_GROUPS.WASTE',
  Others = 'QUANTITY_GROUPS.OTHERS',
  Undefined = 'QUANTITY_GROUPS.UNDEFINED'
}

export interface IQuantityGroup {
  title: QuantityGroup;
  quantities: QuantityItem[];
  sumQuantities: QuantityItem[];
}

interface QuantityGroupDefinition {
  title: QuantityGroup;
  quantityIds: Quantities[];
  sumQuantityIds: Quantities[];
  service: Service;
}

export const quantityGroupDefinitions: QuantityGroupDefinition[] = [
  {
    title: QuantityGroup.EnergyTypes,
    quantityIds: [
      Quantities.Electricity,
      Quantities.NaturalGas,
      Quantities.DistrictHeating,
      Quantities.Oil,
      Quantities.DistrictCooling,
      Quantities.Steam,
      Quantities.HeatingOwnProduction,
      Quantities.CoolingOwnProduction,
      Quantities.BioGas,
      Quantities.Pellet,
      Quantities.WoodChips,
      Quantities.HeavyOil,
      Quantities.ReactivePowerPlus,
      Quantities.ReactivePowerMinus,
      Quantities.OwnElectricity,
      Quantities.COP,
      Quantities.EER,
      Quantities.SCOP,
      Quantities.SEER,
      Quantities.PelletMWh,
      Quantities.NaturalGasMWh,
      Quantities.OilMWh,
      Quantities.LiquidGasMWh,
      Quantities.BiogasMWh,
      Quantities.WoodChipsMWh,
      Quantities.HeavyFuelOilMWh
    ],
    sumQuantityIds: [
      Quantities.HeatingEnergy,
      Quantities.TotalEnergy,
      Quantities.HeatingPurchased,
      Quantities.TotalEnergyPurchased,
      Quantities.TotalElectricity,
      Quantities.TotalCooling,
      Quantities.TotalOwnEnergy
    ],
    service: Service.EnergyReportingBase
  },
  {
    title: QuantityGroup.Water,
    quantityIds: [
      Quantities.Water,
      Quantities.HotWater,
      Quantities.IceAgeWater,
      Quantities.WasteWater,
      Quantities.CoolingWater
    ],
    sumQuantityIds: [],
    service: Service.EnergyReportingBase
  },
  {
    title: QuantityGroup.UsageTypes,
    quantityIds: [
      Quantities.FacilityElectricity,
      Quantities.Lighting,
      Quantities.Refrigeration,
      Quantities.CondensationHeat,
      Quantities.HeatingElectricity,
      Quantities.Ventilation,
      Quantities.CoolingSystem,
      Quantities.ChargingSystem,
      Quantities.HeatPumps,
      Quantities.PUE,
      Quantities.Percentage,
      Quantities.Ratio,
      Quantities.Custom,
      Quantities.Percentage2,
      Quantities.Ratio2,
      Quantities.Custom2
    ],
    sumQuantityIds: [],
    service: Service.EnergyReportingBase
  },
  {
    title: QuantityGroup.IndoorAirQuality,
    quantityIds: [
      Quantities.IndoorAirQuality,
      Quantities.IndoorPermanence,
      Quantities.TemperaturePermanence,
      Quantities.CO2Permanence,
      Quantities.HumidityPermanence,
      Quantities.ParticulatesPermanence,
      Quantities.PressurePermanence,
      Quantities.VOCPermanence,
      Quantities.IndoorTemperature,
      Quantities.IndoorCO2,
      Quantities.IndoorHumidity,
      Quantities.IndoorParticulates,
      Quantities.IndoorPressure,
      Quantities.IndoorVOC
    ],
    sumQuantityIds: [],
    service: Service.EnergyReportingIndoorAir
  },
  {
    title: QuantityGroup.TransportFuels,
    quantityIds: [
      Quantities.DieselTransport,
      Quantities.PetrolTransport,
      Quantities.LNGTransport,
      Quantities.BiogasTransport,
      Quantities.NaturalGasTransport,
      Quantities.ElectricityTransport,
      Quantities.FuelOilTransport,
      Quantities.EthanolE85Transport,
      Quantities.BioDieselTransport,
      Quantities.KmTransport
    ],
    sumQuantityIds: [
      Quantities.Transport
    ],
    service: Service.EnergyReportingTransportFuels
  },
  {
    title: QuantityGroup.Waste,
    quantityIds: [
      Quantities.MetalWaste,
      Quantities.PlasticWaste,
      Quantities.GlassWaste,
      Quantities.MixedNoRecyclingWaste,
      Quantities.Biowaste,
      Quantities.PaperWaste,
      Quantities.MoldedFiberWaste,
      Quantities.ElectricalAndElectronicWaste,
      Quantities.WoodWaste,
      Quantities.OtherWaste,
      Quantities.HazardousWaste
    ],
    sumQuantityIds: [
      Quantities.WasteRecycle,
      Quantities.TotalWaste
    ],
    service: Service.EnergyReportingWaste
  },
  {
    title: QuantityGroup.Others,
    quantityIds: [
      Quantities.CompressedAir,
      Quantities.NaturalGasOther,
      Quantities.CarbonDioxide,
      Quantities.Oxygen,
      Quantities.Nitrogen,
      Quantities.DryIce,
      Quantities.NaturalGasOtherMWh,
      Quantities.PersonsInAvg,
      Quantities.PersonsOutAvg,
      Quantities.PersonsOccupancyAvg,
      Quantities.PersonsIn,
      Quantities.PersonsOut,
      Quantities.PersonsOccupancy,
      Quantities.LiquefiedPetroleumGas
    ],
    sumQuantityIds: [],
    service: Service.EnergyReportingBase
  }
];

export const coeffMethodsPerQuantity = new Map([
  [Quantities.Electricity, CoefficientMethods.FLAT],
  [Quantities.NaturalGas, CoefficientMethods.HEATING],
  [Quantities.DistrictHeating, CoefficientMethods.HEATING],
  [Quantities.Water, CoefficientMethods.FLAT],
  [Quantities.Oil, CoefficientMethods.HEATING],
  [Quantities.HotWater, CoefficientMethods.FLAT],
  [Quantities.IceAgeWater, CoefficientMethods.FLAT],
  [Quantities.WasteWater, CoefficientMethods.FLAT],
  [Quantities.CoolingWater, CoefficientMethods.FLAT],
  [Quantities.CompressedAir, CoefficientMethods.FLAT],
  [Quantities.DistrictCooling, CoefficientMethods.COOLING],
  [Quantities.ReactivePower, CoefficientMethods.FLAT],
  [Quantities.DistrictHeatingWater, CoefficientMethods.HEATING],
  [Quantities.DistrictHeatingOutflowWater, CoefficientMethods.HEATING],
  [Quantities.DistrictHeatingInflowWater, CoefficientMethods.HEATING],
  [Quantities.DistrictCoolingWater, CoefficientMethods.COOLING],
  [Quantities.Steam, CoefficientMethods.STEAM],
  [Quantities.LiquefiedPetroleumGas, CoefficientMethods.FLAT],
  [Quantities.HeatingOwnProduction, CoefficientMethods.HEATING],
  [Quantities.CoolingOwnProduction, CoefficientMethods.COOLING],
  [Quantities.NaturalGasOther, CoefficientMethods.FLAT],
  [Quantities.BioGas, CoefficientMethods.HEATING],
  [Quantities.Pellet, CoefficientMethods.HEATING],
  [Quantities.WoodChips, CoefficientMethods.HEATING],
  [Quantities.HeavyOil, CoefficientMethods.HEATING],
  [Quantities.CarbonDioxide, CoefficientMethods.FLAT],
  [Quantities.Oxygen, CoefficientMethods.FLAT],
  [Quantities.Nitrogen, CoefficientMethods.FLAT],
  [Quantities.DryIce, CoefficientMethods.FLAT],
  [Quantities.ReactivePowerPlus, CoefficientMethods.FLAT],
  [Quantities.ReactivePowerMinus, CoefficientMethods.FLAT],
  [Quantities.IndoorAirQuality, CoefficientMethods.FLAT],
  [Quantities.IndoorPermanence, CoefficientMethods.FLAT],
  [Quantities.TemperaturePermanence, CoefficientMethods.FLAT],
  [Quantities.CO2Permanence, CoefficientMethods.FLAT],
  [Quantities.HumidityPermanence, CoefficientMethods.FLAT],
  [Quantities.ParticulatesPermanence, CoefficientMethods.FLAT],
  [Quantities.PressurePermanence, CoefficientMethods.FLAT],
  [Quantities.VOCPermanence, CoefficientMethods.FLAT],
  [Quantities.IndoorTemperature, CoefficientMethods.FLAT],
  [Quantities.IndoorCO2, CoefficientMethods.FLAT],
  [Quantities.IndoorHumidity, CoefficientMethods.FLAT],
  [Quantities.IndoorParticulates, CoefficientMethods.FLAT],
  [Quantities.IndoorPressure, CoefficientMethods.FLAT],
  [Quantities.IndoorVOC, CoefficientMethods.FLAT],
  [Quantities.FacilityElectricity, CoefficientMethods.FLAT],
  [Quantities.Lighting, CoefficientMethods.FLAT],
  [Quantities.Refrigeration, CoefficientMethods.COOLING],
  [Quantities.CondensationHeat, CoefficientMethods.FLAT],
  [Quantities.HeatingElectricity, CoefficientMethods.HEATING],
  [Quantities.Ventilation, CoefficientMethods.FLAT],
  [Quantities.CoolingSystem, CoefficientMethods.COOLING],
  [Quantities.ChargingSystem, CoefficientMethods.FLAT],
  [Quantities.HeatPumps, CoefficientMethods.HEATING],
  [Quantities.PUE, CoefficientMethods.FLAT],
  [Quantities.Percentage, CoefficientMethods.FLAT],
  [Quantities.Ratio, CoefficientMethods.FLAT],
  [Quantities.Custom, CoefficientMethods.FLAT],
  [Quantities.Percentage2, CoefficientMethods.FLAT],
  [Quantities.Ratio2, CoefficientMethods.FLAT],
  [Quantities.Custom2, CoefficientMethods.FLAT],
  [Quantities.DieselTransport, CoefficientMethods.FLAT],
  [Quantities.PetrolTransport, CoefficientMethods.FLAT],
  [Quantities.LNGTransport, CoefficientMethods.FLAT],
  [Quantities.BiogasTransport, CoefficientMethods.FLAT],
  [Quantities.NaturalGasTransport, CoefficientMethods.FLAT],
  [Quantities.ElectricityTransport, CoefficientMethods.FLAT],
  [Quantities.FuelOilTransport, CoefficientMethods.FLAT],
  [Quantities.EthanolE85Transport, CoefficientMethods.FLAT],
  [Quantities.BioDieselTransport, CoefficientMethods.FLAT],
  [Quantities.KmTransport, CoefficientMethods.FLAT],
  [Quantities.MetalWaste, CoefficientMethods.FLAT],
  [Quantities.PlasticWaste, CoefficientMethods.FLAT],
  [Quantities.GlassWaste, CoefficientMethods.FLAT],
  [Quantities.MixedNoRecyclingWaste, CoefficientMethods.FLAT],
  [Quantities.Biowaste, CoefficientMethods.FLAT],
  [Quantities.PaperWaste, CoefficientMethods.FLAT],
  [Quantities.MoldedFiberWaste, CoefficientMethods.FLAT],
  [Quantities.ElectricalAndElectronicWaste, CoefficientMethods.FLAT],
  [Quantities.WoodWaste, CoefficientMethods.FLAT],
  [Quantities.OtherWaste, CoefficientMethods.FLAT],
  [Quantities.HazardousWaste, CoefficientMethods.FLAT],
  [Quantities.OwnElectricity, CoefficientMethods.FLAT],
  [Quantities.COP, CoefficientMethods.FLAT],
  [Quantities.EER, CoefficientMethods.FLAT],
  [Quantities.SCOP, CoefficientMethods.FLAT],
  [Quantities.SEER, CoefficientMethods.FLAT],
  [Quantities.PersonsInAvg, CoefficientMethods.FLAT],
  [Quantities.PersonsOutAvg, CoefficientMethods.FLAT],
  [Quantities.PersonsOccupancyAvg, CoefficientMethods.FLAT],
  [Quantities.PersonsIn, CoefficientMethods.FLAT],
  [Quantities.PersonsOut, CoefficientMethods.FLAT],
  [Quantities.PersonsOccupancy, CoefficientMethods.FLAT],
  [Quantities.PelletMWh, CoefficientMethods.HEATING],
  [Quantities.NaturalGasMWh, CoefficientMethods.HEATING],
  [Quantities.OilMWh, CoefficientMethods.HEATING],
  [Quantities.LiquidGasMWh, CoefficientMethods.HEATING],
  [Quantities.NaturalGasOtherMWh, CoefficientMethods.HEATING],
  [Quantities.BiogasMWh, CoefficientMethods.HEATING],
  [Quantities.WoodChipsMWh, CoefficientMethods.HEATING],
  [Quantities.HeavyFuelOilMWh, CoefficientMethods.HEATING]
]) as ReadonlyMap<Quantities, CoefficientMethods>;

export const coefficientArray: ReadonlyMap<CoefficientMethods, readonly number[]> = new Map([
  [CoefficientMethods.FLAT, [0.084, 0.0767, 0.085, 0.082, 0.085, 0.082, 0.085, 0.085, 0.082, 0.085, 0.082, 0.085]],
  [CoefficientMethods.HEATING, [0.15, 0.14, 0.13, 0.08, 0.05, 0.03, 0.02, 0.03, 0.05, 0.09, 0.1, 0.13]],
  [CoefficientMethods.COOLING, [0.04, 0.05, 0.05, 0.06, 0.08, 0.14, 0.17, 0.15, 0.1, 0.06, 0.05, 0.05]],
  [CoefficientMethods.STEAM, [0.09, 0.09, 0.09, 0.08, 0.08, 0.08, 0.07, 0.07, 0.08, 0.09, 0.09, 0.09]]
]);

export const conditionallyDisplayedQuantities: ReadonlyMap<Quantities, readonly Quantities[]> = new Map([
  [
    Quantities.HeatingPurchased,
    [Quantities.HeatingOwnProduction, Quantities.CoolingOwnProduction]
  ],
  [
    Quantities.TotalEnergyPurchased,
    [Quantities.CoolingOwnProduction, Quantities.HeatingOwnProduction, Quantities.OwnElectricity]
  ]
]);

export const QUANTITY_ICON_CLASSES: Readonly<Record<number, string>> = getEnumEntries(Quantities).toRecord(
  ([_, value]) => value,
  ([key, _]) => `icon-ekey-${key.toLowerCase()}`
);
