import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { StateRegistry, StateService, Transition, UrlService } from '@uirouter/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { UserService } from '../../../../services/user-service';
import { EnerkeyNg2StateDeclaration } from '../../../../shared/routing';
import { ProfileService } from '../../../../shared/services/profile.service';
import type { ReportingChildState, ReportingParams, ReportingStateDeclaration } from '../../reporting.states';
import { ReportTypeOptionsByStateService } from '../../services/report-type-options-by-state.service';
import { ReportTypeOptionsService } from '../../services/report-type-options.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { TimePeriodHistoryService } from '../../services/time-period-history.service';
import { FacilityService } from '../../../../shared/services/facility.service';
import { Roles } from '../../../admin/constants/roles';
import { TableReportService } from '../../services/table-report.service';
import { SidebarSize } from '../sidebar/sidebar.component';
import { TenantService } from '../../../../shared/services/tenant.service';
import { EnvironmentService } from '../../../../services/environment-service';

const paramsToListenKeys = [
  'gridVisibility$',
  'chartVisibility$',
  'visibleSections$',
  'facilityIds$'
] as const;

const paramsToListen: Record<typeof paramsToListenKeys[number], keyof ReportingParams> = {
  ['gridVisibility$']: 'grids',
  ['chartVisibility$']: 'charts',
  ['visibleSections$']: 'sections',
  ['facilityIds$']: 'facilityIds',
};

const reportsHiddenFromProd: ReportingChildState[] = ['reporting.forecast'];

interface ReportingTab {
  state: EnerkeyNg2StateDeclaration;
  enabled: boolean;
}

@Component({
  selector: 'reporting-base',
  templateUrl: './reporting-base.component.html',
  styleUrls: ['./reporting-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ReportingSearchService,
    ReportTypeOptionsByStateService,
    {
      provide: ReportTypeOptionsService,
      useClass: ReportTypeOptionsByStateService,
    },
    TimePeriodHistoryService
  ],
  animations: [
    trigger('sidebarSize', [
      state('collapsed', style({
        paddingLeft: 'var(--reporting-sidebar-button-width)'
      })),
      state('expanded', style({
        paddingLeft: 'var(--reporting-sidebar-width)'
      })),
      transition('collapsed => expanded', [
        animate('250ms ease-in'),
      ]),
      transition('expanded => collapsed', [
        animate('250ms 100ms ease-in')
      ])
    ])
  ]
})
export class ReportingBaseComponent implements OnDestroy {
  public readonly tabs$: Observable<ReportingTab[]>;
  public readonly selectedFacilities$: Observable<{ id: number, name: string }[]>;
  public readonly requestedRoles: Roles[] = [Roles.FACILITY_IMPORT];
  public showFacilityActions: boolean;

  public sidebarSize: SidebarSize;
  public readonly customClass: string;

  private readonly _destroy$ = new Subject<void>();
  private deregisterUrlChange: ReturnType<UrlService['onChange']>;

  public constructor(
    private readonly stateTransition: Transition,
    private readonly reportingSearchService: ReportingSearchService,
    private readonly stateService: StateService,
    stateRegistry: StateRegistry,
    private readonly userService: UserService,
    profileService: ProfileService,
    // initialize here to disable correct params initially
    _disabledParamsService: ReportTypeOptionsService,
    private readonly facilityService: FacilityService,
    private readonly urlService: UrlService,
    private readonly tableReportService: TableReportService,
    private readonly tenantService: TenantService,
    private readonly environmentService: EnvironmentService
  ) {
    const reportingStates = (stateRegistry.get() as ReportingStateDeclaration[]).filter(
      reportingState => reportingState.name.startsWith('reporting.')
    );

    this.customClass = this.tenantService.isCustomTenant
      ? 'reporting-colors'
      : 'reporting-colors reporting-colors--enerkey';

    this.selectedFacilities$ = combineLatest([
      reportingSearchService.facilityIds$,
      this.facilityService.filteredProfileFacilities$
    ]).pipe(map(([facilityIds, facilities]) => facilities.filterMap(
      f => facilityIds.includes(f.FacilityId),
      f => ({
        id: f.FacilityId,
        name: f.Name
      })
    )));

    this.tabs$ = combineLatest([
      profileService.profile$,
      this.selectedFacilities$
    ]).pipe(
      map(([_profile, facilities]) => reportingStates
        .filter(reportingState => this.environmentService.isProduction()
          ? !reportsHiddenFromProd.includes(reportingState.name as ReportingChildState)
          : true)
        .filterMap(
          reportingState => userService.hasAccess(reportingState.data.auth),
          reportingState => ({
            state: reportingState,
            enabled: reportingState.name === 'reporting.table' || facilities.length > 0
          })
        ))
    );

    this.showFacilityActions = this.hasBulkUpdateAcess();

    this.setInitialParameters();
    this.setStateParamUpdaters();
    this.listenToRouteChange();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();

    this.deregisterUrlChange?.();
    this.tableReportService.resetGridState();
  }

  public trackBy(_index: number, tab: ReportingTab): string {
    return tab.state.name;
  }

  public toggleSidebarState(sidebarCollapsed: boolean): void {
    sidebarCollapsed ? this.sidebarSize = SidebarSize.COLLAPSED : this.sidebarSize = SidebarSize.EXPANDED;
  }

  private setInitialParameters(): void {
    const initialParams = this.stateTransition.params() as ReportingParams;

    this.reportingSearchService.search(this.reportingSearchService.paramsFromState(initialParams));
    if ('facilityIds' in initialParams) {
      this.reportingSearchService.setFacilities(initialParams.facilityIds ?? []);
    }
    if ('sections' in initialParams) {
      this.reportingSearchService.toggleSections(initialParams.sections ?? []);
    }
    if ('grids' in initialParams) {
      this.reportingSearchService.setGridsVisibility(initialParams.grids ?? true);
    }
    if ('charts' in initialParams) {
      this.reportingSearchService.setChartsVisibility(initialParams.charts ?? true);
    }
  }

  private setStateParamUpdaters(): void {
    for (const observableKey of paramsToListenKeys) {
      const observable = this.reportingSearchService[observableKey] as Observable<unknown>;
      observable
        .pipe(
          takeUntil(this._destroy$)
        ).subscribe({
          next: value => {
            this.stateService.go('.', { [paramsToListen[observableKey]]: value });
          }
        });
    }
    this.reportingSearchService.searchParameters$
      .pipe(
        takeUntil(this._destroy$)
      ).subscribe({
        next: value => {
          this.stateService.go('.', value.formValue);
        }
      });
  }

  private listenToRouteChange(): void {
    this.deregisterUrlChange = this.urlService.onChange(_ => {
      this.showFacilityActions = this.hasBulkUpdateAcess();
    });
  }

  private hasBulkUpdateAcess(): boolean {
    return this.urlService.path() === '/reporting/table' && this.userService.hasRole(Roles.FACILITY_IMPORT);
  }
}
