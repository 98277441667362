import { isEqual } from 'date-fns';

import { Quantities } from '@enerkey/clients/metering';
import { EnergyTargetType, ReportingUnit, RequestDuration, RequestResolution } from '@enerkey/clients/reporting';

import {
  ReportingDistributionType,
  ReportingSearchFormValue,
} from './reporting-search-form-value';
import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { ValueType } from '../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { durationToISOString } from './duration-to-string';
import { SelectableResolution } from '../components/reporting-search-form/reporting-search-form.component';
import { getRequestStartAndEnd } from './reporting.functions';
import {
  getSearchParamErrorMessage,
  getSearchParamWarnings,
  ReportingSearchParamWarning,
} from '../services/reporting.search.functions';
import { RelationalValueId } from '../../reportingobjects/constants/facilities-properties';

const minMaxAvgKeys = ['min', 'max', 'average'] as const;
type MinMaxAvgKey = typeof minMaxAvgKeys[number];

const nonDistributableResolutions = [RequestResolution.PT1H, RequestResolution.PT15M];
const nonTemperatureResolutions = [RequestResolution.PT15M];

const nationalCosts = [
  RelationalValueId.NationalCosts,
  RelationalValueId.NationalRetailerCosts,
  RelationalValueId.NationalDistributionCosts
];

const meterBasedCost = [
  RelationalValueId.Costs,
  RelationalValueId.RetailerCosts,
  RelationalValueId.DistributionCosts,
  RelationalValueId.RetailerMonthlyFees,
  RelationalValueId.DistributionMonthlyFees,
  RelationalValueId.RetailerCombinedCosts,
  RelationalValueId.DistributionCombinedCosts,
  RelationalValueId.DistributionTaxes,
  RelationalValueId.MeterBasedAverageCost,
  RelationalValueId.MeterBasedRetailerAverageCost,
  RelationalValueId.MeterBasedDistributionAverageCost
];

export class ReportingSearchParamsError extends Error {
  public constructor(public readonly paramErrorMessage: string) {
    super();
  }
}

export class ReportingSearchParams {
  public readonly unit: ReportingUnit;
  public readonly comparability: Comparability;

  public readonly measured: boolean;
  public readonly normalized: boolean;
  public readonly quantityIds: Quantities[];
  public readonly duration: RequestDuration;
  public readonly timeFrame: string;
  public readonly resolution: SelectableResolution;
  public readonly change: { readonly absolute: boolean; readonly relative: boolean };
  public readonly periods: Date[];
  public readonly searchPeriods: { readonly start: Date; readonly end: Date; }[];
  public readonly derivedIds: number[];
  public readonly emissionIds: number[];
  public readonly costIds: number[];

  public readonly nationalCostIds: number[];
  public readonly meterBasedCostIds: number[];

  public readonly targetTypes: EnergyTargetType[];
  public readonly showConsumption: boolean;
  public readonly showSummedConsumption: boolean;
  public readonly distributionType: ReportingDistributionType;
  public readonly distributionAsPercent: boolean;
  public readonly showTemperature: boolean;
  public readonly minMaxAverageByQuantity: Partial<Record<Quantities, MinMaxAvgKey[]>>;

  public readonly warnings: ReportingSearchParamWarning[];

  /** In general measured values grid gets hidden when distributionType is not None
   * this param is to override that logic */
  public readonly showMeasuredValues: boolean;

  public constructor(
    public readonly formValue: ReportingSearchFormValue & { showMeasuredValues?: boolean }
  ) {
    const errorMessage = getSearchParamErrorMessage(formValue);
    if (errorMessage) {
      throw new ReportingSearchParamsError(errorMessage);
    }
    this.warnings = getSearchParamWarnings(formValue);
    this.quantityIds = [...formValue.quantityIds].sort((a, b) => a - b);
    this.change = formValue.change;

    this.periods = formValue.periods
      .map(period => new Date(period))
      .reverse();

    this.minMaxAverageByQuantity = this.quantityIds.toRecord(
      qId => qId,
      () => minMaxAvgKeys.filter(key => formValue.minMaxAvg[key])
    );

    const valueOptions = this.getValueTypeOptions(formValue.valueType);
    this.measured = valueOptions.measured;
    this.normalized = valueOptions.normalized;

    this.showConsumption = formValue.showConsumption;
    this.showSummedConsumption = formValue.showSummedConsumption;
    this.showTemperature = formValue.temperature && !nonTemperatureResolutions.includes(formValue.resolution);

    this.duration = new RequestDuration({ [formValue.durationName]: formValue.durationLength });
    this.timeFrame = durationToISOString(this.duration);
    this.resolution = formValue.resolution;
    this.derivedIds = [...formValue.specificIds];
    this.emissionIds = formValue.emissionIds;
    this.costIds = [...formValue.costIds];
    this.nationalCostIds = nationalCosts.filter(id => formValue.costIds.includes(id));
    this.meterBasedCostIds = meterBasedCost.filter(id => formValue.costIds.includes(id));

    this.targetTypes = formValue.targetTypes;
    this.unit = formValue.reportingUnit;
    this.distributionType = formValue.distributionType;
    this.distributionAsPercent = formValue.distributionAsPercent;
    this.comparability = formValue.comparability;

    this.searchPeriods = this.periods.map(p => getRequestStartAndEnd(p, this.duration));

    this.showMeasuredValues = formValue.showMeasuredValues;
  }

  public get showChange(): boolean {
    return this.change.absolute || this.change.relative;
  }

  public isTimeSettingsSame(params: ReportingSearchParams): boolean {
    return this.timeFrame === params.timeFrame
      && params.resolution === this.resolution
      && params.periods.length === this.periods.length
      && params.periods.every((period, index) => isEqual(period, this.periods[index]));
  }

  public isQuantitySettingSame(params: ReportingSearchParams): boolean {
    if (this.quantityIds.length !== params.quantityIds.length) {
      return false;
    }
    return this.quantityIds.every(qId => params.quantityIds.includes(qId));
  }

  public getInspectionPeriodInterval(): { start: Date, end: Date } {
    return this.searchPeriods[this.searchPeriods.length - 1];
  }

  public getInterval(): { start: Date, end: Date } {
    return {
      start: this.searchPeriods[0].start,
      end: this.searchPeriods[this.searchPeriods.length - 1].end,
    };
  }

  public get showDistribution(): boolean {
    return this.distributionType !== ReportingDistributionType.None
      && !nonDistributableResolutions.includes(this.resolution);
  }

  public get distributionFields(): ['day', 'night'] | ['workday', 'holiday'] {
    switch (this.distributionType) {
      case ReportingDistributionType.DayNight:
        return ['day', 'night'];
      case ReportingDistributionType.Weekday:
        return ['workday', 'holiday'];
      default:
        return [null, null];
    }
  }

  public get hasDistributionValuesField(): 'hasDayNightValues' | 'hasWorkdayHolidayValues' {
    switch (this.distributionType) {
      case ReportingDistributionType.DayNight:
        return 'hasDayNightValues';
      case ReportingDistributionType.Weekday:
        return 'hasWorkdayHolidayValues';
      default:
        return null;
    }
  }

  private getValueTypeOptions(valueType: ValueType): { normalized: boolean; measured: boolean } {
    switch (valueType) {
      case ValueType.Normalized:
        return { normalized: true, measured: false };
      case ValueType.Both:
        return { normalized: true, measured: true };
      default:
        return { normalized: false, measured: true };
    }
  }
}
