import {
  AfterViewInit,
  Component,
  Host,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  SkipSelf,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnBase, ColumnComponent, ColumnGroupComponent } from '@progress/kendo-angular-grid';
import { Observable, Subject } from 'rxjs';

import { InterfaceOf } from '@enerkey/ts-utils';

import { KendoGridManualColumnComponent } from '../kendo-grid-manual-column/kendo-grid-manual-column.component';

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @angular-eslint/directive-class-suffix */

type ColumnGroupType = InterfaceOf<ColumnGroupComponent>;

export type ManualColumnDefinition = {
  title: string;
  width: number;
  hidden?: boolean;
  filter?: ColumnComponent['filter'];
  format?: 'd';
  visiblefn?: () => boolean;
  cellTemplatefn?: () => TemplateRef<unknown>;
  filterMenuTemplatefn?: () => TemplateRef<unknown>;
  groupHeaderTemplatefn?: () => TemplateRef<unknown>;
};

@Component({
  template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class CustomColumnGroupBase
  extends ColumnBase
  implements AfterViewInit, OnInit, OnDestroy, ColumnGroupType {

  protected abstract propertyField: string;
  protected abstract columnDefinitions: Record<string, ManualColumnDefinition>;

  @Input() public gridData: Record<keyof unknown, unknown>[];
  @Input() public except: string[];
  @Input() public hideAll: boolean = false;

  /** You can refresh columns with next() after `initialized` is true. */
  public children: QueryList<ColumnBase>;

  public override get leafIndex(): number {
    return this.children ? this.firstChild?.leafIndex : -1;
  }

  public get childrenArray(): ColumnBase[] {
    return this.children.toArray();
  }

  public get hasChildren(): boolean {
    return !!(this.firstChild);
  }

  public get firstChild(): ColumnBase {
    return this.children.first;
  }

  public override get colspan(): number {
    if (!this.children || this.children.length === 1) {
      return 1;
    }

    return this.children.reduce((n, col) => {
      if (!col?.isVisible) {
        return n;
      }

      return n + col.colspan ?? 0;
    }, 0);
  }

  protected initialized: boolean = false;

  /** Emits and completes in CustomColumnGroupBase.ngOnDestroy */
  protected get destroy$(): Observable<void> {
    // this NEEDS to be a getter since it might be called from inheriting component constructors
    return this._destroy;
  }

  /** Run in ngAfterViewInit as it requires ViewChildren */
  protected readonly _templateInitializers: Array<() => void> = [];

  private readonly _destroy = new Subject<void>();

  public constructor(
    private readonly translateService: TranslateService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly injector: Injector,
    @Optional() @SkipSelf() @Host() parent: ColumnBase
  ) {
    super(parent);

    // copied from stock kendo column group
    this.parent = parent;
    this.includeInChooser = false;
    this.isColumnGroup = true;
    this.minResizableWidth = 10;
  }

  public override rowspan(): number {
    return 1;
  }

  public ngOnInit(): void {
    const columnComponents: ColumnBase[] = [];

    for (const [field, column] of Object.entries(this.columnDefinitions)) {
      if (this.except?.includes(field)) {
        continue;
      }

      if (column.visiblefn && !column.visiblefn()) {
        continue;
      }

      const componentRef = this.viewContainerRef.createComponent(
        KendoGridManualColumnComponent,
        { injector: this.injector }
      );
      const component = componentRef.instance;

      component.title = this.translateService.instant(column.title);
      component.field = `${this.propertyField}.${field}`;
      component.width = column.width;
      component.hidden = this.hideAll || column.hidden;
      component.filter = column.filter;
      component.format = column.format;
      component.parent = this;

      this._templateInitializers.push(
        () => component.setCellTemplate(column.cellTemplatefn?.()),
        () => component.setFilterMenuTemplate(column.filterMenuTemplatefn?.()),
        () => component.setGroupHeaderTemplate(column.groupHeaderTemplatefn?.())
      );

      columnComponents.push(component);
    }

    this.children = new QueryList();
    this.children.reset(columnComponents);

    this.initialized = true;
  }

  public ngAfterViewInit(): void {
    for (const fn of this._templateInitializers) {
      fn();
    }
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}

