import { EnergyTargetType, ReportingUnit } from '@enerkey/clients/reporting';

import { Quantities } from '@enerkey/clients/metering';

import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';

import { ValueType } from '../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { PERIODS, SelectableResolution } from '../components/reporting-search-form/reporting-search-form.component';

export type DurationName = typeof PERIODS[number];

export enum ReportingDistributionType {
  None,
  DayNight,
  Weekday,
}

export interface ReportingSearchFormValue {
  quantityIds: number[];
  durationName: DurationName;
  durationLength: number;
  resolution: SelectableResolution;
  periods: Date[];
  change: {
    relative: boolean;
    absolute: boolean;
  };
  valueType: ValueType;
  showConsumption: boolean;
  showSummedConsumption: boolean;
  specificIds: number[];
  costIds: number[];
  emissionIds: number[];
  targetTypes: EnergyTargetType[];
  relatedQuantities: Quantities[];
  reportingUnit: ReportingUnit;
  distributionType: ReportingDistributionType;
  temperature: boolean;
  distributionAsPercent: boolean;
  comparability: Comparability;
  minMaxAvg: {
    min: boolean;
    max: boolean;
    average: boolean;
  };
}
