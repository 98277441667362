import { Injectable, Injector } from '@angular/core';
import { from, map, of, take } from 'rxjs';

import { ModalService } from '@enerkey/foundation-angular';

import { REPORT_MODAL_PARAMS, ReportModalComponent } from '../components/report-modal/report-modal.component';
import { QuantityService } from '../../../shared/services/quantity.service';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportType } from '../shared/report-type';

@Injectable({
  providedIn: 'root'
})
export class ReportingModalsService {
  public constructor(
    private readonly modalService: ModalService,
    private readonly quantityService: QuantityService
  ) {
  }

  public openReport(
    facilityId: number,
    params: ReportingSearchParams,
    reportType: ReportType = ReportType.Period,
    meterIds: number[] = [],
    presentation: { sections: string[]; charts: boolean; grids: boolean; meterInfo: boolean } = null
  ): void {
    (params.formValue.quantityIds?.length ?
      of(params.formValue.quantityIds) :
      from(this.quantityService.getGroupedQuantitiesForFacility(facilityId)).pipe(
        take(1),
        map(groups => groups.flatMap(group => group.quantities).map(q => q.ID))
      )
    ).subscribe({
      next: quantityIds => {
        const modalInjector = Injector.create({
          providers: [{
            provide: REPORT_MODAL_PARAMS,
            useValue: {
              facilityId,
              initialReportType: reportType,
              initialSearchParams: new ReportingSearchParams({
                ...params.formValue,
                quantityIds,
              }),
              meterIds,
              presentation
            }
          }]
        });
        this.modalService.open(ReportModalComponent, { injector: modalInjector });
      }
    });
  }
}
