import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ActionOutViewModel } from '@enerkey/clients/attachments';

import { ExtendedFacilityInformation } from '../../../../shared/interfaces/extended-facility-information';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { UserService } from '../../../../services/user-service';
import { Service } from '../../../../constants/service';
import { Roles } from '../../../admin/constants/roles';
import { ReportPlainEvents } from '../../services/report-events.service';
import { ReportingModalsService } from '../../services/reporting-modals.service';
import { ReportType } from '../../shared/report-type';
import { REPORT_MODAL_PARAMS, ReportingModalParams } from '../report-modal/report-modal.component';
// eslint-disable-next-line max-len
import { ActionType, ModuleType } from '../../../../shared/energy-reporting-shared/components/facilities-popup-actions/facilities-popup-actions.component';

@Component({
  selector: 'facility-report-header',
  templateUrl: './facility-report-header.component.html',
  styleUrls: ['./facility-report-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityReportHeaderComponent implements OnDestroy, OnChanges {
  @Input() public facility: ExtendedFacilityInformation;
  @Input() public events: ReportPlainEvents;
  @Input() public modalReportType: ReportType;
  @Input() public showFacilityGroupInfo: boolean = true;
  @Output() public readonly addedAction = new EventEmitter<ActionOutViewModel>();

  public readonly visibleSections$: Observable<string[]>;
  public readonly showDocuments: boolean;
  public readonly showActions: boolean;
  public readonly showComments: boolean;
  public readonly showOpenFacilityButton: boolean;

  public readonly PopupModuleType: typeof ModuleType = ModuleType;
  public readonly PopupActionType: typeof ActionType = ActionType;

  public facilityActions: ReportPlainEvents['actions'];
  public facilityComments: ReportPlainEvents['comments'];

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly reportingSearchService: ReportingSearchService,
    private readonly reportingModalsService: ReportingModalsService,
    userService: UserService,
    @Optional() @Inject(REPORT_MODAL_PARAMS) modalParams: ReportingModalParams
  ) {
    this.showOpenFacilityButton = !modalParams;
    this.showDocuments = userService.hasService(Service.Documents) && userService.hasRole(Roles.DOCUMENT_READ);
    this.showActions = userService.hasService(Service.Actions);
    this.showComments = userService.hasService(Service.Comments);
    this.visibleSections$ = this.reportingSearchService.visibleSections$.pipe(
      takeUntil(this._destroy$)
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.events) {
      this.facilityActions = this.events?.actions?.
        filter(action => action.reportingObjectId === this.facility.facilityId);
      this.facilityComments = this.events?.comments?.
        filter(comment => comment.reportingObjectId === this.facility.facilityId);
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public openReport(): void {
    this.reportingSearchService.searchParameters$.pipe(
      take(1)
    ).subscribe({
      next: params => {
        this.reportingModalsService.openReport(
          this.facility.FacilityId,
          params,
          this.modalReportType
        );
      }
    });
  }
}
