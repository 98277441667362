<div class="quantity-dropdown-wrapper">
  <quantity-dropdown
    formControlName="quantity"
    *labelBefore="'QUANTITY' | translate"
    [quantities]="quantities$ | async"
    [grouped]="true"
    [loading]="loadingQuantities$ | async"
    [disableWhenLoading]="true"
    [noDataMessage]="(noEtCurveMessageVisible$ | async) ? NO_ET_CURVE_TRANSLATION_KEY : null"
    #quantityDropdownComponent
  ></quantity-dropdown>

  <ng-container *ngIf="(noEtCurveMessageVisible$ | async)">
    <div class="message-aligned-right">{{ 'FACILITY_ETCURVE_WIDGET.NO_ETCURVE_AVAILABLE' | translate }}</div>
  </ng-container>
</div>
