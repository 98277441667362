<div class="card reporting-info-card">
  <div class="header-group">
    <div class="header-icon header-icon--bg-round header-icon--bg-white">
      <i [ngClass]="iconClass"></i>
    </div>
    <h3 class="header-group__label">{{ titleTranslationKey | translate }}</h3>
    <div class="actions-link-container">
      <a (click)="showAll()">
        <h3 class="header-group__label">
          {{ 'FACILITIES_REPORT.GO_TO_ACTIONS' | translate }}
        </h3>
      </a>
      <a (click)="addNew()">
        <h3 class="header-group__label">
          {{ 'FACILITIES_REPORT.ADD_NEW' | translate }}
        </h3>
      </a>
    </div>
  </div>
  <div class="actions-table-container">
    <table>
      <tr>
        <th>{{ 'FACILITIES_REPORT.OVERVIEW.CHANGED' | translate }}</th>
        <th>{{ 'FACILITIES_REPORT.OVERVIEW.TITLE' | translate }}</th>
        <th>{{ 'FACILITIES_REPORT.OVERVIEW.STATE' | translate }}</th>
      </tr>
      <tr *ngFor="let action of actions">
        <td>{{ action.updatedAt | date:'shortDate' }}</td>
        <td>
          <a class="reporting-link" (click)="editAction(action)">{{ action.reportedDescription }}</a>
        </td>
        <td>
          <investigation-status [status]="action.investigation"></investigation-status>
        </td>
      </tr>
    </table>
  </div>
</div>
