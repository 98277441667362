import { Pipe, PipeTransform } from '@angular/core';

import { LogLiteDto } from '@enerkey/clients/alarm';

@Pipe({
  name: 'facilityReportAlarms'
})
export class FacilityReportAlarmsPipe implements PipeTransform {
  public transform(alarms: LogLiteDto[], facilityId: number): LogLiteDto[] {
    if (!Number.isFinite(facilityId)) {
      return alarms;
    }
    return alarms?.filter(a => a.facilityId === facilityId);
  }
}
