import { Injectable } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';

import { VisibleSections as SelectableColumns } from '@enerkey/clients/settings';

import { EXCEL_FORMATS } from '../constants/excel-formats-constants';

export interface ExcelExportColumn {
  children?: ExcelExportColumn[];
  title?: string;
  field?: string;
  format?: string;
  hidden?: boolean;
  locked?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ExcelKendoGridFormatterService {

  public constructor(public kendoGrid: GridComponent) { }

  public getExcelExportColumns(): ExcelExportColumn[] {
    if (!this.kendoGrid) { return []; }
    const columns = this.kendoGrid?.columns?.toArray() || [];
    return this.getColumns(columns);
  }

  public getColumns(columns: ExcelExportColumn[]): ExcelExportColumn[] {
    const exportColumns: ExcelExportColumn[] = [];
    for (const column of columns) {
      // group column
      if (column.children) {
        const children = column.children;
        exportColumns.push({
          title: column.title,
          children: this.getColumns(children),
        });
      } else {
      // normal column
        const [start] = column.field?.split('.') ?? '';
        const format = start in SelectableColumns
          ? ''
          : EXCEL_FORMATS[column.format] ?? column.format;

        const isLocked = column.field === 'Name';
        exportColumns.push({
          title: column.title,
          field: column.field,
          locked: isLocked,
          format: format,
          hidden: column.hidden,
        });
      }
    }
    return exportColumns;
  }
}

