<modal-view-header [headingText]="'ADMIN.CONTRACTS.MASS_ADD.TITLE' | translate">
</modal-view-header>

<div class="modal-body">
  <div class="wizard-wrapper">
    <wizard
      [steps]="wizardSteps"
      [(step)]="activeStep"
      [enableLastAction]="true"
      (lastAction)="close()"
    ></wizard>
  </div>

  <div *ngIf="activeStep.id === Steps.Prefill">
    <contract-mass-add-options
      #prefillOptions
      (isValid)="optionsValid = $event"
    ></contract-mass-add-options>
  </div>

  <div
    *ngIf="activeStep.id === Steps.Validate"
    class="inner-container"
  >
    <p>
      <button
        type="button"
        class="button button--secondary"
        (click)="activeStep = wizardSteps[1]"
      >
        <i class="fa fa-chevron-left"></i>
        {{ 'ADMIN.EDIT' | translate }}
      </button>
    </p>

    <p
      *ngIf="!isSheetValid && !isSheetEmpty"
      class="ek-color ek-color--negative"
    >
      <i class="fas fa-exclamation-triangle"></i>
      {{ 'ADMIN.CONTRACTS.MASS_ADD.ERROR_ROWS' | translate }}: {{ invalidRows }}
    </p>

    <p *ngIf="isSheetEmpty">
      <i class="fas fa-info-circle"></i>
      {{ 'ADMIN.CONTRACTS.MASS_ADD.EMPTY' | translate }}
    </p>

    <p>
      <input
        type="checkbox"
        kendoCheckBox
        [(ngModel)]="skipEmptyEnegiaIds"
        (ngModelChange)="onSkipEmptyChanged()"
        *labelWrap="'ADMIN.CONTRACTS.MASS_ADD.IGNORE_ENEGIA_ID' | translate"
      >
    </p>
  </div>

  <div
    *ngIf="activeStep.id === Steps.Save"
    class="inner-container"
  >
    {{ 'ADMIN.SPREADSHEET.SAVING' | translate }}...
  </div>

  <div
    *ngIf="activeStep.id === Steps.Done"
    class="inner-container"
  >
    {{ 'DONE' | translate }}
  </div>

  <div>
    <div id="spreadsheet"></div>
  </div>

  <div tabindex="0">
    <!-- focus trap -->
  </div>
</div>
