import { AggregateDescriptor, AggregateResult } from '@progress/kendo-data-query';

import { FacilityInformationGroup, SpecificConsumptionConfiguration } from '@enerkey/clients/energy-reporting';
import { RequestResolution } from '@enerkey/clients/reporting';

import { Quantities } from '@enerkey/clients/metering';

import {
  normalizedValuesPropertyKey,
  TableReportValueKey,
  tableReportValueKeys,
} from '../constants/table-report-constants';
import { ReportingSearchParams } from './reporting-search-params';
import { ReportingSeriesByFacility } from './reporting-series-collection';
import { ConsumptionLike, ReportingSeries, ReportingSerieType } from './reporting-series';
import { FacilityRow } from '../components/table-report/table-report.component';
import {
  isAverageCostSeries,
  isAverageQuantityAggregate,
  isDistributionPerCentSerie
}
  from '../services/reporting-grid.service';
import { isCost, isEmission } from '../../reportingobjects/shared/relational-value-functions';

export type QuantityValuesBySerieType = { [key: number]: { [key: string]: Record<string, ConsumptionLike> } };
export type FacilitySingleTypeQuantityValues = { [key: string]: QuantityValuesBySerieType };

export type FacilityQuantityValues = Record<TableReportValueKey, FacilitySingleTypeQuantityValues>;

export interface SeriesByType {
  serieType: ReportingSerieType,
  series: ReportingSeries[],
}

export interface QuantitySeriesByType {
  quantityId: Quantities,
  isNormalized: boolean,
  unit: string,
  series: SeriesByType[]
}
interface ConsumptionData {
  [timestamp: string]: number;
}
interface QuantityData {
  [quantityId: string]: ConsumptionData;
}
export interface PropertyData {
  [property: string]: QuantityData;
}

export interface ConsumptionTotalByMissingProperty {
  values: PropertyData;
  normalizedValues: PropertyData;
}

export interface AggregatedConsumptionData {
  timestamp: string;
  quantityId: string;
  consumption?: number;
  normalizedConsumptions?: number;
  isMeasuredTotal: boolean;
}

export function mapQuantityDataByFacilities(
  facilities: FacilityInformationGroup[],
  facilityData: ReportingSeriesByFacility,
  params: ReportingSearchParams
): FacilityQuantityValues {
  return tableReportValueKeys.reduce((dataOfType, dataKey) => {
    dataOfType[dataKey] = groupQuantityValuesByFacilities(
      facilities, params, facilityData, dataKey === normalizedValuesPropertyKey
    );
    return dataOfType;
  }, {} as FacilityQuantityValues);
}

export function getTableReportColumns(facilityData: ReportingSeriesByFacility): QuantitySeriesByType[] {
  const seriesByQuantity = Object.values(facilityData).flat().toGroupsBy(c => c.quantityId);

  const columnsByQuantityAndSerieType: QuantitySeriesByType[] = [];

  for (const [quantityId, series] of seriesByQuantity.entries()) {
    const unit = series[0].unit;
    const visibleSeries = series
      .flatMap(c => c.series)
      .filter(s => s.isShownInTable);

    [false, true].forEach(isNormalized => {
      const columns = getMeasuredOrNormalizedSeries(visibleSeries, isNormalized);
      if (Array.hasItems(columns)) {
        columnsByQuantityAndSerieType.push({ quantityId, series: columns, isNormalized: isNormalized, unit });
      }
    });
  }
  return columnsByQuantityAndSerieType;
}

function getMeasuredOrNormalizedSeries(
  series: ReportingSeries[], isNormalized: boolean
): { serieType: ReportingSerieType, series: ReportingSeries[] }[] {
  const filteredSeries = series.filter(s => !!s.options.isNormalized === isNormalized);
  const quantitySeriesByType = filteredSeries.toGroupsBy(s => s.options.serieType);
  const seriesByType: { serieType: ReportingSerieType, series: ReportingSeries[] }[] = [];
  for (const [serieType, seriesOfType] of quantitySeriesByType) {
    seriesByType.push({
      serieType,
      series: seriesOfType.sortByMany(s => s.serieStart, [s => s.isChangeVisible, 'desc']).uniqueBy(s => s.gridTitle)
    });
  }
  return seriesByType;
}

function groupQuantityValuesByFacilities(
  facilities: FacilityInformationGroup[],
  params: ReportingSearchParams,
  dataByFacilities: ReportingSeriesByFacility,
  isNormalized: boolean
): FacilitySingleTypeQuantityValues {
  return facilities.reduce<FacilitySingleTypeQuantityValues>((data, f) => {
    data[f.FacilityId] ??= {};
    const current = data[f.FacilityId];

    const quantityMappedDataForFacility = dataByFacilities?.[f.FacilityId]?.toRecord(c => c.quantityId);
    for (const q of params.quantityIds) {
      current[q] = {};
      const seriesByType = quantityMappedDataForFacility?.[q]?.series
        .filter(s => s.isShownInTable)
        .filter(s => !!s.options.isNormalized === isNormalized)
        .toGroupsBy(s => s.options.serieType) ?? new Map<ReportingSerieType, ReportingSeries[]>();
      for (const [serieType, series] of seriesByType.entries()) {
        current[q][serieType] = series.toRecord(s => s.serieStart, s => s.values[0]);
      }
    }
    return data;
  }, {});
}

export function requestResolutionByCurrentMonth(currentMonth: number): RequestResolution {
  switch (currentMonth) {
    case 0: return RequestResolution.P1M;
    case 1: return RequestResolution.P2M;
    case 2: return RequestResolution.P3M;
    case 3: return RequestResolution.P4M;
    case 4: return RequestResolution.P5M;
    case 5: return RequestResolution.P6M;
    case 6: return RequestResolution.P7M;
    case 7: return RequestResolution.P8M;
    case 8: return RequestResolution.P9M;
    case 9: return RequestResolution.P10M;
    case 10: return RequestResolution.P11M;
    default: return RequestResolution.P1Y;
  }
}

export function getUnsupportedParamsInfo({ duration, periods }: ReportingSearchParams): string {
  // If period is year or month backend requires start date to be month first
  const isYearOrMonthPeriod = !!(duration.years || duration.months);
  if (isYearOrMonthPeriod && periods?.some(date => date.getDate() !== 1)) {
    return 'REPORTING.ERRORS.UNSUPPORTED_TABLE_REPORT_START_DATE';
  }
  return null;
}

/**
  * Sanitize the aggregate result to ensure that all values are finite numbers.
  */
export function sanitizeAggregateResult(aggregatedValues: AggregateResult): AggregateResult {
  const obj: AggregateResult = {};
  for (const [key, value] of Object.entries(aggregatedValues)) {
    obj[key] = {};
    for (const [k, v] of Object.entries(value) as [AggregateDescriptor['aggregate'], number][]) {
      obj[key][k] = Number.isFinite(v) ? v : 0;
    }
  }
  return obj;
}

/**
 * Calculate the sum of missing properties for each quantity and timestamp.
 * This function iterates over the filtered data and identifies properties that are missing
 * for each facility. It then calculates the sum of the missing consumptions for each quantity
 * and timestamp.
 *
 * @param filteredData - An array of facility data objects, each containing properties and values.
 * @param allProperties - An array of all possible property names.
 * @returns An object where each key is a missing property, and the value is another object
 *          containing the sum of missing consumptions for each quantity and timestamp
 */
export function calculateMissingPropertiesWithSum(
  filteredData: FacilityRow[],
  allProperties: string[]
): ConsumptionTotalByMissingProperty {
  const result = {
    values: {} as PropertyData,
    normalizedValues: {} as PropertyData
  };

  filteredData.forEach(facility => {
    const facilityProperties = facility?.Properties ? Object.keys(facility.Properties) : [];
    const missingProperties = allProperties.filter(property => !facilityProperties.includes(property));
    missingProperties.forEach(property => {
      // Process measured values
      const values = facility?.values || {};
      processConsumption(values, property, result.values);
      // Process normalized values
      const normalizedValues = facility?.normalizedValues || {};
      processConsumption(normalizedValues, property, result.normalizedValues);
    });
  });
  return result;
}

function processConsumption(
  consumptionData: QuantityValuesBySerieType,
  property: string,
  result: PropertyData
): void {
  Object.entries(consumptionData).forEach(([quantityId, quantityData]) => {
    const consumption = quantityData?.consumption || {};

    Object.entries(consumption).forEach(([timestamp, { value }]) => {
      if (!value) {
        return; // Skip if value is undefined or null
      }

      // Initialize nested structure if not already present
      result[property] ??= {};
      result[property][quantityId] ??= {};
      result[property][quantityId][timestamp] ??= 0;
      result[property][quantityId][timestamp] += value;
    });
  });
}

/**
 * override aggregate values calculated by aggregateBy.
 * This function iterates over the aggregated values and sets the sum of unsupported aggregates.
 *
 * @param aggregatedValues - The aggregated values.
 * @param unsupportedAggregates - An array of unsupported aggregate quantities.
 * @param searchParams - The search parameters.
 * @param propertiesAggregates - The properties aggregates.
 * @param missingPropertiesConsumption - The missing properties consumption.
 * @returns The modified aggregated values.
 */
export function setUnsupportedAggregatesValue(
  aggregatedValues: AggregateResult,
  unsupportedAggregates: Quantities[],
  searchParams: ReportingSearchParams,
  propertiesAggregates: {
    aggregates: AggregateResult;
    specificConsumptions: SpecificConsumptionConfiguration[];
  },
  missingPropertiesConsumption: ConsumptionTotalByMissingProperty
): AggregateResult {
  // stores fitlered consumption initially to calculate specific consumption total
  const consumptionDataArray = extractConsumptionData(aggregatedValues);
  const modifiedSpecificAggregates = removePropertiesPrefix(propertiesAggregates?.aggregates || {});
  const aggregates: AggregateResult = {};

  for (const [key, value] of Object.entries(aggregatedValues || {})) {
    aggregates[key] = {};
    const [consumptionType, quantityId, serieType, timestamp] = key.split('.') || [];

    const isDerived = key.includes('derived');
    const derivedId = isDerived ? Number(serieType?.split('derived')[1]) : null;

    const isAverageCosts = isAverageCostSeries(serieType);

    const isSpecificConsumptions =
      isDerived &&
      !isCost(derivedId) &&
      !isEmission(derivedId);

    const isPerCentSerie = isDistributionPerCentSerie(serieType, searchParams?.distributionAsPercent);
    const isUnsupportedAggregates = isAverageQuantityAggregate(
      serieType,
      unsupportedAggregates?.includes(Number(quantityId))
    );

    const specificConsumptionKey = propertiesAggregates?.specificConsumptions?.find(s => s.Id === derivedId)?.Key;
    const currentPropertySum = getSumFromAggregates(modifiedSpecificAggregates, specificConsumptionKey || '');

    for (const [k, v] of Object.entries(value) as [AggregateDescriptor['aggregate'], number][]) {
      if (isSpecificConsumptions && k === 'sum') {
        const isMeasuredTotal = consumptionType === 'values';
        aggregates[key][k] = calculateSpecificConsumptionTotal(
          consumptionDataArray,
          timestamp,
          quantityId,
          isMeasuredTotal,
          isMeasuredTotal ? missingPropertiesConsumption?.values : missingPropertiesConsumption?.normalizedValues,
          specificConsumptionKey,
          currentPropertySum
        );
      } else {
        aggregates[key][k] =
          (isUnsupportedAggregates || isAverageCosts || isPerCentSerie) && k === 'sum' ? null : v;
      }
    }
  }

  return aggregates;
}

/**
 * Calculate the specific consumption total for a given property, quantity, and timestamp.
 *
 * @param consumptionDataArray - An array of consumption data objects, each containing
 * a timestamp, quantity ID, and consumption value.
 * @param timestamp - The timestamp of the specific consumption.
 * @param quantityId - The quantity ID of the specific consumption.
 * @param missingPropertiesConsumption - An object containing the sum of missing consumptions
 * for each quantity and timestamp.
 * @param specificConsumptionKey - The name of the specific consumption.
 * @param currentPropertySum - The sum of the current property.
 * @returns The specific consumption for the given property, quantity, and timestamp.
 */
function calculateSpecificConsumptionTotal(
  consumptionDataArray: AggregatedConsumptionData[],
  timestamp: string,
  quantityId: string,
  isMeasuredTotal: boolean,
  missingPropertiesConsumption: PropertyData,
  specificConsumptionKey: string,
  currentPropertySum: number | null
): number | null {
  const filertedConsumption = isMeasuredTotal ?
    consumptionDataArray.filter(item => item.isMeasuredTotal) :
    consumptionDataArray.filter(item => !item.isMeasuredTotal);
  // Find the same year's consumption for the same quantity
  const sameQuantitySameYearConsumption = isMeasuredTotal ?
    filertedConsumption.find(
      item => item.timestamp === timestamp && item.quantityId === quantityId
    )?.consumption :
    filertedConsumption.find(
      item => item.timestamp === timestamp && item.quantityId === quantityId
    )?.normalizedConsumptions ;

  const matchingProperty = missingPropertiesConsumption?.[specificConsumptionKey];

  const matchingQuantity = matchingProperty?.[quantityId];
  // handle case when missing properties has no consumption for the timestamp
  const matchingTimestampConsumption = matchingQuantity?.[timestamp] ?? 0;
  if (
    Number.isFinite(sameQuantitySameYearConsumption)
    && (Number.isFinite(currentPropertySum) || currentPropertySum !== 0)
  ) {
    return (sameQuantitySameYearConsumption - matchingTimestampConsumption) / currentPropertySum;
  }
  return null;
}

/**
 * Extract consumption data from the filtered aggregated values.
 *
 * @param aggregatedValues - The aggregated values.
 * @returns An array of consumption data objects, each containing a timestamp, quantity ID, and consumption value.
 */
function extractConsumptionData(
  aggregatedValues: AggregateResult
): AggregatedConsumptionData[] {
  if (!aggregatedValues) {
    return [];
  }
  const consumptionDataArray: AggregatedConsumptionData[] = [];
  for (const [key, value] of Object.entries(aggregatedValues)) {
    if (key.includes('consumption') && value?.sum !== null) {
      const [consumptionType, quantityId, , timestamp, valueType] = key.split('.') || [];
      const isMeasuredTotal = consumptionType === 'values' && valueType === 'visibleValue';
      const isNormalizedTotal = consumptionType === 'normalizedValues' && valueType === 'visibleValue';
      if (timestamp && quantityId && isMeasuredTotal) {
        consumptionDataArray.push({ timestamp, quantityId, consumption: value.sum, isMeasuredTotal: true });
      } else if (timestamp && quantityId && isNormalizedTotal) {
        consumptionDataArray.push({ timestamp, quantityId, normalizedConsumptions: value.sum, isMeasuredTotal: false });
      }
    }
  }
  return consumptionDataArray;
}

function removePropertiesPrefix(aggregates: AggregateResult): AggregateResult {
  const result: AggregateResult = {};
  for (const [key, value] of Object.entries(aggregates)) {
    const newKey = key.startsWith('Properties.') ? key.replace('Properties.', '') : key;
    result[newKey] = value;
  }
  return result;
}

function getSumFromAggregates(aggregates: AggregateResult, key: string): number | null {
  if (key in aggregates) {
    return aggregates[key].sum || null;
  }
  return null;
}

