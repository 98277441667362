<!-- assumes actionsAllowed, popupId, graph.actions, graph.facility -->
<ek-dropdown
  [width]="600"
  class="graph-actions-container"
  [class.meter-graph-actions-container]="notesData.meterId"
>
  <button
    class="button icon fa icon-icon-notes-content"
    *ekDropdownToggle="let toggle"
    [title]="'FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_TITLE' | translate"
    (click)="toggle()"
    [ngClass]="{
      'content-loading': !notesData,
      'icon-icon-notes-content': notesData?.actions?.length > 0,
      'icon-icon-notes-add': notesData?.actions?.length === 0 
    }"
  >
  </button>
  <div class="actions-icon-container latest-actions-dropdown">
    <div class="title-row">
      {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_TITLE' | translate }}
    </div>
    <div
      class="table-empty-placeholder"
      *ngIf="(!notesData?.actions || notesData.actions.length === 0) &&
      (!notesData?.alarms || notesData.alarms.length === 0)"
    >
    </div>
    <ng-container *ngIf="actionsAllowed || commentsAllowed">
      <table
        *ngIf="notesData?.actions && notesData.actions.length > 0"
        class="actions-table"
      >
        <thead>
          <tr>
            <th class="action-icon"></th>
            <th class="effect-time-frame no-mobile">
              {{'FACILITIES_REPORT.GRAPH_ACTIONS_EFFECT_START_STOP_COLUMN' | translate}}
            </th>
            <th class="reported-description">
              {{ 'ACTIONS.REPORTEDDESCRIPTION' | translate }}
            </th>
            <th class="action-investigation-lights no-mobile">
              {{ 'ACTIONS.INVESTIGATION' | translate }}
            </th>
            <th class="action-level no-mobile">
              {{'FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_LEVEL_COLUMN' | translate}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let note of notesData.actions">
            <td class="icon-column">
              <span
                *ngIf="note.type === 'comment'"
                [title]="'ACTIONS.ACTIONTYPE_' + note.action.actionType | translate"
                class="ek-color--grey"
              >
                <i class="fa fa-comment"></i>
              </span>
              <span
                *ngIf="note.type === 'action'"
                [title]="'ACTIONS.ACTIONTYPE_' + note.action.actionType | translate"
                class="ek-color--grey icon-icon-notes-blank"
              >
              </span>
            </td>
            <td class="no-mobile">
              {{ note.action.effectStartsAt | kendoDate:'d' }} - {{ note.action.effectStopsAt | kendoDate:'d' }}
            </td>
            <td
              class="ellipsis"
              [title]="note.action.reportedDescription"
            >
              <span class="reported-description">
                <a (click)="showAction(note.action)">
                  {{ note.action.reportedDescription }}
                </a>
              </span>
            </td>
            <td class="no-mobile">
              <investigation-status [status]="note.status"></investigation-status>
            </td>
            <td class="no-mobile">{{ note.level | translate }}</td>
          </tr>
        </tbody>
      </table>
      <span *ngIf="!notesData?.actions || notesData.actions.length === 0">
        {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_NO_ACTIONS' | translate }}
      </span>
      <div class="actions-to-perform">
        <div class="action-to-perform">
          <div
            class="button"
            (click)="addAction()"
          >
            <i class="fa fa-plus icon"></i>
            <span translate="FACILITIES_REPORT.ADD_NEW"></span>
          </div>
        </div>
      </div>
    </ng-container>
    <table
      class="alarms-table"
      *ngIf="alarmsAllowed && notesData?.alarms && notesData.alarms.length > 0"
    >
      <thead>
        <tr>
          <th class="alarm-executed-at">{{'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_ALARM_EXECUTED_AT' | translate}}</th>
          <th class="alarm-type">{{'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_TYPE_ALARM' | translate}}</th>
          <th class="alarm-status">{{'ACTIONS.INVESTIGATION' | translate}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let alarm of notesData.alarms | slice:0:10">
          <td>{{ alarm.alarm.executedAt | kendoDate:'d' }}</td>
          <td
            class="ellipsis"
            [title]="alarm.alarm.alarmTypeId | alarmTypeName"
          >
            <span class="alarm-type">
              <a (click)="openAlarmModal(alarm.alarm)">
                {{ alarm.alarm.alarmTypeId | alarmTypeName }}
              </a>
            </span>
          </td>
          <td>
            <alarm-log-status [status]="alarm.alarm.status"></alarm-log-status>
          </td>
          <td></td>
        </tr>
        <tr *ngIf="notesData.alarms.length > 10">
          <td colspan="4">
            {{ 'FACILITIES_GRID.COUNT' | translate : { count: 10, total: notesData?.alarms.length } }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ek-dropdown>
