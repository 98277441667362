<div [ngSwitch]="reportType$ | async" class="static-settings">
  <div *ngSwitchCase="ReportType.Overview">
    <p class="static-settings-title">{{ "FACILITIES_REPORT.OVERVIEW.STATIC_SETTINGS" | translate }}</p>
    <ul>
      <li>
        {{ "FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.TITLE" | translate }}
        {{ "FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.BY_QUANTITY" | translate | lowercase}}
      </li>
      <li>{{ "FACILITIES_REPORT.OVERVIEW.HEATING_ENERGIES" | translate }}</li>
      <li>{{ "FACILITIES_REPORT.OVERVIEW.DEFAULT_UNIT" | translate }}</li>
    </ul>
  </div>
  <div *ngSwitchCase="ReportType.YearlyTrend">
    <p class="static-settings-title">{{ "REPORTING.YEARLY_TREND.STATIC_SETTINGS_TITLE" | translate }}</p>
    <ul>
      <li>
        {{ "FACILITIES.QUANTITIES" | translate }}
        {{ "QUANTITIES.ELECTRICITY" | translate | lowercase }},
        {{ "QUANTITIES.HEATING_ENERGY" | translate | lowercase }},
        {{ "QUANTITIES.WATER" | translate | lowercase }}
      </li>
      <li>{{ "REPORTING.YEARLY_TREND.STATIC_SETTINGS_PERIOD" | translate }}</li>
      <li>{{ "REPORTING.YEARLY_TREND.STATIC_SETTINGS_SPECIFIC" | translate }}</li>
    </ul>
  </div>
</div>
