import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { groupBy } from '@progress/kendo-data-query';

import { MeteringClient, Quantities } from '@enerkey/clients/metering';

import { indicate } from '@enerkey/rxjs';

import { EkMultiComponent } from '../../../../shared/ek-inputs/ek-multi/ek-multi.component';

import { quantityTranslations } from '../../../../constants/quantity.constant';

@Component({
  selector: 'related-quantity-multi-select',
  templateUrl: '../../../../shared/ek-inputs/multi-select/multi-select.component.html',
  styleUrls: ['../../../../shared/ek-inputs/multi-select/multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: RelatedQuantityMultiSelectComponent,
    multi: true
  }],
})
export class RelatedQuantityMultiSelectComponent extends EkMultiComponent {
  public override placeholderKey = 'MULTISELECT.SEARCH_SELECTABLE_ITEMS';
  public override overflowThreshold = 3;

  public constructor(changeDetectorRef: ChangeDetectorRef,
    private readonly meteringClient: MeteringClient,
    private readonly translateService: TranslateService) {
    super(changeDetectorRef);
    this.meteringClient.getRelatedQuantities().pipe(
      indicate(this._loading)
    )
      .subscribe(
        quantities => {
          if (Object.keys(quantities).length === 0) {
            return;
          }
          this.items = Object.entries(quantities).flatMap(([group, items]) =>
            items.map(item => ({
              value: item.name,
              text: this.translateService.instant(quantityTranslations[item.id as Quantities]),
              group: this.translateService.instant(quantityTranslations[Number(group) as Quantities]),
              groupKey: this.translateService.instant(quantityTranslations[Number(group) as Quantities])
            }))).sortByMany('group', 'text');
          this.groupedItems = groupBy(this.items, [{ field: 'group', dir: 'asc' }]);
        }
      );
  }
}
