import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { getDayOfYear, startOfWeek, startOfYear, sub } from 'date-fns';
import {
  debounceTime,
  from,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';

import { ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';
import { Quantities } from '@enerkey/clients/metering';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { WidgetBase } from '../../shared/widget-base.interface';
import { TimeFrameOptions } from '../../../../constants/time-frame';
import { QuantityService } from '../../../../shared/services/quantity.service';
import { DashboardStateService } from '../../services/dashboard-state.service';
import ErTimeFrameService, { TimeFrameResult } from '../../../energy-reporting/services/er-time-frame.service';
import { PeriodReportService } from '../../../reporting/services/period-report.service';
import { ReportingSearchParams } from '../../../reporting/shared/reporting-search-params';
import { ReportingSeriesCollection } from '../../../reporting/shared/reporting-series-collection';
import { getDefaultReportingParams } from '../../../reporting/services/reporting.search.functions';
import { DurationName } from '../../../reporting/shared/reporting-search-form-value';
import { ReportingChartLabelSettings } from '../../../../shared/energy-reporting-shared/pipes/chart-categories.pipe';

export enum Temperature {
  YES = 'YES',
  INVERTED = 'INVERTED',
  NO = 'NO'
}

export interface FacilityWeeklyConsumptionWidgetOptions {
  facilityId: number;
  selectedQuantityId: Quantities;
  unitKey: ReportingUnit;
  resolution: RequestResolution.P7D | RequestResolution.PT1H;
  timeFrameOption: TimeFrameOptions;
  temperature: Temperature
}

@Component({
  selector: 'facility-weekly-consumption-widget',
  templateUrl: './facility-weekly-consumption-widget.component.html',
  styleUrl: './facility-weekly-consumption-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityWeeklyConsumptionWidgetComponent
implements WidgetBase<FacilityWeeklyConsumptionWidgetOptions>, OnDestroy, OnInit {
  public readonly dataModelChange$: Observable<FacilityWeeklyConsumptionWidgetOptions>;
  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<void>;

  public selectedQuantityIdControl: FormControl<Quantities>;
  public quantities$: Observable<Quantities[]>;
  public dataModelOptions: FacilityWeeklyConsumptionWidgetOptions;
  public params$: Observable<ReportingSearchParams>;
  public data$: Observable<ReportingSeriesCollection[]>;
  public start: TimeFrameResult;
  public chartLabelSettingOverrides: Partial<ReportingChartLabelSettings> = { step: 3 };

  private readonly _dataModelChange$ = new Subject<FacilityWeeklyConsumptionWidgetOptions>();
  private readonly _loading$ = new LoadingSubject(true);
  private readonly _error$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly quantityService: QuantityService,
    private readonly dashboardStateService: DashboardStateService,
    private readonly erTimeFrameService: ErTimeFrameService,
    private readonly periodReportService: PeriodReportService
  ) {

    this.loading$ = this._loading$.asObservable();
    this.error$ = this._error$.asObservable();
    this.dataModelChange$ = this._dataModelChange$.asObservable();
  }

  public ngOnInit(): void {
    this.selectedQuantityIdControl = new FormControl(this.dataModelOptions.selectedQuantityId);

    this.quantities$ = this.dataModelChange$.pipe(
      startWith(this.dataModelOptions),
      switchMap(dataModelOptions => {
        if (dataModelOptions.facilityId) {
          return from(this.quantityService.getSignificantQuantitiesForFacility(dataModelOptions.facilityId)).pipe(
            take(1),
            map(quantities => quantities.map(q => q.ID)),
            indicate(this._loading$)
          );
        }
        return of([]).pipe(indicate(this._loading$));
      }),
      tap(quantities => {
        if (!this.selectedQuantityIdControl.value && quantities.length > 0) {
          this.selectedQuantityIdControl.setValue(
            quantities.includes(Quantities.TotalEnergy) ? Quantities.TotalEnergy : quantities[0]
          );
        }
      }),
      takeUntil(this._destroy$)
    );

    this.selectedQuantityIdControl.valueChanges.pipe(
      debounceTime(500),
      takeUntil(this._destroy$)
    ).subscribe((selectedQuantityId: Partial<Quantities>) => {
      this._dataModelChange$.next({
        ...this.dataModelOptions,
        selectedQuantityId
      });
    });

    this.start = this.erTimeFrameService.getTimeFrameAndResParams(
      this.dataModelOptions.timeFrameOption,
      'None'
    );

    this.params$ = this.dataModelChange$.pipe(
      startWith(this.dataModelOptions),
      tap(dataModelOptions => {
        this.chartLabelSettingOverrides.step = dataModelOptions.resolution === RequestResolution.PT1H ? null : 3;
      }),
      switchMap(_ => of(this.getParams()))
    );

    this.data$ = this.dataModelChange$.pipe(
      startWith(this.dataModelOptions),
      switchMap(_ => this.getConsumptionData())
    );
  }

  public openFacilityReport(): void {
    this.dashboardStateService.openFacilityReport(this.dataModelOptions.facilityId);
  }

  public trackByMethod(_index: number, quantity: ReportingSeriesCollection): number {
    return quantity.quantityId;
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._error$.complete();
    this._loading$.complete();
  }

  private getConsumptionData(): Observable<ReportingSeriesCollection[]> {
    if (!this.dataModelOptions.facilityId || !this.dataModelOptions.selectedQuantityId) {
      return of([]).pipe(indicate(this._loading$));
    }

    return this.periodReportService.getData(
      this.getParams(),
      [this.dataModelOptions.facilityId],
      0
    ).pipe(
      map(data => data[this.dataModelOptions?.facilityId]),
      map(seriesCollections => seriesCollections.map(seriesCollection => {
        const updatedSeries = seriesCollection.series.map(serie => {

          const updatedSerie = serie.fromValues({
            chartOptions: {
              ...serie.chartOptions,
              reverseGraph: serie.chartOptions?.serieType === 'line' &&
                this.dataModelOptions.temperature === Temperature.INVERTED,
            },
          });

          return updatedSerie;
        });

        return {
          ...seriesCollection,
          series: updatedSeries,
        };
      })),
      indicate(this._loading$),
      takeUntil(this._destroy$)
    );
  }

  private getParams(): ReportingSearchParams {
    return new ReportingSearchParams({
      ...getDefaultReportingParams(),
      quantityIds: [this.dataModelOptions.selectedQuantityId],
      periods: this.start.Start.map(start => this.getPeriodStart(start.value)),
      durationName: Object.keys(this.start.Duration)[0] as DurationName,
      durationLength: Object.values(this.start.Duration)[0],
      resolution: this.dataModelOptions.resolution,
      temperature: [Temperature.YES, Temperature.INVERTED].includes(this.dataModelOptions.temperature),
      reportingUnit: this.dataModelOptions.unitKey
    });
  }

  private getPeriodStart(start: string): Date {
    if (this.dataModelOptions.timeFrameOption === TimeFrameOptions.YEAR_BY_YEAR_CALENDAR) {
      const today = new Date();
      // Overriding the deafult current start date condition for the calendar year
      return getDayOfYear(today) < 7
        ? startOfYear(sub(today, { years: 1 }))
        : startOfYear(today);
    }
    return startOfWeek(new Date(start), { weekStartsOn: 1 });
  }
}
