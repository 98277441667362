import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of } from 'rxjs';

import { FacilityClient } from '@enerkey/clients/facility';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { ReportingMeterSelection } from '../shared/reporting-meter-selection';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ReportingMeterDataService } from './reporting-meter-data.service';
import { ReportingSeriesService } from './reporting-series.service';
import { TrendReportBaseService } from './trend-report-base.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ReportType } from '../shared/report-type';

@Injectable({
  providedIn: 'root'
})
export class TrendMeterReportService extends TrendReportBaseService {
  public constructor(
    private readonly reportingMeterDataService: ReportingMeterDataService,
    reportingSeriesService: ReportingSeriesService,
    private readonly toaster: ToasterService,
    private readonly facilityClient: FacilityClient
  ) {
    super(reportingSeriesService);
  }

  public getData(
    params: ReportingSearchParams,
    facilityId: number,
    meters: ReportingMeterSelection
  ): Observable<ReportingSeriesByFacility> {
    if (this.hasUnsupportedParams(params)) {
      this.toaster.info('REPORTING.ERRORS.UNSUPPORTED_TREND_REPORT_TIMEPARAMS');
      return of(meters.meterIds.toRecord(id => id, () => []));
    }
    const chartOptions = this.getChartOptions(params);
    const modifiedParams = this.getModifiedParams(params);
    const modifiedDerivedParams = this.getModifiedParams(params, ReportingUnit.KWh);
    const titlePeriods = this.getTitlePeriods(params);
    return forkJoin({
      measured: forkJoin([
        this.reportingMeterDataService.getMeasuredValues(
          ReportType.Trend,
          modifiedParams,
          facilityId,
          meters,
          false,
          chartOptions,
          titlePeriods
        ),
      ]),
      normalized: forkJoin([
        this.reportingMeterDataService.getNormalizedValues(
          ReportType.Trend,
          modifiedParams,
          facilityId,
          meters,
          false,
          chartOptions,
          titlePeriods
        ),
      ]),
      meterBasedCosts: forkJoin([
        this.reportingMeterDataService.getMeterBasedCosts(
          modifiedParams,
          [facilityId],
          meters,
          false,
          {
            serieSettings: modifiedParams.periods.map(() => ({
              serieType: 'line'
            })),
          },
          titlePeriods
        )
      ]),
      nationalCosts: forkJoin([
        this.reportingMeterDataService.getNationalBasedCostsForMeter(
          modifiedParams,
          [facilityId],
          meters,
          false,
          {
            serieSettings: modifiedParams.periods.map(() => ({
              serieType: 'line'
            })),
          },
          titlePeriods
        )
      ]),
      temperatures: this.reportingMeterDataService.getTemperature(params, [facilityId], meters),
      facilityProperties: this.facilityClient.getFacilityProperties([facilityId], params.derivedIds),
      // the values to calculate specific trend should always in kWh
      measuredAndNormalizedInKWh: forkJoin([
        this.reportingMeterDataService.getMeasuredValues(
          ReportType.Trend,
          modifiedDerivedParams,
          facilityId,
          meters,
          false,
          chartOptions,
          titlePeriods
        ),
        this.reportingMeterDataService.getNormalizedValues(
          ReportType.Trend,
          modifiedDerivedParams,
          facilityId,
          meters,
          false,
          chartOptions,
          titlePeriods
        ),
      ])
    }).pipe(
      map(({
        measured,
        normalized,
        meterBasedCosts,
        nationalCosts,
        temperatures,
        facilityProperties,
        measuredAndNormalizedInKWh
      }) => this.handleResponse(
        meters.meterIds,
        measured,
        normalized,
        temperatures,
        nationalCosts,
        meterBasedCosts,
        [],
        facilityProperties,
        measuredAndNormalizedInKWh,
        modifiedParams.periods
      ))
    );
  }
}
