import { Injectable, OnDestroy } from '@angular/core';
import { asyncScheduler, combineLatest, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { htmlToFile } from '@enerkey/ts-utils';
import { switchJoin } from '@enerkey/rxjs';

import {
  AnalyticStatus,
  AnalyticsType,
  ElectricityDemandResponseData,
  ElectricityDemandResponseInsight,
  HeatBalanceData,
  HeatBalanceInsight,
  HeatingCoolingInsight,
  HeatingOptimizationInsight,
  HeatingPowerData,
  HeatingPowerInsight,
  IElectricityDemandResponseInsight,
  IHeatBalanceInsight,
  IHeatingPowerInsight,
  IMetaData,
  InesClient,
  InsightSearch,
  ISolarPowerInsight,
  IVentilationInsight,
  SolarPowerInsight,
  VentilationData,
  VentilationInsight,
} from '@enerkey/clients/ines';
import {
  AnalysisRequest,
  ElectricityDRResult,
  HeatingPowerResult,
  HeatingResult,
  InesReportsClient,
  LeanheatResult,
  SolarPowerResult,
  SolarRequest,
  VentilationResult
} from '@enerkey/clients/ines-reports';
import {
  ActionClass,
  ActionFilter,
  ActionGroup,
  ActionOutViewModel,
  ActionSlimViewModel,
  ActionType,
  AttachmentsClient,
  IActionViewModel,
  InesActionSlimViewModel,
  Investigation,
} from '@enerkey/clients/attachments';
import { IMeter, MeteringClient } from '@enerkey/clients/metering';
import { FacilityClient, IFacility } from '@enerkey/clients/facility';

import { UserService } from '../../../services/user-service';
import { getNewActionDefaults } from '../../energy-management/constants/em-shared.constant';
import { DateFormatService } from '../../../shared/services/date-format.service';
import { analyticsTypeTranslations } from '../analytics.translations';
import { FacilityService } from '../../../shared/services/facility.service';

import {
  ElectricityDemandResponseInsightModel,
  HeatBalanceInsightModel,
  HeatingCoolingInsightModel,
  HeatingOptimizationInsightModel,
  HeatingPowerInsightModel,
  InsightModelBase,
  SolarPowerInsightModel,
  VentilationInsightModel,
} from '../models/insight-model';

export type InesReport = { html: string; fileName: string };
export type AvailableInsight =
  VentilationInsight |
  HeatBalanceInsight |
  HeatingPowerInsight |
  ElectricityDemandResponseInsight |
  SolarPowerInsight |
  HeatingOptimizationInsight |
  HeatingCoolingInsight;

type AvailableInsights =
  VentilationInsight[] |
  HeatBalanceInsight[] |
  HeatingPowerInsight[] |
  ElectricityDemandResponseInsight[] |
  SolarPowerInsight[] |
  HeatingOptimizationInsight[] |
  HeatingCoolingInsight[];

type ActionCreateModel = Partial<IActionViewModel & { _files: File[] }>;

const _actionOwner = 'INES' as const;

type InsightsByType = {
  [AnalyticsType.Ventilation]: Observable<VentilationInsight[]>;
  [AnalyticsType.HeatBalance]: Observable<HeatBalanceInsight[]>;
  [AnalyticsType.HeatingPower]: Observable<HeatingPowerInsight[]>;
  [AnalyticsType.ElectricityDemandResponse]: Observable<ElectricityDemandResponseInsight[]>;
  [AnalyticsType.SolarPower]: Observable<SolarPowerInsight[]>;
  [AnalyticsType.HeatingOptimization]: Observable<HeatingOptimizationInsight[]>;
  [AnalyticsType.HeatingCooling]: Observable<HeatingCoolingInsight[]>;
};

type AnalyticsTypeWithInsight = keyof InsightsByType;

export type Currencies = string;

export enum CurrencyFormatTypes {
  WIDE = 'wide',
  NARROW = 'narrow'
}

type InsightResultsByType =
  VentilationResult |
  SolarPowerResult |
  ElectricityDRResult |
  HeatingPowerResult |
  HeatingResult |
  LeanheatResult;

/** Provided by analytics base component, lifetime lasts until user leaves INES-tab. */
@Injectable()
export class InsightService implements OnDestroy {
  private readonly _reportCache = new Map<string, Observable<InesReport>>();
  private readonly _destroy = new Subject<void>();
  private readonly _refresh = new Subject<AnalyticsType>();

  private _ventilationInsights: Observable<VentilationInsight[]>;
  private _heatBalanceInsights: Observable<HeatBalanceInsight[]>;
  private _heatingPowerInsights: Observable<HeatingPowerInsight[]>;
  private _electricityDemandResponseInsights: Observable<ElectricityDemandResponseInsight[]>;
  private _solarPowerResponseInsights: Observable<SolarPowerInsight[]>;
  private _heatingOptimizationInsights: Observable<HeatingOptimizationInsight[]>;
  private _heatingCoolingInsights: Observable<HeatingCoolingInsight[]>;

  public constructor(
    private readonly facilityService: FacilityService,
    private readonly inesClient: InesClient,
    private readonly inesReportsClient: InesReportsClient,
    private readonly facilityClient: FacilityClient,
    private readonly meterClient: MeteringClient,
    private readonly userService: UserService,
    private readonly dateFormatService: DateFormatService,
    private readonly translateService: TranslateService,
    private readonly attachmentClient: AttachmentsClient
  ) {
    this._ventilationInsights = this.getSharedInsights(AnalyticsType.Ventilation, 'getVentilationInsights');
    this._heatBalanceInsights = this.getSharedInsights(AnalyticsType.HeatBalance, 'getHeatBalanceInsights');
    this._heatingPowerInsights = this.getSharedInsights(AnalyticsType.HeatingPower, 'getHeatingPowerInsights');
    this._electricityDemandResponseInsights =
      this.getSharedInsights(AnalyticsType.ElectricityDemandResponse, 'getElectricityDemandResponseInsights');
    this._solarPowerResponseInsights = this.getSharedInsights(AnalyticsType.SolarPower, 'getSolarPowerInsights');
    this._heatingOptimizationInsights = this.getSharedInsights(AnalyticsType.HeatingOptimization,
      'getHeatingOptimizationInsights');
    this._heatingCoolingInsights = this.getSharedInsights(AnalyticsType.HeatingCooling, 'getHeatingCoolingInsights');
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._refresh.complete();
    this._reportCache.clear();
  }

  public onRecalculateUpdateInsightsState(analyticsType: AnalyticsType): void {
    switch (analyticsType) {
      case AnalyticsType.Ventilation:
        this._ventilationInsights = this.getSharedInsights(AnalyticsType.Ventilation, 'getVentilationInsights');
        break;
      case AnalyticsType.HeatBalance:
        this._heatBalanceInsights = this.getSharedInsights(AnalyticsType.HeatBalance, 'getHeatBalanceInsights');
        break;
      case AnalyticsType.HeatingPower:
        this._heatingPowerInsights = this.getSharedInsights(AnalyticsType.HeatingPower, 'getHeatingPowerInsights');
        break;
      case AnalyticsType.ElectricityDemandResponse:
        this._electricityDemandResponseInsights =
          this.getSharedInsights(AnalyticsType.ElectricityDemandResponse, 'getElectricityDemandResponseInsights');
        break;
      case AnalyticsType.SolarPower:
        this._solarPowerResponseInsights = this.getSharedInsights(AnalyticsType.SolarPower, 'getSolarPowerInsights');
        break;
      case AnalyticsType.HeatingOptimization:
        this._heatingOptimizationInsights =
          this.getSharedInsights(AnalyticsType.HeatingOptimization, 'getHeatingOptimizationInsights');
        break;
      case AnalyticsType.HeatingCooling:
        this._heatingCoolingInsights =
          this.getSharedInsights(AnalyticsType.HeatingCooling, 'getHeatingCoolingInsights');
        break;
    }
  }

  public getVentilationModels(action: ActionSlimViewModel[]): Observable<VentilationInsightModel[]> {
    return this.getModels(
      this._ventilationInsights,
      (insight, facility) => new VentilationInsightModel(insight, facility, action)
    );
  }

  public getHeatBalanceModels(action: ActionOutViewModel[]): Observable<HeatBalanceInsightModel[]> {
    return this.getModels(
      this._heatBalanceInsights,
      (insight, facility) => new HeatBalanceInsightModel(insight, facility, action)
    );
  }

  public getHeatingPowerModels(action: ActionOutViewModel[]): Observable<HeatingPowerInsightModel[]> {
    return this.getModels(
      this._heatingPowerInsights,
      (insight, facility) => new HeatingPowerInsightModel(insight, facility, action)
    );
  }

  public getElectricityDemandResponseModels(action: ActionOutViewModel[]):
  Observable<ElectricityDemandResponseInsightModel[]> {
    return this.getModels(
      this._electricityDemandResponseInsights,
      (insight, facility) => new ElectricityDemandResponseInsightModel(insight, facility, action)
    );
  }

  public getSolarPowerModels(action: ActionOutViewModel[]): Observable<SolarPowerInsightModel[]> {
    return this.getModels(
      this._solarPowerResponseInsights,
      (insight, facility) => new SolarPowerInsightModel(insight, facility, action)
    );
  }

  public getHeatingOptimizationModels(action: ActionOutViewModel[]): Observable<HeatingOptimizationInsightModel[]> {
    return this.getModels(
      this._heatingOptimizationInsights,
      (insight, facility) => new HeatingOptimizationInsightModel(insight, facility, action)
    );
  }

  public getHeatingCoolingModels(action: ActionOutViewModel[]): Observable<HeatingCoolingInsightModel[]> {
    return this.getModels(
      this._heatingCoolingInsights,
      (insight, facility) => new HeatingCoolingInsightModel(insight, facility, action)
    );
  }

  public getInsights(analyticsType: AnalyticsType): Observable<AvailableInsights> {
    const byType: Record<AnalyticsType, Observable<AvailableInsights>> = {
      [AnalyticsType.Ventilation]: this._ventilationInsights,
      [AnalyticsType.HeatBalance]: this._heatBalanceInsights,
      [AnalyticsType.HeatingPower]: this._heatingPowerInsights,
      [AnalyticsType.ElectricityDemandResponse]: this._electricityDemandResponseInsights,
      [AnalyticsType.SolarPower]: this._solarPowerResponseInsights,
      [AnalyticsType.HeatingOptimization]: this._heatingOptimizationInsights,
      [AnalyticsType.HeatingCooling]: this._heatingCoolingInsights,
      [AnalyticsType.HeatingPeakShaving]: throwError(() => new Error('Not implemented')),
      [AnalyticsType.ElectricityPeakShaving]: throwError(() => new Error('Not implemented')),
      [AnalyticsType.ElectricityBaseloadOptimisation]: throwError(() => new Error('Not implemented')),
    };

    return byType[analyticsType]?.pipe(take(1)) ??
      throwError(`Unsupported analytics type ${analyticsType}`);
  }

  public getReport(analyticsType: AnalyticsType, facilityId: number): Observable<InesReport> {
    const key = `${analyticsType}_${facilityId}`;

    const fromCache = this._reportCache.get(key);

    if (fromCache) {
      return fromCache.pipe(take(1));
    }

    const report$ = this.inesClient.getProfileAnalyticsReport(
      analyticsType,
      this.userService.profileId,
      facilityId
    ).pipe(
      map(html => ({ html, fileName: this.getFileName(analyticsType, facilityId) })),
      catchError(err => {
        // don't cache failed requests
        this._reportCache.delete(key);
        return throwError(err);
      }),
      shareReplay({ bufferSize: 1, scheduler: asyncScheduler, refCount: false }),
      takeUntil(this._destroy)
    );

    this._reportCache.set(key, report$);
    return report$.pipe(take(1));
  }

  public getEmbeddedReport(
    analyticsType: AnalyticsType,
    facilityId: number,
    requestBody: AnalysisRequest | SolarRequest
  ): Observable<InsightResultsByType> {
    switch (analyticsType) {
      case AnalyticsType.Ventilation:
        return this.inesReportsClient.getVentilationReport(requestBody, facilityId).pipe(
          shareReplay(1),
          take(1)
        );
      case AnalyticsType.SolarPower:
        return this.inesReportsClient.getSolarPowerReport(requestBody, facilityId).pipe(
          shareReplay(1),
          take(1)
        );
      case AnalyticsType.ElectricityDemandResponse:
        return this.inesReportsClient.getDemandResponseReport(requestBody, facilityId).pipe(
          shareReplay(1),
          take(1)
        );
      case AnalyticsType.HeatingPower:
        return this.inesReportsClient.getHeatingPowerReport(requestBody, facilityId).pipe(
          shareReplay(1),
          take(1)
        );
      case AnalyticsType.HeatBalance:
        return this.inesReportsClient.getHeatingReport(requestBody, facilityId).pipe(
          shareReplay(1),
          take(1)
        );
      case AnalyticsType.HeatingOptimization:
        return this.inesReportsClient.getLeanheatReport(requestBody, facilityId).pipe(
          shareReplay(1),
          take(1)
        );
      case AnalyticsType.HeatingCooling:
        return this.inesReportsClient.getHeatingCoolingReport(requestBody, facilityId).pipe(
          shareReplay(1),
          take(1)
        );
      default:
        return throwError(() => new Error(`Unsupported analytics type ${analyticsType}`));
    }
  }

  public getActionModel(analyticsType: AnalyticsType, facilityId: number): Observable<ActionCreateModel> {
    let actionSelector: (insight: AvailableInsight, meters: IMeter[], report: InesReport) => ActionCreateModel;

    if (analyticsType === AnalyticsType.Ventilation) {
      actionSelector = actionFromVentilation;
    } else if (analyticsType === AnalyticsType.HeatBalance) {
      actionSelector = actionFromHeatBalance;
    } else if (analyticsType === AnalyticsType.HeatingPower) {
      actionSelector = actionFromHeatingPower;
    } else if (analyticsType === AnalyticsType.ElectricityDemandResponse) {
      actionSelector = actionFromElectricityDemandResponse;
    } else if (analyticsType === AnalyticsType.SolarPower) {
      actionSelector = actionFromSolarPower;
    } else if (analyticsType === AnalyticsType.HeatingOptimization) {
      actionSelector = actionFromHeatingOptimization;
    } else if (analyticsType === AnalyticsType.HeatingCooling) {
      actionSelector = actionFromHeatingCooling;
    } else {
      throw Error(`Unsupported analytics type ${analyticsType}`);
    }

    return this.getInsights(analyticsType).pipe(
      map((insights: AvailableInsight[]) => insights.find((i: AvailableInsight) => i.facilityId === facilityId))
    ).pipe(
      switchMap(insight => forkJoin([
        of(insight),
        this.getMeters(insight?.data?.meta),
        this.getReport(analyticsType, facilityId)
      ])),
      map(args => actionSelector(...args))
    );
  }

  public setStatus(
    analyticsType: AnalyticsType,
    facilityId: number,
    value: AnalyticStatus
  ): Observable<void> {
    return this.inesClient.setAnalyticsState(
      analyticsType,
      value,
      [facilityId]
    ).pipe(
      tap({ complete: () => this._refresh.next(analyticsType) })
    );
  }

  public getInesActions(analyticsType: AnalyticsType): Observable<InesActionSlimViewModel[]> {
    /* eslint-disable max-len */
    return this.attachmentClient.getActionsFilter(
      new ActionFilter({
        owner: 'INES',
        actionGroup: this.mapActionGroupBasedOnType(analyticsType)
      })
    ).pipe(
      take(1),
      map(data => data.sortBy('updatedAt', 'desc')),
      shareReplay(1),
      takeUntil(this._destroy)
    );
  }

  private getMeters(meta: IMetaData): Observable<IMeter[]> {
    if (!Array.hasItems(meta?.meterIds)) {
      return of([]);
    }

    return this.meterClient.getMeters(meta.meterIds).pipe(takeUntil(this._destroy));
  }

  private getFileName(analyticsType: AnalyticsType, facilityId: number): string {
    const typeName = this.translateService.instant(analyticsTypeTranslations[analyticsType]);
    const timestamp = this.dateFormatService.fileNameFormattedDate();

    return `INES_${typeName}_${facilityId}_${timestamp}`;
  }

  private getSharedInsights<T extends AnalyticsTypeWithInsight>(
    analyticsType: T,
    endpoint: 'getVentilationInsights'
    | 'getHeatBalanceInsights'
    | 'getHeatingPowerInsights'
    | 'getElectricityDemandResponseInsights'
    | 'getSolarPowerInsights'
    | 'getHeatingOptimizationInsights'
    | 'getHeatingCoolingInsights'
  ): InsightsByType[T] {
    const params$ = this.facilityService.filteredProfileFacilityIds$.pipe(
      map(facilityIds => [this.userService.profileId, new InsightSearch({ facilityIds })] as const)
    );

    return combineLatest([
      params$,
      this._refresh.pipe(filter(t => t === analyticsType), startWith(analyticsType))
    ]).pipe(
      switchMap(([params]) => this.inesClient[endpoint](...params)),
      shareReplay(1),
      takeUntil(this._destroy)
    );
  }

  private getModels<TInsight extends AvailableInsight, TModel extends InsightModelBase>(
    insights$: Observable<TInsight[]>,
    modelfn: (insight: TInsight, facility: IFacility) => TModel
  ): Observable<TModel[]> {
    return insights$.pipe(
      take(1),
      filter(insights => Array.hasItems(insights)),
      switchJoin(insights => this.facilityClient.getFacilities(insights.map(i => i.facilityId))),
      map(([insights, facilities]) => insights.joinLeft(
        facilities,
        insight => insight.facilityId,
        facility => facility.id,
        modelfn
      ))
    );
  }

  private mapActionGroupBasedOnType(analyticsType: AnalyticsType): ActionGroup {
    switch (analyticsType) {
      case 1:
        return ActionGroup.Airconditioning;
      case 2:
      case 3:
      case 7:
      case 8:
        return ActionGroup.Heating;
      case 4:
        return ActionGroup.OtherElectricity;
      case 5:
        return ActionGroup.SolarPower;
      default:
        return ActionGroup.OtherDeviceElectricity;
    }
  }

}

function actionFromElectricityDemandResponse(
  item: IElectricityDemandResponseInsight,
  meters: IMeter[],
  report: InesReport
): ActionCreateModel {
  return {
    ...getNewActionDefaults(ActionType.ES),
    _files: [htmlToFile(report.html, report.fileName)],
    reportingObjectId: item.facilityId,
    meterIds: meters.map(m => m.id),
    quantityIds: meters.unique('quantityId'),
    actionType: ActionType.ES,
    investigation: Investigation.InvestigationRequired,
    actionClass: ActionClass.OtherProcedure,
    actionGroup: ActionGroup.OtherElectricity,
    heatingSavingsEur: item.data.heatingEur,
    electricitySavingsEur: item.data.eleDrPotentialEur,
    owner: _actionOwner,
    reportedDescription: `Electricity demand response analysis ${new Date().toDateString()}`,
    internalDescription: `Created from INES on ${new Date().toLocaleString()}`
  };
}

function actionFromVentilation(
  item: IVentilationInsight,
  meters: IMeter[],
  report: InesReport
): ActionCreateModel {
  return {
    ...getNewActionDefaults(ActionType.ES),
    _files: [htmlToFile(report.html, report.fileName)],
    reportingObjectId: item.facilityId,
    meterIds: meters.map(m => m.id),
    quantityIds: meters.unique('quantityId'),
    actionType: ActionType.ES,
    investigation: Investigation.InvestigationRequired,
    actionClass: ActionClass.TimeprogramChange,
    actionGroup: ActionGroup.Airconditioning,
    electricitySavings: item.data.electricitySavingsInMwh,
    electricitySavingsCo2: co2toTonnes(item.data.electricitySavingsCO2InKg),
    electricitySavingsEur: item.data.electricitySavingsInEur,
    heatingSavings: item.data.heatingSavingsInMwh,
    heatingSavingsCo2: co2toTonnes(item.data.heatingSavingsCO2InKg),
    heatingSavingsEur: item.data.heatingSavingsInEur,
    owner: _actionOwner,
    reportedDescription: `Ventilation analysis (${new Date().toDateString()})`,
    internalDescription: `Created from INES on ${new Date().toLocaleString()}`,
    // referenceId: item.facilityId
  };

  // actions use CO2 tonnes
  function co2toTonnes(value: number | null): number | null {
    // prevent NaNs
    if (Number.isFinite(value)) {
      return value / 1000;
    }
    return null;
  }
}

function actionFromHeatBalance(
  item: IHeatBalanceInsight,
  meters: IMeter[],
  report: InesReport
): ActionCreateModel {
  return {
    ...getNewActionDefaults(ActionType.ES),
    _files: [htmlToFile(report.html, report.fileName)],
    reportingObjectId: item.facilityId,
    meterIds: meters.map(m => m.id),
    quantityIds: meters.unique('quantityId'),
    actionType: ActionType.ES,
    investigation: Investigation.InvestigationRequired,
    actionClass: ActionClass.OtherProcedure,
    actionGroup: ActionGroup.Heating,
    owner: _actionOwner,
    reportedDescription: `Heat balance analysis (${new Date().toDateString()})`,
    internalDescription: `Created from INES on ${new Date().toLocaleString()}`,
    // referenceId: item.facilityId
  };
}

function actionFromHeatingPower(
  item: IHeatingPowerInsight,
  meters: IMeter[],
  report: InesReport
): ActionCreateModel {
  return {
    ...getNewActionDefaults(ActionType.ES),
    _files: [htmlToFile(report.html, report.fileName)],
    reportingObjectId: item.facilityId,
    meterIds: meters.map(m => m.id),
    quantityIds: meters.unique('quantityId'),
    actionType: ActionType.ES,
    investigation: Investigation.InvestigationRequired,
    actionClass: ActionClass.OtherProcedure,
    actionGroup: ActionGroup.Heating,
    owner: _actionOwner,
    reportedDescription: `Heating power analysis (${new Date().toDateString()})`,
    internalDescription: `Created from INES on ${new Date().toLocaleString()}`,
    // referenceId: item.facilityId
  };
}

function actionFromHeatingOptimization(
  item: HeatingOptimizationInsight,
  meters: IMeter[],
  report: InesReport
): ActionCreateModel {
  return {
    ...getNewActionDefaults(ActionType.ES),
    _files: [htmlToFile(report.html, report.fileName)],
    reportingObjectId: item.facilityId,
    meterIds: meters.map(m => m.id),
    quantityIds: meters.unique('quantityId'),
    actionType: ActionType.ES,
    investigation: Investigation.InvestigationRequired,
    actionClass: ActionClass.OtherProcedure,
    actionGroup: ActionGroup.Heating,
    heatingSavings: item.data.heatingSavingsInMwh,
    owner: _actionOwner,
    reportedDescription: `Heating optimization analysis (${new Date().toDateString()})`,
    internalDescription: `Created from INES on ${new Date().toLocaleString()}`,
  };
}

function actionFromHeatingCooling(
  item: HeatingCoolingInsight,
  meters: IMeter[],
  report: InesReport
): ActionCreateModel {
  return {
    ...getNewActionDefaults(ActionType.ES),
    _files: [htmlToFile(report.html, report.fileName)],
    reportingObjectId: item.facilityId,
    meterIds: Array.from(new Set(meters
      .filter(meter => [1, 3, 11].includes(meter.quantityId))
      .map(meter => meter.id))),
    quantityIds: Array.from(new Set(meters
      .filter(meter => [1, 3, 11].includes(meter.quantityId))
      .map(meter => meter.quantityId))),
    actionType: ActionType.ES,
    investigation: Investigation.InvestigationRequired,
    actionClass: ActionClass.OtherProcedure,
    actionGroup: ActionGroup.Heating,
    heatingSavings: item.data.heatingSavingsInMwh,
    heatingSavingsEur: item.data.heatingMonetarySavings,
    electricitySavings: item.data.electricitySavingsInMwh,
    electricitySavingsEur: item.data.electricityMonetarySavings,
    owner: _actionOwner,
    reportedDescription: `Heating cooling analysis (${new Date().toDateString()})`,
    internalDescription: `Created from INES on ${new Date().toLocaleString()}`,
  };
}

function actionFromSolarPower(
  item: ISolarPowerInsight,
  _meters: IMeter[],
  report: InesReport
): ActionCreateModel {
  return {
    ...getNewActionDefaults(ActionType.O),
    _files: [htmlToFile(report.html, report.fileName)],
    reportingObjectId: item.facilityId,
    actionType: ActionType.O,
    investigation: Investigation.InvestigationRequired,
    actionClass: ActionClass.RenewableEnergyUtilization,
    actionGroup: ActionGroup.SolarPower,
    investment: item.data.investment ?? null,
    owner: _actionOwner,
    reportedDescription: `Solar power analysis (${new Date().toDateString()})`,
    internalDescription: `Created from INES on ${new Date().toLocaleString()}`,
  };
}

_generateVentilationData;
_generateHeatBalanceData;
_generateHeatingPowerData;
_generateElectricityDemandResponseData;

function _generateVentilationData(): VentilationInsight[] {
  const mockIds = [713, 714, 1186, 1201, 2751, 2820, 2838, 3720, 14038];

  const mockData = Array.from({ length: mockIds.length }).map((_, i) => {
    const noSavings = Math.random() < 0.05;
    return new VentilationInsight({
      analyticStatus: random(0, 4),
      facilityId: mockIds[i],
      latestState: random(0, 4),
      stateReason: random(0, 10),
      data: new VentilationData({
        usesTimeProgram: random(0, 3),
        totalConsumptionSavings: noSavings ? 0 : random(100, 5000),
        totalMonetarySavings: noSavings ? 0 : random(100, 5000),
        electricitySavingsCO2InKg: random(0, 500),
        electricitySavingsInEur: random(0, 500),
        electricitySavingsInMwh: random(0, 500),
        heatingSavingsCO2InKg: random(0, 500),
        heatingSavingsInEur: random(0, 500),
        heatingSavingsInMwh: random(0, 500),
      }),
    });
  });

  return mockData;

  function random(min: number, max: number): number {
    return Math.floor(Math.random() * max) + min;
  }
}

function _generateHeatingPowerData(): HeatingPowerInsight[] {
  const mockIds = [713, 714, 1186, 1201, 2751, 2820, 2838, 3720, 14038];

  const mockData = Array.from({ length: mockIds.length }).map((_, i) => new HeatingPowerInsight({
    analyticStatus: random(0, 4),
    facilityId: mockIds[i],
    latestState: random(0, 6),
    stateReason: random(0, 10),
    data: new HeatingPowerData({
      heatingLimit: 10 + Math.random() * 20,
      maxHeatingPowerDemand: 10 + Math.random() * 20,
      maxHeatingPowerDemandM2: 10 + Math.random() * 20,
      heatingLimitSavingsEur: 50 + Math.random() * 100,
      powerDemandSavingsEur: 150 + Math.random() * 200,
      powerDemandSavingsMwh: 150 + Math.random() * 200,
      waterFlowMaxValue: 10 + Math.random() * 20,
      waterFlowOutliers: random(0, 5),
      summerIndex: Math.random() > 0.8 ? 1.0 : Math.random(),
      winterIndex: Math.random() > 0.8 ? 1.0 : Math.random(),
      usesAuxiliaryElectricHeating: Math.random() >= 0.5,
    }),
  }));

  return mockData;

  function random(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min)) + min;
  }
}

function _generateHeatBalanceData(): HeatBalanceInsight[] {
  const mockIds = [713, 714, 1186, 1201, 2751, 2820, 2838, 3720, 14038];

  const mockData = Array.from({ length: mockIds.length }).map((_, i) => new HeatBalanceInsight({
    analyticStatus: random(0, 4),
    facilityId: mockIds[i],
    latestState: random(0, 6),
    stateReason: random(0, 10),
    data: new HeatBalanceData({
      wattPerM2: 10 + Math.random() * 20,
      baseLoad: 10 + Math.random() * 20,
      baseLoadReference: 10 + Math.random() * 20,
      baseLoadRefDiffMwh: 10 + Math.random() * 20,
      baseLoadRefDiffEur: 10 + Math.random() * 20,
      baseLoadRefDiffCO2: 10 + Math.random() * 20,
      ventilation: 10 + Math.random() * 20,
      ventilationReference: 10 + Math.random() * 20,
      ventilationRefDiffMwh: 10 + Math.random() * 20,
      ventilationRefDiffEur: 10 + Math.random() * 20,
      ventilationRefDiffCO2: 10 + Math.random() * 20,
      hotWater: 10 + Math.random() * 20,
      airTightness: 10 + Math.random() * 20,
    }),
  }));

  return mockData;

  function random(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min)) + min;
  }
}

function _generateElectricityDemandResponseData(): ElectricityDemandResponseInsight[] {
  const mockIds = [713, 714, 1186, 1201, 2751, 2820, 2838, 3720, 14038];

  const mockData = Array.from({ length: mockIds.length }).map((_, i) => new ElectricityDemandResponseInsight({
    analyticStatus: random(0, 4),
    facilityId: mockIds[i],
    latestState: random(0, 6),
    stateReason: random(0, 10),
    data: new ElectricityDemandResponseData({
      coolingCapacity: 10 + Math.random() * 200,
      heatingCapacity: 10 + Math.random() * 200,
      differenceCapacity: 10 + Math.random() * 200,
      coolingEur: random(200, 1000),
      heatingEur: random(200, 1000),
      differenceEur: random(200, 1000),
      eleDrPotentialEur: random(1000, 5000),
    }),
  }));

  return mockData;

  function random(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min)) + min;
  }
}
