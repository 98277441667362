import { Quantities } from '@enerkey/clients/metering';

import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { ConsumptionLike, ReportingSeries } from '../shared/reporting-series';
import { ReportingData } from './reporting-data-service-base';
import { sumKey } from './sum-report.service';
import { SelectableResolution } from '../components/reporting-search-form/reporting-search-form.component';
import { Amounts } from '../shared/reporting-series-collection';

export type AmountsPerQuantity = Partial<Record<Quantities, Amounts>>;

export function getQuantitySums({
  measured,
  normalized,
  measuredTargets,
  normalizedTargets,
  comparability,
  incompletes,
  ids,
  meterBasedCosts,
  resolutions,
  durationLength,
  searchPeriods
}: {
  measured: ReportingData[],
  normalized: ReportingData[],
  measuredTargets: ReportingData[],
  normalizedTargets: ReportingData[],
  comparability: Comparability,
  incompletes: Record<number, Set<Quantities>>,
  ids: number[],
  meterBasedCosts: ReportingData[],
  resolutions?: SelectableResolution,
  durationLength?: number,
  searchPeriods?: { start: Date; end: Date; }[]
}): {
    measured: ReportingData[],
    normalized: ReportingData[],
    measuredTargets: ReportingData[],
    normalizedTargets: ReportingData[],
    meterBasedCosts: ReportingData[]
  } {
  return {
    measured: getSumOfValues(measured, ids, incompletes, comparability, resolutions, durationLength, searchPeriods),
    normalized: getSumOfValues(normalized, ids, incompletes, comparability, resolutions, durationLength, searchPeriods),
    measuredTargets: getSumOfValues(
      measuredTargets, ids, incompletes, comparability, resolutions, durationLength, searchPeriods
    ),
    normalizedTargets: getSumOfValues(
      normalizedTargets, ids, incompletes, comparability, resolutions, durationLength, searchPeriods
    ),
    meterBasedCosts: getSumOfValues(
      meterBasedCosts,
      ids,
      incompletes,
      comparability,
      resolutions,
      durationLength,
      searchPeriods
    )
  };
}

export function getIncompleteFacilitiesAndQuantities(
  data: ReportingData[],
  facilityIds: number[],
  incompleteThreshold: number
): Record<number, Set<Quantities>> {
  return facilityIds.toRecord(
    fId => fId,
    fId => {
      const incompleteQuantities = new Set<Quantities>();
      for (const quantity of data) {
        const maxIncomplete = Math.max(...quantity.series[fId]?.map(s => Math.max(
          ...s.values.map(v => v.incomplete ?? 0)
        )) ?? [Infinity]);
        if (maxIncomplete > incompleteThreshold) {
          incompleteQuantities.add(quantity.quantityId);
        }
      }
      return incompleteQuantities;
    }
  );
}

export function getAmountsPerQuantity(
  data: ReportingData[],
  facilityIds: number[],
  incompletes: Record<number, Set<Quantities>>,
  comparability: Comparability
): AmountsPerQuantity {
  const quantityFacilityAmounts: AmountsPerQuantity = {};

  for (const [quantity, quantityData] of data.toGroupsBy(d => d.quantityId)) {
    const applicableFacilities = facilityIds.count(
      fId => quantityData.some(d => isFacilityQuantitySumApplicable(d, fId, comparability, incompletes))
        && !!quantityData.some(d => d.series[fId])
    );
    const totalFacilities = facilityIds.count(fId => quantityData.some(d => d.series[fId]?.some(s => s.hasValues)));

    quantityFacilityAmounts[quantity] = { totalFacilities, applicableFacilities };
  }
  const metersWithValues = getMetersWithValues(data);
  for (const [qId, v] of Object.integerEntries(quantityFacilityAmounts)) {
    v.metersWithValues = metersWithValues[qId];
  }
  return quantityFacilityAmounts;
}

/**
 * Returns unique meter ids that have values in specific reporting data
 */
function getMetersWithValues(data: ReportingData[]): Record<number, Set<number>> {
  const metersWithValues = [];
  for (const item of data) {
    const obj = {
      quantityId: item.quantityId,
      meterIds: [] as number[],
    };
    for (const [id, series] of Object.integerEntries(item.series)) {
      if (series?.length) {
        obj.meterIds.push(id);
      }
    }
    if (obj.meterIds.length) {
      metersWithValues.push(obj);
    }
  }
  const metersWithValuesPerQuantity: Record<number, Set<number>> = {};
  for (const o of metersWithValues) {
    if (metersWithValuesPerQuantity[o.quantityId] === undefined) {
      metersWithValuesPerQuantity[o.quantityId] = new Set<number>(o.meterIds);
    } else {
      for (const id of o.meterIds) {
        metersWithValuesPerQuantity[o.quantityId].add(id);
      }
    }
  }
  return metersWithValuesPerQuantity;
}

function getSumOfValues(
  data: ReportingData[],
  facilityIds: number[],
  incompletes: Record<number, Set<Quantities>>,
  comparability: Comparability,
  resolutions?: SelectableResolution,
  durationLength?: number,
  searchPeriods?: { start: Date; end: Date; }[]
): ReportingData[] {
  if (!Array.hasItems(data)) {
    return data;
  }
  return data.map(d => {
    const allSeries = facilityIds.filterMap(
      fId => !!d.series[fId] || isFacilityQuantitySumApplicable(d, fId, comparability, incompletes),
      fId => d.series[fId] ?? []
    );
    const quantityData = allSeries.flat();
    const grouped = quantityData.toGroupsBy(x => `${x.options.serieType}${x.options.serieTitle}`).values();

    const quantitySumSeries: ReportingSeries[] = [];
    for (const series of grouped) {
      quantitySumSeries.push(series[0].fromValues({
        consumptions: getValues(series),
        comparisonConsumptions: getComparisonValues(series),
      }, {
        resolution: resolutions,
        searchPeriods: searchPeriods,
        durationLength: durationLength
      }));
    }

    return {
      quantityId: d.quantityId,
      derivedId: d.derivedId,
      series: {
        [sumKey]: quantitySumSeries
      }
    };
  });
}

/**
 * Returns sum of values for each timestamp
 */
function getValues(series: ReportingSeries[]): ConsumptionLike[] {
  const values: ConsumptionLike[] = [];
  for (let i = 0; i < series[0].values.length; i++) {
    const allValuesNull = series.every(s => s.values[i].value === null || s.values[i].value === undefined);
    values.push({
      ...series.reduce((accum, current) => ({
        value: allValuesNull ? null : accum.value + (current.values[i].value ?? 0),
        incomplete: Math.max(accum.incomplete, current.values[i].incomplete ?? 0)
      }), { value: 0, incomplete: 0 }),
      timestamp: series[0].values[i].timestamp
    });
  }
  return values;
}

/**
 * Returns sum of comparison values for each timestamp
 */
function getComparisonValues(series: ReportingSeries[]): ConsumptionLike[] {
  const comparisonValues: ConsumptionLike[] = [];
  for (let i = 0; i < series[0].values.length; i++) {
    if (series[0].comparisonConsumptions) {
      const allComparisonValuesNull = series.every(s => (
        s.comparisonConsumptions[i].value === null ||
        s.comparisonConsumptions[i].value === undefined
      ));
      comparisonValues.push({
        value: allComparisonValuesNull
          ? null
          : series.reduce((accum, current) => accum + (current.comparisonConsumptions[i].value ?? 0), 0),
        timestamp: series[0].comparisonConsumptions[i].timestamp
      });
    }
  }
  return comparisonValues;
}

function isFacilityQuantitySumApplicable(
  data: ReportingData,
  facilityId: number,
  comparability: Comparability,
  incompletes: Record<number, Set<Quantities>>
): boolean {
  if (comparability === Comparability.ByQuantities && (incompletes[facilityId].size > 0)) {
    return false;
  }
  if (comparability === Comparability.ByQuantity && incompletes[facilityId].has(data.quantityId)) {
    return false;
  }
  return true;
}
