import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

import { formGroupFrom } from '@enerkey/ts-utils';

import { kendoNumericTextboxOptions } from '../../models/constants';

export type ContractMassAddForm = {
  rowCount: number;
  enegiaId: number;
  contractId: string;
  productId: string;
  fromDate: Date;
  toDate: Date;
  unitPrice: number;
  unitCount: number;
};

@Component({
  selector: 'contract-mass-add-options',
  templateUrl: './contract-add-options.component.html',
  styleUrls: ['./contract-add-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractMassAddOptionsComponent implements ControlValueAccessor {
  public readonly numberInputOpts: Partial<NumericTextBoxComponent> = kendoNumericTextboxOptions;

  public readonly formGroup: UntypedFormGroup;

  @Output()
  public readonly isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor() {
    this.formGroup = formGroupFrom<ContractMassAddForm>({
      rowCount: 100,
      enegiaId: null,
      contractId: null,
      productId: null,
      fromDate: null,
      toDate: null,
      unitPrice: null,
      unitCount: null,
    }, {
      rowCount: [Validators.required, Validators.min(1), Validators.max(200)],
      unitCount: Validators.min(0),
      unitPrice: Validators.min(0),
    });

    this.formGroup.statusChanges.subscribe(
      (value: string) => this.isValid.emit(value === 'VALID')
    );
  }

  public writeValue(value?: ContractMassAddForm): void {
    if (value) {
      this.formGroup.setValue(value);
    } else {
      this.formGroup.reset();
    }
  }

  public registerOnChange(fn: (value: ContractMassAddForm) => void): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  /* istanbul ignore next */
  public registerOnTouched(): void { }

  public setDisabledState?(isDisabled: boolean): void {
    this.formGroup[isDisabled ? 'disable' : 'enable']();
  }
}
