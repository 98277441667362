import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FacilityInformationGroup } from '@enerkey/clients/energy-reporting';

import {
  FacilityColumnsPropertiesWithFields,
  FacilityPropertiesService
} from '../../../energy-reporting/services/facility-properties.service';

@Component({
  selector: 'facility-group-info',
  templateUrl: './facility-group-info.component.html',
  styleUrls: ['./facility-group-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityGroupInfoComponent implements OnInit {
  @Input() public facility: FacilityInformationGroup;
  @Input() public groupName: string;

  public group$: Observable<FacilityColumnsPropertiesWithFields>;

  public constructor(private readonly facilityPropertiesService: FacilityPropertiesService) {
  }

  public ngOnInit(): void {
    this.group$ = this.facilityPropertiesService.facilityProperties$.pipe(
      map(groups => groups.find(group => group.Property === this.groupName))
    );
  }
}
