import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable, of, tap } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';

import { ColorService } from '../../../shared/services/color.service';
import { ReportingMeterSelection } from '../shared/reporting-meter-selection';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ForecastReportBaseService } from './forecast-report-base.service';
import { ReportingData } from './reporting-data-service-base';
import { ReportingMeterDataService } from './reporting-meter-data.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ReportType } from '../shared/report-type';

@Injectable({
  providedIn: 'root'
})
export class ForecastMeterReportService extends ForecastReportBaseService {
  public constructor(
    private readonly reportingMeterDataService: ReportingMeterDataService,
    translateService: TranslateService,
    colorService: ColorService,
    private readonly toaster: ToasterService
  ) {
    super(translateService, colorService);
  }

  public getForecastData(
    params: ReportingSearchParams,
    threshold: number,
    facilityId: number,
    meters: ReportingMeterSelection,
    averageQuantities?: Quantities[]
  ): Observable<ReportingSeriesByFacility> {
    if (this.hasUnsupportedParams(params)) {
      this.toaster.info('REPORTING.ERRORS.UNSUPPORTED_FORECAST_TIMEPARAMS');
      return of(null);
    }
    return forkJoin([
      params.showConsumption
        ? forkJoin({
          measured: this.reportingMeterDataService.getMeasuredValues(
            ReportType.Forecast,
            params,
            facilityId,
            meters,
            true,
            this.consumptionSerieSettings
          ),
          normalized: this.reportingMeterDataService.getNormalizedValues(
            ReportType.Forecast,
            params,
            facilityId,
            meters,
            true,
            this.consumptionSerieSettings
          ),
          measuredTargets: this.reportingMeterDataService.getMeasuredTargetsForMeter(params, facilityId, meters),
          normalizedTargets: this.reportingMeterDataService.getNormalizedTargetsForMeter(params, facilityId, meters),
        }).pipe(
          tap(series => {
            [series.measured, series.normalized, series.measuredTargets, series.normalizedTargets].forEach(serie => {
              this.addForecastSeries(serie, meters.meterIds, threshold, params.unit, false);
            });
          })
        )
        : forkJoin({
          measured: of<ReportingData[]>([]),
          normalized: of<ReportingData[]>([]),
          measuredTargets: of<ReportingData[]>([]),
          normalizedTargets: of<ReportingData[]>([]),
        }),
    ]).pipe(
      map(([consumptions]) => this.mapValuesByIds({
        values: consumptions.measured,
        normalizedValues: consumptions.normalized,
        ids: meters.meterIds,
        measuredTargets: consumptions.measuredTargets,
        normalizedTargets: consumptions.normalizedTargets,
        averageQuantities
      }))
    );
  }
}
