import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';

import { TopbarState } from '../topbar/topbar.functions';

@Component({
  selector: 'topbar-tab',
  templateUrl: './topbar-tab.component.html',
  styleUrls: ['./topbar-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarTabComponent {
  @HostBinding('class.overflowTab') @Input() public isOverflowTab: boolean = false;
  @Input() public state: TopbarState;
  @Input() public isMobile: boolean = false;

  public constructor(public readonly el: ElementRef) {
  }
}
