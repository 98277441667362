import { TranslateService } from '@ngx-translate/core';

import { Costs } from '@enerkey/clients/reporting';

import { Quantities } from '@enerkey/clients/metering';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { SerieChartOptions, TitleParams } from './reporting-data-service-base';
import { ConsumptionLike, ReportingSeries } from '../shared/reporting-series';
import { ColorService } from '../../../shared/services/color.service';
import {
  CostPlottingPoints,
  CostTypes,
  meterBasedCostsIdMatch,
  meterBasedCostsNameMatch,
  nationalCostsIdMatch,
  nationalCostsNameMatch,
  ResultType
} from '../constants/costs-report-constants';
import { RelationalValueId } from '../../reportingobjects/constants/facilities-properties';

export type PerFacilitySeriesRecord = Record<number, ResultType[]>;

const avgCostIds = [RelationalValueId.MeterBasedAverageCost,
  RelationalValueId.MeterBasedRetailerAverageCost,
  RelationalValueId.MeterBasedDistributionAverageCost];

export function createCostSeriesData(
  costs: Costs[],
  selectedCosts: CostTypes,
  currencySymbol: string,
  quantityId: number,
  hasSingleCurrency: boolean,
  averageCostsUnit: string
): ResultType {
  return selectedCosts.reduce((acc: ResultType, select) => {
    const costsSeriesForSelect = costs.map((curr: Costs) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = (curr as any)[select.name] || null;
      return {
        value,
        timestamp: new Date(curr.timestamp)
      } as CostPlottingPoints;
    });

    const hasValue = costsSeriesForSelect.some(c => c.value !== null);
    acc[select.id] = {
      costs: costsSeriesForSelect,
      currency: avgCostIds.includes(select.id) ? averageCostsUnit : currencySymbol,
      quantityId: quantityId,
      hasValue,
      hasSingleCurrency
    };
    return acc;
  }, {});
}

export function createReportingSeries(
  data: PerFacilitySeriesRecord,
  fId: number,
  params: ReportingSearchParams,
  quantityId: Quantities,
  translateService: TranslateService,
  colorService: ColorService,
  isCumulative: boolean,
  getPeriodName: (params: ReportingSearchParams, titleParams: TitleParams, index: number) => string,
  chartOptions?: SerieChartOptions,
  titleParams?: TitleParams,
  isNationalCosts?: boolean
): ReportingSeries[] {
  return data[fId].map((item, index, arr) => {
    const costIds = Object.keys(item).filter(costId => item[costId].hasValue);
    return costIds.map(costId => {
      const name = findCostIdName(+costId, isNationalCosts);
      const costName = translateCostName(name, isNationalCosts, translateService);
      const values = item[costId].costs;
      const currency = item[costId].currency;
      const graphColors = colorService.colorsForRelationalValue(+costId, params.periods.length);
      const serieTitle = getPeriodName(params, titleParams, index);
      const serieSettings = chartOptions?.serieSettings?.[index];
      const comparisonConsumption = arr[arr.length - 1][costId].costs;
      const setUnit = setUnitAsPerCostType(currency, item[costId].hasSingleCurrency, +costId);
      return new ReportingSeries({
        chartOptions: {
          serieType: serieSettings?.serieType,
          lineOpacity: serieSettings?.lineOpacity
        },
        gridOptions: {
          gridTitle: `${costName} ${serieTitle} [${setUnit}]`,
          comparisonColor: colorService.firstComparisonPeriodColor
        },
        options: {
          quantityId: quantityId,
          color: graphColors[index],
          isComparisonPeriod: index === 0 && arr.length > 1,
          isInspectionPeriod: index === arr.length - 1,
          serieTitle: `${costName} ${serieTitle} [${setUnit}]`,
          unit: currency,
          isNormalized: false,
          serieType: `derived${+costId}`,
        },
        chartItemOptions: {
          derivedId: +costId,
          positionInTooltip: chartOptions?.positionInTooltip,
          title: costName,
        },
        consumptions: values as ConsumptionLike[],
        comparisonConsumptions: comparisonConsumption as ConsumptionLike[],
        isCumulative: isCumulative,
        comparisonPeriodAsBase: true
      }, {
        resolution: params.resolution,
        searchPeriods: params.searchPeriods,
        durationLength: params.formValue.durationLength
      });
    });
  }).flat();
}

export function findCostIdName(costId: number, isNationalCosts: boolean): string {
  const matchArray = isNationalCosts ? nationalCostsIdMatch : meterBasedCostsIdMatch;
  const nameMatch = matchArray.find(costMap => costMap.id === costId);
  return nameMatch?.name;
}

export function translateCostName(name: string, isNationalCosts: boolean, translateService: TranslateService): string {
  const matchArray = isNationalCosts ? nationalCostsNameMatch : meterBasedCostsNameMatch;
  const costMatch = matchArray.find(cost => cost.name === name);
  return translateService.instant(costMatch?.translationKey);
}

function setUnitAsPerCostType(currency: string, hasSingleCurrency: boolean, selectedCosts: number): string {
  const hasAvgCosts = avgCostIds.includes(selectedCosts);
  if (hasAvgCosts && !hasSingleCurrency) {
    return `¤/${currency.split('/')[1]}`;
  }
  if (!hasAvgCosts && !hasSingleCurrency) {
    return '¤';
  }
  return currency;
}

