import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { ProductService } from '../services/product.service';

@Pipe({ name: 'productName' })
export class ProductNamePipe implements PipeTransform {

  public constructor(
    private readonly productService: ProductService
  ) { }

  public transform(productId: string): Observable<string> {
    return this.productService.getProductName(productId);
  }

}
