import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ActiveToast,
  GlobalConfig,
  IndividualConfig,
  ToastrService,
} from 'ngx-toastr';

/** Global overrides for toasts, see `AppModule`. */
const TOASTR_DEFAULT_CONFIG: Readonly<Partial<GlobalConfig>> = {};

/** Possible toast types. */
type ToastType = 'error' | 'info' | 'success' | 'warning';

type Toast = ActiveToast<unknown>;

type TranslationContext = {
  /** Translation key to interpolate values to. */
  key: string;

  /** Interpolation parameters, ie: `{ count: arr.length }` */
  params: Record<string, string | number>;
};

type ToastConfig = {
  /** Toast type, affects only toast color and icon. */
  type: ToastType;

  /**
   * Optional toast body, either string or interpolation parameters for TranslateService.
   * Interpolation parameters are always translated.
   */
  message: string | TranslationContext;

  /**
   * Toast title, either string or interpolation parameters for TranslateService.
   * Interpolation parameters are always translated.
   */
  title?: string | TranslationContext;

  /**
   * Whether to run title and message through TranslateService. Defaults to `false`.
   * Applicable only for string `title` and `message.`
   */
  translate?: boolean;

  /** Timeout in milliseconds. */
  timeout?: number;

  /** Whether the toast should hide after being clicked. */
  tapToDismiss?: boolean;

  /** **Dangerous:** Whether raw HTML is allowed. */
  allowHtml?: boolean;

  /** Whether to use a 420px wide toast instead of the default 300px. */
  wider?: boolean;

  /** If true, show toast until user clicks is */
  disableTimeOut?: boolean;
};

type GeneralErrorType = 'LOAD' | 'DELETE' | 'UPDATE' | 'DOWNLOAD' | 'SAVE';
type GeneralErrorContext =
  'WIDGETS' |
  'BOOKMARKS' |
  'BOOKMARK' |
  'READ_NEWS' |
  'REPORT' |
  'PROFILES' |
  'FILTERS' |
  'DASHBOARDS' |
  'METERS' |
  'ADDRESS' |
  'PROPERTIES' |
  'FACILITY' |
  'ACTION' |
  'ACTIONS' |
  'COMMENT'|
  'LINKS' |
  'WEBHOOK' |
  'POA' |
  'POAS' |
  'CUSTOMER_FIELDS' |
  'TARGET';

@Injectable({ providedIn: 'root' })
class ToasterService {

  public constructor(
    private readonly _ngxtoastr: ToastrService,
    private readonly _translate: TranslateService
  ) { }

  /** Shows a success toast with translated content. */
  public success(message: string | TranslationContext, title?: string | TranslationContext): Toast {
    return this.toastInternal({ type: 'success', title, message, translate: true });
  }

  /** Shows an error toast with translated content. */
  public error(message: string | TranslationContext, title?: string | TranslationContext): Toast {
    return this.toastInternal({ type: 'error', title, message, translate: true });
  }

  /** Shows a warning toast with translated content. */
  public warning(message: string | TranslationContext, title?: string | TranslationContext): Toast {
    return this.toastInternal({ type: 'warning', title, message, translate: true });
  }

  /** Shows an info toast with translated content. */
  public info(message: string | TranslationContext, title?: string | TranslationContext): Toast {
    return this.toastInternal({ type: 'info', title, message, translate: true });
  }

  public generalError(
    errorType: GeneralErrorType,
    context: GeneralErrorContext,
    message?: string | TranslationContext
  ): Toast {
    const contextTranslated = this._translate.instant(`ERROR_CONTEXT.${context}`);
    let title: string | TranslationContext = {
      key: `GENERAL_ERROR.${errorType}`,
      params: { context: contextTranslated }
    };
    if (message === undefined) {
      [message, title] = [title, message];
    }
    return this.error(
      message,
      title
    );
  }

  /** Shows a toast with custom configuration. */
  public toast(config: ToastConfig): Toast {
    const [message, title, cfg] = this.getArgs(config);
    return this._ngxtoastr.show(
      message,
      title,
      cfg,
      this._ngxtoastr.toastrConfig.iconClasses[config.type] ?? ''
    );
  }

  /** Clears the specified toasts, clearing all if no arguments are provided. */
  public clear(...toasts: Toast[]): void {
    if (toasts.length) {
      for (const toast of toasts) {
        this._ngxtoastr.clear(toast.toastId);
      }
    } else {
      this._ngxtoastr.clear();
    }
  }

  private toastInternal(config: ToastConfig): Toast {
    return this._ngxtoastr[config.type](...this.getArgs(config));
  }

  private getArgs(config: ToastConfig): [string, string, Partial<IndividualConfig>] {
    return [
      this.getTextContent(config.message, config.translate),
      this.getTextContent(config.title, config.translate),
      this.getConfigOverride(config),
    ];
  }

  /** Returns the text visible in toast title or body. */
  private getTextContent(content: string | TranslationContext, translate: boolean | undefined): string {
    if (!content) {
      return undefined;
    } else if (typeof content === 'object') {
      return this._translate.instant(content.key, content.params);
    } else if (translate) {
      return this._translate.instant(content);
    } else {
      return content;
    }
  }

  /** Returns "per toast"-configuration passed on to `ngx-toastr`. */
  private getConfigOverride(override: ToastConfig): Partial<IndividualConfig> {
    const cfg: Partial<IndividualConfig> = {};

    if (override.tapToDismiss !== undefined) {
      cfg.tapToDismiss = override.tapToDismiss;
    }
    if (override.allowHtml !== undefined) {
      cfg.enableHtml = override.allowHtml;
    }
    if (override.timeout !== undefined) {
      cfg.timeOut = override.timeout;
    }
    if (override.disableTimeOut !== undefined) {
      cfg.disableTimeOut = override.disableTimeOut;
    }
    if (override.wider === true) {
      cfg.toastClass = `${this._ngxtoastr.toastrConfig.toastClass} toast-wide`;
    }

    return cfg;
  }
}

export {
  TOASTR_DEFAULT_CONFIG,
  ToastType,
  ToasterService,
};
