import { Directive, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged, map, shareReplay, switchMap, take, takeUntil
} from 'rxjs/operators';
import { AggregateResult } from '@progress/kendo-data-query';

import { LoadingSubject } from '@enerkey/rxjs';
import { RequestResolution } from '@enerkey/clients/reporting';

import { ExtendedFacilityInformation } from '../../../shared/interfaces/extended-facility-information';
import { FacilityService } from '../../../shared/services/facility.service';
import { ReportingSearchService } from '../services/reporting-search.service';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportEvents, ReportEventService } from '../services/report-events.service';
import { ReportingSeriesCollection } from '../shared/reporting-series-collection';
import { ReportingGridColumnGroup, ReportingGridData } from '../services/reporting-grid.service';
import { ReportSeriesDataPoint } from '../shared/reporting-series';
import { adjustByTimezoneOffset } from '../../../shared/date.functions';
import { DurationName, ReportingSearchFormValue } from '../shared/reporting-search-form-value';
import { SelectableResolution } from './reporting-search-form/reporting-search-form.component';
import { ReportType } from '../shared/report-type';
import { reportTypeOptions } from '../services/report-type-options.service';
import { getDefaultReportingParams } from '../services/reporting.search.functions';

export interface ReportingGridConfig {
  data: ReportingGridData[];
  columns: ReportingGridColumnGroup[];
  aggregates: AggregateResult;
}

interface ZoomedDurationAndResolution {
  durationName: DurationName,
  durationLength: number,
  resolution: SelectableResolution
}

type ZoomableResolution = Exclude<SelectableResolution, RequestResolution.PT1H | RequestResolution.PT15M>;
const zoomedDurationsAndResolutions: Record<ZoomableResolution, ZoomedDurationAndResolution> = {
  [RequestResolution.P1Y]: {
    durationName: 'years',
    durationLength: 1,
    resolution: RequestResolution.P1M
  },
  [RequestResolution.P3M]: {
    durationName: 'months',
    durationLength: 3,
    resolution: RequestResolution.P1D
  },
  [RequestResolution.P1M]: {
    durationName: 'months',
    durationLength: 1,
    resolution: RequestResolution.PT1H
  },
  [RequestResolution.P7D]: {
    durationName: 'days',
    durationLength: 7,
    resolution: RequestResolution.PT1H
  },
  [RequestResolution.P1D]: {
    durationName: 'days',
    durationLength: 1,
    resolution: RequestResolution.PT1H
  },
};

@Directive()
export abstract class ReportBase implements OnDestroy {
  protected abstract readonly reportType: ReportType;
  protected abstract transformParams(params: ReportingSearchParams): ReportingSearchParams;

  public readonly facilities$: Observable<ExtendedFacilityInformation[]>;
  public readonly searchParams$: Observable<ReportingSearchParams>;
  public readonly loading$: Observable<boolean>;
  public readonly notes$: Observable<ReportEvents>;

  public readonly chartsVisible$: Observable<boolean>;
  public readonly gridsVisible$: Observable<boolean>;

  public readonly pageOptions$: Observable<number>;
  public readonly skipStart$: Observable<number>;
  public readonly skipEnd$: Observable<number>;
  public readonly modalReportType: ReportType = ReportType.Period;

  protected readonly allowZoom: boolean = false;
  protected readonly facilityIds$: Observable<number[]>;
  protected readonly _loading$ = new LoadingSubject();
  protected readonly _destroy$ = new Subject<void>();
  protected readonly pageSize = 10;
  private readonly _pageNumber$ = new BehaviorSubject<number>(0);
  private readonly _refreshNotes$ = new BehaviorSubject<boolean>(true);

  public constructor(
    facilityService: FacilityService,
    private readonly reportingSearchService: ReportingSearchService,
    reportEventService: ReportEventService
  ) {
    this.chartsVisible$ = reportingSearchService.chartVisibility$;
    this.gridsVisible$ = reportingSearchService.gridVisibility$;
    this.loading$ = this._loading$.asObservable();
    this.searchParams$ = reportingSearchService.searchParameters$.pipe(
      takeUntil(this._destroy$),
      map(params => this.excludeDisabledParams(params)),
      map(params => this.transformParams(params))
    );

    this.facilities$ = combineLatest([
      reportingSearchService.facilityIds$,
      facilityService.filteredProfileFacilities$
    ]).pipe(
      map(([selectedFacilities, filteredFacilities]) => filteredFacilities
        .filter(
          f => selectedFacilities.includes(f.FacilityId)
        ).sortBy(f => f.Name)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.pageOptions$ = this.facilities$.pipe(
      map(facilities => Math.ceil(facilities.length / this.pageSize))
    );

    this.skipStart$ = this._pageNumber$.pipe(
      map(pageNumber => pageNumber * this.pageSize)
    );
    this.skipEnd$ = this._pageNumber$.pipe(
      map(pageNumber => (pageNumber + 1) * this.pageSize)
    );

    this.facilityIds$ = this.facilities$.pipe(
      map(facilities => facilities.map(f => f.FacilityId))
    );

    this.notes$ = combineLatest([
      this.searchParams$.pipe(
        distinctUntilChanged((oldParams, newParams) => oldParams.isTimeSettingsSame(newParams) &&
          oldParams.isQuantitySettingSame(newParams))
      ),
      this.facilityIds$,
      this._refreshNotes$.asObservable()
    ]).pipe(
      switchMap(([params, facilityIds]) => reportEventService.getEvents(facilityIds, params)),
      shareReplay(1)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._pageNumber$.complete();
    this._refreshNotes$.complete();
  }

  public pageChange(pageNumber: number): void {
    this._pageNumber$.next(pageNumber);
  }

  public trackByMethod(_index: number, quantity: ReportingSeriesCollection): number {
    return quantity.quantityId;
  }

  public seriesClick(dataItem: ReportSeriesDataPoint): void {
    if (this.allowZoom) {
      this.zoomToPeriod(dataItem);
    }
  }

  public onActionUpdate(): void {
    this._refreshNotes$.next(true);
  }

  protected zoomToPeriod(dataItem: ReportSeriesDataPoint): void {
    this.searchParams$.pipe(take(1)).subscribe({
      next: params => {
        if (params.resolution !== RequestResolution.PT1H && params.resolution !== RequestResolution.PT15M) {
          this.reportingSearchService.search({
            ...params.formValue,
            ...zoomedDurationsAndResolutions[params.resolution],
            periods: [adjustByTimezoneOffset(dataItem.timestamp)],
          });
        }
      }
    });
  }

  private excludeDisabledParams(searchParams: ReportingSearchParams): ReportingSearchParams {
    const defaultParams = getDefaultReportingParams();
    const originalFormValue = searchParams.formValue;
    const disabledParams = reportTypeOptions[this.reportType].disabledParams ?? [];

    const remainingParams: Partial<ReportingSearchFormValue> = Object.entries(originalFormValue)
      .filter(([key]) => !disabledParams.includes(key as keyof ReportingSearchFormValue))
      .toRecord(
        ([key]) => key,
        ([, value]) => value
      );

    return new ReportingSearchParams({
      periods: originalFormValue.periods,
      ...defaultParams,
      ...remainingParams,
    });
  }
}
