<div class="header header--modal">
  <h2>{{ headingText }}</h2>

  <div class="header--modal__buttons-container">
    <button type="button"
      id="download"
      class="topbar-icon-item"
      *ngIf="modalViewHeaderService"
      [disabled]="!modalViewHeaderService.isDownloadEnabled"
      (click)="modalViewHeaderService.onDownload()"
    >
      <i class="far fa-arrow-alt-circle-down"></i>
    </button>
    <button
      *ngIf="bookmarkEnabled"
      type="button"
      class="topbar-icon-item"
      (click)="openBookmarkModal()"
      [title]="'BOOKMARK.BOOKMARKS' | translate"
    >
      <i class="fa fa-bookmark"></i>
    </button>
    <div class="close-button-container">
      <button type="button"
        *ngIf="activeModal"
        (click)="dismiss()"
        ngfNoAutoFocus
      >
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</div>
