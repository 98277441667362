<ng-container *ngIf="facilityProperties$ | async as groups">
  <div
    class="card"
    *ngFor="let group of groups"
  >
    <div class="header-group bg-enerkey-facilityinformation">
      <div class="header-icon header-icon--bg-round header-icon--bg-white">
        <i class="fa fa-home"></i>
      </div>
      <h3 class="header-group__label h-secondary">{{ group.Name }}</h3>
    </div>
    <div class="facility-property-grid">
      <ng-container
        *ngFor="let property of group.Items"
        class="facility-property-grid-item"
      >
        <div *ngIf="facility | propertyPath:group.Property:property.Property as value">
          <div>
            <span class="bold">{{ property.Name }}</span>
          </div>
          <ng-container [ngSwitch]="property.Type">
            <span *ngSwitchCase="'boolean'">
              <!-- Do not show boolean mark for tags as tags with value false are not present -->
              <boolean-mark
                *ngIf="group.Property !== 'Tags'"
                [value]="value"
              ></boolean-mark>
            </span>
            <span *ngSwitchCase="'date'">
              {{ value | todate | date:'short' }}
            </span>
            <span *ngSwitchDefault>
              {{ value }}
            </span>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
