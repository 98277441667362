import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Quantities } from '@enerkey/clients/metering';

import { RequestResolution } from '@enerkey/clients/reporting';

import { ReportingSeries } from '../shared/reporting-series';
import { ReportingSeriesByFacility, ReportingSeriesCollection } from '../shared/reporting-series-collection';
import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { ReportingData } from './reporting-data-service-base';
import { SelectableResolution } from '../components/reporting-search-form/reporting-search-form.component';

import { PeriodLabelService } from './period-label.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingSeriesService {

  public constructor(
    private readonly translateService: TranslateService,
    private readonly periodLabelService: PeriodLabelService
  ) {
  }

  public mapValuesById({
    ids,
    measured,
    normalized,
    nationalCosts,
    meterBasedCosts,
    emissions,
    measuredTargets,
    normalizedTargets,
    temperatures,
    comparability,
    incompleteThreshold,
    facilityAmountsPerQuantity,
    periods,
    resolution,
    searchPeriods,
    averageQuantities
  }: {
    ids: number[],
    measured: ReportingData[],
    normalized: ReportingData[],
    measuredTargets?: ReportingData[],
    normalizedTargets?: ReportingData[],
    temperatures?: ReportingData[],
    nationalCosts?: ReportingData[],
    meterBasedCosts?: ReportingData[],
    emissions?: ReportingData[],
    comparability?: Comparability,
    incompleteThreshold?: number,
    periods?: Date[],
    resolution?: SelectableResolution,
    searchPeriods?: { readonly start: Date; readonly end: Date; }[],
    averageQuantities?: Quantities[]
    /** Amount of total and applicable facilities, used only in sum reports */
    facilityAmountsPerQuantity?: Partial<Record<Quantities, { total: number; applicable: number }>>,
  }): ReportingSeriesByFacility {
    return ids.toRecord(
      id => id,
      id => this.getSeriesValues({
        id,
        incompleteThreshold,
        comparability,
        values: [...measured, ...normalized],
        measuredTargets: measuredTargets ?? [],
        normalizedTargets: normalizedTargets ?? [],
        temperatures: temperatures ?? [],
        nationalCosts: nationalCosts ?? [],
        meterBasedCosts: meterBasedCosts ?? [],
        emissions: emissions ?? [],
        facilityAmountsPerQuantity: facilityAmountsPerQuantity,
        periods,
        resolution,
        searchPeriods,
        averageQuantities
      }).sortBy('quantityId')
    );
  }

  private getSeriesValues({
    id,
    incompleteThreshold,
    values,
    measuredTargets,
    normalizedTargets,
    temperatures,
    nationalCosts,
    meterBasedCosts,
    emissions,
    comparability,
    facilityAmountsPerQuantity,
    periods,
    resolution,
    searchPeriods,
    averageQuantities
  }: {
    id: number
    incompleteThreshold: number,
    values: ReportingData[],
    measuredTargets: ReportingData[],
    normalizedTargets: ReportingData[],
    temperatures: ReportingData[],
    nationalCosts: ReportingData[],
    meterBasedCosts: ReportingData[],
    emissions: ReportingData[],
    comparability: Comparability,
    facilityAmountsPerQuantity: Partial<Record<Quantities, { total: number; applicable: number }>>,
    periods?: Date[],
    searchPeriods?: { readonly start: Date; readonly end: Date; }[],
    resolution?: SelectableResolution,
    averageQuantities?: Quantities[]
  }): ReportingSeriesCollection[] {
    const allSeries: { quantityId: Quantities, series: ReportingSeries[] }[] = [];
    for (const [quantityId, series] of values.toGroupsBy(v => v.quantityId).entries()) {
      const facilitySeries = series.flatMap(s => s.series[id]).filter(s => s);
      const facilityMeasuredTargets = measuredTargets.find(t => t.quantityId === quantityId)?.series?.[id] ?? [];
      const facilityNormalizedTargets = normalizedTargets.find(t => t.quantityId === quantityId)?.series?.[id] ?? [];
      const facilityTemperatures = temperatures.flatMap(s => s.series[id] ?? []);
      const facilityCosts = nationalCosts.find(t => t.quantityId === quantityId)?.series?.[id] ?? [];
      const facilityMeterBasedCosts = meterBasedCosts.find(t => t.quantityId === quantityId)?.series?.[id] ?? [];
      const facilityEmissions = emissions.filterMap(
        t => t.quantityId === quantityId,
        t => t.series?.[id] ?? []
      ).flat();

      const measuredInspectionPeriod = facilitySeries.find(s =>
        s.options.isInspectionPeriod && !s.options.isNormalized);
      const normalizedInspectionPeriod = facilitySeries.find(s =>
        s.options.isInspectionPeriod && s.options.isNormalized);

      if (measuredInspectionPeriod) {
        const inspectionPeriodValues = measuredInspectionPeriod.values;
        for (const serie of facilityMeasuredTargets) {
          serie.calculateChanges(inspectionPeriodValues, true);
        }
      }

      if (normalizedInspectionPeriod) {
        const inspectionPeriodValues = normalizedInspectionPeriod.values;
        for (const serie of facilityNormalizedTargets) {
          serie.calculateChanges(inspectionPeriodValues, true);
        }
      }

      const minMaxSeries = this.getMinMaxSeries(facilitySeries);
      if (Array.hasItems(facilitySeries)) {
        allSeries.push(new ReportingSeriesCollection({
          quantityId,
          series: [
            ...facilitySeries.sortBy(s => s.options.isComparisonPeriod ? -1 : 1),
            ...minMaxSeries,
            ...facilityMeasuredTargets,
            ...facilityNormalizedTargets,
            ...facilityTemperatures,
            ...facilityCosts,
            ...facilityMeterBasedCosts,
            ...facilityEmissions
          ],
          facilityAmounts: facilityAmountsPerQuantity?.[quantityId],
          averageQuantities
        }));
      }
    }

    let seriesWithComparableData = this.getSeriesWithComparableValues(
      allSeries,
      comparability,
      incompleteThreshold,
      resolution,
      searchPeriods
    );

    if (Array.isArray(periods) && periods.every(period => period instanceof Date)) {
      seriesWithComparableData = seriesWithComparableData.map(s => ({
        ...s,
        series: this.sortByComsumptionType(s.series, periods)
      }));
    }

    return seriesWithComparableData.map(s => new ReportingSeriesCollection({
      quantityId: s.quantityId,
      series: s.series,
      facilityAmounts: facilityAmountsPerQuantity?.[s.quantityId],
      averageQuantities
    }));
  }

  private getMinMaxSeries(series: ReportingSeries[]): ReportingSeries[] {
    if (series.some(s => s.options.isComparisonPeriod)) {
      return [];
    }
    const inspectionSeries = series.filter(s => s.isMinMaxApplicableSeries);

    if (!Array.hasItems(inspectionSeries)) {
      return [];
    }

    const minValueCandidates = inspectionSeries.map(s => s.minValue);
    const minValue = Math.min(...minValueCandidates);
    const minValueSerieIndex = inspectionSeries.findIndex(
      s => s.minValue === minValue
    );
    const minValueSerie = inspectionSeries[minValueSerieIndex];

    const maxValueCandidates = inspectionSeries.map(s => s.maxValue);
    const maxValue = Math.max(...maxValueCandidates);
    const maxValueSerieIndex = inspectionSeries.findIndex(
      s => s.maxValue === maxValue
    );
    const maxValueSerie = inspectionSeries[maxValueSerieIndex];

    return [
      new ReportingSeries({
        chartOptions: { serieType: 'line', showMarkers: true },
        gridOptions: { hideInGrid: true },
        options: {
          quantityId: maxValueSerie.options.quantityId,
          color: 'red',
          unit: maxValueSerie.options.unit,
          serieTitle: this.translateService.instant('FACILITIES_REPORT.MAX'),
          serieType: 'max',
        },
        consumptions: maxValueSerie.consumptions.map((v, index) => ({
          value: index === maxValueSerie.maxValueIndex ? v.value : null,
          timestamp: v.timestamp
        }))
      }),
      new ReportingSeries({
        chartOptions: { serieType: 'line', showMarkers: true },
        gridOptions: { hideInGrid: true },
        options: {
          quantityId: minValueSerie.options.quantityId,
          color: 'green',
          unit: minValueSerie.options.unit,
          serieTitle: this.translateService.instant('FACILITIES_REPORT.MIN'),
          serieType: 'min',
        },
        consumptions: minValueSerie.consumptions.map((v, index) => ({
          value: index === minValueSerie.minValueIndex ? v.value : null,
          timestamp: v.timestamp
        }))
      })
    ];
  }

  private getSeriesWithComparableValues(
    series: { quantityId: Quantities, series: ReportingSeries[] }[],
    comparabilityOption: Comparability = Comparability.All,
    threshold: number,
    resolution: SelectableResolution,
    searchPeriods: { readonly start: Date; readonly end: Date; }[]
  ): { quantityId: Quantities, series: ReportingSeries[] }[] {
    switch (comparabilityOption) {
      case Comparability.All:
        return series;
      case Comparability.ByQuantity: {
        return series.map(s => {
          const incompleteIndexes = this.getIncompleteValueIndexes(s.series, threshold);
          return {
            quantityId: s.quantityId,
            series: s.series.map(serie =>
              serie.fromValues({
                consumptions: serie.values.map((v, index) => incompleteIndexes.has(index) ||
                  this.isComparableData(s.series, resolution, index, searchPeriods)
                  ? { ...v, value: null }
                  : v)
              }))
          };
        });
      }
      case Comparability.ByQuantities: {
        const allSeries = series.flatMap(s => s.series);
        const incompleteIndexes = this.getIncompleteValueIndexes(allSeries, threshold);

        return series.map(s => ({
          quantityId: s.quantityId,
          series: s.series.map(serie => serie.fromValues({
            consumptions: serie.values.map((v, index) => incompleteIndexes.has(index) ||
              this.isComparableData(allSeries, resolution, index, searchPeriods)
              ? { ...v, value: null }
              : v)
          }))
        }));
      }
    }
  }

  private getIncompleteValueIndexes(series: ReportingSeries[], threshold: number): Set<number> {
    const incompleteIndexes = new Set<number>();
    series.forEach(serie => {
      for (const [index, value] of serie.values.entries()) {
        if (value.incomplete > threshold) {
          incompleteIndexes.add(index);
        }
      }
    });

    return incompleteIndexes;
  }

  /**
   *if any value in comparison period is null, then all series in that period should be null
   *if any value in inspection period is null, then all series in that period should be null
   */
  private isComparableData(
    series: ReportingSeries[],
    resolution: SelectableResolution,
    index: number,
    searchPeriods: { readonly start: Date; readonly end: Date; }[]
  ): boolean {
    /** resolution set to P1M since base table report resolution is null and to get period name resolution is required*/
    const effectiveResolution = resolution ?? RequestResolution.P1M;
    const targetPeriodName = this.periodLabelService.getChartCategoryLabel({
      timestamps: series.map(s => s.values[index]?.timestamp).filter(t => t),
      resolution: effectiveResolution,
      index,
      amountOfPeriods: searchPeriods.length,
      useShortFormat: resolution !== RequestResolution.PT15M
    });

    const hasNullValue = (serie: ReportingSeries): boolean =>
      serie.values.some((val, i) => {
        const currentPeriodName = this.periodLabelService.getChartCategoryLabel({
          timestamps: series.map(s => s.values[i]?.timestamp).filter(t => t),
          resolution: effectiveResolution,
          index: i,
          amountOfPeriods: searchPeriods.length,
          useShortFormat: resolution !== RequestResolution.PT15M
        });
        return currentPeriodName === targetPeriodName && val.value === null;
      });

    const inspectionSeries = series.filter(s => s.options.serieType === 'consumption' && s.options.isInspectionPeriod);
    const comparisonSeries = series.filter(s => s.options.serieType === 'consumption' && s.options.isComparisonPeriod);
    return (
      inspectionSeries.some(hasNullValue) ||
      comparisonSeries.some(hasNullValue)
    );
  }

  private sortByComsumptionType(series: ReportingSeries[], selectedPeriods: Date[]): ReportingSeries[] {
    const years = selectedPeriods.map(date => date.getFullYear()).sort((a, b) => a - b);
    return series.sortBy(s => {
      const year = new Date(s.values[0]?.timestamp).getFullYear();
      if (isNaN(year)) {
        const closestYearIndex = years.findIndex(y => y > years[0]) + 1;
        return closestYearIndex >= 0 ? years[closestYearIndex] : years[years.length - 1];
      }
      return years.indexOf(year);
    });
  }

}
