import { Pipe, PipeTransform } from '@angular/core';

import { ReportingSeries } from '../../../modules/reporting/shared/reporting-series';

interface ReportingChartOptions {
  name: string;
  color: string;
  isConsumptionAxis: boolean;
  min?: number;
  max?: number;
  format?: string;
  reverse?: boolean;
}

@Pipe({
  name: 'chartValueAxisOptions'
})
export class ChartValueAxisOptionsPipe implements PipeTransform {
  public transform(series: ReportingSeries[]): ReportingChartOptions[] {
    return series?.filter(s => !s.chartOptions.hideInChart)
      .uniqueBy(s => s.chartAxisName)
      .map(s => ({
        name: s.chartAxisName,
        color: s.chartItemOptions?.derivedId ? s.options.color : null,
        isConsumptionAxis: s.options.serieType === 'consumption',
        min: s.options.isPercentSerie ? 0 : undefined,
        max: s.options.isPercentSerie ? 1 : undefined,
        format: s.options.isPercentSerie ? 'p0' : undefined,
        reverse: s.chartOptions?.reverseGraph ?? false
      })) ?? [];
  }
}
