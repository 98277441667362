<ng-container *ngIf="showBanner$ | async">
  <div class="banner">
    <button
      type="button"
      class="button button--link button--external-link"
      (click)="openNewReporting()"
    >
      {{ 'TRY_NEW_BANNER.TEXT' | translate }}
    </button>
    <button
      type="button"
      class="button button--link button--close"
      (click)="closeBanner()"
    >
      <i class="fa fa-times"></i>
    </button>
  </div>
</ng-container>
