import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ReportingDataService } from './reporting-data.service';
import { ReportingSeriesService } from './reporting-series.service';
import { FacilityAmountsPerQuantity } from './sum-report.functions';
import { ReportType } from '../shared/report-type';

export const sumKey = NaN;

@Injectable({
  providedIn: 'root'
})
export class SumReportService {
  public constructor(
    private readonly reportingDataService: ReportingDataService,
    private readonly reportingSeriesService: ReportingSeriesService
  ) {
  }

  public getData(
    params: ReportingSearchParams,
    facilityIds: number[],
    threshold: number
  ): Observable<ReportingSeriesByFacility> {
    const facilityAmountsPerQuantity = this.getFacilityAmountsByQuantity(params, facilityIds, threshold);
    return forkJoin({
      measured: forkJoin([
        params.measured
          ? this.reportingDataService.getSumReportData(params, facilityIds, threshold, false)
          : of([]),
        this.reportingDataService.getDistributions(ReportType.Sum, params, facilityIds, true),
        this.reportingDataService.getMeasuredDerivedValues(
          ReportType.Sum,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          },
          undefined,
          true
        ),
      ]),
      normalized: forkJoin([
        params.normalized
          ? this.reportingDataService.getSumReportData(params, facilityIds, threshold, true)
          : of([]),
        this.reportingDataService.getNormalizedDerivedValues(
          ReportType.Sum,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          },
          undefined,
          true
        ),
      ]),
      measuredTargets: this.reportingDataService.getMeasuredTargets(params, facilityIds, false),
      normalizedTargets: this.reportingDataService.getNormalizedTargets(params, facilityIds, false),
      temperatures: this.reportingDataService.getTemperature(params, facilityIds, true),
      nationalCosts: forkJoin([
        this.reportingDataService.getCostsSumReportData(
          params,
          facilityIds,
          true,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        )
      ]),
      meterBasedCosts: forkJoin([
        this.reportingDataService.getCostsSumReportData(
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        )
      ]),
      emission: forkJoin([
        this.reportingDataService.getMeasuredEmissionsValues(
          ReportType.Sum,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          },
          undefined,
          true
        ),
        this.reportingDataService.getNormalizedEmissionsValues(
          ReportType.Sum,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          },
          undefined,
          true
        )
      ]),
      facilitiesPerQuantity: facilityAmountsPerQuantity,
    }).pipe(
      map(({
        measured,
        normalized,
        measuredTargets,
        normalizedTargets,
        temperatures,
        nationalCosts,
        meterBasedCosts,
        emission,
        facilitiesPerQuantity,
      }) => this.reportingSeriesService.mapValuesById({
        ids: [sumKey],
        measured: measured.flat(),
        normalized: normalized.flat(),
        measuredTargets,
        normalizedTargets,
        temperatures: temperatures,
        nationalCosts: nationalCosts.flat(),
        meterBasedCosts: meterBasedCosts.flat(),
        emissions: emission.flat(),
        facilityAmountsPerQuantity: facilitiesPerQuantity,
        periods: params.periods
      }))
    );
  }

  private getFacilityAmountsByQuantity(
    params: ReportingSearchParams,
    facilityIds: number[],
    threshold: number
  ): Observable<FacilityAmountsPerQuantity> {
    const normalized = !params.measured;
    return this.reportingDataService.getSumConsumptionResponse(params, facilityIds, threshold, normalized).pipe(
      map(s => {
        const series = Object.values(s.consumptionsBySeries);
        const quantityFacilityAmounts: FacilityAmountsPerQuantity = {};
        for (const quantity of Object.keys(series[0])) {
          let included: number[] = [];
          let excluded: number[] = [];
          for (const data of series) {
            included = [...included, ...data[quantity].facilitiesIncluded].unique();
            excluded = [...excluded, ...data[quantity].facilitiesExcluded].unique();
          }
          const total = included.length + excluded.length;
          quantityFacilityAmounts[quantity as unknown as Quantities] = {
            total: total,
            applicable: included.length
          };
        }
        return quantityFacilityAmounts;
      })
    );
  }
}
