import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';

import { BehaviorSubject, EMPTY, Observable, Subject, switchMap, take } from 'rxjs';
import { catchError, delay, filter, skip, takeUntil, tap } from 'rxjs/operators';

import { MeterGroupDto } from '@enerkey/clients/metering';
import { ModalService, NgfModalRef } from '@enerkey/foundation-angular';

import { TemplateLifterService } from '../../../../../shared/services/template-lifter.service';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { MeterGroupNotFoundDeletionError } from '../../../models/meter-groups.model';
import { MeterGroupsService } from '../../../services/meter-groups/meter-groups.service';
import { MeterGroupsCreateModalComponent } from '../meter-groups-create-modal/meter-groups-create-modal.component';
import { MeterGroupsDeleteModalComponent } from '../meter-groups-delete-modal/meter-groups-delete-modal.component';
import { MeterGroupsGridComponent } from '../meter-groups-grid/meter-groups-grid.component';

@Component({
  selector: 'meter-groups',
  templateUrl: './meter-groups.component.html'
})
export class MeterGroupsComponent implements AfterViewInit, OnDestroy {
  public readonly selectedMeterGroup$: Observable<MeterGroupDto>;
  public readonly selectedFacility$: Observable<number>;
  public readonly editMode$: Observable<boolean>;

  @ViewChild('topbarTemplate') private readonly topRightTemplate: TemplateRef<unknown>;
  @ViewChild(MeterGroupsGridComponent) private readonly meterGroupsGrid: MeterGroupsGridComponent;

  private readonly _selectedMeterGroup$ = new BehaviorSubject<MeterGroupDto>(null);
  private readonly _selectedFacility$ = new BehaviorSubject<number>(null);
  private readonly _destroy$ = new Subject<void>();
  private readonly _editMode$ = new BehaviorSubject<boolean>(null);

  public constructor(
    private readonly templateLifter: TemplateLifterService,
    private readonly modalService: ModalService,
    private readonly meterGroupsService: MeterGroupsService,
    private readonly toasterService: ToasterService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.selectedMeterGroup$ = this._selectedMeterGroup$.asObservable();
    this.selectedFacility$ = this._selectedFacility$.asObservable();
    this.editMode$ = this._editMode$.asObservable();

    // Reload grid when edit mode changes
    this.editMode$.pipe(
      filter(editMode => editMode !== null),
      tap(editMode => this.meterGroupsGrid.reloadData(!editMode)),
      takeUntil(this._destroy$)
    ).subscribe();

    // Set edit mode to true when meter group is created, but after new meter group is selected
    this.meterGroupsService.meterGroupCreate$.pipe(
      switchMap(() => this.selectedMeterGroup$.pipe(skip(1), take(1))),
      delay(50),
      tap(() => this._editMode$.next(true)),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  public ngAfterViewInit(): void {
    this.templateLifter.template = this.topRightTemplate;
  }

  public ngOnDestroy(): void {
    this.templateLifter.template = null;
    this._destroy$.next();
    this._destroy$.complete();
    this._selectedMeterGroup$.complete();
    this._selectedFacility$.complete();
    this._editMode$.complete();
  }

  public onMeterGroupChange(meterGroup: MeterGroupDto): void {
    this._selectedMeterGroup$.next(meterGroup);
    this.changeDetectorRef.detectChanges();
  }

  public onFacilityChange(facilityId: number): void {
    this._selectedFacility$.next(facilityId);
    this.changeDetectorRef.detectChanges();
  }

  public onEditMeterGroupMetersClick(): void {
    this._editMode$.next(true);
  }

  public onCreateMeterGroupClick(): void {
    this.openEditModal();
  }

  public onSearchClick(): void {
    const shouldFilterByActiveMeters = !this._editMode$.value;
    this.meterGroupsGrid.reloadData(shouldFilterByActiveMeters);
  }

  public onEditMeterGroupClick(): void {
    const { id, name, description, quantityGroupId } = this._selectedMeterGroup$.value;
    const modalRef = this.openEditModal();

    modalRef.componentInstance.formGroup.patchValue({ id, name, description, quantityGroupId });
  }

  public onDeleteMeterGroupClick(): void {
    const selectedMeterGroup = this._selectedMeterGroup$.value;

    // Clear cache to ensure that we have the latest version of the meter group before deletion
    this.meterGroupsService.invalidateMeterGroupsCache();
    this.meterGroupsService.getMeterGroupsOnCurrentProfile(undefined, undefined, true).pipe(
      take(1),
      tap(meterGroups => {
        const meterGroup = meterGroups.find(mg => mg.id === selectedMeterGroup.id);
        if (!meterGroup) { throw new MeterGroupNotFoundDeletionError(); }

        const visibleMeterCount = meterGroup?.meters.length ?? 0;
        const modalRef = this.openDeleteModal();

        modalRef.componentInstance.visibleMeterCount = visibleMeterCount;
        modalRef.componentInstance.formGroup.patchValue({
          id: selectedMeterGroup.id,
          name: selectedMeterGroup.name,
          description: selectedMeterGroup.description,
          quantityGroupId: meterGroup.quantityGroupId,
          allMetersAreIncluded: meterGroup.allMetersAreIncluded,
          totalMeterCount: meterGroup.totalMeterCount
        });
      }),
      catchError(error => {
        const failureMessageKey = error instanceof MeterGroupNotFoundDeletionError ?
          'ADMIN.METERGROUPS.METER_GROUP_DELETE_FAILURE_METERGROUP_NOT_FOUND' :
          'ADMIN.METERGROUPS.METER_GROUP_DELETE_FAILURE';

        this.toasterService.error(error.message, failureMessageKey);
        return EMPTY;
      }),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  public onGridSave(): void {
    this._editMode$.next(false);
  }

  public onGridCancel(): void {
    this._editMode$.next(false);
  }

  private openEditModal(): NgfModalRef<MeterGroupsCreateModalComponent> {
    const modalRef = this.modalService.open(MeterGroupsCreateModalComponent, {
      size: 'content',
      beforeDismiss: () => {
        if (modalRef.componentInstance.formGroup.pristine) { return true; }
        modalRef.componentInstance.showConfirmDialog = true;

        return false;
      }
    });

    return modalRef;
  }

  private openDeleteModal(): NgfModalRef<MeterGroupsDeleteModalComponent> {
    return this.modalService.open(MeterGroupsDeleteModalComponent, { size: 'content' });
  }
}
