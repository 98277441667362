import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';

import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SwaggerException } from '@enerkey/clients/metering';
import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';

import { ToasterService } from '../../../../../shared/services/toaster.service';
import { MeterGroup } from '../../../models/meter-groups.model';
import { MeterGroupsService } from '../../../services/meter-groups/meter-groups.service';

@Component({
  templateUrl: './meter-groups-delete-modal.component.html',
  styleUrls: ['./meter-groups-delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeterGroupsDeleteModalComponent extends ModalBase implements OnInit, OnDestroy {
  public readonly formGroup: FormGroup<{ [K in keyof MeterGroup]: FormControl<MeterGroup[K]> }>;
  public showMeterCountWarning: boolean = false;
  public visibleMeterCount: number = null;

  protected readonly loading$: Observable<boolean>;
  protected readonly controls: ControlsOf<MeterGroup>;
  protected readonly titleKey: string = 'ADMIN.METERGROUPS.DELETE_METER_GROUP';

  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly meterGroupsService: MeterGroupsService,
    private readonly toasterService: ToasterService
  ) {
    super(currentModal);

    this.loading$ = this._loading$.asObservable();
    this.controls = formControlsFrom<MeterGroup>(
      {
        id: undefined,
        name: undefined,
        description: undefined,
        quantityGroupId: undefined,
        allMetersAreIncluded: false,
        totalMeterCount: undefined
      },
      {
        id: Validators.required,
        allMetersAreIncluded: Validators.requiredTrue
      }
    );

    this.formGroup = new UntypedFormGroup(this.controls);
  }

  public ngOnInit(): void {
    const hasMeterCountMismatch = Number.isFinite(this.visibleMeterCount) &&
      this.visibleMeterCount !== this.controls.totalMeterCount.value;

    if (hasMeterCountMismatch) { this.showMeterCountWarning = true; }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public submit(): void {
    const meterGroupId = this.controls.id.value;

    this.meterGroupsService.deleteMeterGroup(meterGroupId).pipe(
      indicate(this._loading$),
      tap(() => {
        const successMessageKey = 'ADMIN.METERGROUPS.METER_GROUP_DELETE_SUCCESS';
        this.toasterService.success(successMessageKey);

        this.closeModal();
      }),
      catchError((error: SwaggerException) => {
        const failureMessageKey = 'ADMIN.METERGROUPS.METER_GROUP_DELETE_FAILURE';
        this.toasterService.error(error.message, failureMessageKey);

        return EMPTY;
      })
    ).subscribe();
  }

  public onCloseModalClick(): void {
    this.closeModal();
  }
}
