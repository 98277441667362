import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  DataStateChangeEvent,
  GridComponent,
  GroupableSettings,
  SelectableSettings,
  SelectAllCheckboxState,
  SortSettings,
} from '@progress/kendo-angular-grid';
import * as dataQuery from '@progress/kendo-data-query';
import { IntlService } from '@progress/kendo-angular-intl';
import { isSameMonth } from 'date-fns';

import { removeGridFilters } from '@enerkey/ts-utils';

import { ContractSearchService } from '../../services/contract-search.service';
import { ContractRow } from '../../models/contract-row';
import { MeterCountColumnConfig } from '../../models/meter-counts';
import { DateFormatService } from '../../../../shared/services/date-format.service';
import { ClipboardService } from '../../../../shared/services/clipboard.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { BillingPeriod } from '../../models/contract-search-params';
import { KendoGridService } from '../../../../shared/ek-kendo/services/kendo-grid.service';

type RowSelectKey = 'id';
type BillingPeriodDetails = BillingPeriod & { display: string };

@Component({
  selector: 'contract-grid',
  templateUrl: './contract-grid.component.html',
  styleUrls: ['./contract-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KendoGridService],
})
export class ContractGridComponent implements AfterViewInit, OnDestroy {
  public readonly currencyFormat = '#,#.00';

  private static get defaultState(): dataQuery.State {
    // Kendo modifies the state-object so this has to be a getter.
    return {
      group: [{ field: 'facility.displayName', aggregates: [] }],
      skip: 0,
      take: 50,
      sort: [],
      filter: null,
    };
  }

  private static get aggregatedFields(): string[] {
    return [
      'unitCount',
      'cost',
      'billingInfo.unitCount',
      'billingInfo.cost',
    ];
  }

  public get gridRows(): ContractRow[] {
    return this._gridRows;
  }

  public set gridRows(value: ContractRow[]) {
    this._gridRows = value || [];
    this.dataStateChange(this.state);
  }

  public get selectedRows(): ContractRow[] {
    return this.gridRows.filter(row => this.selection.includes(row.id));
  }

  public get excelFileName(): string {
    const dateStamp = this.dateFormatService.fileNameFormattedDate();

    return this.activeBillingPeriod
      ? `${this.activeBillingPeriod.display}_${dateStamp}.xlsx`
      : `contracts_${dateStamp}.xlsx`;
  }

  public get visibleRows(): ContractRow[] {
    return this._visibleRows;
  }

  public gridData: dataQuery.DataResult;
  public state: dataQuery.State = ContractGridComponent.defaultState;

  public aggregates: dataQuery.AggregateDescriptor[] = [];
  public aggregateResult: dataQuery.AggregateResult = {};
  public columnConfig: MeterCountColumnConfig = null;
  public activeBillingPeriod: BillingPeriodDetails = null;

  public readonly selectKey: RowSelectKey = 'id';
  public selection: ContractRow[RowSelectKey][] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';

  public readonly gridSelectableSettings: SelectableSettings = {
    checkboxOnly: true,
    enabled: true,
    mode: 'multiple',
  };

  public readonly gridSortSettings: SortSettings = {
    allowUnsort: true,
    initialDirection: 'asc',
    mode: 'multiple',
  };

  public readonly gridGroupableSettings: GroupableSettings = {
    enabled: true,
    showFooter: true,
  };

  public readonly isLoading$: Observable<boolean>;

  private _gridRows: ContractRow[] = [];
  private _visibleRows: ContractRow[] = [];
  private readonly subscription: Subscription = new Subscription();

  @ViewChild(GridComponent, { static: true })
  private readonly kendoGrid: GridComponent;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly contractService: ContractSearchService,
    private readonly gridService: KendoGridService<ContractRow, RowSelectKey>,
    private readonly dateFormatService: DateFormatService,
    private readonly clipboardService: ClipboardService,
    private readonly toaster: ToasterService,
    private readonly intlService: IntlService
  ) {
    this.isLoading$ = this.contractService.loading$;
    this.subscription.add(
      this.contractService.fetched$.subscribe(result => this.rowsChanged(...result))
    );
  }

  public ngAfterViewInit(): void {
    this.gridService.initialize(this.selectKey, this.kendoGrid);

    this.gridService.selection$.subscribe(keys => {
      this.selection = keys;
    });

    this.gridService.visibleData$.subscribe(data => {
      this._visibleRows = data;
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public dataStateChange(state: dataQuery.State | DataStateChangeEvent): void {
    this.updateAggregates();

    for (const group of state?.group ?? []) {
      group.aggregates = this.aggregates;
    }

    // Clear possibly missing product IDs
    if (state.filter) {
      const productIds = this.gridRows.unique('productId');
      removeGridFilters(state.filter, f => f.field === 'productId' && !productIds.includes(f.value));
    }

    this.state = state;
    this.aggregateResult = dataQuery.aggregateBy(
      dataQuery.filterBy(this.gridRows, this.state.filter),
      this.aggregates
    );

    this.gridData = dataQuery.process(this.gridRows, this.state);
  }

  public resetState(): void {
    this.dataStateChange(ContractGridComponent.defaultState);
  }

  public copyColumn(column: 'contractId' | 'enegiaId'): void {
    const rows = this.visibleRows;

    const values = column === 'contractId'
      ? rows.mapFilter(row => row.contractId, id => !!id).unique()
      : rows.mapFilter(row => row.facility.enegiaId, id => Number.isInteger(id)).unique();

    if (!values.hasItems()) {
      this.toaster.warning('GRID_SHORTCUTS.COPY_EMPTY');
      return;
    }

    this.clipboardService.copy(values.join(', ')).then(
      () => this.toaster.success('GRID_SHORTCUTS.COPY_SUCCESS'),
      () => this.toaster.error('GRID_SHORTCUTS.COPY_ERROR')
    );
  }

  public groupByColumn(column: string): void {
    this.dataStateChange({
      ...this.state,
      group: [{ field: column, aggregates: [] }],
    });
  }

  public productNameSelector(row: ContractRow): string {
    return `${row.productId}: ${row.productName}`;
  }

  private rowsChanged(rows: ContractRow[], period: BillingPeriod): void {
    this.columnConfig = MeterCountColumnConfig.from(
      rows.mapFilter(row => row.meterCounts, count => !!count),
      'meterCounts'
    );

    this.activeBillingPeriod = this.getActiveBillingPeriod(period);

    this.addMissingCounts(rows);
    this.gridRows = rows;
    this.gridService.dataChanged(rows);

    this.changeDetector.detectChanges();
  }

  private updateAggregates(): void {
    const fields = Array.from(ContractGridComponent.aggregatedFields);

    if (this.columnConfig) {
      fields.push(...this.columnConfig.allColumns.map(c => c.field));
    }

    this.aggregates = fields.map(field => ({ field, aggregate: 'sum' }));
  }

  /**
   * Add missing properties to meter counts. Kendo requires a value to correctly count aggregates,
   * so we need to add null, as undefined doesn't work.
   */
  private addMissingCounts(rows: ContractRow[]): void {
    if (!this.columnConfig) {
      return;
    }

    const splitPaths = this.columnConfig.allColumns.map(c => c.field.split('.'));

    for (const row of rows.uniqueBy('meterCounts').filter(r => !!r)) {
      for (const propertyPath of splitPaths) {
        const lastIndex = propertyPath.length - 1;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        propertyPath.reduce((obj: any, key: string, index: number) => {
          if (!(key in obj) || obj[key] === undefined) {
            obj[key] = (index === lastIndex) ? null : {};
          }
          return obj[key];
        }, row);
      }
    }
  }

  private getActiveBillingPeriod(period: BillingPeriod): BillingPeriodDetails {
    if (!period || !period.from || !period.to) {
      return null;
    }

    const format = 'MM.yyyy';
    const start = this.intlService.formatDate(period.from, format);
    const end = this.intlService.formatDate(period.to, format);

    return {
      ...period,
      display: isSameMonth(period.from, period.to) ? start : `${start}-${end}`
    };
  }

}
