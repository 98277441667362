import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { Quantities } from '@enerkey/clients/metering';
import {
  CostReportingRequest,
  EnergyMeterTargetSingleFacilityRequest,
  FacilityConsumptions,
  MeterConsumptionRequest,
  ReportingClient,
  ReportingUnit,
  RequestDuration,
  RequestResolution,
} from '@enerkey/clients/reporting';

import { WeatherClient, WeatherSearchRequest } from '@enerkey/clients/weather';

import { ColorService } from '../../../shared/services/color.service';
import { QuantityService } from '../../../shared/services/quantity.service';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ToasterService } from '../../../shared/services/toaster.service';
import {
  defaultChartLineWidth,
  ReportingData,
  ReportingDataServiceBase,
  ReportingSeriesById,
  SerieChartOptions,
  TitleParams
} from './reporting-data-service-base';
import { ReportingMeterSelection } from '../shared/reporting-meter-selection';
import { createCostSeriesData, createReportingSeries } from './costs-report.functions';
import { meterBasedCostsIdMatch, nationalCostsIdMatch } from '../constants/costs-report-constants';
import { ReportType } from '../shared/report-type';
import { localToUtc } from '../../../shared/date.functions';
import { ReportingSeries } from '../shared/reporting-series';

import { durationToString } from '../shared/duration-to-string';
import { targetSeriesDefinitions } from '../shared/target-series-definitions';
import { ValueType } from '../../../shared/ek-inputs/value-type-select/value-type-select.component';

@Injectable({ providedIn: 'root' })
export class ReportingMeterDataService extends ReportingDataServiceBase {
  public constructor(
    private readonly reportingClient: ReportingClient,
    quantityService: QuantityService,
    colorService: ColorService,
    translateService: TranslateService,
    toasterService: ToasterService,
    private weatherClient: WeatherClient
  ) {
    super(
      colorService,
      translateService,
      toasterService,
      quantityService
    );
  }

  public getMeasuredValues(
    reportType: ReportType,
    params: ReportingSearchParams,
    facilityId: number,
    meters: ReportingMeterSelection,
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingData[]> {
    if (!Array.hasItems(meters.meterIds)) {
      return of([]);
    }
    const quantityIds$ = this.getMeaningfulMeasuredQuantityIds(
      meters.quantities,
      params.measured
    );
    return quantityIds$.pipe(
      switchMap(quantityIds => {
        if (!Array.hasItems(quantityIds)) {
          return of([]);
        }
        return forkJoin(
          quantityIds.map(qId => forkJoin({
            series: this.getQuantityData({
              reportType,
              params,
              facilityIds: [facilityId],
              quantityId: qId,
              normalization: false,
              chartOptions,
              isCumulative,
              titleParams,
              meterIds: meters.meterIdsByQuantities.get(qId)
            }),
            quantityId: of(qId)
          }))
        );
      })
    );
  }

  public getNormalizedValues(
    reportType: ReportType,
    params: ReportingSearchParams,
    facilityId: number,
    meters: ReportingMeterSelection,
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingData[]> {
    if (!params.normalized) {
      return of([]);
    }
    if (!Array.hasItems(meters.meterIds)) {
      return of([]);
    }
    const quantityIds$ = this.getMeaningfulNormalizedQuantityIds(meters.quantities);
    return quantityIds$.pipe(
      switchMap(quantityIds => {
        if (!Array.hasItems(quantityIds)) {
          return of([]);
        }
        return forkJoin(
          quantityIds.map(qId => forkJoin({
            series: this.getQuantityData({
              reportType,
              params,
              facilityIds: [facilityId],
              quantityId: qId,
              normalization: true,
              chartOptions,
              isCumulative,
              titleParams,
              meterIds: meters.meterIdsByQuantities.get(qId)
            }),
            quantityId: of(qId)
          }))
        );
      })
    );
  }

  public getMeterBasedCosts(
    params: ReportingSearchParams,
    facilityIds: number[],
    meters: ReportingMeterSelection,
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingData[]> {

    if (!Array.hasItems(meters.meterIds) || !Array.hasItems(params?.meterBasedCostIds)) {
      return of([]);
    }

    return forkJoin(
      meters.quantities.map(qId => forkJoin({
        series: this.getMeterBasedCostsForQuantity(
          params,
          facilityIds,
          meters,
          qId,
          isCumulative,
          chartOptions,
          titleParams
        ),
        quantityId: of(qId)
      }))
    );
  }

  public getMeterBasedCostsForQuantity(
    params: ReportingSearchParams,
    facilityIds: number[],
    meters: ReportingMeterSelection,
    quantityId: Quantities,
    isCumulative: boolean,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingSeriesById> {
    const selectedCosts = meterBasedCostsIdMatch.filter(cost => params.meterBasedCostIds.includes(cost.id));
    const selectedMeters: number[] = meters?.meterIds;
    const requests = params.searchPeriods.map(period =>
      this.reportingClient.getMeterBasedCosts(
        new CostReportingRequest({
          facilityIds,
          quantityId,
          unit: params.unit,
          resolution: params.resolution,
          start: localToUtc(period.start),
          duration: params.duration,
        })
      ));

    return forkJoin(requests).pipe(
      map(response => selectedMeters.toRecord(
        mId => mId,
        mId => response
          .filter(res => res.costsPerFacilityAndMeter[facilityIds[0]][mId]
          && Array.isArray(res.costsPerFacilityAndMeter[facilityIds[0]][mId].values))
          .map(res => createCostSeriesData(
            res.costsPerFacilityAndMeter[facilityIds[0]][mId].values,
            selectedCosts,
            res.costsPerFacilityAndMeter[facilityIds[0]][mId].currencySymbol,
            quantityId,
            res.responseHasSingleCurrency,
            res.costsPerFacilityAndMeter[facilityIds[0]][mId].averageCostsUnit
          ))
      )),
      map(res => selectedMeters.toRecord(
        mId => mId,
        mId => createReportingSeries(
          res,
          mId,
          params,
          quantityId,
          this.translateService,
          this.colorService,
          isCumulative,
          this.getPeriodNamePublic.bind(this),
          chartOptions,
          titleParams,
          false
        )
      ))
    );
  }

  public getNationalBasedCostsForMeter(
    params: ReportingSearchParams,
    facilityIds: number[],
    meters: ReportingMeterSelection,
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingData[]> {

    if (!Array.hasItems(meters.meterIds) || !Array.hasItems(params?.nationalCostIds)) {
      return of([]);
    }

    return forkJoin(
      meters.quantities.map(qId => forkJoin({
        series: this.getNationalBasedCostsForQuantity(
          params,
          facilityIds,
          meters,
          qId,
          isCumulative,
          chartOptions,
          titleParams
        ),
        quantityId: of(qId)
      }))
    );
  }

  public getMeasuredTargetsForMeter(
    params: ReportingSearchParams,
    facilityId: number,
    meters: ReportingMeterSelection
  ): Observable<ReportingData[]> {
    if (!Array.hasItems(meters.meterIds)) {
      return of([]);
    }
    const quantityIds$ = this.getMeaningfulMeasuredQuantityIds(meters.quantities, params.measured);
    return quantityIds$.pipe(
      switchMap(quantityIds => {
        if (!Array.hasItems(quantityIds)) {
          return of([]);
        }
        return forkJoin(
          meters.quantities.map(qId => forkJoin({
            series: this.getTargetsForQuantity(
              params,
              facilityId,
              qId,
              meters,
              params.searchPeriods[params.searchPeriods.length - 1],
              false
            ),
            quantityId: of(qId)
          }))
        );
      })
    );
  }

  public getNormalizedTargetsForMeter(
    params: ReportingSearchParams,
    facilityId: number,
    meters: ReportingMeterSelection
  ): Observable<ReportingData[]> {
    if (!Array.hasItems(meters.meterIds)) {
      return of([]);
    }
    const quantityIds$ = this.getMeaningfulNormalizedQuantityIds(meters.quantities);
    return quantityIds$.pipe(
      switchMap(quantityIds => {
        if (!Array.hasItems(quantityIds)) {
          return of([]);
        }
        return forkJoin(
          meters.quantities.map(qId => forkJoin({
            series: this.getTargetsForQuantity(
              params,
              facilityId,
              qId,
              meters,
              params.searchPeriods[params.searchPeriods.length - 1],
              true
            ),
            quantityId: of(qId)
          }))
        );
      })
    );
  }

  public getTargetsForQuantity(
    params: ReportingSearchParams,
    facilityId: number,
    quantityId: Quantities,
    meters: ReportingMeterSelection,
    period: { start: Date},
    normalization: boolean
  ): Observable<ReportingSeriesById> {
    const selectedMeters: number[] = meters?.meterIds;

    const targetTypes = params.targetTypes.filter(t => {
      const targetDefinition = targetSeriesDefinitions[t];
      return targetDefinition.applicableQuantities?.includes(quantityId) ?? true;
    });

    if (!Array.hasItems(targetTypes)) {
      return of({});
    }

    const requests = targetTypes.flatMap(targetType =>
      this.reportingClient.getEnergyTargetForMeters(
        new EnergyMeterTargetSingleFacilityRequest({
          facilityId,
          quantityId,
          targetType,
          resolution: params.resolution,
          start: localToUtc(period.start),
          duration: params.duration,
          unit: params.unit
        })
      ));
    const comparisonColor = this.colorService.getQuantityColor(quantityId);
    return forkJoin(requests).pipe(
      map(res => selectedMeters.toRecord(
        mId => mId,
        mId => {
          const targetSeries: ReportingSeries[] = res.filterMap(
            r => r.meterTargets[mId]?.hasValues,
            r => {
              const values = r.meterTargets[mId].values;
              const seriesDefinition = targetSeriesDefinitions[r.targetType];
              const targetName = this.translateService.instant(seriesDefinition.translationKey);
              const periodName = durationToString(values[0].timestamp, params.duration, 0, true);
              return new ReportingSeries({
                chartOptions: {
                  lineWidth: defaultChartLineWidth,
                  dashType: seriesDefinition.dashType,
                  serieType: 'line'
                },
                gridOptions: {
                  comparisonColor,
                  hideInGrid: !!(normalization && (params.formValue.valueType === ValueType.Measured))
                },
                options: {
                  quantityId: quantityId,
                  color: seriesDefinition.color,
                  serieTitle: `${targetName} ${periodName}`,
                  unit: r.unit,
                  unitKey: params.unit,
                  serieType: seriesDefinition.targetType,
                  isNormalized: normalization
                },
                chartItemOptions: {
                  title: targetName,
                  positionInTooltip: 0
                },
                consumptions: values.map(v => ({
                  value: v.value,
                  incomplete: 0,
                  modeled: 0,
                  timestamp: v.timestamp
                })),
                isCumulative: false
              },
              {
                resolution: params.resolution,
                searchPeriods: params.searchPeriods,
                durationLength: params.formValue.durationLength
              });
            }
          ).flat();
          return targetSeries;
        }
      )),
      catchError(() => {
        this.toasterService.error('FACILITIES.DOWNLOAD_ERROR');
        return this.getEmptyResponse(selectedMeters);
      })
    );
  }

  public getNationalBasedCostsForQuantity(
    params: ReportingSearchParams,
    facilityIds: number[],
    meters: ReportingMeterSelection,
    quantityId: Quantities,
    isCumulative: boolean,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingSeriesById> {
    const selectedCosts = nationalCostsIdMatch.filter(cost => params.nationalCostIds.includes(cost.id));
    const selectedMeters: number[] = meters?.meterIds;
    const requests = params.searchPeriods.map(period =>
      this.reportingClient.getNationalCostsForMeters(
        new CostReportingRequest({
          facilityIds,
          quantityId,
          unit: params.unit,
          resolution: params.resolution,
          start: localToUtc(period.start),
          duration: params.duration,
        })
      ));

    return forkJoin(requests).pipe(
      map(response => selectedMeters.toRecord(
        mId => mId,
        mId => response
          .filter(res => res.costsPerFacilityAndMeter[facilityIds[0]][mId]
          && Array.isArray(res.costsPerFacilityAndMeter[facilityIds[0]][mId].values))
          .map(res => createCostSeriesData(
            res.costsPerFacilityAndMeter[facilityIds[0]][mId].values,
            selectedCosts,
            res.costsPerFacilityAndMeter[facilityIds[0]][mId].currencySymbol,
            quantityId,
            res.responseHasSingleCurrency,
            res.costsPerFacilityAndMeter[facilityIds[0]][mId].averageCostsUnit
          ))
      )),
      map(res => selectedMeters.toRecord(
        mId => mId,
        mId => createReportingSeries(
          res,
          mId,
          params,
          quantityId,
          this.translateService,
          this.colorService,
          isCumulative,
          this.getPeriodNamePublic.bind(this),
          chartOptions,
          titleParams,
          true
        )
      ))
    );
  }

  public getPeriodNamePublic(
    params: ReportingSearchParams,
    titleParams: TitleParams,
    index: number
  ): string {
    return this.getPeriodName(params, titleParams, index);
  }

  public getTemperature(
    params: ReportingSearchParams,
    facilityIds: number[],
    meters: ReportingMeterSelection
  ): Observable<ReportingData[]> {
    if (!params.showTemperature) {
      return of([]);
    }

    if (!Array.hasItems(meters.meterIds)) {
      return of([]);
    }

    const requests = params.searchPeriods.map(period => forkJoin({
      period: of(period),
      result: this.weatherClient.getWeatherReadings(new WeatherSearchRequest({
        facilityIds: facilityIds,
        resolution: params.resolution,
        start: localToUtc(period.start),
        end: localToUtc(period.end),
      })),
    }));

    return forkJoin(requests).pipe(
      map(results => results.map(
        ({ period, result }, index): ReportingData => {
          const title = this.translateService.instant('FACILITIES.SIDEBAR.TEMPERATURE');
          const periodName = durationToString(period.start, params.duration, index, true);
          return {
            quantityId: null,
            series: meters.meterIds.toRecord(
              meterId => meterId,
              () => [
                new ReportingSeries({
                  consumptions: result[facilityIds[0]].map(v => ({
                    value: v.value,
                    timestamp: v.timestamp
                  })),
                  options: {
                    serieType: 'temperature',
                    color: this.colorService.shadeColor('#4ddd4d', index * -20),
                    quantityId: null,
                    serieTitle: `${title} ${periodName}`,
                    unit: '°C',
                  },
                  chartOptions: { serieType: 'line', dashType: 'solid' },
                  gridOptions: { gridTitle: periodName },
                  chartItemOptions: { positionInTooltip: 100 },
                }, {
                  resolution: params.resolution,
                  durationLength: params.formValue.durationLength,
                  searchPeriods: params.searchPeriods
                }),
              ]
            )
          };
        }
      )),
      catchError(() => {
        this.toasterService.error('REPORTING.ERRORS.TEMPERATURE');
        return of([]);
      })
    );
  }

  protected consumptionRequest(
    quantityId: Quantities,
    isNormalized: boolean,
    facilityIds: number[],
    resolution: RequestResolution,
    start: Date,
    duration: RequestDuration,
    unit: ReportingUnit
  ): Observable<{ unit: string; relatedUnit: string; values: { [key: string]: FacilityConsumptions; } }> {
    const endpoint: keyof ReportingClient = isNormalized
      ? 'getNormalizedConsumptionsForMeters'
      : 'getConsumptionsForMeters';

    const requestParams = new MeterConsumptionRequest({
      quantityId,
      facilityIds: facilityIds,
      resolution: resolution,
      start: localToUtc(start),
      duration: duration,
      unit: unit
    });
    return this.reportingClient[endpoint](requestParams).pipe(
      map(r => ({
        values: r.consumptions[facilityIds[0]],
        unit: r.unit,
        relatedUnit: r.relatedUnit,
      }))
    );
  }
}
