import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ReportingDataService } from './reporting-data.service';
import { ReportingSeriesService } from './reporting-series.service';
import { ReportType } from '../shared/report-type';

@Injectable({
  providedIn: 'root'
})
export class PeriodReportService {
  public constructor(
    private readonly reportingDataService: ReportingDataService,
    private readonly reportingSeriesService: ReportingSeriesService
  ) {
  }

  public getData(
    params: ReportingSearchParams,
    facilityIds: number[],
    incompleteThreshold: number,
    averageQuantities?: Quantities[]
  ): Observable<ReportingSeriesByFacility> {
    return forkJoin({
      measured: forkJoin([
        this.reportingDataService.getMeasuredValues(
          ReportType.Period,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'column', lineOpacity: 0.85
            })),
          }
        ),
        this.reportingDataService.getDistributions(ReportType.Period, params, facilityIds),
        this.reportingDataService.getMeasuredDerivedValues(
          ReportType.Period,
          params,
          facilityIds,
          averageQuantities,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        ),
      ]),
      normalized: forkJoin([
        this.reportingDataService.getNormalizedValues(
          ReportType.Period,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'column', lineOpacity: 0.85
            })),
          }
        ),
        this.reportingDataService.getNormalizedDerivedValues(
          ReportType.Period,
          params,
          facilityIds,
          averageQuantities,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        ),
      ]),
      measuredTargets: this.reportingDataService.getMeasuredTargets(params, facilityIds, false),
      normalizedTargets: this.reportingDataService.getNormalizedTargets(params, facilityIds, false),
      temperatures: this.reportingDataService.getTemperature(params, facilityIds),
      nationalCosts: this.reportingDataService.getNationalCostsReportingData(
        params,
        facilityIds,
        false,
        {
          serieSettings: params.periods.map(() => ({
            serieType: 'line'
          })),
        }
      ),
      meterBasedCosts: this.reportingDataService.getMeterBasedCostsReportingData(
        params,
        facilityIds,
        false,
        {
          serieSettings: params.periods.map(() => ({
            serieType: 'line'
          })),
        }
      ),
      emissions: forkJoin([
        this.reportingDataService.getMeasuredEmissionsValues(
          ReportType.Period,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        ),
        this.reportingDataService.getNormalizedEmissionsValues(
          ReportType.Period,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        )
      ])
    }).pipe(
      map(({
        measured,
        normalized,
        measuredTargets,
        normalizedTargets,
        temperatures,
        nationalCosts,
        meterBasedCosts,
        emissions
      }) => this.reportingSeriesService.mapValuesById({
        ids: facilityIds,
        measuredTargets,
        normalizedTargets,
        temperatures,
        nationalCosts,
        meterBasedCosts,
        emissions: emissions.flat(),
        measured: measured.flat(),
        normalized: normalized.flat(),
        incompleteThreshold,
        comparability: params.comparability,
        periods: params.periods,
        searchPeriods: params.searchPeriods,
        resolution: params.resolution,
        averageQuantities
      }))
    );
  }
}
