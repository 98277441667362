import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as dataQuery from '@progress/kendo-data-query';

import { OnPropertyChange, OnPropertyChanged } from '@enerkey/angular-utils';
import { getEnumEntries } from '@enerkey/ts-utils';
import { Quantities } from '@enerkey/clients/metering';

import {
  quantityGroupDefinitions,
  quantityTranslations,
} from '../../../constants/quantity.constant';

type QuantityOption = {
  readonly value: Quantities;
  readonly iconClass: string;
  readonly key: string;
  readonly group: string;
};

@Component({
  selector: 'quantity-dropdown',
  templateUrl: './quantity-dropdown.component.html',
  styleUrls: ['./quantity-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QuantityDropdownComponent),
    multi: true
  }],
})
export class QuantityDropdownComponent implements ControlValueAccessor, OnPropertyChanged {

  /** Possible values of the dropdown. */
  @Input() @OnPropertyChange() public quantities: Quantities[] = [];

  /** Whether to group the quantities (see `quantity.constant` for groups) */
  @Input() @OnPropertyChange() public grouped: boolean = false;

  /** Shows a spinner when true. Does not disable the dropdown. */
  @Input() public loading: boolean = false;

  /** Overrides disabled state when `loading` is `true`. */
  @Input() public disableWhenLoading: boolean = false;

  @Input() public noDataMessage?: string | null = null;

  public value: Quantities = null;
  public disabled: boolean = false;
  public options: QuantityOption[] | dataQuery.GroupResult[] = [];

  /** CVA Hander */ private _onChange: (value: Quantities) => void;
  /** CVA Hander */ private _onTouch: () => void;

  private readonly iconClasses: Record<Quantities, string>;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly translateService: TranslateService
  ) {
    this.iconClasses = getEnumEntries(Quantities).reduce<Record<number, string>>((obj, [key, value]) => {
      obj[value] = `icon-ekey-${key.toLowerCase()}`;
      return obj;
    }, {});
  }

  /** Kendo component event */
  public valueChanged(value: Quantities): void {
    this._onChange?.(value);
  }

  /** Kendo component event */
  public blur(): void {
    this._onTouch?.();
  }

  /** ControlValueAccessor interface. Resets value if attempting to set outside component `quantities`. */
  public writeValue(value: Quantities): void {
    this.value = this.quantities?.includes(value)
      ? value
      : null;

    this.changeDetector.detectChanges();
  }

  /** ControlValueAccessor interface */
  public registerOnChange(fn: (value: Quantities) => void): void {
    this._onChange = fn;
  }

  /** ControlValueAccessor interface */
  public registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  /** ControlValueAccessor interface */
  public setDisabledState(isDisabled: boolean): void {
    if (!!this.disabled !== !!isDisabled) {
      this.disabled = isDisabled;
      this.changeDetector.detectChanges();
    }
  }

  public onPropertyChanged(): void {
    const result = (this.quantities ?? []).map<QuantityOption>(quantity => ({
      value: quantity,
      key: quantityTranslations[quantity],
      iconClass: this.iconClasses[quantity],
      group: this.getGroupName(quantity),
    }));

    this.options = this.grouped
      ? dataQuery.groupBy(result, [{ field: 'group' }])
      : result;
  }

  private getGroupName(quantity: Quantities): string {
    return this.translateService.instant(
      quantityGroupDefinitions.find(g => g.quantityIds.includes(quantity) || g.sumQuantityIds.includes(quantity))?.title
      ?? 'QUANTITY_GROUPS.UNDEFINED'
    );
  }
}
