import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridsterModule } from 'angular-gridster2';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { InputsModule, SliderModule } from '@progress/kendo-angular-inputs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { IntlModule } from '@progress/kendo-angular-intl';
import { GridModule } from '@progress/kendo-angular-grid';

import { NgfTabsetModule, NgfTooltipModule } from '@enerkey/foundation-angular';
import { EkFormsModule } from '@enerkey/ek-forms';

import { AnalyticsSharedModule } from '../../shared/analytics-shared/analytics-shared.module';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { dashboardState } from './dashboard-states';
import { SharedModule } from '../../shared/shared.module';
import { DashboardRootComponent } from './components/dashboard-root/dashboard-root.component';
import { NewsWidgetComponent } from './components/news-widget/news-widget.component';
import { WidgetContainerComponent } from './components/widget-container/widget-container.component';
import { WidgetSettingsGeneralComponent } from './components/widget-settings-general/widget-settings-general.component';

import { DashboardEditModalComponent } from './components/dashboard-edit-modal/dashboard-edit-modal.component';
import { CommentsWidgetComponent } from './components/comments-widget/comments-widget.component';
import { CommentsWidgetOptionsComponent } from './components/comments-widget-options/comments-widget-options.component';
import { EkInputsModule } from '../../shared/ek-inputs/ek-inputs.module';
import { ActionsWidgetComponent } from './components/actions-widget/actions-widget.component';
import { ActionsWidgetOptionsComponent } from './components/actions-widget-options/actions-widget-options.component';
import { EnergyManagementSharedModule } from '../../shared/energy-management-shared/energy-management-shared.module';
import { DashboardTabsComponent } from './components/dashboard-tabs/dashboard-tabs.component';
import { GroupedConsumptionsWidgetComponent } from './components/grouped-consumptions-widget/grouped-consumptions-widget.component';
import { GroupedConsumptionsTableComponent } from './components/grouped-consumptions-table/grouped-consumptions-table.component';
import { WidgetChangeSelectComponent } from './components/widget-change-select/widget-change-select.component';
import { WidgetComparisonPeriodSelectComponent } from './components/widget-comparison-period-select/widget-comparison-period-select.component';
import { WidgetTimeframeSelectComponent } from './components/widget-timeframe-select/widget-timeframe-select.component';
import { WidgetVariableSelectComponent } from './components/widget-variable-select/widget-variable-select.component';
import { GroupedConsumptionWidgetNumberFormatPipe } from './pipes/grouped-consumption-widget-number-format.pipe';
import { GroupedConsumptionsWidgetOptionsComponent } from './components/grouped-consumptions-widget-options/grouped-consumptions-widget-options.component';
import { WidgetGroupBySelectComponent } from './components/widget-group-by-select/widget-group-by-select.component';
import { GroupedConsumptionsFacilityAmountPipe } from './pipes/grouped-consumptions-facility-amount.pipe';
import { TopChangedConsumptionsWidgetComponent } from './components/top-changed-consumptions-widget/top-changed-consumptions-widget.component';
import { TopChangedConsumptionsTableComponent } from './components/top-changed-consumptions-table/top-changed-consumptions-table.component';
import { SumConsumptionsWidgetComponent } from './components/sum-consumptions-widget/sum-consumptions-widget.component';
import { SumConsumptionsWidgetOptionsComponent } from './components/sum-consumptions-widget-options/sum-consumptions-widget-options.component';
import { TopChangedActionsTitlePipe } from './pipes/top-changed-actions-title.pipe';
import { TopChangedConsumptionsWidgetOptionsComponent } from './components/top-changed-consumptions-widget-options/top-changed-consumptions-widget-options.component';
import { ConsumptionsWidgetComponent } from './components/consumptions-widget/consumptions-widget.component';
import { ConsumptionsWidgetOptionsComponent } from './components/consumptions-widget-options/consumptions-widget-options.component';
import { ConsumptionsWidgetChartComponent } from './components/consumptions-widget-chart/consumptions-widget-chart.component';
import { SumConsumptionsWidgetContentComponent } from './components/sum-consumptions-widget-content/sum-consumptions-widget-content.component';
import { SumConsumptionsPieChartComponent } from './components/sum-consumptions-pie-chart/sum-consumptions-pie-chart.component';
import { SumConsumptionsTableComponent } from './components/sum-consumptions-table/sum-consumptions-table.component';
import { QuantityInfoHeaderComponent } from './components/quantity-info-header/quantity-info-header.component';
import { EmissionsWidgetComponent } from './components/emissions-widget/emissions-widget.component';
import { EmissionsWidgetOptionsComponent } from './components/emissions-widget-options/emissions-widget-options.component';
import { EmissionsWidgetPieChartComponent } from './components/emissions-widget-pie-chart/emissions-widget-pie-chart.component';
import { EmissionsWidgetChartComponent } from './components/emissions-widget-chart/emissions-widget-chart.component';
import { EmissionsWidgetChartTooltipComponent } from './components/emissions-widget-chart-tooltip/emissions-widget-chart-tooltip.component';
import { EmissionsTypeSelectComponent } from './components/emissions-type-select/emissions-type-select.component';
import { EkDropdownModule } from '../../shared/ek-dropdown/ek-dropdown.module';
import { CommonPipesModule } from '../../shared/common-pipes/common-pipes.module';
import { EnergyReportingSharedModule } from '../../shared/energy-reporting-shared/energy-reporting-shared.module';
import { ErrorTicketWidgetComponent } from './components/error-ticket-widget/error-ticket-widget.component';
import { ErrorTicketMeterModalComponent } from './components/error-ticket-meter-modal/error-ticket-meter-modal.component';
import { ErrorTicketWidgetOptionsComponent } from './components/error-ticket-widget-options/error-ticket-widget-options.component';
import { ErrorTicketTypeNamePipe } from './pipes/error-ticket-type-name.pipe';
import { LatestAlarmsWidgetComponent } from './components/latest-alarms-widget/latest-alarms-widget.component';
import { LatestAlarmsWidgetOptionsComponent } from './components/latest-alarms-widget-options/latest-alarms-widget-options.component';
import { AlarmsSharedModule } from '../../shared/alarms-shared/alarms-shared.module';
import { EmissionsFacilityWiseWidgetComponent } from './components/emissions-facility-wise-widget/emissions-facility-wise-widget.component';
import { EmissionsFacilityWiseWidgetOptionsComponent } from './components/emissions-facility-wise-widget-options/emissions-facility-wise-widget-options.component';
import { FacilityLatestAlarmsWidgetComponent } from './components/facility-latest-alarms-widget/facility-latest-alarms-widget.component';
import { FacilityLatestAlarmsWidgetOptionsComponent } from './components/facility-latest-alarms-widget-options/facility-latest-alarms-widget-options.component';
import { ConsumptionsByFacilityWidgetComponent } from './components/consumptions-by-facility-widget/consumptions-by-facility-widget.component';
import { ConsumptionsByFacilityWidgetOptionsComponent } from './components/consumptions-by-facility-widget-options/consumptions-by-facility-widget-options.component';
import { FacilitySelectHeaderComponent } from './components/facility-select-header/facility-select-header.component';
import { FacilityCommentsWidgetComponent } from './components/facility-comments-widget/facility-comments-widget.component';
import { FacilityCommentsWidgetOptionsComponent } from './components/facility-comments-widget-options/facility-comments-widget-options.component';
import { FacilityActionsWidgetComponent } from './components/facility-actions-widget/facility-actions-widget.component';
import { FacilityActionsWidgetOptionsComponent } from './components/facility-actions-widget-options/facility-actions-widget-options.component';
import { FacilityEtCurveWidgetComponent } from './components/facility-et-curve/facility-et-curve-widget/facility-et-curve-widget.component';
import { FacilityEtCurveWidgetOptionsComponent } from './components/facility-et-curve/facility-et-curve-widget-options/facility-et-curve-widget-options.component';
import { DerivedValueSingleSelectComponent } from './components/derived-value-select/derived-value-single-select.component';
import { PowerWidgetComponent } from './components/power-widget/power-widget.component';
import { PowerWidgetOptionsComponent } from './components/power-widget-options/power-widget-options.component';
import { WidgetRelatedValueSelectComponent } from './components/widget-related-value-select/widget-related-value-select.component';
import { TableSortDirective } from './directives/table-sort.directive';
import { FacilityWeeklyConsumptionWidgetComponent } from './components/facility-weekly-consumption-widget/facility-weekly-consumption-widget.component';
import { FacilityWeeklyConsumptionWidgetOptionsComponent } from './components/facility-weekly-consumption-widget-options/facility-weekly-consumption-widget-options.component';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardRootComponent,
    NewsWidgetComponent,
    WidgetContainerComponent,
    WidgetSettingsGeneralComponent,
    DashboardEditModalComponent,
    CommentsWidgetComponent,
    CommentsWidgetOptionsComponent,
    ActionsWidgetComponent,
    ActionsWidgetOptionsComponent,
    DashboardTabsComponent,
    GroupedConsumptionsWidgetComponent,
    GroupedConsumptionsTableComponent,
    WidgetChangeSelectComponent,
    WidgetComparisonPeriodSelectComponent,
    WidgetTimeframeSelectComponent,
    WidgetVariableSelectComponent,
    SumConsumptionsWidgetComponent,
    SumConsumptionsWidgetOptionsComponent,
    GroupedConsumptionWidgetNumberFormatPipe,
    GroupedConsumptionsWidgetOptionsComponent,
    WidgetGroupBySelectComponent,
    GroupedConsumptionsFacilityAmountPipe,
    TopChangedConsumptionsWidgetComponent,
    TopChangedConsumptionsTableComponent,
    TopChangedActionsTitlePipe,
    TopChangedConsumptionsWidgetOptionsComponent,
    ConsumptionsWidgetComponent,
    ConsumptionsWidgetOptionsComponent,
    ConsumptionsWidgetChartComponent,
    SumConsumptionsWidgetContentComponent,
    SumConsumptionsPieChartComponent,
    SumConsumptionsTableComponent,
    QuantityInfoHeaderComponent,
    EmissionsWidgetComponent,
    EmissionsWidgetOptionsComponent,
    EmissionsWidgetPieChartComponent,
    EmissionsWidgetChartComponent,
    EmissionsWidgetChartTooltipComponent,
    EmissionsTypeSelectComponent,
    ErrorTicketWidgetComponent,
    ErrorTicketMeterModalComponent,
    ErrorTicketWidgetOptionsComponent,
    ErrorTicketTypeNamePipe,
    LatestAlarmsWidgetComponent,
    LatestAlarmsWidgetOptionsComponent,
    EmissionsFacilityWiseWidgetComponent,
    EmissionsFacilityWiseWidgetOptionsComponent,
    FacilityLatestAlarmsWidgetComponent,
    FacilityLatestAlarmsWidgetOptionsComponent,
    ConsumptionsByFacilityWidgetComponent,
    ConsumptionsByFacilityWidgetOptionsComponent,
    FacilitySelectHeaderComponent,
    FacilityCommentsWidgetComponent,
    FacilityCommentsWidgetOptionsComponent,
    FacilityActionsWidgetComponent,
    FacilityActionsWidgetOptionsComponent,
    FacilityEtCurveWidgetComponent,
    FacilityEtCurveWidgetOptionsComponent,
    DerivedValueSingleSelectComponent,
    PowerWidgetComponent,
    PowerWidgetOptionsComponent,
    WidgetRelatedValueSelectComponent,
    TableSortDirective,
    FacilityWeeklyConsumptionWidgetComponent,
    FacilityWeeklyConsumptionWidgetOptionsComponent,
  ],
  imports: [
    CommonModule,
    GridsterModule,
    UIRouterUpgradeModule.forChild({ states: [dashboardState] }),
    SharedModule,
    EnergyManagementSharedModule,
    NgfTabsetModule,
    EkFormsModule,
    InputsModule,
    SliderModule,
    EkInputsModule,
    ChartsModule,
    IntlModule,
    EkDropdownModule,
    NgfTooltipModule,
    CommonPipesModule,
    EnergyReportingSharedModule,
    GridModule,
    AlarmsSharedModule,
    AnalyticsSharedModule
  ],
})
export class DashboardModule { }
